import { React, useState, useEffect } from 'react';
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';




export default function Topsellingwheels() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const navigate = useNavigate();
    const [isloading, setIsLoadingWheels] = useState(false);
    const [wheelData, setWheelData] = useState("");
    const [loaderShow, setLoaderShow] = useState(false);

    const S3_IMAGES = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";


    useEffect(() => {
        fetchWheelData();
    }, []);

    const fetchWheelData = async () => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/wheels/getTopSellingWheels`,
            };
            axios.request(config)
                .then((response) => {
                    setWheelData(response.data);
                    setIsLoadingWheels(true);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoadingWheels(true);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoadingWheels(true);

        }
    }

    const handleSubmit = (product_name, product_desc, sku) => {
        setLoaderShow(true)
        const item_name = product_name ? product_name : product_desc;
        let name = item_name.replace(/\s+|[\\/]/g, "-").toLowerCase();
        setTimeout(() => {
            navigate(`/wheels-product-details/${sku}`);
            setLoaderShow(false)
        }, 1000); // Delay of 2000 milliseconds (2 seconds)


    };

    return (
        <div>
            <section>
                <div className="main-tires-section">
                    <div className="container">
                        <h2 className="tyre-section-txt">Top Selling Wheels</h2>
                        <div className="row">
                            {
                                isloading && wheelData && wheelData.data && wheelData.data.length > 0 ?
                                    wheelData.data.map((item, index) => (
                                        <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={item.sku}>
                                            <div className="Drivetrain-cart ribbon_box position-relative">   
                                            {
                                                   item.msrp_usd && item.msrp_usd >= 100 ? (
                                                        <div className="ribbon"><span>Free Shipping</span></div>
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                                <div className="engle-imge-upper" onClick={() => handleSubmit(item.product_desc, item.product_name, item.sku)}>
                                                    {
                                                        !loaderShow ?
                                                            <button className="hover-btn " >  View Details </button>
                                                            :
                                                            <button className="hover-btn loading " > Loading  </button>
                                                    }


                                                    {
                                                        item.s3_images ?
                                                            (item.vendor_id == 5 ? <img className="" src={item.image_url1} alt="img" height="100%" width="100%" /> :  <img className="" src={S3_IMAGES + item.s3_images} alt="img" height="100%" width="100%" /> )
                                                            :

                                                            item.image_url1
                                                                ?
                                                                <img className="" src={item.image_url1} alt="img" height="100%" width="100%" />
                                                                :
                                                                <img className="w-100" src={S3_IMAGES + item.logo} alt="img" height="100%" width="100%" />

                                                    }
                                                </div>


                                                <div className="cart-body">
                                                    <h3 className='top_sell_cat_text'>{item.category_name}</h3>
                                                    <span className="m-0 description text-dark" >{item.vendor_id == 5 ? item.product_name : item.brand_desc + " " + item.style + " " + item.fancy_finish_desc + " " + item.size_desc + " " + item.wheel_offset +  'MM' + " " + item.bolt_pattern_metric.replace(/-/g, 'X')}</span>

                                                    <div className="price-cart">
                                                        <div className="price mt-2">
                                                            <strong>{item.sku}</strong>
                                                        </div>
                                                        <div className="price" title='Stock'>
                                                            {
                                                                item.quantity == 0 ?
                                                                    <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> {item.quantity}</span>
                                                                    :
                                                                    <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {item.quantity}</span>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="price-cart">
                                                        <div className="price">
                                                            <h4 className="price_tag m-0">${parseFloat(item.msrp_usd).toFixed(2)}</h4>
                                                            {/* <p className="m-0">$81<span>/month</span></p> */}
                                                        </div>
                                                        <div className="cart-btn">
                                                            <a href={`wheels?brandname=${item.brand_desc}&brandid=${item.brand_id}`}>
                                                                <img src={S3_IMAGES + item.logo} alt="img" height="100%" width="100%" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                    :
                                    Array.from({ length: 12 }).map((_, idx) => (
                                        <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={idx}>
                                            <div className="card-tyr">
                                                <div className="tyre-imge-upper">
                                                    <ShimmerThumbnail height={163} rounded />
                                                </div>
                                                <div className="cart-body">
                                                    <h3 className='top_sell_cat_text'><ShimmerButton size="sm" /></h3>
                                                    
                                                    <div className="price-cart">
                                                        <div className="price">
                                                            <h4 className="price_tag m-0"><ShimmerButton size="sm" /></h4>
                                                            <div className="m-0"><ShimmerButton size="sm" /></div>
                                                        </div>
                                                        {/* <div className="cart-btn">
                                                            <a href="w">
                                                                <ShimmerButton size="sm" />
                                                            </a>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    ))
                            }


                        </div>

                        <div className="view-catagirs-btn">
                            <a href='/wheels'>
                                <button className="vieww-all">View All</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
