import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function WheelsItemscard(prop) {
    const { data } = prop
    
    
    const S3_IMAGES = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const navigate = useNavigate();
    const [loaderShow, setLoaderShow] = useState(false);
    const handleSubmit = (event) => {
        setLoaderShow(true)
        const item_name = data.product_name ? data.product_name : data.product_desc;
        const sku = data.sku;
        let name = item_name.replace(/\s+|[\\/]/g, "-").toLowerCase();
        setTimeout(() => {
            navigate(`/wheels-product-details/${sku}`);
            setLoaderShow(false)
        }, 1000); // Delay of 2000 milliseconds (2 seconds)


    };


    const onSubmit = (item) => {
        // navigate('/brands/brands-products', { state: item });
        setTimeout(() => {
            // navigate(`/wheels-product-details/${sku}?${name}`);
            // setLoaderShow(false)
        }, 1000); // Delay of 2000 milliseconds (2 seconds)
    };

    return (
        <div className="col-lg-6 col-12 ">
            <div className="row Drivetrain-cart  wheelsPageDiv">
                <div className="col-lg-6 col-6 ">

                    <div className="wheelsPage leftSide">
                        <div className="engle-imge-upper" onClick={handleSubmit}>
                            {
                                !loaderShow ?
                                    <button className="hover-btn " >  View Details </button>
                                    :
                                    <button className="hover-btn loading " > Loading  </button>
                            }
                            {
                                data && data.image_url1 ?
                                    <img className="" src={data.image_url1} alt="" />
                                    :
                                    <img className="w-100 h-auto" src="/images/coming_soon.jpg" alt="" />

                            }
                        </div>

                        <div className="cart-body">
                            <p><strong>Brand: </strong>{data.brand_desc}</p>
                            <p><strong>Model:</strong>{data.style}</p>
                            <p><strong>Finish:</strong>{data.fancy_finish_desc}</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-6">
                    <div className="wheelsPage  rightSide">
                        <div className="" onClick={handleSubmit}>

                            <p> <strong>Front:</strong> {data.size_desc}</p>
                            <p><strong>Offset:</strong>{data.style}</p>
                            <p> <strong>SKU:</strong>{data.sku}</p>
                            <p className='priceWheel'><strong className='text-danger'>${parseFloat(data.msrp_usd).toFixed(2)}</strong>(each)</p>

                            <hr></hr>

                            <p> <strong>Rear:</strong> {data.size_desc}</p>
                            <p><strong>Offset:</strong>{data.style}</p>
                            <p> <strong>SKU:</strong>{data.sku}</p>
                            <p className='priceWheel'><strong className='text-danger'>${parseFloat(data.msrp_usd).toFixed(2)}</strong>(each)</p>

                            <hr></hr>

                            <p className='priceWheel'>
                                Set of 4 :<strong className='text-danger'>${parseFloat((data.msrp_usd*2)+(data.msrp_usd*2)).toFixed(2)}</strong>
                            </p>
                            

                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}
