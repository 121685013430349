import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function WheelsItemscard(prop) {
    const { data } = prop
    const S3_IMAGES="https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    let first_image_path = "";
    const navigate = useNavigate();
    const [loaderShow, setLoaderShow] = useState(false);
    const handleSubmit = (event) => {
        const ProductPage= true;
        localStorage.setItem('inProductPage', JSON.stringify(ProductPage));
        setLoaderShow(true)
        const item_name = `${data.brand_desc}  ${data.style}  ${data.fancy_finish_desc} ${data.size_desc} ${data.bolt_pattern_standard}  ${data.wheel_offset}mm` ;
        const sku = data.sku;
        let name = item_name.replace(/\s+|[\\/]/g, "-").toLowerCase();
        setTimeout(() => {
            navigate(`/wheels-product-details/${sku}`);
            setLoaderShow(false)
        }, 1000); // Delay of 2000 milliseconds (2 seconds)


    };
    let s3_images = data.s3_images
    let image = data.image_url1;
    let logo = data.logo;
    if(s3_images && s3_images.trim() !== ""){
        let imageArray = s3_images.split(',');
        first_image_path =  S3_IMAGES +  imageArray[0];
    }   
    else if (image &&  image.trim() !== "") {
        first_image_path =  image;
    } else if (logo) {
        first_image_path = S3_IMAGES +  logo;
    }
//    console.log('first_image_path',first_image_path);

    // const onSubmit = (item) => {
    //     // navigate('/brands/brands-products', { state: item });
    //     setTimeout(() => {
    //         // navigate(`/wheels-product-details/${sku}?${name}`);
    //         // setLoaderShow(false)
    //     }, 1000); // Delay of 2000 milliseconds (2 seconds)
    // };


    let bolt_pattern_metric = data.bolt_pattern_metric.replace(/-/g, 'X');
    let product_name = `${data.brand_desc}  ${data.style}  ${data.fancy_finish_desc} ${data.size_desc}  ${data.wheel_offset}MM  ${bolt_pattern_metric}`
    return (
        <div className="col-lg-3 col-6">
            
            <div className="Drivetrain-cart wheelsPage">
            {
                        data &&  data.msrp_usd >= 100 ? (
                                <div className="ribbon"><span>Free Shipping</span></div>
                            ) : (
                                ""
                            )
                        }

                <div className="engle-imge-upper" onClick={handleSubmit}>
                    {
                        !loaderShow ?
                            <button className="hover-btn " >  View Details </button>
                            :
                            <button className="hover-btn loading " > Loading  </button>
                    }


                    {
                        data && first_image_path ?
                            <img className="" src={first_image_path} alt="img" height="100%" width="100%" />
                            :
                            <img className="w-100 h-auto" src="/images/coming_soon.jpg" alt="img" height="100%" width="100%" />

                    }
                </div>

                <div className="cart-body">
                    <span onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                        
                        <span className="m-0 description text-dark" >{data.vendor_id == 5 || (data.vendor_id == 4 && data.wheel_offset == null) ? data.product_name : data.vendor_id == 7 && data.product_name == "ESR - -  0MM 5X105" ? data.product_desc : product_name}</span>
                    </span>

                    <div className="price-cart">
                        <div className="price mt-2">
                            <strong>{data.sku}</strong>
                        </div>
                        <div className="price" title='Stock'>
                            {
                                data.quantity == 0 ?
                                    <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> {data.quantity}</span>
                                    :
                                    <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {data.quantity}</span>
                            }

                        </div>
                    </div>



                    <div className="price-cart mt-3">

                        <div className="price">
                            {
                                data && data.map_usd ?
                                    <h4 className="m-0">${data.map_usd && data.map_usd > 0 ?parseFloat( data.map_usd).toFixed(2) : data.msrp_usd}</h4>
                                    :
                                    <h5 className="m-0">${parseFloat(data.msrp_usd).toFixed(2)}</h5>
                            }

                            {/* <h5 className="m-0">{price}</h5> */}
                        </div>

                        <div className="cart-btn">
                            <a href={`/wheels?brandname=${data.brand_desc}&brandid=${data.brand_id}`}

                                // onClick={() => onSubmit(data.brand_id)} // Pass the item to onSubmit function
                                style={{ cursor: 'pointer' }}
                            >
                                {
                                    data.logo ?
                                        <img src={S3_IMAGES + data.logo} alt="img" height="100%" width="100%" />
                                        :
                                        <img src="/images/no-image-found.png" alt="img" height="100%" width="100%" />

                                }
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    )
}
