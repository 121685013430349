import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function WheelsItemscard(prop) {
    const S3_IMAGES = "https://extremewheelsdevelopment.s3.amazonaws.com/"

    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const { data, staggered } = prop
    const navigate = useNavigate();
    const [loaderShow, setLoaderShow] = useState(false);
    const category_type = 'wheel';

    const vehical_details = JSON.parse(localStorage.getItem('vehical_details'));
    const year = vehical_details ? vehical_details.year : "";
    const make_name = vehical_details ? vehical_details.make_name : "";
    const model_name = vehical_details ? vehical_details.model_name : "";
    const variant_name = vehical_details ? vehical_details.variant_name : "";

    const handleSubmit = () => {
        setLoaderShow(true)
        const item_name = data[0].product_name ? data[0].product_name : data[0].product_desc;
        const sku = data[0].sku;
        let name = item_name.replace(/\s+|[\\/]/g, "-").toLowerCase();
        setTimeout(() => {
            navigate(`/wheels-product-details/${sku}`);
            setLoaderShow(false)
        }, 1000); // Delay of 2000 milliseconds (2 seconds)


    };

    let brand_desc = data[0].brand_desc;
    let style = data[0].style;
    let fancy_finish_desc = data[0].fancy_finish_desc;
    let size_desc = data[0].size_desc;
    let bolt_pattern_metric_ = data[0].bolt_pattern_metric;
    let wheel_offset = data[0].wheel_offset;
    let vendor_id = data[0].vendor_id;

    let brand_desc2 = data[1].brand_desc;
    let style2 = data[1].style;
    let fancy_finish_desc2 = data[1].fancy_finish_desc;
    let size_desc2 = data[1].size_desc;
    let bolt_pattern_metric_2 = data[1].bolt_pattern_metric;
    let wheel_offset2 = data[1].wheel_offset;
    let vendor_id2 = data[1].vendor_id;



    let product_name_new = `${brand_desc}  ${style}  ${fancy_finish_desc} ${size_desc} ${bolt_pattern_metric_}  ${wheel_offset}MM`
    let product_name_new2 = `${brand_desc2}  ${style2}  ${fancy_finish_desc2} ${size_desc2} ${bolt_pattern_metric_2}  ${wheel_offset2}MM`


    const addItemstoCaart = (product_id, product_name, total_quantity, image, price) => {

        try {
            let product_name_ = product_name_new;
            if (year) {
                product_name_ = product_name_new + "(" + year + ", " + make_name + ", " + model_name + ", " + variant_name + ")";

            }
            const cartItems = JSON.parse(localStorage.getItem('totalCartItems')) || {};
            const itemId = product_id;
            const existingValue = cartItems[itemId] || 0;
            const updatedValue = existingValue + 4;
            const invnetoryStock = total_quantity
            // Check if the updated value exceeds the stockInventory
            if (updatedValue > invnetoryStock) {
                toast.info("Cannot add more items than available in stock!", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }
            cartItems[itemId] = updatedValue;
            localStorage.setItem('totalCartItems', JSON.stringify(cartItems));


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/add-to-cart`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {
                    product_image: image,
                    product_id: product_id,
                    product_name: product_name_,
                    product_price: price,
                    product_quantity: 4,
                    category_type: category_type,
                    total_quantity: total_quantity,
                    part_number: product_id,
                    vendor_id: vendor_id
                }
            };
            axios
                .request(config)
                .then((response) => {
                    localStorage.setItem('cartItemsArray', JSON.stringify(response.data.cart));
                    toast.success("Item Added to cart!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        navigate(`/add-cart`);
                    }, 1000);


                })
                .catch((error) => {
                    console.log(error);

                });
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    }
    const addItemstoCaart3 = (product_id, product_name, total_quantity, image, price) => {

        try {

            let product_name_ = product_name_new2;
            if (year) {

                product_name_ = `${product_name_new2}\n("${year}, ${make_name}, ${model_name}, ${variant_name}")`;

            }
            const cartItems = JSON.parse(localStorage.getItem('totalCartItems')) || {};
            const itemId = product_id;
            const existingValue = cartItems[itemId] || 0;
            const updatedValue = existingValue + 2;
            const invnetoryStock = total_quantity
            // Check if the updated value exceeds the stockInventory
            if (updatedValue > invnetoryStock) {
                toast.info("Cannot add more items than available in stock!", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }
            cartItems[itemId] = updatedValue;
            localStorage.setItem('totalCartItems', JSON.stringify(cartItems));


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/add-to-cart`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {
                    product_image: image,
                    product_id: product_id,
                    product_name: product_name_,
                    product_price: price,
                    product_quantity: 2,
                    category_type: category_type,
                    total_quantity: total_quantity,
                    part_number: product_id,
                    vendor_id: vendor_id2
                }
            };
            axios
                .request(config)
                .then((response) => {
                    localStorage.setItem('cartItemsArray', JSON.stringify(response.data.cart));
                    toast.success("Item Added to cart!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        navigate(`/add-cart`);
                    }, 1000);

                })
                .catch((error) => {
                    console.log(error);

                });
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    }

    const addItemstoCaart2 = (product_id, product_name, total_quantity, image, price, product_id2, product_name2, total_quantity2, image2, price2) => {

        try {

            let product_name_ = product_name_new;
            if (year) {
                product_name_ = product_name_new + "(" + year + ", " + make_name + ", " + model_name + ", " + variant_name + ")";

            }

            const cartItems = JSON.parse(localStorage.getItem('totalCartItems')) || {};
            const itemId = product_id;
            const existingValue = cartItems[itemId] || 0;
            const updatedValue = existingValue + 2;
            const invnetoryStock = total_quantity
            // Check if the updated value exceeds the stockInventory
            if (updatedValue > invnetoryStock) {
                toast.info("Cannot add more items than available in stock!", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }
            cartItems[itemId] = updatedValue;
            localStorage.setItem('totalCartItems', JSON.stringify(cartItems));


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/add-to-cart`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {
                    product_image: image,
                    product_id: product_id,
                    product_name: product_name_,
                    product_price: price,
                    product_quantity: 2,
                    category_type: category_type,
                    total_quantity: total_quantity,
                    part_number: product_id,
                    vendor_id: vendor_id
                }
            };
            axios
                .request(config)
                .then((response) => {
                    localStorage.setItem('cartItemsArray', JSON.stringify(response.data.cart));
                    addItemstoCaart3(product_id2, product_name2, total_quantity2, image2, price2);
                    // navigate(`/add-cart`);


                })
                .catch((error) => {
                    console.log(error);

                });
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    }



    return (
        <div className="col-lg-6 col-12 ">
            <div className="row Drivetrain-cart  wheelsPageDiv">
                {
                    data && data[0].msrp_usd >= 100 ? (
                        <div className="ribbon"><span>Free Shipping</span></div>
                    ) : (
                        ""
                    )
                }
                <div className="col-lg-6 col-6 ">

                    <div className="wheelsPage leftSide">
                        <div className="engle-imge-upper">
                            {/* {
                                !staggered ?
                                    !loaderShow ?
                                        <button className="hover-btn " onClick={handleSubmit}>  View Details </button>
                                        :
                                        <button className="hover-btn loading " > Loading  </button>
                                    : ""
                            } */}
                            {
                                data && data[0].image_url1 ?
                                    <img className="" src={data[0].image_url1} alt="" />
                                    :
                                    <img className="w-100 h-auto" src="/images/coming_soon.jpg" alt="" />

                            }
                        </div>

                        <div className="cart-body">
                            <p><strong>Brand: </strong>{data[0].brand_desc}</p>
                            <p><strong>Model:</strong>{data[0].style}</p>
                            <p><strong>Finish:</strong>{data[0].fancy_finish_desc}</p>
                            <div className='tire_logo_cart_div'>
                                {
                                    !staggered ?
                                        <div className="cart-div">

                                            <span className='btn btn-danger btn-sm' onClick={() => addItemstoCaart(data[0].sku, data[0].product_desc, data[0].quantity, data[0].image_url1, data[0].msrp_usd)}>Add to Cart</span>
                                        </div>

                                        :
                                        <div className="cart-div">

                                            <span className='btn btn-danger btn-sm' onClick={() => addItemstoCaart2(data[0].sku, data[0].product_desc, data[0].quantity, data[0].image_url1, data[0].msrp_usd, data[1].sku, data[1].product_desc, data[1].quantity, data[1].image_url1, data[1].msrp_usd)}>Add to Cart</span>
                                        </div>
                                }
                                <div className='tire_logo'>
                                    <img className="" src={S3_IMAGES + data[0].logo} alt="Brand Logo" />
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

                <div className="col-lg-6 col-6">
                    <div className="wheelsPage  rightSide">
                        <div className="">

                            <p> <strong>Front:</strong> {data[0].size_desc}</p>
                            <p><strong>Offset:</strong>{data[0].wheel_offset}</p>

                            <p> <strong>Lug Pattern:</strong>{data[0].bolt_pattern_metric}</p>
                            <p className='wheelSku'> <strong >SKU:</strong>{data[0].sku}</p>
                            <p className='priceWheel'>
                                <span><strong className='text-danger'>${parseFloat(data[0].msrp_usd).toFixed(2)}</strong>(each)</span>

                                {
                                    data[0].quantity > 0 ?
                                        <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {data[0].quantity}</span>

                                        :
                                        <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> 0 </span>
                                }


                            </p>
                            <p> <span><a href={`/wheels-product-details/${data[0].sku}`} target='_blank'>More Details...</a></span></p>
                            <hr></hr>

                            <p> <strong>Rear:</strong> {data[1].size_desc}</p>
                            <p><strong>Offset:</strong>{data[1].wheel_offset}</p>
                            <p> <strong>Lug Pattern:</strong>{data[1].bolt_pattern_metric}</p>
                            <p className='wheelSku'> <strong>SKU:</strong>{data[1].sku}</p>

                            <p className='priceWheel'>
                                <span><strong className='text-danger'>${parseFloat(data[1].msrp_usd).toFixed(2)}</strong>(each)</span>
                                {
                                    data[1].quantity > 0 ?
                                        <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {data[1].quantity}</span>

                                        :
                                        <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> 0 </span>
                                }
                            </p>
                            <p> <span><a href={`/wheels-product-details/${data[1].sku}`} target='_blank'>More Details...</a></span></p>
                            <hr></hr>

                            <p className='priceWheel'>
                                Set of 4 :<strong className='text-danger'>${parseFloat((data[0].msrp_usd * 2) + (data[1].msrp_usd * 2)).toFixed(2)}</strong>
                            </p>


                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}
