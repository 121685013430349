import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import SERVICES from "../../components/Footer/services";
import { Helmet } from 'react-helmet';




export default function Aboutus() {

    const currentLink = window.location.href;
    const url = new URL(currentLink);
    const updatedUrl = url.toString();


    const [isHidden, setIsHidden] = useState(false);
    const [cartItems, setCartItems] = useState("");
    const [showMenuSide, setShowMenuSide] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);

    }, []);


    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    return (
        <>

            <Helmet>
                <title>Blog</title>
                <meta name="description" content='Getting The Best Tonneau Cover For Your Pickup Truck ' />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>


            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />

            <section>
                <div className="warrent-banner blog-details">
                    <div className="parformane-bar ">
                        <div className="container">
                            <ul className="p-0 m-0 listing-parmofamne">
                                <li>
                                    <a href="#">Home /</a>
                                </li>
                                <li className="active-bread">
                                    <a href="">Finding Custom Tonneau Cover Options To Match Your Trucks’ Wheels & Rims</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='blog_detail_main'>
                    <div className='container'>
                        <div className='content-main'>
                            <h2 className='blog_title'>Finding Custom Tonneau Cover Options To Match Your Trucks’ Wheels & Rims</h2>
                            <div className='dev_details'>
                                <span className='author'>by dev</span>
                                <span className='date'>May 31, 2022</span>
                                <span className='category'>Wheels</span>
                                <span className='commnt'><span className='cmmnt_num'>0</span> comments</span>
                            </div>
                            <div className='blog_banner'>
                                <img src="https://w8f8j4d9.rocketcdn.me/wp-content/uploads/2022/05/Finding-Custom-Tonneau-Cover-Options-To-Match-Your-Trucks-Wheels-Rims-1-1080x675.jpg" alt="Blog Banner" height="100%" width="100%" />
                            </div>
                            <div className='blog_content'>
                                <h2 className='main_title'>Getting The Best Tonneau Cover For Your Pickup Truck</h2>
                                <p>Are you ready to purchase a tonneau cover but unsure about the size of the truck bed? Don’t worry, there’s help. It’s not difficult to know that if you have a measuring tape. Experts in <a href='https://truckaccessplus.com/tonneau-covers.html' target='_blank'>tonneau covers in Arizona</a> will help you find out how to measure your pickup truck for a tonneau cover without much hassle.</p>
                                <p>Truck bed covers were created to have a snug fit. The point of using a tonneau cover is to protect your truck bed and the cargo. Therefore, it’s extremely important to know the right length of the truck bed.</p>
                                <img src="https://w8f8j4d9.rocketcdn.me/wp-content/uploads/2022/05/Finding-Custom-Tonneau-Cover-Options-To-Match-Your-Trucks-Wheels-Rims-2.jpg" alt='image' width="100%" height="100%" />
                                <h3 className='sub_headings'>Measuring Your Truck Bed The Right Way</h3>
                                <p>You need a measuring tape to do this, it must be at least 8 - 19 feet long. You also need something to write down the measurements.</p>
                                <p>Once you have your tools ready, follow these steps:</p>
                                <ul>
                                    <li>Stand behind your truck to lower the tailgate.</li>
                                    <li>Extend the measuring tape from the floor of the bed until the bulkhead wall.</li>
                                    <li>Measure at the end of the floor of the bed (where you see the gap).</li>
                                    <li>Divide by 12 to convert the measurement from inches to feet.</li>
                                </ul>
                                <p>Manufacturers normally round off the length of the tonneau to an even number, so be sure to do it within a few inches of that. Your measurement doesn’t have to be exact to what you see when buying a tonneau.</p>
                                <p>These steps are helpful to find the right size of <a href="https://truckaccessplus.com/" target='_blank'>tonneau covers in Phoenix</a>. However, there are other tricks you can consider.</p>
                                <h3 className='sub_headings'>Other Ways To Check The Length Of a Tonneau Bed</h3>
                                <p>You can use some websites to verify the tonneau you want. To do so, simply select your truck make, model, and year to see which tonneau is the best for you.</p>
                                <p>You can also know your truck bed length from when you bought your truck. You can simply ask the store about the length of your truck bed. They usually have the entire information about your truck.</p>
                                <p>Even if you know the specs of your truck bed, make sure to double-check the measurements. It’s the best way to ensure you’re calculating the right size. Besides, if you have other accessories there, you will have to measure them for yourself.</p>
                                <p>Most truck manufacturers don’t have standardized sizes. Some manufacturers prefer to list their bed lengths in feet and others in inches. Terms like “short bed” or “long bed” mean different things for different truck makers, so you should not use them as a general reference.</p>
                                <p>As we previously mentioned, you only need a measurement within an inch or two of what your tonneau is listed. That should be enough.</p>
                                <h3 className='sub_headings'>Things To Consider When Measuring Tonneau Covers In Phoenix</h3>
                                <p>You may be ready with your measurements, but there are other things to think about before purchasing a tonneau cover.</p>
                                <p>You don’t want to return a tonneau that doesn’t fit, so make sure you have the right measurements, and consider the following:</p>
                                <ul>
                                    <li><b>Don’t forget your built-in or after-market accessories.</b> If you have them, you may need to choose a specific tonneau cover.</li>
                                    <li><b>Do you have a bed liner?</b> This item can impact the space of your tonneau cover. Measure the space inside the bed liner.</li>
                                    <li><b>Remember your budget!</b> Fiberglass hard-top tonneaus have average prices of $1,500 and go up from there. Soft tonneaus are less expensive, with a $200 - $900 price range.</li>
                                </ul>
                                <h3 className='sub_headings'>How Can I Know The Best Material For My Tonneau Cover?</h3>
                                <p>Material and style are also important when choosing a tonneau cover. There are two main options:</p>
                                <ul>
                                    <li><b>Soft Tonneau Covers:</b> They are the most popular category because they are more affordable. They are also lighter and easier to install. Soft tonneau covers come in different styles, including rolling, retractable, expandable, etc.</li>
                                    <li><b>Hard Tonneau Covers:</b> They are made of aluminum, fiberglass, or hard plastic, and they are more expensive than soft tonneau covers. Since they are heavier, they require more than one person to install.</li>
                                </ul>
                                <p>What Should I Do Next?</p>
                                <p>Now that you know the basics, measure your truck bed and find a nice tonneau cover. We recommend you <a href='https://truckaccessplus.com/contact-us/' target='_blank'>contact a reliable automobile store</a> to obtain the best accessories for your truck!</p>
                                <p className='italic'>This article is courtesy of <a href='https://truckaccessplus.com/' target='_blank'>Truck Access Plus</a>, a top-rated company that specializes in providing numerous truck accessories in Phoenix, Arizona.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <SERVICES />
            < FOOTER />
        </>

    )
}
