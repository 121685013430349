import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function Itemcards(data) {
    const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const S3_IMAGES = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/"
    const navigate = useNavigate();
    let S3ImagesData = data.data.s3_images;
    let ImagesData = data.data.images;
    let logo = data.data.brand_logo;
    let first_image_path = "";
    if (S3ImagesData.length > 0) {
        first_image_path = S3URL + S3ImagesData[0];
    } else if (ImagesData.length > 0) {
        first_image_path = ImagesData[0];
    }
    else {
        first_image_path = logo
    }
    // console.log("newdata",data);
    const [loaderShow, setLoaderShow] = useState(false);
    const handleSubmit = (event) => {
        setLoaderShow(true)
     
        const item_name = data.data.part_description ? data.data.part_description : data.data.product_name;
        const item_id = data.data.item_id;
        let name = item_name.replace(/\s+|[\\/]/g, "-").toLowerCase();
        let vendor_id =  data.data.vendor_id;
        if (vendor_id == 2 || vendor_id == 4 || vendor_id == 5 || vendor_id == 6) {

            setTimeout(() => {
                navigate(`/wheels-product-details/${item_id}`);
                document.body.classList.remove('open_search');
                // setSideActive('')
                window.location.reload(); // Perform a full page refresh
                setLoaderShow(false)
            }, 1000); // Delay of 2000 milliseconds (2 seconds)

        }
        else if (vendor_id == 0) {
            let state = {
                item_id: item_id,
            }
            setTimeout(() => {
                navigate(`/custom-product-details/${item_id}`, { state: state });
                document.body.classList.remove('open_search');
                // setSideActive('')
                window.location.reload(); // Perform a full page refresh
                setLoaderShow(false)
            }, 1000); // Delay of 2000 milliseconds (2 seconds)
        }

        else if (vendor_id == 3) {
            const str = item_id;
            const numberBeforeUnderscore = parseInt(str.split('_')[0]);
            let state = {
                item_id: numberBeforeUnderscore,
            }
            setTimeout(() => {
                navigate(`/tires-details/${numberBeforeUnderscore}?`, { state: state });
                document.body.classList.remove('open_search');
                // setSideActive('')
                window.location.reload(); // Perform a full page refresh
                setLoaderShow(false)
            }, 1000); // Delay of 2000 milliseconds (2 seconds)
        }

        else {
            let state = {
                item_id: data.data.item_id,
                subcategory_name: data.data.subcategory_name,
                category_name: data.data.category_name,
    
            }
            setTimeout(() => {
                navigate(`/product-details/${item_id}?${name}`, { state: state });
                document.body.classList.remove('open_search');
                // setSideActive('')
                window.location.reload(); // Perform a full page refresh
                setLoaderShow(false)
            }, 1000); // Delay of 2000 milliseconds (2 seconds)
        }


      

    };


    const onSubmit = (item) => {

        navigate('/brands/brands-products', { state: item });


    };


    let actualPrice = data.data.price;
    // console.log( " typroffsdfsdfdsf",data.data.price)
    //5% off
    let discount = 0.05; // 5% discount
    let discountedPrice = actualPrice - (actualPrice * discount);
//    console.log( " typrof", typeof data.data.price)
    
    return (
        <div className="col-lg-3 col-6">
            <div className="Drivetrain-cart ribbon_box">
            {
                        data.data.price &&  data.data.price >= 100 ? (
                                <div className="ribbon"><span>Free Shipping</span></div>
                            ) : (
                                ""
                            )
                        }
           
                <div className="engle-imge-upper" onClick={handleSubmit}>
                    {
                        !loaderShow ?
                            <button className="hover-btn " >  View Details </button>
                            :
                            <button className="hover-btn loading " > Loading  </button>
                    }


               {
                        data.data.category_name === 'CustomeProduct' ? (
                            <img src={ data.data.image} alt="img" height="100%" width="100%" />
                        ) :  data.data.category_name === 'Wheels' ||  data.data.category_name === 'WHEELS' ? (

                            <img src={ data.data.image &&  data.data.image.startsWith('https://') ?  data.data.image :  data.data.image ? S3_IMAGES +  data.data.image.split(',')[0] : S3_IMAGES +  data.data.brand_logo} alt="img" height="100%" width="100%" />

                        ) :  data.data.category_name === 'tires_wp' ? (
                            <img src={ data.data.image} alt="img" height="100%" width="100%" />
                        ) : (
                            <img
                                src={ data.data.s3_images.length > 0 ? S3_IMAGES +  data.data.s3_images[0] : S3_IMAGES +  data.data.brand_logo}
                                alt="img"
                                height="100%"
                                width="100%"
                            />
                        )
                    }
                </div>

                <div className="cart-body">
                    <span onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                        <h4>{data.data.subcategory_name}</h4>
                        <span className="m-0 description text-dark" >{data.data.part_description ? data.data.part_description : data.data.product_name}</span>
                    </span>

                    <div className="price-cart">
                        <div className="price mt-2">
                            <strong>{data.data.part_number}</strong>
                        </div>
                        <div className="price" title='Stock'>
                            {
                                data.data.stock > 0 ?
                                    <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {data.data.stock}</span>

                                    :
                                    <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> 0 </span>
                            }

                        </div>
                    </div>



                    <div className="price-cart">
                        <div className="price">
                            {
                                data.data && data.data.price ?

                                    <h5 className="m-0  price_card_acc">
                                        
                                        <span  className='price_in_card_two'>${(parseFloat(data.data.price) + parseFloat(data.data.surcharge)).toFixed(2)} </span>
                                        {/* <s  className='price_in_card_one'>${parseFloat(actualPrice).toFixed(2)}</s> */}
                                        
                                    </h5>


                                    :
                                    <h5 className="m-0">N.A</h5>
                            }
                        </div>

                        <div className="cart-btn">
                            <a href={`/categories-home/brand-category?brandname=${data.data.brand}&brandid=${data.data.brand_id}`}

                                // onClick={() => onSubmit(data.data.brand_id)} // Pass the item to onSubmit function
                                style={{ cursor: 'pointer' }}
                            >
                             
                                {
                                     data.data.brand_logo ?
                                     data.data.category_name != 'Wheels' ?
                                    <img src={S3_IMAGES + data.data.brand_logo} alt="img" height="100%" width="100%" />
                                     : <img src={S3_IMAGES + data.data.brand_logo} alt="img" height="100%" width="100%" />
                                    :
                                    <img src="/images/no-image-found.png" alt="img" height="100%" width="100%" />

                                 }
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    )
}
