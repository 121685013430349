import { React, useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Loader } from 'rsuite';





export default function Category() {
    const S3_Images_local="https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/local_images/"

    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;

    const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
    const [selectedYear, setSelectedYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [selectedMake, setSelectedMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [selectedMakeName, setSelectedMakeName] = useState(vehicalDetails ? vehicalDetails.make_name : "");
    const [selectedModel, setSelectedModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [selectedModelName, setSelectedModelName] = useState(vehicalDetails ? vehicalDetails.model_name : "");
    const [selectedVariant, setSelectedVariant] = useState(vehicalDetails ? vehicalDetails.variant : "");
    const [selectedVariantName, setSelectedVariantName] = useState(vehicalDetails ? vehicalDetails.variant_name : "");

    const navigate = useNavigate();
    const [dataCategory, setDataCategory] = useState([]);
    const [isLoadingCategory, setIsLoadingCategory] = useState(true);
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);


    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');


    const handleShow = (id, cat) => {
        localStorage.removeItem('setSelectedFitmentType');
        localStorage.removeItem('selectedCategories');
        localStorage.removeItem('selectedCategoriesName');
        localStorage.removeItem('selectedBrands');
        localStorage.removeItem('selectedBrandsName');
        localStorage.removeItem('setWheelOffsetArray');
        localStorage.removeItem('setColourWheel');
        localStorage.removeItem('setStockWheel');
        localStorage.removeItem('setDiameterArray');
        localStorage.removeItem('setWidthArray');
        localStorage.removeItem('setPatternArray');
        localStorage.removeItem('setBradArray');
        localStorage.removeItem('setBradNameArray');
        localStorage.removeItem('setcategoryArray');
        localStorage.removeItem('setcategoryNameArray');
        localStorage.removeItem('setModelArray');
        localStorage.removeItem('setMaxPrice');
        localStorage.removeItem('setMinPrice');
        localStorage.removeItem('setFirstValue');
        localStorage.removeItem('setSecondValue');
        localStorage.removeItem('setSpeedLoadRange');
        localStorage.removeItem('setSpeedRatingArray');
        localStorage.removeItem('setratioArray');
        localStorage.removeItem('setPlyArray');
        localStorage.removeItem('setSideWallArray');
        localStorage.removeItem('setMilageRatingArray');
        localStorage.removeItem('setRunFlat');
        localStorage.removeItem('setstuddable');
        localStorage.removeItem('settiresizes');
        localStorage.removeItem('setIsChecked');
        localStorage.removeItem('setIsCheckedStock');
        localStorage.removeItem('setSortingValue');
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        localStorage.removeItem('inProductPage');
  
        // setShow(true);
        // Perform desired action with the id
        setTimeout(() => {
            navigate(`/categories-home?id=${id}&cat=${cat}`);
        }, 1000);
    };
    const handleClose = () => {
        setShow(false);
        setSelectedCheckboxes([]);
        setIsLoadingCategory(true);
    };

 
    
    const handleChange = () => {
        localStorage.removeItem('setSelectedFitmentType');
        localStorage.removeItem('selectedCategories');
        localStorage.removeItem('selectedCategoriesName');
        localStorage.removeItem('selectedBrands');
        localStorage.removeItem('selectedBrandsName');
        localStorage.removeItem('setWheelOffsetArray');
        localStorage.removeItem('setColourWheel');
        localStorage.removeItem('setStockWheel');
        localStorage.removeItem('setDiameterArray');
        localStorage.removeItem('setWidthArray');
        localStorage.removeItem('setPatternArray');
        localStorage.removeItem('setBradArray');
        localStorage.removeItem('setBradNameArray');
        localStorage.removeItem('setcategoryArray');
        localStorage.removeItem('setcategoryNameArray');
        localStorage.removeItem('setModelArray');
        localStorage.removeItem('setMaxPrice');
        localStorage.removeItem('setMinPrice');
        localStorage.removeItem('setFirstValue');
        localStorage.removeItem('setSecondValue');
        localStorage.removeItem('setSpeedLoadRange');
        localStorage.removeItem('setSpeedRatingArray');
        localStorage.removeItem('setratioArray');
        localStorage.removeItem('setPlyArray');
        localStorage.removeItem('setSideWallArray');
        localStorage.removeItem('setMilageRatingArray');
        localStorage.removeItem('setRunFlat');
        localStorage.removeItem('setstuddable');
        localStorage.removeItem('settiresizes');
        localStorage.removeItem('setIsChecked');
        localStorage.removeItem('setIsCheckedStock');
        localStorage.removeItem('setSortingValue');
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        localStorage.removeItem('inProductPage');
        setLoader(true);
        setTimeout(() => {
            navigate(`/categories-home/all-category`);
        }, 1000);

    }
    const handleChangePage = (id) => {
        setLoader(true);
        setTimeout(() => {
            navigate(`/products/${id}`);
            setLoader(false);

        }, 1000);

    }
    const emptyLocalStorage = () => {
       
        localStorage.removeItem('setSelectedFitmentType');
        localStorage.removeItem('selectedCategories');
        localStorage.removeItem('selectedCategoriesName');
        localStorage.removeItem('selectedBrands');
        localStorage.removeItem('selectedBrandsName');
        localStorage.removeItem('setWheelOffsetArray');
        localStorage.removeItem('setColourWheel');
        localStorage.removeItem('setStockWheel');
        localStorage.removeItem('setDiameterArray');
        localStorage.removeItem('setWidthArray');
        localStorage.removeItem('setPatternArray');
        localStorage.removeItem('setBradArray');
        localStorage.removeItem('setBradNameArray');
        localStorage.removeItem('setcategoryArray');
        localStorage.removeItem('setcategoryNameArray');
        localStorage.removeItem('setModelArray');
        localStorage.removeItem('setMaxPrice');
        localStorage.removeItem('setMinPrice');
        localStorage.removeItem('setFirstValue');
        localStorage.removeItem('setSecondValue');
        localStorage.removeItem('setSpeedLoadRange');
        localStorage.removeItem('setSpeedRatingArray');
        localStorage.removeItem('setratioArray');
        localStorage.removeItem('setPlyArray');
        localStorage.removeItem('setSideWallArray');
        localStorage.removeItem('setMilageRatingArray');
        localStorage.removeItem('setRunFlat');
        localStorage.removeItem('setstuddable');
        localStorage.removeItem('settiresizes');
        localStorage.removeItem('setIsChecked');
        localStorage.removeItem('setIsCheckedStock');
        localStorage.removeItem('setSortingValue');
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        localStorage.removeItem('inProductPage');
  

        
        

    }
    const redirectToWheelPage = () => {
        localStorage.removeItem('setSelectedFitmentType');
        localStorage.removeItem('selectedCategories');
        localStorage.removeItem('selectedCategoriesName');
        localStorage.removeItem('selectedBrands');
        localStorage.removeItem('selectedBrandsName');
        localStorage.removeItem('setWheelOffsetArray');
        localStorage.removeItem('setColourWheel');
        localStorage.removeItem('setStockWheel');
        localStorage.removeItem('setDiameterArray');
        localStorage.removeItem('setWidthArray');
        localStorage.removeItem('setPatternArray');
        localStorage.removeItem('setBradArray');
        localStorage.removeItem('setBradNameArray');
        localStorage.removeItem('setcategoryArray');
        localStorage.removeItem('setcategoryNameArray');
        localStorage.removeItem('setModelArray');
        localStorage.removeItem('setMaxPrice');
        localStorage.removeItem('setMinPrice');
        localStorage.removeItem('setFirstValue');
        localStorage.removeItem('setSecondValue');
        localStorage.removeItem('setSpeedLoadRange');
        localStorage.removeItem('setSpeedRatingArray');
        localStorage.removeItem('setratioArray');
        localStorage.removeItem('setPlyArray');
        localStorage.removeItem('setSideWallArray');
        localStorage.removeItem('setMilageRatingArray');
        localStorage.removeItem('setRunFlat');
        localStorage.removeItem('setstuddable');
        localStorage.removeItem('settiresizes');
        localStorage.removeItem('setIsChecked');
        localStorage.removeItem('setIsCheckedStock');
        localStorage.removeItem('setSortingValue');
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        localStorage.removeItem('inProductPage');
        const apiKey = 'qYDXq4NfU3U8faPscasr06bXuKD5RJ1Z';

        if (selectedYear) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://api.fitmentatlas.com/v1.1/services/Vehicles/smartsizes/?YearId=${selectedYear}&MakeId=${selectedMake}&ModelId=${selectedModel}&ProductType=wheel&SubmodelID=${selectedVariant}`,
                headers: {
                    'FG-ApiKey': apiKey
                }
            };

            axios.request(config)
                .then((response) => {
                    let staggered = false;
                    let fmkWithStaggered = null;
                    // Find the object with isStaggered: true and get its fmk
                    for (const obj of response.data.result) {
                        if (obj.isStaggered === true) {
                            fmkWithStaggered = obj.fmk;
                            staggered = true;
                            break; // Break the loop once we find the first object with isStaggered: true
                        }
                    }

                    // If no object has isStaggered: true, get the fmk from the first object in the array
                    if (fmkWithStaggered === null && response.data.result.length > 0) {
                        fmkWithStaggered = response.data.result[0].fmk;
                    }

                   
                    

                    if (staggered) {
                        navigate(`/wheels-search-by-vehical/staggered?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`);
                    } else {
                        navigate(`/wheels-search-by-vehical?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });


        } else {
            setTimeout(() => {
                navigate(`/wheels`);
                setLoader(false);

            }, 1000);
        }

    }
    return (
        <div>
            <section className='categoriesSectionMain'>
                <div className="container">
                    <h2 className="categrories-txt">Categories</h2>

                    <div className=''>
                        <p>
                            <strong style={{ borderBottom: '2px solid black', fontSize: '30px' }}>
                                {selectedYear} {selectedMakeName}{selectedModelName} {selectedVariantName}
                            </strong>
                        </p>
                    </div>


                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                        <a className="text-decoration-none" href="/wheels">
                            <div className="categrories" onClick={redirectToWheelPage}>
                                <img src={`${S3_Images_local}category/Wheels.svg`} alt="img" height="100%" width="100%" />
                            </div>
                            <h3 className="catigrious-name">Wheels</h3>
                            </a>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <a className="text-decoration-none" href="/tires" onClick={emptyLocalStorage}>
                                <div className="categrories">
                                    <img src={`${S3_Images_local}category/Tires.svg`} alt="img" height="100%" width="100%" />
                                </div>
                                <h3 className="catigrious-name">Tires</h3>
                            </a>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                        <a className="text-decoration-none" href="/categories-home/3/Performance">

                            <div className="categrories" onClick={() => handleShow(3, 'Performance')} style={{ cursor: 'pointer' }}>
                                <img src={`${S3_Images_local}category/Performance.svg`} alt="img" height="100%" width="100%" />
                            </div>
                            <h3 className="catigrious-name">Performance</h3>
                            </a>

                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <a className="text-decoration-none" href="/products/11" style={{ cursor: 'pointer' }} onClick={emptyLocalStorage}>
                                <div className="categrories">
                                    <img src={`${S3_Images_local}category/Lighting.svg`} alt="img" height="100%" width="100%" />
                                </div>
                                <h3 className="catigrious-name">Lighting</h3>
                            </a>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <a className="text-decoration-none" href="/products/21" style={{ cursor: 'pointer' }} onClick={emptyLocalStorage}>
                                <div className="categrories">
                                    <img src={`${S3_Images_local}category/Suspension.svg`}alt="img" height="100%" width="100%" />
                                </div>
                                <h3 className="catigrious-name">Suspension</h3>
                            </a>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <a className="text-decoration-none" href="/products/27" style={{ cursor: 'pointer' }} onClick={emptyLocalStorage}>
                                <div className="categrories">
                                    <img src={`${S3_Images_local}category/Exhaust.svg`} alt="img" height="100%" width="100%" />
                                </div>
                                <h3 className="catigrious-name">Exhaust, Mufflers & Tips</h3>
                            </a>
                        </div>
                    </div>
                    <div className="row catigioa-2">
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                        <a className="text-decoration-none" href="/categories-home/7/Body-Parts">

                            <div className="categrories" onClick={() => handleShow(7, 'Body Parts')}>
                                <img src={`${S3_Images_local}category/Body_Part.svg`} alt="img" height="100%" width="100%" />
                            </div>
                            <h3 className="catigrious-name">Body Parts</h3>
                            </a>

                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <a className="text-decoration-none" href="/products/2" style={{ cursor: 'pointer' }} onClick={emptyLocalStorage}>
                                <div className="categrories">
                                    <img src={`${S3_Images_local}category/Winchens.svg`} alt="img" height="100%" width="100%" />
                                </div>
                                <h3 className="catigrious-name">Winches & Hitches</h3>
                            </a>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            {/* <a className="text-decoration-none" href="/products/33" style={{ cursor: 'pointer' }}> */}
                        <a className="text-decoration-none" href="/categories-home/1/Interior">

                                <div className="categrories" onClick={() => handleShow(1, 'Interior')} >
                                    <img src={`${S3_Images_local}category/interior-accessories.png`} alt="img" height="100%" width="100%" />
                                </div>
                                <h3 className="catigrious-name">Interior</h3>
                            </a>

                            {/* </a> */}
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <a className="text-decoration-none" href="/products/14" style={{ cursor: 'pointer' }} onClick={emptyLocalStorage}>
                                <div className="categrories">
                                    <img src={`${S3_Images_local}category/Bumper.svg`} alt="img" height="100%" width="100%" />
                                </div>
                                <h3 className="catigrious-name">Bumpers, Grilles & Guards</h3>
                            </a>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <a className="text-decoration-none" href="/products/18" style={{ cursor: 'pointer' }} onClick={emptyLocalStorage}>
                                <div className="categrories">
                                    <img src={`${S3_Images_local}category/Forced_Inductions.svg`} alt="img" height="100%" width="100%" />
                                </div>
                                <h3 className="catigrious-name">Forced Induction</h3>
                            </a>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <a className="text-decoration-none" href="/products/17" style={{ cursor: 'pointer' }} onClick={emptyLocalStorage}>
                                <div className="categrories">
                                    <img src={`${S3_Images_local}category/Running_Boards.svg`} alt="img" height="100%" width="100%" />
                                </div>
                                <h3 className="catigrious-name">Nerf Bars & Running Boards</h3>
                            </a>
                        </div>
                    </div>
                    <div className="view-catagirs-btn">

                        <button onClick={handleChange}>View All Categories</button>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Select Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loader ?
                            <Loader center />
                            :
                            ""
                    }

                    <div className="container">
                        {errorMessage}
                        <div className="row">
                            {!isLoadingCategory && dataCategory ? (
                                dataCategory.data.map((item, index) => (
                                    <div className="col-md-2" key={item.id} style={{ cursor: 'pointer' }}>
                                        <div className="category-item popupImagesDiv" onClick={() => handleChangePage(item.id)}>
                                            <div
                                                style={{ position: 'relative' }}
                                            >
                                                <img
                                                    src={"/category/" + item.image}
                                                    alt={item.image}
                                                    style={{ width: '100%' }}
                                                />

                                            </div>
                                            <label>{item.name}</label>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                Array.from({ length: 18 }).map((_, idx) => (
                                    <div className="col-md-2" key={idx}>
                                        <ShimmerThumbnail height={150} rounded />
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="primary" onClick={handleOpen}>
                        Open
                    </Button>
                </Modal.Footer> */}
            </Modal>

        </div >
    )
}



