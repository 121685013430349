import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import { Helmet } from 'react-helmet';





export default function Index() {

    const [isHidden, setIsHidden] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("");
    const [cartItems, setCartItems] = useState("");


    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);

    }, []);


    const showLoaderLine = (id) => {
        if (id === 1) {
            setIsHidden(false);
        } else {
            setIsHidden(true);
        }

    };

    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    const currentLink = window.location.href;
    const url = new URL(currentLink);
    const updatedUrl = url.toString();

    return (
        <>
            <Helmet>
                <title>Financing</title>
                <meta name="description" content='100 Days Same As Cash – 18-month payback • apply online • fast & easy' />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>

            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />

            <section className="finance-section">
                <div className="container">
                    <div className="content">
                        <h2><b>Financing Available Up To $7500, No Credit Needed</b></h2>
                        <p>100 Days Same As Cash – 18-month payback • apply online • fast &amp; easy</p>
                        <a className="page_btn_cmmncls" href="https://consumer.snapfinance.com/#/?mid=490293126" target="_blank" rel="noreferrer">Get Approved</a>

                    </div>
                </div>
            </section>
            <section className="finance-logo">
                <div className="container">
                    <h2>BUY NOW / PAY LATER With These Easy Financing Options</h2>
                    <div className="logos">
                        <a href="https://consumer.snapfinance.com/#/?mid=490293126" target="_blank" rel="noreferrer"><img width="300" height="100" src="/icons/snapfinancelogo.png" alt="snapfinance logo" className="entered lazyloaded" /><noscript><img width="300" height="100" src="/icons/snapfinancelogo.png" alt="snapfinance logo" /></noscript></a>
                        <a href="https://www.affirm.com/" target="_blank" rel="noreferrer"><img width="948" height="288" src="/icons/affirm-logo.png" alt="affirm logo" data-lazy-src="icons/affirm-logo.png" data-ll-status="loaded" className="entered lazyloaded" /><noscript><img width="948" height="288" src="/icons/affirm-logo.png" alt="affirm logo" /></noscript></a>
                        <a href="https://www.acima.com/" target="_blank" rel="noreferrer"><img width="750" height="232" src="/icons/acima-logo.png" alt="acima logo" data-lazy-src="icons/acima-logo.png" data-ll-status="loaded" className="entered lazyloaded" /><noscript><img width="750" height="232" src="/icons/acima-logo.png" alt="acima logo" /></noscript></a>
                    </div>
                </div>
            </section>
            <section className="shop-section">
                <div className="container">
                    <div className="shop-with">
                        <h2>SHOP WITH</h2>
                        <a href="https://consumer.snapfinance.com/#/?mid=490293126" target="_blank" rel="noreferrer">
                            <img width="250" height="83" src="/icons/snapfinancelogo.png" alt="affirm logo" data-lazy-src="icons/snapfinancelogo.png" data-ll-status="loaded" className="entered lazyloaded" /><noscript><img width="250" height="83" src="/icons/snapfinancelogo.png" alt="affirm logo" /></noscript>
                        </a>
                        <div className="step-boxes">
                            <div className="box">
                                <div className="box-content">
                                    <h3>Approvals up to $7500</h3>
                                    <p>80% approval rate</p>
                                    <p>5 minute application</p>
                                    <p>Instant Approval</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="box-content">
                                    <h3>100 Days Same as Cash 18 month terms</h3>
                                    <p>Plans that work with your paydays and lower cost of ownership with early payoff options</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="box-content">
                                    <h3>Apply now. Get an answer in Seconds</h3>
                                    <p>Apply now, or add items to your shopping cart and apply at checkout</p>
                                    <a className="snap-finance" href="https://consumer.snapfinance.com/#/?mid=490293126" target="_blank" rel="noreferrer">Apply now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shop-with">
                        <h2>SHOP WITH</h2>
                        <a href="https://www.affirm.com/" target="_blank" rel="noreferrer">
                            <img width="150" height="46" src="/icons/affirm-logo.png" alt="affirm logo" data-lazy-src="icons/affirm-logo.png" data-ll-status="loaded" className="entered lazyloaded" /><noscript><img width="150" height="46" src="/icons/affirm-logo.png" alt="affirm logo" /></noscript>
                        </a>
                        <div className="step-boxes">
                            <div className="box">
                                <div className="box-content">
                                    <h3>Add your items to cart</h3>
                                    <p>Then select "Affirm" at checkout on orders of $50</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="box-content">
                                    <h3>Apply and get immediate approval</h3>
                                    <p>Get an instance approval decision. Complete checkout and your all set</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="box-content">
                                    <h3>Pay over time</h3>
                                    <p>0% interest up to 6-Months</p>
                                    <p>Payment terms up to 36 months available</p>
                                    <a className="affirm" href="https://www.affirm.com/" target="_blank" rel="noreferrer">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shop-with">
                        <h2>SHOP WITH</h2>
                        <a href="https://www.acima.com/" target="_blank" rel="noreferrer">
                            <img width="200" height="62" src="/icons/acima-logo.png" alt="acima logo" data-lazy-src="icons/acima-logo.png" /><noscript><img width="200" height="62" src="/icons/acima-logo.png" alt="acima logo" /></noscript>
                        </a>
                        <div className="step-boxes">
                            <div className="box">
                                <div className="box-content">
                                    <h3>Add your items to cart</h3>
                                    <p>Then select "Acima" at checkout on orders of $300</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="box-content">
                                    <h3>Apply and get immediate approval</h3>
                                    <p>Get an instance approval decision. Complete checkout and your all set</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="box-content">
                                    <h3>Pay over time</h3>
                                    <p>Acima allows you to take home up to $4,000 of must-have merchandise, like furniture, electronics, appliances, tires, and much more—without paying cash or using credit cards. Our flexible, lease-to-own solution provides a new alternative to traditional financing.</p>
                                    <a className="affirm" href="https://www.acima.com/" target="_blank" rel="noreferrer">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            < FOOTER />
        </>

    )
}
