import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

export default function Register() {
    const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = 'First name is required.';
        if (!formData.lastName) newErrors.lastName = 'Last name is required.';
        if (!formData.email) newErrors.email = 'Email is required.';
        if (!formData.password) newErrors.password = 'Password is required.';
        if (!validatePassword(formData.password)) {
            newErrors.password = 'Password must be at least 8 characters long and contain atleast an uppercase letter, a lowercase letter, a number, and a special character.';
        }
        if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match.';
        setErrors(newErrors);


        if (Object.keys(newErrors).length === 0) {
            try {
                // Send registration data to server
                const response = await axios.post(`${mainUrl}/api/register`, formData);
                setSuccessMessage(response.data.message);
                toast.info(response.data.message);



            } catch (error) {
                console.error('Registration failed:', error);
            }
        }
    };
    return (
        <div className="container d-flex justify-content-center vh-100">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"

            />
            <div className="col-md-3">
                <div className='logo-register-page'> <img className="" src="/images/extreme_logo.svg" alt="logo" height="100%" width="100%" /> </div>
                <div className='form-main-div'>
                    <span className='heading'>Create Account</span>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">First Name:</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                            />
                            {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}

                            <label htmlFor="lastName" className="form-label">Last Name:</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                            />
                            {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}


                            <label htmlFor="email" className="form-label">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            />
                            {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}


                            <label htmlFor="password" className="form-label">Password:</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}


                            <label htmlFor="confirmPassword" className="form-label">Confirm Password:</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                            />
                            {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}


                        </div>
                        <button type="submit" className="btn btn-danger">Register</button>
                    </form>

                    <div className="a-divider-inner"></div>
                    <span className='span-login'>Already have an account?<a href="login" rel="nofollow" className="nav-a">Sign in.</a> </span>
                </div>

            </div>
        </div>
    );

}