import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export default function Itemcards(data) {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const navigate = useNavigate();
    const [loaderShow, setLoaderShow] = useState(false);

    const addItemstoCaart = (product_id, product_name, total_quantity, image, price) => {

        try {
            let product_name_ = product_name;
            const cartItems = JSON.parse(localStorage.getItem('totalCartItems')) || {};
            const itemId = product_id;
            const existingValue = cartItems[itemId] || 0;
            const updatedValue = existingValue + 1;
            const invnetoryStock = total_quantity
            // Check if the updated value exceeds the stockInventory
            if (updatedValue > invnetoryStock) {
                toast.info("Cannot add more items than available in stock!", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }
            cartItems[itemId] = updatedValue;
            localStorage.setItem('totalCartItems', JSON.stringify(cartItems));


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/add-to-cart`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {
                    product_image: image,
                    product_id: product_id,
                    product_name: product_name_,
                    product_price: price,
                    product_quantity: 1,
                    category_type: "Custome",
                    total_quantity: total_quantity,
                    part_number: product_id
                }
            };
            axios
                .request(config)
                .then((response) => {
                    localStorage.setItem('cartItemsArray', JSON.stringify(response.data.cart));
                    toast.success("Item Added to cart!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        navigate(`/add-cart`);
                    }, 1000);


                })
                .catch((error) => {
                    console.log(error);

                });
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    }

    const handleSubmit = (event) => {
        setLoaderShow(true)
        let state = {
            item_id: data.data.item_id,           
        }
        const item_name = data.data.part_description ? data.data.part_description : data.data.product_name;
        const item_id = data.data.item_id;
        let name = item_name.replace(/\s+|[\\/]/g, "-").toLowerCase();
        setTimeout(() => {
            navigate(`/custom-product-details/${item_id}?${name}`, { state: state });
            setLoaderShow(false)
        }, 1000); // Delay of 2000 milliseconds (2 seconds)


    };

    return (
        <div className="col-lg-3 col-6 mb-2">
            <div className="Drivetrain-cart">
                <div className="engle-imge-upper" onClick={handleSubmit}>
                    {
                        !loaderShow ?
                            <button className="hover-btn " >  View Details </button>
                            :
                            <button className="hover-btn loading " > Loading  </button>
                    }
                    {

                        <img className="" src={data.data.s3_images} alt="img" height="100%" width="100%" />


                    }
                </div>
                <div className="cart-body">
                    <span style={{ cursor: 'pointer' }}>
                        <h4>{data.data.subcategory_name}</h4>
                        <span className="m-0 description text-dark" >{data.data.part_description}</span>
                    </span>
                    <br></br>

                    <div className="price-cart  mb-2">
                        <div className="price">
                            <h5 className="m-0">${parseFloat(data.data.price).toFixed(2)}</h5>
                        </div>
                        <div className="add_to_cart">
                            {/* <span className="m-0 btn btn-sm btn-danger" onClick={() => addItemstoCaart(data.data.part_number, data.data.product_name, 1000000, data.data.s3_images, data.data.price)}>Add to cart</span> */}
                            <span></span>
                        </div>
                    </div>

                </div>
            </div>

        </div>




    )
}
