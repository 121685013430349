import { React, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import SERVICES from "../../components/Footer/services";
import axios from 'axios';
import { Helmet } from 'react-helmet';


export default function Dashboard() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [brandData, setBrandData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const brandSectionsRef = useRef([]);
    const [isHidden, setIsHidden] = useState(false);
    const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const [cartItems, setCartItems] = useState("");

    const [showMenuSide, setShowMenuSide] = useState("")

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');

        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
    }, []);


    useEffect(() => {
        fetchBrandData();
    }, []);

    const fetchBrandData = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        try {
            const response = await axios.get(`${mainUrl}/api/brand`);
            setBrandData(response.data);
            setIsHidden(true)
            setIsLoading(false)

        } catch (error) {
            console.error('Error fetching data:', error);
            navigate(`/error`);
            setIsLoading(false)
        }
    }

    const onSubmit = (item) => {
        const vendor = item.vendor_id;
        const trimmed_brand_name = item.name.trim(); // Removes leading and trailing whitespace
        const modified_brand_name = trimmed_brand_name.replace(/\s+/g, '-');


        if (vendor === 1) {
            setTimeout(() => {
                navigate(`/categories-home/brand-category/${modified_brand_name}/${item.brand_id}`);
            }, 1000);
        } else if(vendor === 3){
            setTimeout(() => {
                navigate(`/tires/${item.brand_id}/${modified_brand_name}`);
            }, 1000);
        }else {
            setTimeout(() => {
                navigate(`/wheels/${item.brand_id}/${modified_brand_name}`);
            }, 1000);
        }
    };

    const handleSearchFilter = (event) => {
        setSearchTerm(event.target.value);
    };
    const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ#'.split('');


    const handleAlphabetFilter = (alphabet) => {
        if (brandSectionsRef.current[alphabet]) {
            brandSectionsRef.current[alphabet].scrollIntoView({ behavior: 'smooth' });
        }

    };
    // Function to determine if a character is a letter (A-Z or a-z)
    const isLetter = (char) => {
        return /^[A-Za-z]$/.test(char);
    };
    // Apply search filter to both alphabetical and non-alphabetical brands
    const filteredBrandData = brandData?.data
        ? brandData.data.filter((item) => {
            const brandName = item.name.toLowerCase().trim();
            const searchTermLowerCase = searchTerm.toLowerCase().trim();
            if (searchTermLowerCase !== '') {
                return brandName.includes(searchTermLowerCase);
            }
            return true;
        })
        : [];

    // Create brand sections grouped by alphabets or non-alphabetic characters
    const brandSections = alphabets.reduce((sections, alphabet) => {
        const brandsWithAlphabet = filteredBrandData.filter((item) => {
            const brandName = item.name.trim();
            return brandName.length > 0 && brandName.charAt(0).toUpperCase() === alphabet;
        });

        if (brandsWithAlphabet.length > 0) {
            sections[alphabet] = brandsWithAlphabet;
        }
        return sections;
    }, {});

    // Handle brands that start with numbers or non-alphabetic characters
    const brandsWithNumberOrSpecialChar = filteredBrandData.filter((item) => {
        const brandName = item.name.trim();
        return brandName.length > 0 && !isLetter(brandName.charAt(0));
    });

    if (brandsWithNumberOrSpecialChar.length > 0) {
        brandSections['#'] = brandsWithNumberOrSpecialChar;
    }


    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    const currentLink = window.location.href;
    const url = new URL(currentLink);
    url.search = '';
    const updatedUrl = url.toString();
    return (
        <>
            <Helmet>
                <title>All Brands</title>
                <meta name="description" content="We are dedicated to maintaining our position as a leading distributor, specializing in wheels, tires, and a wide array of accessories" />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>

            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <section>
                <div className="main-inner-banner">
                    <div className="parformane-bar">
                        <div className="container">
                            <ul className="p-0 m-0 listing-parmofamne">
                                <li><a href="/">Home /</a></li>
                                <li className="active-bread"><a href="#">Brands</a></li>
                            </ul>
                        </div>
                    </div>
                    <h2 className="text-center txt-main-listing">Brands</h2>
                </div>
            </section>
            <section>
                <div className='main-top-brands'>
                    <div className='container'>
                        <div className='text-search'>
                            <div className='text'>
                                <span>
                                    Choose From {brandData && brandData.data ? brandData.data.length : ""} Brands
                                </span>
                            </div>
                            <div className='searchInput'>
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleSearchFilter}
                                    placeholder="Search by brand name..."
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className="filter-and-brands">
                            <div className="brand-filters row">
                                <div>
                                    <ul className="alphabet-list">
                                        {alphabets.map((alphabet) => (
                                            <li
                                                key={alphabet}
                                                className={filteredBrandData.some((item) =>
                                                    item.name.charAt(0).toUpperCase() === alphabet
                                                ) ? 'active' : ''}
                                                onClick={() => handleAlphabetFilter(alphabet)}
                                            >
                                                {alphabet}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                            </div>
                            <div className="brands_images">
                                {Object.entries(brandSections).length === 0 && !isLoading ? (
                                    <div className='brandNotFound'>
                                        <p>There is no brand with these keywords. Please search with other keywords!</p>
                                    </div>
                                ) : (
                                    Object.entries(brandSections).map(([alphabet, brands]) => (
                                        <div key={alphabet} ref={(ref) => brandSectionsRef.current[alphabet] = ref}>
                                            <h3 className="section-heading">{alphabet}</h3>
                                            <div className="row">
                                                {brands.map((item) => (
                                                    <div
                                                        key={item.id}
                                                        className="col-lg-2 col-md-3 col-sm-4 col-6 mt-5 brandPageTile"
                                                        onClick={() => onSubmit(item)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <div className='image_brands'>
                                                            {
                                                                 item.logo_s3_url?   
                                                                <img
                                                                    src={S3URL + item.logo_s3_url}
                                                                    alt={item.name}
                                                                    className='img-fluid'
                                                                    height="100%"
                                                                    width="100%"
                                                                />
                                                                :<span className='brand_name'>{item.name}</span>
                                                            }

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SERVICES />
            <FOOTER />
        </>
    );
}
