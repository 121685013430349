import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function Itemcards(data) {

    const navigate = useNavigate();
    let S3ImagesData = data.data.s3_images;
    let ImagesData = data.data.images;
    let logo = data.data.brand_logo;
    let first_image_path = "";
    if (S3ImagesData.length > 0) {
        first_image_path = S3ImagesData[0];
    } else if (ImagesData.length > 0) {
        first_image_path = ImagesData[0];
    } else {
        first_image_path = logo
    }


    const [loaderShow, setLoaderShow] = useState(false);
    const handleSubmit = (event) => {
        setLoaderShow(true)
        let state = {
            item_id: data.data.item_id,
            subcategory_name: data.data.subcategory_name,
            category_name: data.data.category_name,

        }
        // const item_name = data.data.part_description ? data.data.part_description : data.data.product_name;
        const item_id = data.data.item_id;
        // let name = item_name.replace(/\s+|[\\/]/g, "-").toLowerCase();

        if (data.data.category_name == 'Wheels') {

            setTimeout(() => {
                navigate(`/wheels-product-details/${item_id}`);
                setLoaderShow(false)
            }, 1000); // Delay of 2000 milliseconds (2 seconds)

        } else {
            setTimeout(() => {
                navigate(`/product-details/${item_id}`, { state: state });
                setLoaderShow(false)
                window.location.reload();
            }, 1000); // Delay of 2000 milliseconds (2 seconds)
        }



    };


    const onSubmit = (item) => {

        navigate('/brands/brands-products', { state: item });



    };

    let actualPrice = data.data.price;
    //5% off
    let discount = 0.05; // 5% discount
    let discountedPrice = actualPrice - (actualPrice * discount);
    // console.log("Actual Price: $" + actualPrice);
    // console.log("5% Off Price: $" + discountedPrice);


    return (
        <div className="col-lg-2 col-6 customeChanges">

            <div className="Drivetrain-cart ribbon_box">
                {/* <div class="ribbon"><span>5% Off</span></div> */}
                {
                        data.data.price &&  data.data.price >= 100 ? (
                                <div className="ribbon"><span>Free Shipping</span></div>
                            ) : (
                                ""
                            )
                        }

                <div className="engle-imge-upper" onClick={handleSubmit}>
                    {
                        !loaderShow ?
                            <button className="hover-btn " >  View Details </button>
                            :
                            <button className="hover-btn loading " > Loading  </button>
                    }


                    {
                        data.data && first_image_path ?
                            <img className="" src={first_image_path} alt="img" height="100%" width="100%" />
                            :
                            <img className="w-100 h-auto" src="/images/coming_soon.jpg" alt="img" height="100%" width="100%" />

                    }
                </div>

                <div className="cart-body">
                    <span onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                        <h4 className='similar_pro_cat_name'>{data.data.subcategory_name}</h4>
                        <span className="m-0 description text-dark" >{data.data.part_description ? data.data.part_description : data.data.product_name}</span>
                    </span>

                    <div className="price-cart">
                        <div className="price mt-2">
                            <strong>{data.data.part_number}</strong>
                        </div>
                        <div className="price" title='Stock'>
                            {
                                data.data.stock == 0 ?
                                    <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> {data.data.stock}</span>
                                    :
                                    <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {data.data.stock}</span>
                            }

                        </div>
                    </div>



                    <div className="price-cart">

                        <div className="price">
                            {
                                data.data && data.data.price ?
                                    <h5 className="m-0  price_card_acc">
                                       
                                        <span className='price_in_card_two'>${parseFloat(data.data.price).toFixed(2)} </span>
                                        {/* <s className='price_in_card_one'>${parseFloat(actualPrice).toFixed(2)}</s> */}

                                    </h5>

                                    :
                                    <h5 className="m-0">N.A</h5>
                            }

                            {/* <h5 className="m-0">{price}</h5> */}
                        </div>

                        <div className="cart-btn">
                            <a href={`/categories-home/brand-category?brandname=${data.data.brand}&brandid=${data.data.brand_id}`}


                                // onClick={() => onSubmit(data.data.brand_id)} // Pass the item to onSubmit function
                                style={{ cursor: 'pointer' }}
                            >
                                {
                                    data.data.brand_logo ?
                                        <img src={data.data.brand_logo} alt="img" height="100%" width="100%" />
                                        :
                                        <img src="/images/no-image-found.png" alt="img" height="100%" width="100%" />

                                }
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    )
}
