import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import { Helmet } from 'react-helmet';

const PageNotFoundScreen = () => {
    const [isHidden, setIsHidden] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("");
    const navigate = useNavigate(); // Add useNavigate hook

    const [cartItems, setCartItems] = useState("");
    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');

        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, [navigate]);

    const showLoaderLine = (id) => {
        if (id === 1) {
            setIsHidden(false);
        } else {
            setIsHidden(true);
        }
    };

    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value);
    };

    const goBackToHomepage = () => {
        navigate('/');
    };

    return (
        <><Helmet>
        <title>Page Not Found</title>
         </Helmet>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <div className="error-screen">
                <h1><strong>404</strong></h1>
                <h2>PAGE NOT FOUND</h2>
                <button className="btn btn-sm btn-danger" onClick={goBackToHomepage}>
                    Go back to homepage
                </button>
            </div>
            <FOOTER />
        </>
    );
};

export default PageNotFoundScreen;
