import React, { useState } from 'react';
import axios from 'axios';
import {  toast } from 'react-toastify';




export default function Newsletter() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;

    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/subscribe`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {
                    email: email,
                   

                }
            };
            axios
                .request(config)
                .then((response) => {
                    toast.success("Subscribed Sucessfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    // Reset form fields and close the modal
                    setEmail("");

                })
                .catch((error) => {
                    console.log(error);

                });
        } catch (error) {

        }
    };

    return (
        <div>
            <section>
                <div className="main-news-letter">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 news-letter" data-aos="fade-right">
                                <h2>Subscribe to our Newsletter</h2>
                                <p>Subscribe to our newsletter to get our daily updates and feeds, and get up to date to our latest products
                                    and offers we made.</p>
                            </div>
                            <div className="col-lg-6 news-letter-serech" data-aos="fade-left">
                                <div className="search-bar news-letter-barr mt-5">
                                    <form className="form-search" onSubmit={handleSubmit}>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            required
                                        />
                                        <button type="submit">
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                {/* SVG path for your subscribe button */}
                                            </svg>
                                            Subscribe
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
