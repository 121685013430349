import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from 'react'

import homeRoutes from "./routes/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import './style.css';
import './custome_style.css';
import axios from 'axios';



function App() {

  useEffect(() => {
    let newUrl = window.location.href;
  
    // if (!newUrl.startsWith("https://")) {
    //   newUrl = newUrl.replace("http://", "https://");
    // }

    // if (!newUrl.startsWith("http://www.")) {
    //   newUrl = newUrl.replace("http://", "https://www.");
    //   console.log("httppppp");

    // }
  //  console.log("newurl",newUrl);
  
    if (!newUrl.includes("www.")) {
      console.log("first+++");
      newUrl = newUrl.replace("https://", "https://www.");
     
    }
   
    if (newUrl !== window.location.href) {
      window.location.replace(newUrl);
      console.log("reloadd+++");

    }
    fetchNonceData();
  }, []); 


  const fetchNonceData = async () => {
    try {

      console.log('response.data++++++++++++++++')
      const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;

      let config = {
        method: 'get',  
        url: `${mainUrl}/api/getNonceData`
      };
      axios.request(config)
        .then((response) => {
          console.log('response.data++++++++++++++++',response.data);
          localStorage.setItem('nonce',response.data.message ? response.data.message.nonce : "");     

        })

    } catch (error) {
      console.log(error);
    }
  }
  

  return (
    <BrowserRouter>
      <Routes>
        {homeRoutes.map((itm, idx) => {
          return (<Route path={itm.path} element={<itm.component />} key={idx} />)
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
