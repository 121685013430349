import { React, useState, useEffect ,useRef} from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";

import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import Itemcards from "../../components/Cards/itemscard_search";
import Pagination from 'react-bootstrap/Pagination';
import { ShimmerButton, ShimmerThumbnail, ShimmerCategoryItem } from "react-shimmer-effects";
import "rsuite/dist/rsuite.css";
import axios from 'axios';
import Collapse from 'react-bootstrap/Collapse';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';





export default function SearchBarDetails() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const location = useLocation();

    const S3_IMAGES = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/"
    const MAX_PAGES_DISPLAY = 10;
    const PRODUCT_PER_PAGE = 28;
    const [cartItems, setCartItems] = useState("");
    const [isHidden, setIsHidden] = useState(false);

  
    const searchterm = new URLSearchParams(location.search).get('searchterm');

    console.log("searchterm+++++++++++",searchterm)
  
    const [showSuggestions, setShowSuggestions] = useState(true);
    const suggestionsRef = useRef(null);
  

   
    const [dataCategory, setDataCategory] = useState([]);
    const [isLoadingCategory, setIsLoadingCategory] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [totalProducts, setTotalProducts] = useState(0);
    const [isloading, setIsloading] = useState(false);
    const [isInputText,setSearchInput] = useState(false);
    const [isloadingSearch, setIsloadingSearch] = useState(false);
    const [searchLength, setSearchLength] = useState(0);


    const [dataBradnAndCategory, setDataBradnAndCategory] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [showResult, setShowResult] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
    const [isSuggestions, setIsSuggestions] = useState(false);

    
  
    
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMake, setIsLoadingMake] = useState(true);
    const [isLoadingModel, setIsLoadingModel] = useState(true);
    const [isLoadingVarient, setIsLoadingVarient] = useState(true);


    const [isLoaderMake, setIsLoaderMake] = useState(true);
    const [isLoaderModel, setIsLoaderModel] = useState(true);
    const [isLoaderVarient, setIsLoaderVarient] = useState(true);

    const [sortingValue, setSortingValue] = useState(1);



    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoriesName, setSelectedCategoriesName] = useState([]);
    const [selectedBrandsName, setSelectedBrandsName] = useState([]);


    const [firstValue, setFirstValue] = useState(0);
    const [secondValue, setSecondValue] = useState(100000);

    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);


    const [maxPriceDefault, setMaxPriceDefault] = useState(null);
    const [minPriceDefault, setMinPriceDefault] = useState(null);

    const [showWholwPageLoader, setShowWholwPageLoader] = useState(false);

    const [open, setOpen] = useState(true);
    const [openBrand, setOpenBrand] = useState(false);
    const [openPrice, setOpenPrice] = useState(false);

    const [stockWheel, setStockWheel] = useState([]);


    const [filterClass, setFilterClass] = useState('');




    const [showError, setShowError] = useState(false)
    const [openStock, setOpenStock] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("")

    const [isChecked, setIsChecked] = useState(true);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    

    useEffect(() => {
        const handleClickOutside = (event) => {

            console.log("suggestionsRef++++++++++++++++",suggestionsRef)
          if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
            setShowSuggestions(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    useEffect(() => {
        document.body.classList.remove('open_search');  
  
        
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }

    



        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
     
        

    }, []);

    useEffect(() => {   
          

        setTimeout(() => {
            
            if(!isInputText){
                setIsloading(true);
                if(searchterm.length <= 0 ){
                    setDataCategory();
                    setIsLoadingCategory(false)
                    setIsloading(false);
                    setShowResult(false);
    
                }else{
                    fetchProductData();
                    setIsloading(true);
                    setShowResult(true);
                }
            }else{
                setSuggestions([]);
                setIsLoadingSuggestions(false);
        console.log("searchTerm++++++++++",searchTerm)
        setIsloading(true);
        if (searchTerm.length > 2) {
           
            setSearchInput(true);
        console.log("inputText+++++++++fssaff+",searchTerm)
          fetchData(searchTerm,currentPage, sortingValue, firstValue, secondValue, isChecked);
          setShowResult(true);
        } else {
            
        setCurrentPage(0);
        console.log("inputText++++++sdfsdf++++",searchTerm)
            setDataCategory(0);
            setIsloading(false);
            setIsloadingSearch(false)
            setShowResult(false);
        }
            }
          
           
        }, 1000);
    }, [currentPage, sortingValue, firstValue, secondValue, isChecked]);


    useEffect(() => {
    
        // Debounce function to delay API call
        const debounceTimer = setTimeout(() => {
            
          if (inputValue.length > 2) {
            setShowSuggestions(true);
            // Make API call to fetch suggestions based on inputValue
            setIsSuggestions(false)
            fetchSuggestions();
          } else {
            setIsSuggestions(true)
            setSuggestions([]); // Clear suggestions if input is empty
          }
        }, 300); // Adjust the delay time as needed
    
        // Cleanup function to clear the timer on each input change
        return () => clearTimeout(debounceTimer);
      }, [inputValue]);

      const fetchSuggestions = async () => {
        try {
            
            setIsLoadingSuggestions(true)
            console.log("inputValue+++++++++++++++++++++++kh",inputValue)
            const inputValue1 = encodeURIComponent(inputValue)
       
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/search/getallsearchedname?searchTerm=${inputValue1}&limit=10&page=${currentPage}&isChecked=${isChecked}`,
            };
            axios.request(config)
                .then((response) => {
                    setSuggestions(response.data.data);
                    console.log("newone++++++++++",response.data)
                    document.body.classList.remove('full-screen-loader');
                    document.body.classList.remove('loader-line');
                    setIsLoadingSuggestions(false)
                    
                })
                .catch((error) => {
                    console.log(error);
                    
                    document.body.classList.remove('full-screen-loader');
                    document.body.classList.remove('loader-line');


                });
       
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      };
    


    //fetch product items 
    const fetchProductData = async () => {
        document.body.classList.add('full-screen-loader');
        setSuggestions([]);
        setIsLoadingSuggestions(false);
        setShowWholwPageLoader(true)

        setIsLoadingCategory(true);
 
        const limit = 28; // Define the limit per page
        const offset = (currentPage - 1) * limit; // Calculate the offset based on the current page and limit
        console.log("searchterm++++++sdfsdf+++searchterm+",searchterm)
        console.log("limit",limit,"offset",offset,"sortingValue",sortingValue,"firstValue",firstValue,"currentPage",currentPage,"isChecked",isChecked)
        try {

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/search/getallsearcheditemsdetails?searchTerm=${searchterm}&limit=${limit}&offset=${offset}&sorting=${sortingValue}&startingPrice=${firstValue}&endPrice=${secondValue}&page=${currentPage}&isChecked=${isChecked}`,
            };
            axios.request(config)
                .then((response) => {
                    setDataCategory(response.data);
                    setIsLoadingCategory(false);
                    setTotalPages(response.data.totalPages);
                    setTotalProducts(response.data.count);
                    setIsloading(false);
                  //  setCategoryName(response.data.data ? response.data.data[0].category_name : "");
                    setShowWholwPageLoader(false)
                    window.scrollTo({ top: 400, behavior: 'smooth' });
                    setMinPrice(Math.floor(response.data.minimumPrice));
                    setMaxPrice(Math.ceil(response.data.maximumPrice));
                    setMaxPriceDefault((Math.ceil(response.data.maxDefaultPrice)) + 1);
                    // setMinPriceDefault((Math.ceil(response.data.maximumPrice))-1);

                    document.body.classList.remove('full-screen-loader');

                })
                .catch((error) => {
                    console.log(error);
                    setShowWholwPageLoader(false)
                    document.body.classList.remove('full-screen-loader');

                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoadingCategory(false);
            setShowWholwPageLoader(false)
            document.body.classList.remove('full-screen-loader');


        }
    };



 




    const handleSorting = (event) => {
        const sortingValue = event.target.value;
        setSortingValue(sortingValue);
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
        document.body.classList.add('full-screen-loader');
    };

    const getPageNumbers = () => {
        const totalDisplayPages = Math.min(totalPages, MAX_PAGES_DISPLAY);
        const middlePage = Math.ceil(totalDisplayPages / 2);
        let startPage = Math.max(1, currentPage - middlePage + 1);

        // Ensure the start page does not exceed the available pages
        if (startPage + totalDisplayPages > totalPages) {
            startPage = Math.max(1, totalPages - totalDisplayPages + 1);
        }

        return Array.from({ length: totalDisplayPages }, (_, index) => startPage + index);
    };



    const clearAllFilters = () => {
        setSelectedBrands([]);
        setSelectedCategories([]);
        setSelectedCategoriesName([]);
        setSelectedBrandsName([]);
        setCurrentPage(1);


    }

    const showFiltersClick = () => {
        setFilterClass('active-filter')
        document.body.classList.add('home-layout')

    }
    const hideFilterClass = () => {
        setFilterClass('')
        document.body.classList.remove('home-layout')
    }

    const handleStockChange = (event) => {

        const selectedStock = event.target.value;
        setStockWheel(selectedStock)

    }
    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    const startIndex = (currentPage - 1) * PRODUCT_PER_PAGE + 1;
    const endIndex = Math.min(startIndex + PRODUCT_PER_PAGE - 1, totalProducts);

  

    const handleStaggeredChange = (event) => {
        setIsChecked(!isChecked)
    }
    const handleChangeSlider = (event, newValue) => {
        setFirstValue(newValue[0]);
        setSecondValue(newValue[1]);
        setMinPrice(newValue[0]);
        setMaxPrice(newValue[1]);
    };


    // const handleKeyDown = (e) => {
    //     // Check if the Backspace key (key code 8) is pressed
    //     if (e.keyCode === 8) {
    //         setCurrentPageInStock(1);
    //         setCurrentPageAllItems(1);
    //         console.log('current page set to default', currentPage);

    //     }
    // };
    const fetchData = async (searchTerm,currentPage, sortingValue, firstValue, secondValue, isChecked) => {
        try {
            setSuggestions([]);
            setIsLoadingSuggestions(false);
             document.body.classList.add('full-screen-loader');
            document.body.classList.add('loader-line');
            const searchTerm1 = encodeURIComponent(searchTerm)
            encodeURIComponent(searchTerm)
            setShowWholwPageLoader(true)
    
            setIsLoadingCategory(true);
     
            const limit = 28; // Define the limit per page
            const offset = (currentPage - 1) * limit; // Calculate the offset based on the current page and limit
            
        console.log("===========searchTerm++++++sdfsdf+++searchterm+",searchTerm1)
         
        console.log("limit====",limit,"offset====",offset,"sortingValue===",sortingValue,"firstValue===",firstValue,"currentPage===",currentPage,"isChecked====",isChecked);
    
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/search/getallsearcheditemsdetails?searchTerm=${searchTerm1}&limit=${limit}&offset=${offset}&sorting=${sortingValue}&startingPrice=${firstValue}&endPrice=${secondValue}&page=${currentPage}&isChecked=${isChecked}`,
                };
                axios.request(config)
                    .then((response) => {
                        setDataCategory(response.data);
                        setIsLoadingCategory(false);
                        setTotalPages(response.data.totalPages);
                        setTotalProducts(response.data.count);
                       
                        setIsloading(false);
                   //     setCategoryName(response.data.data ? response.data.data[0].category_name : "");
                        setShowWholwPageLoader(false)
                        window.scrollTo({ top: 400, behavior: 'smooth' });
                        setMinPrice(Math.floor(response.data.minimumPrice));
                        setMaxPrice(Math.ceil(response.data.maximumPrice));
                        setMaxPriceDefault((Math.ceil(response.data.maxDefaultPrice)) + 1);
                        // setMinPriceDefault((Math.ceil(response.data.maximumPrice))-1);
                        setIsSuggestions(true)
                         document.body.classList.remove('full-screen-loader');
                        document.body.classList.remove('loader-line');
    
                    })
                    .catch((error) => {
                        console.log(error);
                        setShowWholwPageLoader(false)
                         document.body.classList.remove('full-screen-loader');
                        document.body.classList.remove('loader-line');

    
                    });
        
    } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoadingCategory(false);
        setShowWholwPageLoader(false)
        document.body.classList.remove('full-screen-loader');
        document.body.classList.remove('loader-line');



    }



      };


      const handleInputChange = (e) => {
        const inputText = e.target.value;
        setSearchTerm(inputText);
        setShowSuggestions(true);
        setCurrentPage(1);
        if (inputValue.trim() === '') {
            setCurrentPage(1);
        }
      };


    
    const handleSearch = () => {
        setSuggestions([]);
        setIsloading(true);
        if (searchTerm.length > 2) {
            setSearchInput(true);
        setCurrentPage(1);
        console.log("inputText+++++++++fssaff+",searchTerm)
            const newUrl = window.location.pathname;
            window.history.replaceState({}, '', newUrl);
          fetchData(searchTerm,currentPage, sortingValue, firstValue, secondValue, isChecked);
          setShowResult(true);
        } else {
        setCurrentPage(0);
        console.log("inputText++++++sdfsdf++++",searchTerm)
            setDataCategory(0);
            setIsloading(false);
            setIsloadingSearch(false)
            setShowResult(false);
        }
    };
    
      const handleSuggestionClick = (suggestion) => {
        setIsSuggestions(true)
        let searchdata;
        if(suggestion.category_name == 'tires_wp' || suggestion.category_name == 'Wheels' || suggestion.category_name == 'WHEELS'){
            searchdata=suggestion.product_name
        }else{
            searchdata =suggestion.part_description
        }
        setSearchTerm(searchdata);
        setCurrentPage(1);
        fetchData(searchdata, currentPage, sortingValue, firstValue, secondValue, isChecked);
        setShowResult(true);
        setSuggestions([]);
    };


      const handleKeyDown = (e) => {
        // Check if the Backspace key (key code 8) is pressed
        if (e.keyCode === 8) {
            setCurrentPage(1);
            setShowSuggestions(true);
            console.log('current page set to default', currentPage);

        }
        if (e.key === 'Enter') {
          
            e.preventDefault();
            setIsSuggestions(true)
            handleSearch();
          }
    };

    const highlightSearchTerm = (item, searchTerm) => {
        //  console.log("item",item)
        //  console.log("searchTerm",searchTerm)
          const defaultDescription =  '';
  
     // console.log("defaultDescription",defaultDescription)
          if (!searchTerm) {
              return {
                  highlightedDescription: defaultDescription,
  
              };
          }
          
        
      const searchTermWords = searchTerm.split(/\s+/).filter(word => word.trim() !== '');
      const regex = new RegExp(searchTermWords.join('|'), 'gi');
      const itemWords = item.split(' ');
      const highlightedDescription = itemWords.map(word => {
      return word.replace(regex, match => `<span style="background-color: yellow; font-weight: bold; color: blue;">${match}</span>`);
      }).join(' ');
  
    //  console.log(highlightedDescription);
  
          
          return {
              highlightedDescription,
  
          };
      };



    return (
        <>

            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />

            <section>
                <div className="main-inner-banner">
                    <div className="parformane-bar">
                   
                        <div className="container d-flex justify-content-between">
                            <div>

                                <ul className="p-0 m-0 listing-parmofamne">
                                    <li><a href="/">Home /</a></li>
                                    <li className='active-bread text-decoration-none'><a href="#">SEARCH DETAILS</a></li>
                                </ul>

                            </div>

                        </div>
                    </div>
                    {/* <div className="five_percent_products"><img src='../icons/homepage_discount.png' /></div> */}
                    <h2 className="text-center txt-main-listing">SEARCH DETAILS</h2>
                </div>

            </section>


            
            <section>
            <header className="main-header1">
            
               <div className="container1">
                        <div className="mian-header main-header-searchbar1">
                <div className='search-bar1' style={{ marginLeft: '20%' }}>
                <div className='search-container1'>
                <form className="form-search1">
                   
                        <input
                            type="search"
                            name="search"
                            className="search1 exclude-styling"
                            placeholder="I am shopping for ..."
                            autoComplete="off"
                            value={searchTerm}
                            onInput={handleInputChange}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => setInputValue(e.target.value)}
                        /><div className="loader1-container">
                        {isLoadingSuggestions && (
                           
                                <div className="loader1"></div>
                            
                        )}
                        </div>
                    
                    <button type="submit">Search</button>
                </form>
                
                    
                    {suggestions.length > 0 && searchTerm.length > 2 && !isSuggestions && showSuggestions &&(
                            <div ref={suggestionsRef} className='suggestions-container'>
                                {suggestions.map((suggestion) => (
                                    <div key={suggestion.item_id} className="suggestion-item" onClick={() => handleSuggestionClick(suggestion)}>
                                        <div className="suggestion-details">
                                        <div className='image'>
    

                                                    {
                                                        suggestion.category_name == 'CustomeProduct' ? (
                                                            <img src={suggestion.image} alt="img" height="100%" width="100%" />
                                                        ) : suggestion.category_name == 'Wheels' || suggestion.category_name == 'WHEELS' ? (

                                                            <img src={suggestion.image && suggestion.image.startsWith('https://') ? suggestion.image : suggestion.image ? S3_IMAGES + suggestion.image.split(',')[0] : S3_IMAGES + suggestion.brand_logo} alt="img" height="100%" width="100%" />

                                                        ) : suggestion.category_name == 'tires_wp' ? (
                                                            <img src={suggestion.image} alt="img" height="100%" width="100%" />
                                                        ) : (
                                                            <img
                                                                src={suggestion.s3_images.length > 0 ? S3_IMAGES + suggestion.s3_images[0] : S3_IMAGES + suggestion.brand_logo}
                                                                alt="img"
                                                                height="100%"
                                                                width="100%"
                                                            />
                                                        )
                                                    }



                                                </div>
                                                <div className='content'>
                                            <p>{suggestion.category_name == 'tires_wp' ? (
                                                                         <span  dangerouslySetInnerHTML={{ __html: highlightSearchTerm(suggestion.product_name, searchTerm).highlightedDescription }}></span>
                                                                    ) : suggestion.category_name == 'Wheels' || suggestion.category_name == 'WHEELS' ? (
                                                                        <span dangerouslySetInnerHTML={{ __html: highlightSearchTerm(suggestion.product_name, searchTerm).highlightedDescription }}></span>
                                                                    ) : (
                                                                        <span dangerouslySetInnerHTML={{ __html: highlightSearchTerm(suggestion.part_description, searchTerm).highlightedDescription }}></span>
                                                                    )}</p>
                                              <span className='sku'  dangerouslySetInnerHTML={{ __html: highlightSearchTerm(suggestion.part_number, searchTerm).highlightedDescription }}></span>   
                                                 </div>             
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                   </div>

                    </div>
                    </div>
                    </div>
                    </header>
                   
     
               
                <div className="main-section-lsting">
                    <div className="container">
                        <div className='d-flex justify-content-between car-selection-title'>
                            <div>
                              
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-2">
                       
                            </div>
                            <div className="col-lg-9 filter-for-mobile" >

                                <div className='selectedFilters '>
                                    {
                                        selectedCategoriesName && selectedCategoriesName.length > 0 ?
                                            selectedCategoriesName.map((item, index) => (
                                                <span className="span-filter-Checkbox m-1" key={item.name}>
                                                    <span> {item.name} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} ></i>
                                                </span>
                                            ))
                                            : ""
                                    }
                                    {
                                        selectedBrandsName && selectedBrandsName.length > 0 ?
                                            selectedBrandsName.map((item, index) => (
                                                <span className="span-filter-Checkbox m-1" key={item.id}>
                                                    <span> {item.name} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                                                </span>
                                            ))
                                            : ""
                                    }
                                    {
                                        selectedCategoriesName.length > 0 || selectedBrandsName.length > 0 ?

                                            <span className="clearFilterStyle" onClick={clearAllFilters} >
                                                <span>Clear filters</span>
                                            </span>

                                            :

                                            ""
                                    }

                                </div>
                                {/* {totalProducts > 0 ? */}
                                <div className="d-flex justify-content-end filters-sort-by ">
                                    <div className="">
                                        {totalProducts > 0 && showResult ?
                                            <p className="px-5">
                                                Showing {startIndex} - {endIndex} of {parseInt(totalProducts).toLocaleString('en-US')} products
                                            </p>
                                            : ""
                                        }
                                    </div>
                                    <div className='stockCheckbox'>
                                        <ul className='stockCheckboxUl'>
                                            <li>

                                                <input
                                                    type="checkbox"
                                                    value="2"
                                                    className=' tgl tgl-light'
                                                    onChange={handleStaggeredChange}
                                                    id='stockCheck'
                                                    name="stockCheck"

                                                />


                                                <label for='stockCheck' className=' tgl-btn'></label>


                                            </li>
                                            <li className='staggeredLi'>
                                                {
                                                    isChecked ?
                                                        <span>In Stock Items</span>
                                                        : <span>All Items</span>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="">
                                        <select className="form-control" name="sorting" id="sortingWithPrice" onChange={handleSorting}>
                                            <option disabled >Sort By Price</option>
                                            <option value="1" selected>Low-High</option>
                                            <option value="2">High-Low</option>
                                        </select>

                                    </div>
                                    <div className="main-filter-resposive">
                                        <button className="mobile-filter-btn" onClick={showFiltersClick} title="filter search"> <svg class="me-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.25901 14.35L5.25888 14.35C5.24769 14.3502 5.23405 14.3476 5.21863 14.339L5.21836 14.3389C5.19936 14.3282 5.19074 14.318 5.18703 14.3124C5.17887 14.2972 5.176 14.2839 5.176 14.2707V7.91365C5.176 7.82275 5.1652 7.72056 5.13235 7.61486C5.09945 7.50839 5.04961 7.41561 4.99386 7.33726L0.724519 1.26892C0.724485 1.26887 0.724451 1.26882 0.724418 1.26877C0.676725 1.20083 0.65495 1.13291 0.650688 1.06991C0.64665 1.00345 0.660203 0.933143 0.696549 0.863781L0.696552 0.863774C0.732993 0.794228 0.783357 0.74264 0.84197 0.707465L0.841971 0.707466L0.843135 0.706765C0.902543 0.670943 0.973036 0.65 1.05357 0.65H12.9463C13.0271 0.65 13.0973 0.670932 13.1564 0.706609L13.1578 0.70745C13.2164 0.742594 13.2667 0.794096 13.3032 0.863535C13.3407 0.935416 13.3534 1.00516 13.3492 1.06874C13.345 1.13245 13.3232 1.20066 13.2755 1.26862C13.2755 1.26872 13.2754 1.26882 13.2753 1.26892L9.0045 7.33779L9.00402 7.33848C8.94618 7.42089 8.90098 7.51067 8.86985 7.6068L8.86979 7.60678L8.8672 7.61518C8.83481 7.71999 8.82355 7.82177 8.82355 7.91441V12.1555C8.82355 12.1724 8.81984 12.1838 8.81525 12.1923C8.81075 12.1999 8.8025 12.2101 8.78506 12.2207L8.34805 12.4857L8.34827 12.4864L5.3042 14.3361C5.30409 14.3362 5.30399 14.3362 5.30388 14.3363C5.28736 14.3462 5.27261 14.3497 5.25901 14.35ZM5.27154 14.9999C5.14389 15.0023 5.01635 14.9707 4.90099 14.9061L5.27154 14.9999Z" stroke="#E81C2E" stroke-width="1.3"></path>
                                        </svg>
                                        </button>
                                    </div>
                                </div>
                                {/* : ""
                                } */}
                                <div className='row'>
                                    { showResult && totalProducts > 0?
                                        !isLoadingCategory && dataCategory ?
                                            dataCategory.data.length > 0  ?
                                                dataCategory.data.map((itm, _idx) => {
                                                    return (<Itemcards data={itm} key={itm.item_id} />);
                                                })
                                                : <>
                                                    <div className='container m-0 text-center'>
                                                        <h2>No Product Found</h2>
                                                        <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                                        <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                                                    </div>
                                                </>

                                                  :  Array.from({ length: 28 }).map((_, idx) => (
                                                        <div className="col-lg-3 col-6" key={idx}>
                                                            <a href="#">
                                                            </a>
                                                            <div className="Drivetrain-cart"><a href="">
                                                                <div className="engle-imge-upper">
                                                                    {/* <button className="hover-btn">View Details</button> */}
                                                                    <ShimmerThumbnail height={163} rounded />
                                                                </div>
                                                            </a>
                                                                <div className="cart-body">
                                                                    <a href="#">
                                                                        <h6><ShimmerButton size="sm" /></h6>
                                                                        <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                                                                    </a>
                                                                    <div className="price-cart">
                                                                        <a href="#">
                                                                            <div className="price">
                                                                                {/* {
                                                                                itm && itm.cost ?
                                                                                    <h5 className="m-0">${itm.cost}</h5>
                                                                                    :
                                                                                    <h5 className="m-0">N.A</h5>
                                                                            } */}

                                                                            </div>
                                                                        </a>
                                                                        <div className="cart-btn">
                                                                            <a href="#">
                                                                            </a>
                                                                            <a href="#">

                                                                                {/* <img src={data.data.brand_logo} alt="" /> */}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )):
                                                    <>
                                                    <div className='container m-0 text-center'>
                                                        <h2>No Product Found</h2>
                                                        <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                                        <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                                                    </div>
                                                </>
                                          

                                    }


                                </div>
                                {
                                    totalPages > 0 && showResult?

                                        <div className="d-flex mt-4 justify-content-center">
                                            <Pagination>
                                                <Pagination.First
                                                    disabled={currentPage === 1}
                                                    onClick={() => handlePageChange(1)}
                                                />
                                                <Pagination.Prev
                                                    disabled={currentPage === 1}
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                />

                                                {/* Render page numbers */}
                                                {getPageNumbers().map((page) => (
                                                    <Pagination.Item
                                                        key={page}
                                                        active={currentPage === page}
                                                        onClick={() => handlePageChange(page)}
                                                    >
                                                        {page}
                                                    </Pagination.Item>
                                                ))}

                                                <Pagination.Next
                                                    disabled={currentPage === totalPages}
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                />
                                                <Pagination.Last
                                                    disabled={currentPage === totalPages}
                                                    onClick={() => handlePageChange(totalPages)}
                                                />
                                            </Pagination>
                                        </div>
                                        : ""
                                }


                            </div>

                        </div>
                    </div>
                </div>
            </section >
            {/* <NEWSLETTER /> */}
            < FOOTER />
        </>

    )
}
