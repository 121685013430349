import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Mousewheel } from "swiper";
import "./styles.css";
import "swiper/css";
import "swiper/css/navigation";


export default function Slider(data) {
  const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";

  const brandlogo1 = data.data.brandData && data.data.brandData.length > 0 ? data.data.brandData[0].logo : "";
  const sliderImages = data.data.itemRelatedData?.[0]?.s3_images ?? "";
  const sliderImages2 = data.data.itemRelatedData?.[0]?.images ?? "";

  const youtubeData = data.data.itemRelatedData?.[0]?.media ?? "";
  let S3_IMAGES_brand = data.data.brandData && data.data.brandData.length > 0 ? data.data.brandData[0].logo_s3_url : "";

  const brandlogo = S3_IMAGES_brand ? S3URL + S3_IMAGES_brand : brandlogo1
  
  let YoutubeFilesLinks = [];
  let imageArray = [];
  let pdfFiles = [];
  let otherFiles = [];



  if (youtubeData) {
    try {
      const youtubeObjects = JSON.parse(youtubeData);
      // console.log("youtubeObjects++++++++", youtubeObjects);
  
      Object.values(youtubeObjects).forEach(link => {
        const videoId = getYoutubeVideoId(link);
        if (videoId) {
          YoutubeFilesLinks.push( {videoId} );
        } else {
          // console.warn("Invalid YouTube link");
        }
      });
    } catch (error) {
      console.error("Error parsing YouTube data:", error);
    }
  } else {
    // console.log("No YouTube data available");
  }

  if (sliderImages) {
    imageArray = sliderImages.split(',');
    imageArray.forEach(url => {
      const extension = url.split('.').pop();
      if (extension.toLowerCase() === 'pdf') {
        pdfFiles.push(S3URL + url);
      } else if (url.trim() !== '' && !isYouTubeLink(url)) {
        otherFiles.push(S3URL + url);
      }
    });
    otherFiles.push(brandlogo);
  
  } else if (sliderImages2) {
    imageArray = sliderImages2.split(',');
    imageArray.forEach(url => {
      const extension = url.split('.').pop();
      if (extension.toLowerCase() === 'pdf') {
        pdfFiles.push(url);
      } else if (url.trim() !== '' && !isYouTubeLink(url)) {
        otherFiles.push(url);
      }
    });
    otherFiles.push(brandlogo);
  
  } else {
    if (!brandlogo) {
      otherFiles.push("/images/no-image-found.png");
    } else {
      otherFiles.push(brandlogo);
    }
  }
  
  function isYouTubeLink(url) {
    return url.includes('youtube.com') || url.includes('youtu.be');
  }
  

const allFIles = otherFiles.concat(YoutubeFilesLinks);
function getYoutubeVideoId(url) {

  const standardPattern = /[?&]v=([^&#]*)/;
  const standardMatch = standardPattern.exec(url);

  
  const newerPattern = /\/watch\?v=([^&]+)/;
  const newerMatch = newerPattern.exec(url);

  
  if (standardMatch && standardMatch[1]) {
    return standardMatch[1];
  } else if (newerMatch && newerMatch[1]) {
   
    return newerMatch[1];
  } else {
   
    // console.warn("Invalid or unsupported YouTube link:", url);
    return null;
  }
}

      

  const [imagesNavSlider, setImagesNavSlider] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  return (
    <>
      {imageArray ?
        <section className="slider">
          <div className="slider__flex">
            <div className="slider__col">

              <div className="slider__thumbs">
                {/* <div className="slider__prev"><i className="fa fa-angle-up"></i></div> */}
                <div className="swiper-container">
                  <Swiper
                    onSwiper={setImagesNavSlider}
                    direction="vertical"
                    spaceBetween={5}
                    slidesPerView={5}
                    usecss3transforms="false"
                    // navigation={{
                    //   nextEl: ".slider__next",
                    //   prevEl: ".slider__prev"
                    // }}
                    className="swiper-container1"
                    breakpoints={{
                      0: {
                        direction: "horizontal"
                      },
                      768: {
                        direction: "vertical"
                      }
                    }}
                    modules={[Navigation, Thumbs]}
                  >
                    {allFIles.map((slide, index) => {
                      // console.log("slide",slide,"index",index)
                      return (
                        <SwiperSlide key={index}>
                          <div className="slider__image" onMouseEnter={() => setActiveImageIndex(index)}>
                          {slide.videoId ? (
                            <div className="youtube_image">

                               <img src="../icons/play-button.png" alt="Play Button" className="play-button"/>
                                </div>
                              ) : (
                                <img src={slide} alt="prodImg" height="100%" width="100%" />
                              )}
                          </div>
                        </SwiperSlide>
                      );
                    })}
    
                  </Swiper>
                  {/* <div className="slider__next"><i className="fa fa-angle-down"></i></div> */}
                </div>
              </div>


            </div>
            <div className="slider__images">
              <div className="swiper-container bigimagelsider">
                <Swiper
                  thumbs={{ swiper: imagesNavSlider && !imagesNavSlider.destroyed ? imagesNavSlider : null }}
                  direction="horizontal"
                  slidesPerView={1}
                  spaceBetween={15}
                  mousewheel={false}
                  
                  navigation={{
                    nextEl: ".slider__next",
                    prevEl: ".slider__prev"
                  }}
                  breakpoints={{
                    0: {
                      direction: "horizontal"
                    },
                    768: {
                      direction: "horizontal"
                    }
                  }}
                  className="swiper-container2"
                  modules={[Navigation, Thumbs, Mousewheel]}
                  onSlideChange={(swiper) => setActiveImageIndex(swiper.activeIndex)}
                >
                  {allFIles.map((slide, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="slider__image">
                        {slide.videoId ? (
                                <iframe
                                  width="100%"
                                  height="100%"
                                  src={`https://www.youtube.com/embed/${slide.videoId}`}
                                  title={`YouTube Video ${index + 1}`}
                                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                  //allowFullScreen
                                ></iframe>
                               
                              ) : (
                                <img src={slide} alt="prodImg" height="100%" width="100%" />
                              )}
                        </div>
                      </SwiperSlide>
                    );
                  })}

                </Swiper>

              </div>
            </div>
          </div>
        </section>
        :
        "No Image Found !"}
    </>

  );
}



