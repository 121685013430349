import { React, useState, useEffect } from 'react';
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';





export default function Topselling() {
    const [showModal, setShowModal] = useState(false);
    const S3_IMAGES_local = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/local_images/";
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const navigate = useNavigate();
    const S3_IMAGES = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const [isloading, setIsLoadingTires] = useState(false);
    const [tiresData, setTiresData] = useState("");
    const [loaderShow, setLoaderShow] = useState(false);



    const showContactUs = () => {
        setShowModal(true)
    }
    const handleClose = () => {
        setShowModal(false)
    }


    useEffect(() => {
        fetchTiresData();
    }, []);

    const fetchTiresData = async () => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/wheels/getTopSellingTires`,
            };
            axios.request(config)
                .then((response) => {
                    setTiresData(response.data);
                    setIsLoadingTires(true);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoadingTires(true);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoadingTires(true);

        }
    }


    const handleSubmit = (brand, line, size, loadindex, speedrating, sku) => {
        setLoaderShow(true)
        const item_name = `${brand}  ${line}  ${size}  ${loadindex}  ${speedrating}`;
        let name = item_name.replace(/\s+|[\\/]/g, "-").toLowerCase();
        setTimeout(() => {
            navigate(`/tires-details/${sku}`);
            setLoaderShow(false)
        }, 1000); // Delay of 2000 milliseconds (2 seconds)


    };

    return (
        <div>
            <section>
                <div className="main-tires-section">
                    <div className="container">
                        <h2 className="tyre-section-txt">Top Selling Tires</h2>
                        <div className="row">
                            {
                                isloading && tiresData && tiresData.data && tiresData.data.length > 0 ?
                                    tiresData.data.map((item, index) => (
                                        <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={index}>
                                            <div className="Drivetrain-cart ribbon_box position-relative">
                                                {
                                                   item.price && item.price >= 100 ? (
                                                        <div className="ribbon"><span>Free Shipping</span></div>
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                                <div className="engle-imge-upper" onClick={() => handleSubmit(item.brand, item.line, item.size, item.loadindex, item.speedrating, item.item_id)}>
                                                    {
                                                        !loaderShow ?
                                                            <button className="hover-btn " >  View Details </button>
                                                            :
                                                            <button className="hover-btn loading " > Loading  </button>
                                                    }


                                                    {
                                                        item.s3_images ?
                                                            <img className="" src={S3_IMAGES + item.s3_images} alt="img" height="100%" width="100%" />
                                                            :

                                                            item.image_url1
                                                                ?
                                                                <img className="" src={item.image_url1} alt="img" height="100%" width="100%" />
                                                                :
                                                                <img className="w-100" src={S3_IMAGES + item.logo_s3_url} alt="img" height="100%" width="100%" />

                                                    }
                                                </div>


                                                <div className="cart-body">
                                                    <h3 className='top_sell_cat_text'>{item.category_name}</h3>
                                                    <span className="m-0 description text-dark" >{item.brand} {item.line} {item.size} {item.loadindex} {item.speedrating}</span>

                                                    <div className="price-cart">
                                                        <div className="price mt-2">
                                                            <strong>{item.part_number}</strong>
                                                        </div>
                                                        <div className="price" title='Stock'>
                                                            {
                                                                item.quantity == 0 ?
                                                                    <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> {item.quantity}</span>
                                                                    :
                                                                    <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {item.quantity}</span>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="price-cart">
                                                        <div className="price">
                                                            <h4 className="price_tag m-0">${parseFloat(item.price).toFixed(2)}</h4>
                                                            {/* <p className="m-0">$81<span>/month</span></p> */}
                                                        </div>
                                                        <div className="cart-btn">
                                                            {/* <a href={`wheels?brandname=${item.brand_desc}&brandid=${item.brand_id}`}> */}
                                                            <img src={S3_IMAGES + item.logo_s3_url} alt="img" height="100%" width="100%" />
                                                            {/* </a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                    :
                                    Array.from({ length: 12 }).map((_, idx) => (
                                        <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={idx}>
                                            <div className="card-tyr">
                                                <div className="tyre-imge-upper">
                                                    <ShimmerThumbnail height={163} rounded />
                                                </div>
                                                <div className="cart-body">
                                                    <h3 className='top_sell_cat_text'><ShimmerButton size="sm" /></h3>
                                                    <div className="price-cart">
                                                        <div className="price">
                                                            <h4 className="price_tag m-0"><ShimmerButton size="sm" /></h4>
                                                            <div className="m-0"><ShimmerButton size="sm" /></div>
                                                        </div>
                                                        {/* <div className="cart-btn">
                                                            <a href="w">
                                                                <ShimmerButton size="sm" />
                                                            </a>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    ))
                            }


                        </div>
                        <div className="view-catagirs-btn" >
                            <a href='/tires'>
                                <button className="vieww-all">View All</button>
                            </a>

                        </div>
                    </div>
                </div>
            </section>
            <Modal show={showModal} onHide={handleClose} size="" centered>
                {/* <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className='mainDiv'>
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <span>Oops! Tires are not loading now !</span>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <strong>Reach out to us</strong>
                            </div>

                        </div>

                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <span><i className="fa fa-envelope-o" aria-hidden="true"></i></span><br></br>
                                {/* <span className='text-danger'>contact@extremeperformance.com</span> */}
                                <span className='text-danger'><a href="mailto:contact@extremeperformance.com" className='text-danger'>contact@extremeperformance.com</a></span>
                            </div>
                            <div className='col-md-12 text-center'>
                                <span><i className="fa fa-phone" aria-hidden="true"></i></span>
                                <br></br>
                                <span className='text-danger'><a href="tel:4808929433" className='text-danger'>480-892-9433</a></span>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
