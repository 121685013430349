import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import MENUBAR from "../../components/Menu";
import SEARCHBAR from "../../components/SeachBar";
import FOOTER from "../../components/Footer";
import SERVICES from "../../components/Footer/services";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CheckoutThankyou() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('order_id');
    const orderKey = queryParams.get('order_key');
    const [isHidden, setIsHidden] = useState(false);
    const [getLoader, setLoader] = useState(true);
    const [getText, setText] = useState('wait');

    const WooCommerceUrl = 'https://extremeperformance.com/wordpress';

    useEffect(() => {
        deleteCartApi();


        // Check if both parameters exist
        if (!orderId || !orderKey) {
            navigate('/');
            setTimeout(() => {
                setIsHidden(true);
            }, 2000);
        } else {
            fetchOrder(orderId, orderKey);

        }
    }, [orderId, orderKey, navigate]);

    const fetchOrder = async (orderId, orderKey) => {
        try {
            setLoader(true);
            setText('wait');
            const response = await axios.get(`${WooCommerceUrl}/wp-json/custom-api/v1/order`, {
                params: {
                    order_id: orderId,
                    order_key: orderKey,
                },
            });
            const orderDetails = response.data;
            if (orderDetails) {
                setLoader(false);
                setIsHidden(true);
            }
        } catch (error) {
            console.error("Error fetching order:", error);
            setText(error.response.data.message);
            setIsHidden(true);
            toast.info(error.response.data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setTimeout(() => {
                navigate('/');
            }, 1000);
        }
    };


    const deleteCartApi = () => {

        const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/clear`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {}
            };
            axios
                .request(config)
                .then((response) => {
                    localStorage.removeItem('totalCartItems');
                })
                .catch((error) => {
                    console.log(error);

                });
        } catch (error) {
            console.error('Error clearing data:', error);

        }
    };


    return (
        <>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR />
            <MENUBAR />
            <section>
                <div className='mt-5'>
                    {getLoader ?
                        <div className='d-flex justify-content-center align-items-center gap-1'>
                            {getText !== "wait" && <i className="fa fa-exclamation-circle text-danger fs-3" aria-hidden="true"></i>}{getText === "wait" ? "Please wait a moment..." : getText}
                        </div>
                        :
                        <>
                            <div className='d-flex justify-content-center align-items-center gap-1'>
                                <i class="fa fa-check-circle text-success fs-3" aria-hidden="true"></i> Thank you. Your order has been received.
                            </div>
                            <div className="_1AtVbE col-12-12">
                                <div className="Vy94J0 mb-2">
                                    <button className="_2KpZ6l _1sbqEe _3dESVI text-light"><a href='/' className=" text-light" ><span> Continue Shopping</span></a></button>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>
            <SERVICES />
            <FOOTER />
        </>
    )
}