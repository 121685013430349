import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ShimmerThumbnail } from "react-shimmer-effects";
import Itemcards from "../../../components/Cards/similarItemCards";

// import { useNavigate } from 'react-router-dom';

export default function Bought(prop) {
  const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;
  const navigate = useNavigate();
  const { data } = prop;
  // console.log(data);
  const [dataSubCategory, setSubDataCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
  const dataYear = vehicalDetails ? vehicalDetails.year : "";
  const dataMake = vehicalDetails ? vehicalDetails.make : "";
  const dataModel = vehicalDetails ? vehicalDetails.model : "";
  const dataModelVarient = vehicalDetails ? vehicalDetails.variant : "";
  const [isdata, setIsdata] = useState(true);

  useEffect(() => {
    fetchProductData();
  }, []);
  const fetchProductData = async () => {
    try {

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${mainUrl}/api/getSubCategorySimilarData/${data}?year=${dataYear}&make=${dataMake}&model=${dataModel}&varient=${dataModelVarient}`,
      };
      axios.request(config)
        .then((response) => {
          setSubDataCategory(response.data);

          if(response.data.status == 500){
            setIsdata(false);
    
           }
          setIsLoading(false);
          // console.log('++++++++++response', response);

        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };




  return (
    <div>
      <section className='similar-products-acc'>

        <div className="container">
          <hr />
          <div className="col-lg-12">
            <div className="row">
            {isdata ? (<>
              {
                !isLoading && dataSubCategory && dataSubCategory.data2 && dataSubCategory.data2.length > 0 ?
                <h3 className="Simila-txt py-3">Customer also bought</h3> : ""
              }
              
              {
                !isLoading && dataSubCategory ?
                  dataSubCategory.data2 && dataSubCategory.data2.length > 0 ?
                    dataSubCategory.data2.map((itm, idx) => {
                      return (<Itemcards data={itm} key={itm.item_id} />);
                    })
                    : ""
                  :

                  Array.from({ length: 5 }).map((_, idx) => (
                    <div className="col-lg-2 col-md-3 col-sm-4 col-6 customeChanges" key={idx}>
                      <div className="card-tyr smilar-product">
                        <div className="tyre-imge-upper">
                          <ShimmerThumbnail height={163} rounded />
                        </div>
                        <div className="cart-body">
                          <h6><ShimmerThumbnail height={10} rounded /></h6>
                          <h4><ShimmerThumbnail height={20} rounded /></h4>
                          <div className="price-cart">
                            <div className="price">
                              <h5 className="m-0"><ShimmerThumbnail height={20} rounded /></h5>
                              <h4 className="m-0"><ShimmerThumbnail height={20} rounded /><span><ShimmerThumbnail height={20} rounded /></span></h4>
                            </div>
                            <div className="cart-btn">
                              <a href="/">
                                <ShimmerThumbnail height={20} rounded />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))

                }</>) : ("") }



            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
