import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import { Helmet } from 'react-helmet';





export default function Warranty() {

    const currentLink = window.location.href;
    const url = new URL(currentLink);
    const updatedUrl = url.toString();
    
    const [isHidden, setIsHidden] = useState(false);
    const [cartItems, setCartItems] = useState("");
    const [showMenuSide, setShowMenuSide] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);

    }, []);


    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    return (
        <>

            <Helmet>
                <title>Terms and Conditions</title>
                <meta name="description" content='All products carry the manufacturer’s warranty policy.' />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>

            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />


            <section>
                <div className="warrent-banner">
                    <div className="parformane-bar ">
                        <div className="container">
                            <ul className="p-0 m-0 listing-parmofamne">
                                <li>
                                    <a href="#">Home /</a>
                                </li>
                                <li className="active-bread">
                                    <a href="">Warranty Policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <h2 className="text-center txt-main-listing">Our Warranty Policy</h2>
                </div>
            </section>

            <section className="warranty-instruction">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mian-vernty-img">
                            <img src="images/lucery.png" alt="img" width="100%" height="100%" />
                        </div>
                        <div className="col-lg-6 warnnty-text">
                            <h2>Warranty Instructions</h2>
                            <h3 className="check-text">
                                <img className="me-3" src="images/check.svg" alt="" width="100%" height="100%" /> All products
                                carry the manufacturer’s warranty policy.
                            </h3>
                            <h3 className="check-text">
                                <img className="me-3" src="images/check.svg" alt="img" width="100%" height="100%" /> One year
                                warranty on chrome plate wheels.
                            </h3>
                            <h3 className="check-text">
                                <img className="me-3" src="images/check.svg" alt="img" width="100%" height="100%" /> Should a
                                problem arise, we will assist in any way possible.
                            </h3>
                            <h3 className="check-text">
                                <img className="me-3" src="images/check.svg" alt="img" width="100%" height="100%" /> All returns
                                must be pre-approved and accompanied by the original invoice.
                            </h3>
                            <h3 className="check-text">
                                <img className="me-3" src="images/check.svg" alt="img" width="100%" height="100%" /> If necessary,
                                the item(s) will be shipped (prepaid by the customer) to the
                                manufacturer for inspection.
                            </h3>
                            <h3 className="check-text">
                                <img className="me-3" src="images/check.svg" alt="img" width="100%" height="100%" /> The item(s)
                                will be repaired or replaced at the sole discretion of the
                                manufacturer and if approved, returned (prepaid by the manufacturer).
                            </h3>
                            <h3 className="check-text text_link">
                                <img className="me-3 invisible" src="images/check.svg" alt="img" width="100%" height="100%" /> For
                                warranty questions please contact us{" "}
                                <a className="contact-anker" href="/contact-us">
                                    here.
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>
            </section>


            < FOOTER />
        </>

    )
}
