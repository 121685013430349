import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import NEWSLETTER from "../../components/NewsLetter";
import FOOTER from "../../components/Footer";
import SERVICES from "../../components/Footer/services";
import BANNER from "./components/banner";
import CATEGORY from "./components/category";
import POPULAR from "./components/popularproduct";
import TOPSELLINGTIRES from "./components/topsellingtires";
import SEARCHFORVEHICAL from "./components/searchforvehical";
import TOPSELLINGWHEELS from "./components/topsellingwheels";
import PARTS from "./components/parts";
import BRANDS from "./components/brands";
import { Helmet } from 'react-helmet';



export default function Dashboard() {
    const [isHidden, setIsHidden] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("");

    const [cartItems, setCartItems] = useState("");
    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');

        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
    }, []);




    useEffect(() => {

        console.log("Clearing localStorage items");
        localStorage.removeItem('setSwheelsDiameterArray');
        localStorage.removeItem('setSwheelsWidthArray');
        localStorage.removeItem('setSwheelsPatternArray');
        localStorage.removeItem('setSwheelsBradArray');
        localStorage.removeItem('setSwheelsBradNameArray');
        localStorage.removeItem('setSwheelsModelArray');
        localStorage.removeItem('setSwheelsWheelOffsetArray');
        localStorage.removeItem('setSwheelsColourWheel');
        localStorage.removeItem('setSwheelsFirstValue');
        localStorage.removeItem('setSwheelsMaxPrice');
        localStorage.removeItem('setSwheelsMinPrice');
        localStorage.removeItem('setSwheelsStockWheel');
        localStorage.removeItem('setSwheelsSecondValue');
        localStorage.removeItem('setWheelDiameterArray');
        localStorage.removeItem('setWheelWidthArray');
        localStorage.removeItem('setWheelPatternArray');
        localStorage.removeItem('setWheelBradArray');
        localStorage.removeItem('setWheelBradNameArray');
        localStorage.removeItem('setWheelWheelOffsetArray');
        localStorage.removeItem('setWheelModelArray');
        localStorage.removeItem('setWheelMaxPrice');
        localStorage.removeItem('setWheelMinPrice');
        localStorage.removeItem('setWheelFirstValue');
        localStorage.removeItem('setWheelSecondValue');
        localStorage.removeItem('setWheelColourWheel');
        localStorage.removeItem('setWheelStockWheel');
        localStorage.removeItem('setWheelIsCheckedStock');
        localStorage.removeItem('setWheelSortingValue');
        localStorage.removeItem('setWheeloffsetCurrentPage');
        localStorage.removeItem('setWheelCurrentPage');
        localStorage.removeItem('setWheelsDiameterArray');
        localStorage.removeItem('setWheelsWidthArray');
        localStorage.removeItem('setWheelsPatternArray');
        localStorage.removeItem('setWheelsBradArray');
        localStorage.removeItem('setWheelsBradNameArray');
        localStorage.removeItem('setWheelsWheelOffsetArray');
        localStorage.removeItem('setWheelsModelArray');
        localStorage.removeItem('setWheelsMaxPrice');
        localStorage.removeItem('setWheelsMinPrice');
        localStorage.removeItem('setWheelsFirstValue');
        localStorage.removeItem('setWheelsSecondValue');
        localStorage.removeItem('setWheelsColourWheel');
        localStorage.removeItem('setWheelsStockWheel');

        //tires

        localStorage.removeItem('setDiameterArray');
        localStorage.removeItem('setWidthArray');
        localStorage.removeItem('setPatternArray');
        localStorage.removeItem('setBradArray');
        localStorage.removeItem('setBradNameArray');
        localStorage.removeItem('setcategoryArray');
        localStorage.removeItem('setcategoryNameArray');
        localStorage.removeItem('setModelArray');
        localStorage.removeItem('setMaxPrice');
        localStorage.removeItem('setMinPrice');
        localStorage.removeItem('setFirstValue');
        localStorage.removeItem('setSecondValue');
        localStorage.removeItem('setSpeedLoadRange');
        localStorage.removeItem('setSpeedRatingArray');
        localStorage.removeItem('setratioArray');
        localStorage.removeItem('setPlyArray');
        localStorage.removeItem('setSideWallArray');
        localStorage.removeItem('setMilageRatingArray');
        localStorage.removeItem('setRunFlat');
        localStorage.removeItem('setstuddable');
        localStorage.removeItem('settiresizes');
        localStorage.removeItem('settiresizes');

        

        setTimeout(() => {
            setIsHidden(true);
        }, 1000);


    }, []);


    const showLoaderLine = (id) => {
        if (id === 1) {
            setIsHidden(false);
        } else {
            setIsHidden(true);
        }

    };

    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }


    return (
        <>
            <Helmet>
                <title>Extreme Performance</title>
                <meta name="description" content="We are dedicated to maintaining our position as a leading distributor, specializing in wheels, tires, and a wide array of accessories" />
                <link rel="canonical" href='https://www.extremeperformance.com/' />
            </Helmet>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <BANNER showLoaderLine={showLoaderLine} />
            <CATEGORY />
            <POPULAR />
            <TOPSELLINGTIRES />
            {/* <SEARCHFORVEHICAL /> */}
            <TOPSELLINGWHEELS />
            {/*<PARTS />*/}
            <BRANDS />
            <NEWSLETTER />
            <SERVICES />
            <FOOTER />

        </>
    )
}
