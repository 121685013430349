import { React, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function TopBar(prop) {
    const navigate = useNavigate();
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;

    const { isHidden } = prop;
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const paramName = params.get('redirected');
    if (paramName == 1) {
        deleteCartApi();
    }

    const goToFinance = () => {
        navigate(`/financing`);

    }

    const isVisitedViaBackButton = performance && performance.getEntriesByType('navigation').map(nav => nav.type).includes('back_forward')

    useEffect(() => {
        if (isVisitedViaBackButton) {
            fetchWooCommerceCart();
            fetchCartItems();
        } else {
            fetchWooCommerceCart();
            fetchCartItems();
        }

    }, [isVisitedViaBackButton])


    const deleteCartApi = () => {

        const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/clear`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {}
            };
            axios
                .request(config)
                .then((response) => {
                    localStorage.removeItem('totalCartItems');
                })
                .catch((error) => {
                    console.log(error);

                });
        } catch (error) {
            console.error('Error clearing data:', error);

        }
    };

    const fetchWooCommerceCart = () => {
        const cartKey = localStorage.getItem("cart-key") || "";
        const userToken = localStorage.getItem("user-token") || "";
        if (!cartKey) {
            console.error('Cart key is missing.');
            return;
        }

        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${mainUrl}/api/fetch-cart?cart_key=${cartKey}&user_token=${userToken}`,
            withCredentials: true,
        };

        axios
            .request(config)
            .then((response) => {
                console.log("Cart fetched successfully:", response.data);
                const cartItem = response.data.cartItems;
                if (cartItem.items.length === 0) {
                    deleteCartApi();
                }
            })
            .catch((error) => {
                console.error("Error fetching WooCommerce cart data:", error.message);
            });
    };

    const clearCartApi = async () => {
        const cartKey = localStorage.getItem("cart-key") || "";
        const userToken = localStorage.getItem("user-token") || "";
        if (!cartKey) {
            console.error('Cart key is missing.');
            return;
        }

        try {
            const response = await axios.get(`${mainUrl}/api/clear-cart?cart_key=${cartKey}&user_token=${userToken}`);
            if (response.data) {
                console.log("Cart cleared successfully");
            } else {
                console.warn("Unexpected error from clear cart api.")
            }
        } catch (error) {
            console.error("Error clearing WooCommerce cart data:", error.message);
        }
    }

    const fetchCartItems = async () => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/cart`,
                credentials: 'include',
                withCredentials: true,
            };
            axios
                .request(config)
                .then(async (response) => {
                    if (response && response.data && response.data.allData.length < 1) {
                        await clearCartApi();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div>
            <div className={isHidden ? 'loader-line hide' : 'loader-line'}></div>
            <section className="annusment-section">

                <div className="container mobile-mar">
                    <div className="main-annusment-bar">
                        <div className="inner-annusment-text">
                            <h1 className="m-0">
                                No credit financing available! 100 days flexible payment options!
                            </h1>
                        </div>
                        <div className="inner-annusment-button">
                            <button onClick={goToFinance}>Apply Now</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}