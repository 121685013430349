import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import WheelsItemscardStaggered from "../../components/Cards/items-staggered-card";
import BREADCRUMBAR from "../../components/BreadCrumBar";
import Pagination from 'react-bootstrap/Pagination';
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import { RangeSlider } from 'rsuite';
import "rsuite/dist/rsuite.css";
import axios from 'axios';
import Collapse from 'react-bootstrap/Collapse';
import { Helmet } from 'react-helmet';



export default function WheelsSerachByVehical() {
  const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
  const vehical_details = JSON.parse(localStorage.getItem('vehical_details'));
  const vehicalYear = queryParams.get('year');
  const vehicalMake = queryParams.get('make');
  const vehicalModel = queryParams.get('model');
  const vehicalVarient = queryParams.get('variant');
  const MAX_PAGES_DISPLAY = 10;
  const [showMenuSide, setShowMenuSide] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const categoryName = 'Wheels';
  const limit = 28;
  const [isLoadingWheels, setIsLoadingWheels] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * limit;
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [firstValue, setFirstValue] = useState(0);
  const [secondValue, setSecondValue] = useState(5000);
  const [sortingValue, setSortingValue] = useState(1);
  const [filterClass, setFilterClass] = useState('');
  const [diameterArray, setDiameterArray] = useState([]);
  const [widthArray, setWidthArray] = useState([]);
  const [patternArray, setPatternArray] = useState([]);
  const [brandArray, setBradArray] = useState([]);
  const [brandNameArray, setBradNameArray] = useState([]);
  const [modelArray, setModelArray] = useState([]);
  const [openDiameter, setOpenDiameter] = useState(true);
  const [openWidth, setOpenWidth] = useState(false);
  const [openPattern, setOpenPattern] = useState(false);
  const [openBrand, setOpenBrand] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);
  const [colourWheel, setColourWheel] = useState([]);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const [fmkID, setFmkID] = useState("");
  const [cartItems, setCartItems] = useState("");
  const [isCheckedStock, setIsCheckedStock] = useState(true);
  const [staggeredWheelData, setStaggeredWheelData] = useState("")

  const [rimDiameterWidthFront, setrimDiameterWidthFront] = useState([]);
  const [rimDiameterWidthRear, setrimDiameterWidthRear] = useState([]);

  const [frontSelection, setFrontSelection] = useState("");
  const [rearSelection, setRearSelection] = useState("");

  const [staggeredDiameterFront, setStaggeredDiameterFront] = useState("");
  const [staggeredDiameterRear, setStaggeredDiameterRear] = useState("");

  const [maxPriceDefault, setMaxPriceDefault] = useState(null);
  const [minPriceDefault, setMinPriceDefault] = useState(null);
  const [displayedProducts, setdisplayedProducts] = useState('');

  const [openDiameterStag, setOpenDiameterStag] = useState(true);
  const PRODUCTS_PER_PAGE = 28;

  const [isdataOnClick, setIsdataOnClick] = useState(true);

  // const startIndex = (currentPage - 1) * PRODUCT_PER_PAGE + 1;
  // const endIndex = Math.min(startIndex + PRODUCT_PER_PAGE - 1, totalProducts);


  // Calculate the total number of pages based on loaded data

  // Calculate the indices of products to be displayed on the current page
  const startIndex = (currentPage - 1) * PRODUCTS_PER_PAGE;
  const endIndex = Math.min(startIndex + PRODUCTS_PER_PAGE, totalProducts);


  const year = vehical_details ? vehical_details.year : "";
  const make_name = vehical_details ? vehical_details.make_name : "";
  const model_name = vehical_details ? vehical_details.model_name : "";
  const variant_name = vehical_details ? vehical_details.variant_name : "";
  const [isdata, setIsdata] = useState(true);


  useEffect(() => {
    const storedCartItems = localStorage.getItem('totalCartItems');
    if (storedCartItems) {
      const parsedCartItems = JSON.parse(storedCartItems);
      const numCartItems = Object.values(parsedCartItems);
      const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setCartItems(sum);
    }
  }, []);



  useEffect(() => {
    fetchWheelsData();
    setIsHidden(false);
  }, [isdataOnClick]);


  useEffect(() => {
    if (staggeredWheelData && staggeredWheelData.data) {
      const totalProducts = staggeredWheelData.data.products.length;
      setTotalPages(Math.ceil(totalProducts / PRODUCTS_PER_PAGE));

      const startIndex = (currentPage - 1) * PRODUCTS_PER_PAGE;
      const endIndex = Math.min(startIndex + PRODUCTS_PER_PAGE, totalProducts);
      setdisplayedProducts(staggeredWheelData.data.products.slice(startIndex, endIndex));

      setIsLoadingWheels(false);
    }
  }, [staggeredWheelData, currentPage]);


  const fetchWheelsData = async () => {
    document.body.classList.add('full-screen-loader');
    setIsLoadingWheels(true);

    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${mainUrl}/api/wheels/getStaggeredWheelData?year=${vehicalYear}&make=${vehicalMake}&model=${vehicalModel}&variant=${vehicalVarient}&sorting=${sortingValue}&startingPrice=${firstValue}&endPrice=${secondValue}&diameter=${diameterArray}&brand=${brandArray}&width=${widthArray}&colourWheel=${colourWheel}&style=${modelArray}&offset=${offset}&isStagered=${isChecked}&isCheckedStock=${isCheckedStock}&staggeredDiameterFront=${staggeredDiameterFront}&staggeredDiameterRear=${staggeredDiameterRear}`,
      };
      axios.request(config)
        .then((response) => {

          setStaggeredWheelData(response.data)
          if (response.data.status == 500) {
            setIsdata(false);

          }
          // console.log("dadadsad",response.data)
          setrimDiameterWidthFront(response.data.rimDiameterWidthFront)
          setrimDiameterWidthRear(response.data.rimDiameterWidthRear)
          // setTotalPages(response.data.totalPages);
          setTotalPages(Math.ceil(response.data.data.products.length / PRODUCTS_PER_PAGE));
          setTotalProducts(response.data.count);
          setIsLoadingWheels(false);
          setIsHidden(true);
          window.scrollTo({ top: 400, behavior: 'smooth' });
          document.body.classList.remove('full-screen-loader');
          setMaxPriceDefault((Math.ceil(response.data.maximumPrice)) + 1);
          setMinPrice(Math.floor(response.data.minimumPrice));
          setMaxPrice(Math.ceil(response.data.maximumPrice));
          setdisplayedProducts(response.data.data.products.slice(startIndex, endIndex));

        })
        .catch((error) => {
          setIsdata(false);

          setIsHidden(true);
          setIsLoadingWheels(false);
          document.body.classList.remove('full-screen-loader');
          // navigate(`/error`);

        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoadingWheels(false);
      setIsHidden(true);
      document.body.classList.remove('full-screen-loader');
      navigate(`/error`);

    }
  };

  const handleRemoveDiameter = (categoryName) => {
    const updatedCategories = diameterArray.filter((category) => category !== categoryName);
    setDiameterArray(updatedCategories);
  };

  const handleRemoveWidth = (width) => {
    const updatedCategories = widthArray.filter((id) => id !== width);
    setWidthArray(updatedCategories);

  };


  const handleRemoveModel = (width) => {
    const updatedCategories = modelArray.filter((id) => id !== width);
    setModelArray(updatedCategories);

  };

  const clearAllFilters = () => {

    setDiameterArray([]);
    setWidthArray([]);
    setPatternArray([]);
    setBradArray([]);
    setModelArray([]);
    setBradNameArray([]);

    setColourWheel("");
    setOpenDiameter(false);
    setOpenPattern(false);
    setOpenBrand(false);
    setOpenWidth(false);
    setOpenModel(false);
    setOpenFinish(false);
    setOpenPrice(false)
    setFirstValue(0);
    setSecondValue(5000);
    setIsdataOnClick(prevState => !prevState);

  }



  const showFiltersClick = () => {
    setFilterClass('active-filter')
    document.body.classList.add('home-layout')

  }

  const handleRemoveBrand = (width) => {
    const updatedCategories = brandArray.filter((id) => id !== width);
    setBradArray(updatedCategories);
    const updatedBrandNames = brandNameArray.filter((name) => name.id !== width);
    setBradNameArray(updatedBrandNames);

  };

  const handleWheelColor = (event) => {
    const selectedColor = event.target.value;
    setIsHidden(true);

    if (colourWheel.includes(selectedColor)) {
      const updatedColourWheel = colourWheel.filter((id) => id !== selectedColor);
      setColourWheel(updatedColourWheel);

    } else {
      const updatedColourWheel = [...colourWheel, selectedColor];
      setColourWheel(updatedColourWheel);

    }
  }

  const handleSorting = (event) => {
    const sortingValue = event.target.value;
    setSortingValue(sortingValue);
    setIsdataOnClick(prevState => !prevState);

  };


  const handlePageChange = (page) => {
    setCurrentPage(page);
    setdisplayedProducts(staggeredWheelData && staggeredWheelData.data ? staggeredWheelData.data.products.slice(startIndex, endIndex) : "");
    window.scrollTo({ top: 400, behavior: 'smooth' });
    setIsdataOnClick(prevState => !prevState);

  };

  const getPageNumbers = () => {
    const totalDisplayPages = Math.min(totalPages, MAX_PAGES_DISPLAY);
    const middlePage = Math.ceil(totalDisplayPages / 2);
    let startPage = Math.max(1, currentPage - middlePage + 1);

    if (startPage + totalDisplayPages > totalPages) {
      startPage = Math.max(1, totalPages - totalDisplayPages + 1);
    }

    return Array.from({ length: totalDisplayPages }, (_, index) => startPage + index);
  };


  function handleRanges(value) {
    setFirstValue(value[0]);
    setSecondValue(value[1]);
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
  }
  const setValueMenuSideBar = (value) => {
    setShowMenuSide(value)
  }

  const hideFilterClass = () => {
    setFilterClass('')
    document.body.classList.remove('home-layout')
  }

  const handleDiameterCheckboxChange = (event) => {
    setIsHidden(true);
    const sdiameter = event.target.value;
    if (diameterArray.includes(sdiameter)) {
      setDiameterArray(diameterArray.filter((id) => id !== sdiameter));
    } else {
      setDiameterArray([...diameterArray, sdiameter]);
    }
  };

  const handleWidthCheckboxChange = (event) => {
    setIsHidden(true);
    const sWidth = event.target.value;
    if (widthArray.includes(sWidth)) {
      setWidthArray(widthArray.filter((id) => id !== sWidth));
    } else {
      setWidthArray([...widthArray, sWidth]);
    }

  };




  const handleBrandCheckboxChange = (event) => {
    setIsHidden(true);
    const sBrand = event.target.value;
    const brandIdName = event.target.name;

    if (brandArray.includes(sBrand)) {
      setBradArray(brandArray.filter((id) => id !== sBrand));
    } else {
      setBradArray([...brandArray, sBrand]);
    }

    // Check if the category object is already in the selectedCategoriesName array
    const categoryObject = { id: sBrand, name: brandIdName };
    const categoryObjectIndex = brandNameArray.findIndex(
      (obj) => obj.id === sBrand
    );
    if (categoryObjectIndex !== -1) {
      setBradNameArray(
        brandNameArray.filter((obj) => obj.id !== sBrand)
      );
    } else {
      setBradNameArray([...brandNameArray, categoryObject]);
    }


  };

  const handleModelCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (modelArray.includes(sModle)) {
      setModelArray(modelArray.filter((id) => id !== sModle));
    } else {
      setModelArray([...modelArray, sModle]);
    }

  };



  const onRedirectHome = () => {
    localStorage.removeItem('vehical_details');
    setTimeout(() => {
      navigate('/');

    }, 1000);
  }

  const handleStaggeredChange = (event) => {
    setIsChecked(!isChecked)
    setDiameterArray([]);
    setWidthArray([]);
    setPatternArray([]);
    setBradArray([]);
    setBradNameArray([]);
    setBradNameArray([]);
    setModelArray([]);

    setOpenDiameter(false);
    setOpenPattern(false);
    setOpenBrand(false);
    setOpenWidth(false);
    setOpenModel(false);
    setOpenFinish(false);
  }


  const handleChangeStockAvail = () => {
    setIsCheckedStock(!isCheckedStock)
    setIsdataOnClick(prevState => !prevState);

  }

  const handleRemoveColor = () => {
    setColourWheel("");
  }




  const handleSelectChangeFront = (event) => {
    const selectedOption = event.target.value;
    // console.log('handleSelectChangeFront', selectedOption);
    if (selectedOption == staggeredDiameterFront) {
      setStaggeredDiameterFront("");
    } else {
      setStaggeredDiameterFront(selectedOption);
    }
    setDiameterArray([]);
    setWidthArray([]);
    setPatternArray([]);
    setBradArray([]);
    setBradNameArray([]);
    setBradNameArray([]);
    setModelArray([]);

    setOpenDiameter(false);
    setOpenPattern(false);
    setOpenBrand(false);
    setOpenWidth(false);
    setOpenModel(false);
    setOpenFinish(false);

  }

  const optionsColour = [
    { value: "Anthracite", label: "Anthracite" },
    { value: "Blue", label: "Blue" },
    { value: "Black", label: "Black" },
    { value: "Bronze", label: "Bronze" },
    { value: "Brushed", label: "Brushed" },
    { value: "Charcoal", label: "Charcoal" },
    { value: "Copper", label: "Copper" },
    { value: "Chrome", label: "Chrome" },
    { value: "Custom", label: "Custom" },
    { value: "Gray", label: "Gray" },
    { value: "Gun Metal", label: "Gun Metal" },
    { value: "Graphite", label: "Graphite" },
    { value: "Gold", label: "Gold" },
    { value: "Machined", label: "Machined" },
    { value: "Polished", label: "Polished" },
    { value: "Red", label: "Red" },
    { value: "Smoke", label: "Smoke" },
    { value: "Silver", label: "Silver" },
    { value: "Titanium", label: "Titanium" },
    { value: "White", label: "White" },
  ];
  const goBackToHomepage = () => {
    navigate('/');
  };

  // console.log('displayedProducts', displayedProducts)
  function onclickFilter() {
    setIsdataOnClick(prevState => !prevState);
  }
  return (
    <>{isdata ? "" : <Helmet>
      <title>Page Not Found</title>
    </Helmet>}

      <TOPBAR isHidden={isHidden} />
      <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
      <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
      {isdata ? (
        <>
          <BREADCRUMBAR categoryName={categoryName} />

          <section>
            <div className="main-section-lsting wheelsPageSectioin">
              <div className="container">
                <div className='d-flex  car-selection-title'>

                  <div className='vechicleName'>
                    <strong style={{ fontSize: '30px' }}> {vehicalDetails ? vehicalDetails.year : ""}&nbsp;
                      {vehicalDetails ? vehicalDetails.make_name : ""}
                      {vehicalDetails ? vehicalDetails.model_name : ""}&nbsp;
                      {vehicalDetails ? vehicalDetails.variant_name : ""}</strong>
                  </div>
                  <div className='staggeredCheckbox'>
                    <div className="price-filter">
                      <ul className='p-0 m-0 list-filter'>
                        <li>
                          <input
                            type="checkbox"
                            value="2"
                            className=' tgl tgl-light'
                            onChange={handleStaggeredChange}
                            id='stagerd'
                            name="staggered"
                          />
                          <label htmlFor='stagerd' className=' tgl-btn'></label>
                        </li>
                        <li className='staggeredLi'>
                          {
                            isChecked ?
                              <span>Staggered Wheels</span>
                              : <span>All Wheels</span>
                          }
                        </li>
                      </ul>
                    </div>


                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3">
                    <div className={`main-outer-filter ${filterClass}`}>
                      <div className="filter-crose " id="close-bar" >
                        {/* <button className="submit" onClick={hideFilterClass}>
                      Submit
                    </button> */}
                        <button className="abc" onClick={hideFilterClass}>
                          <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                        </button>
                      </div>


                      <div className="price-filter filter-button">
                        <div>
                          <button className='btn btn-danger' onClick={onclickFilter}>APPLY FILTER</button>
                        </div>
                      </div>


                      {
                        isChecked ?
                          <div className="price-filter ">
                            <h3 className="price-filter-text" role="button" onClick={() => setOpenDiameterStag(!openDiameterStag)} aria-expanded={openDiameterStag} aria-controls="width-collapse-text">
                              Wheel Diameter <i className={openDiameterStag ? "fa fa-minus" : "fa fa-plus"}></i>
                            </h3>
                            <Collapse in={openDiameterStag}>
                              <div id='id="width-collapse-text"'>
                                <div className='forntWheelDiameter'>
                                  <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                                    {/* <select className='form-control' value={staggeredDiameterFront} onChange={handleSelectChangeFront}>
                                <option value="">Select</option> */}
                                    {

                                      staggeredWheelData && staggeredWheelData.data ?
                                        staggeredWheelData.data.combinedRimDiameters && staggeredWheelData.data.combinedRimDiameters.length > 0 ?
                                          staggeredWheelData.data.combinedRimDiameters.map((item, index) => (

                                            <li key={item} value={item}>
                                              <label className='label-filter'>
                                                <input
                                                  type="checkbox"
                                                  id={item}
                                                  name={item}
                                                  value={item}
                                                  className='filter-box'
                                                  checked={staggeredDiameterFront == item}
                                                  onChange={handleSelectChangeFront}
                                                />
                                                {item + '"'}
                                                <label htmlFor={item} className='custome-checkboxes'></label>
                                              </label>
                                            </li>
                                          ))
                                          : ""
                                        : <ShimmerThumbnail height={10} rounded />
                                    }
                                  </ul>
                                  {/* </select> */}
                                </div>
                              </div>
                            </Collapse>
                          </div>
                          : <>
                            <div className="price-filter">
                              <h3 className="price-filter-text" role="button" onClick={() => setOpenDiameter(!openDiameter)} aria-expanded={openDiameter} aria-controls="example-collapse-text">
                                Wheel Diameter <i className={openDiameter ? "fa fa-minus" : "fa fa-plus"}></i>
                              </h3>
                              <Collapse in={openDiameter}>
                                <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                                  {
                                    staggeredWheelData && staggeredWheelData.data ?
                                      staggeredWheelData.data.rimDiameterWidthFront && staggeredWheelData.data.rimDiameterWidthFront.length > 0 ?
                                        staggeredWheelData.data.rimDiameterWidthFront.map((item, index) => (
                                          <li key={item}>
                                            <label className='label-filter'>
                                              <input
                                                type="checkbox"
                                                id={item}
                                                name={item}
                                                value={item}
                                                className='filter-box'
                                                checked={diameterArray.includes(String(item))}
                                                onChange={handleDiameterCheckboxChange}
                                              />
                                              {item + '"'}
                                              <label htmlFor={item} className='custome-checkboxes'></label>
                                            </label>
                                          </li>
                                        ))
                                        : ""
                                      : <ShimmerThumbnail height={10} rounded />
                                  }
                                </ul>
                              </Collapse>
                            </div>


                            <div className="price-filter ">
                              <h3 className="price-filter-text" role="button" onClick={() => setOpenWidth(!openWidth)} aria-expanded={openWidth} aria-controls="width-collapse-text">
                                Wheel Width <i className={openWidth ? "fa fa-minus" : "fa fa-plus"}></i>
                              </h3>

                              <Collapse in={openWidth}>
                                <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                                  {

                                    staggeredWheelData && staggeredWheelData.data.uniqueWidth ?
                                      staggeredWheelData.data.uniqueWidth.map((item, index) => (
                                        <li key={item.value}>
                                          <label className='label-filter'>
                                            <input
                                              type="checkbox"
                                              id={"width" + item.value}
                                              name={item.value}
                                              value={item.value}
                                              className='filter-box'
                                              checked={widthArray.includes(String(item.value))}
                                              onChange={handleWidthCheckboxChange}
                                            />
                                            {item.value + '"'}
                                            <label htmlFor={"width" + item.value} className='custome-checkboxes'></label>
                                          </label>

                                        </li>
                                      ))
                                      : <ShimmerThumbnail height={10} rounded />
                                  }
                                </ul>
                              </Collapse>
                            </div>

                          </>

                      }





                      <div className="price-filter ">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenBrand(!openBrand)} aria-expanded={openBrand} aria-controls="brand-collapse-text">
                          Brands <i className={openBrand ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openBrand}>
                          <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                            {

                              staggeredWheelData && staggeredWheelData.data.uniqueBrandsArray ?
                                staggeredWheelData.data.uniqueBrandsArray.map((item, index) => (
                                  <li key={item.brand_id}>
                                    <label className='label-filter'>
                                      <input type="checkbox"
                                        id={item.brand_id}
                                        name={item.brand_desc}
                                        value={item.brand_id}
                                        className='filter-box'
                                        checked={brandArray.includes(String(item.brand_id))}
                                        onChange={handleBrandCheckboxChange}
                                      /> {item.brand_desc}
                                      <label htmlFor={item.brand_id} className='custome-checkboxes'></label>

                                    </label>
                                  </li>
                                ))
                                : <ShimmerThumbnail height={10} rounded />
                            }
                          </ul>
                        </Collapse>
                      </div>

                      <div className="price-filter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenModel(!openModel)} aria-expanded={openModel} aria-controls="brand-collapse-text">
                          Model <i className={openModel ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openModel}>
                          <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                            {

                              staggeredWheelData && staggeredWheelData.data.uniqueStyles ?
                                staggeredWheelData.data.uniqueStyles.map((item, index) => (
                                  <li key={item.style}>
                                    <label className='label-filter'>
                                      <input type="checkbox"
                                        id={item.style}
                                        name={item.style}
                                        value={item.style}
                                        className='filter-box'
                                        checked={modelArray.includes(String(item.style))}
                                        onChange={handleModelCheckboxChange}
                                      /> {item.style}
                                      <label htmlFor={item.style} className='custome-checkboxes'></label>

                                    </label>
                                  </li>
                                ))
                                : <ShimmerThumbnail height={10} rounded />
                            }
                          </ul>
                        </Collapse>
                      </div>


                      <div className="price-filter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenFinish(!openFinish)} aria-expanded={openFinish} aria-controls="color-collapse-text">
                          Finish<i className={openFinish ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openFinish}>
                          <div id='stock-collapse-text'>
                            <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                              {
                                optionsColour.map((option, index) => (
                                  <li key={option.value}>
                                    <label className="label-filter">
                                      <input
                                        type="checkbox"
                                        name="wheelWidth"
                                        value={option.value}
                                        id={option.value}
                                        onChange={handleWheelColor}
                                        checked={colourWheel.includes(String(option.value))}
                                      />
                                      {option.value}
                                      <label htmlFor={option.value} className='custome-checkboxes'></label>
                                    </label>
                                  </li>
                                ))
                              }
                            </ul>
                          </div>
                        </Collapse>
                      </div>


                      <div className="price-filter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenPrice(!openPrice)} aria-expanded={openPrice} aria-controls="price-collapse-text">
                          Price
                          <i
                            className={openPrice ? "fa fa-minus" : "fa fa-plus"}
                          >

                          </i>
                        </h3>
                        {
                          !isLoadingWheels && minPrice !== null && maxPrice !== null ?
                            <Collapse in={openPrice}>
                              <div id='price-collapse-text'>
                                <form >
                                  <div className="aside-price-range-form d-flex  m-3" >
                                    <div className="form-field me-2" style={{ width: '80%' }}>
                                      {/* <label htmlFor="filter-price-min">$ Min</label> */}
                                      <input
                                        className="form-control"
                                        type="text"
                                        id="minPrice"
                                        value={"$" + minPrice}
                                        placeholder={"$" + minPrice}
                                        autoComplete="off"
                                        maxLength="10"
                                        style={{ width: '100%' }}
                                        disabled={true}
                                      />
                                    </div>
                                    <div className="form-field me-2" style={{ width: '80%' }}>

                                      <input
                                        className="form-control"
                                        type="text"
                                        id="maxPrice"
                                        value={"$" + maxPrice}
                                        placeholder={"$" + maxPrice}
                                        autoComplete="off"
                                        maxLength="10"
                                        style={{ width: '100%' }}
                                        disabled={true}
                                      />
                                    </div>

                                  </div>
                                </form>

                                <div className="">
                                  <RangeSlider
                                    min={0}
                                    step={1}
                                    max={5000}
                                    defaultValue={[minPrice, maxPrice]}
                                    onChangeCommitted={handleRanges}
                                    tipprops={{ visible: true }}
                                    tooltipvisible="true"

                                  />
                                </div>
                              </div>
                            </Collapse> : ""}
                      </div>

                    </div>
                  </div>

                  <div className="col-lg-9 filter-for-mobile">
                    <div className='selectedFilters '>
                      {
                        diameterArray && diameterArray.length > 0 ?
                          diameterArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item + '"'} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveDiameter(item)}></i>
                            </span>
                          ))
                          : ""
                      }

                      {
                        widthArray && widthArray.length > 0 ?
                          widthArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item + '"'} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveWidth(item)}></i>
                            </span>
                          ))
                          : ""
                      }

                      {
                        brandNameArray && brandNameArray.length > 0 ?
                          brandNameArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item.id}>
                              <span> {item.name} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                            </span>
                          ))
                          : ""
                      }

                      {
                        modelArray && modelArray.length > 0 ?
                          modelArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveModel(item)}></i>
                            </span>
                          ))
                          : ""
                      }

                      {
                        colourWheel && colourWheel.length > 0 ?
                          colourWheel.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveColor(item)}></i>
                            </span>
                          ))
                          : ""
                      }


                      {
                        diameterArray.length > 0 || widthArray.length > 0 || brandNameArray.length > 0 || modelArray.length > 0 || colourWheel.length > 0 ?
                          <span className="clearFilterStyle" onClick={clearAllFilters} >
                            <span>Clear filters</span>
                          </span>
                          :
                          ""
                      }
                    </div>

                    <div className="d-flex filters-sort-by mainDivStaggered">
                      <div className='textShowing'>
                        <div className="">
                          {totalProducts > 0 ?
                            <p className="px-5">
                              {/* Showing {productsPerPage} of {parseInt(totalProducts).toLocaleString('en-US')} products */}
                              Showing {startIndex} - {endIndex} of {parseInt(totalProducts).toLocaleString('en-US')} products

                            </p>
                            : ""
                          }
                        </div>
                        <div className='stockCheckbox'>
                          <ul className='stockCheckboxUl'>
                            <li>
                              <input
                                type="checkbox"
                                value="2"
                                className=' tgl tgl-light'
                                onChange={handleChangeStockAvail}
                                id='stockChecki'
                                name="stockCheck"
                              />
                              <label htmlFor='stockChecki' className=' tgl-btn'></label>
                            </li>
                            <li className='staggeredLi'>
                              {
                                isCheckedStock ?
                                  <span>In Stock Items</span>
                                  : <span>All Items</span>
                              }
                            </li>
                          </ul>
                        </div>


                        <div className="">
                          {totalProducts > 0 ?
                            <select className="form-control" name="sorting" id="sortingWithPrice" onChange={handleSorting} value={sortingValue}>
                              <option disabled >Sort By Price</option>
                              <option value="1" >Low-High</option>
                              <option value="2">High-Low</option>
                            </select>
                            : ""
                          }
                        </div>
                        <div className="main-headin">
                          <div className="main-filter-resposive">
                            <button className="mobile-filter-btn" onClick={showFiltersClick}> <svg className="me-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.25901 14.35L5.25888 14.35C5.24769 14.3502 5.23405 14.3476 5.21863 14.339L5.21836 14.3389C5.19936 14.3282 5.19074 14.318 5.18703 14.3124C5.17887 14.2972 5.176 14.2839 5.176 14.2707V7.91365C5.176 7.82275 5.1652 7.72056 5.13235 7.61486C5.09945 7.50839 5.04961 7.41561 4.99386 7.33726L0.724519 1.26892C0.724485 1.26887 0.724451 1.26882 0.724418 1.26877C0.676725 1.20083 0.65495 1.13291 0.650688 1.06991C0.64665 1.00345 0.660203 0.933143 0.696549 0.863781L0.696552 0.863774C0.732993 0.794228 0.783357 0.74264 0.84197 0.707465L0.841971 0.707466L0.843135 0.706765C0.902543 0.670943 0.973036 0.65 1.05357 0.65H12.9463C13.0271 0.65 13.0973 0.670932 13.1564 0.706609L13.1578 0.70745C13.2164 0.742594 13.2667 0.794096 13.3032 0.863535C13.3407 0.935416 13.3534 1.00516 13.3492 1.06874C13.345 1.13245 13.3232 1.20066 13.2755 1.26862C13.2755 1.26872 13.2754 1.26882 13.2753 1.26892L9.0045 7.33779L9.00402 7.33848C8.94618 7.42089 8.90098 7.51067 8.86985 7.6068L8.86979 7.60678L8.8672 7.61518C8.83481 7.71999 8.82355 7.82177 8.82355 7.91441V12.1555C8.82355 12.1724 8.81984 12.1838 8.81525 12.1923C8.81075 12.1999 8.8025 12.2101 8.78506 12.2207L8.34805 12.4857L8.34827 12.4864L5.3042 14.3361C5.30409 14.3362 5.30399 14.3362 5.30388 14.3363C5.28736 14.3462 5.27261 14.3497 5.25901 14.35ZM5.27154 14.9999C5.14389 15.0023 5.01635 14.9707 4.90099 14.9061L5.27154 14.9999Z" stroke="#E81C2E" strokeWidth="1.3"></path>
                            </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className='row'>
                      {
                        !isLoadingWheels && displayedProducts ?
                          displayedProducts.length > 0 ?
                            displayedProducts.map((itm, idx) => {
                              return (<WheelsItemscardStaggered data={itm} key={(itm.sku ? itm.sku : idx)} staggered={isChecked} />);
                            })
                            :
                            <div className='container m-0 text-center'>
                              <h2>No Product Found</h2>
                              <p>Sorry, we couldn't find any products matching your search criteria.</p>
                              <p ><a href='#' className=' btn btn-sm btn-danger' onClick={onRedirectHome}>Click here</a></p>
                            </div>

                          :
                          Array.from({ length: 28 }).map((_, idx) => (
                            <div className="col-lg-3 col-6" key={idx}>
                              <a href="#">
                              </a>
                              <div className="Drivetrain-cart"><a href="">
                                <div className="engle-imge-upper">
                                  {/* <button className="hover-btn">View Details</button> */}
                                  <ShimmerThumbnail height={163} rounded />
                                </div>
                              </a>
                                <div className="cart-body">
                                  <a href="#">
                                    <h6><ShimmerButton size="sm" /></h6>
                                    <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>
                                  </a>
                                  <div className="price-cart">
                                    <a href="#">
                                      <div className="price">
                                      </div>
                                    </a>
                                    <div className="cart-btn">
                                      <a href="#">
                                      </a>
                                      <a href="#">
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          ))
                      }


                    </div>
                    <div className="d-flex mt-4 justify-content-center">
                      <Pagination>
                        <Pagination.First
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(1)}
                        />
                        <Pagination.Prev
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        />

                        {getPageNumbers().map((page) => (
                          <Pagination.Item
                            key={page}
                            active={currentPage === page}
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </Pagination.Item>
                        ))}

                        <Pagination.Next
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(currentPage + 1)}
                        />
                        <Pagination.Last
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(totalPages)}
                        />
                      </Pagination>
                    </div>
                    <div className='text-light'>{fmkID}</div>
                  </div>

                </div>
              </div>
            </div>
          </section >
        </>

      )
        : (<div className="error-screen">
          <h1><strong>404</strong></h1>
          <h2>PAGE NOT FOUND</h2>
          <button className="btn btn-sm btn-danger" onClick={goBackToHomepage}>
            Go back to homepage
          </button>
        </div>)}
      <FOOTER />


    </>
  )
}
