import { React, useState, useEffect } from 'react';

import axios from 'axios';

export default function Vehicle(prop) {
  const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;
  const { item_id } = prop;
  // console.log("item_id+++++++++=========",item_id)
  const [vehicleDetails, setVehicleDetails] = useState([]);
  // console.log("vehicleDetails++++++++++",vehicleDetails)
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    fetchProductData();
  }, []);
  const fetchProductData = async () => {
    try {

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${mainUrl}/api/getVehicleDetails/${item_id}`,
      };
      axios.request(config)
        .then((response) => {
         setVehicleDetails(response.data);
          setIsLoading(false);
          // console.log('++++++++++', response.data);

        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const groupByUniqueKey = (data) => {
    const groupedData = {};
    data.forEach((details) => {
      const key = `${details.year}-${details.make_name}-${details.model_name}`;
      if (!groupedData[key]) {
        groupedData[key] = {
          year: details.year,
          make_name: details.make_name,
          model_name: details.model_name,
          submodels: [details.sub_model_name],
        };
      } else {
        groupedData[key].submodels.push(details.sub_model_name);
      }
    });
  
    // Sort submodels alphabetically
    Object.values(groupedData).forEach((group) => {
      group.submodels.sort();
    });
  
    return Object.values(groupedData);
  };
  



  return (
    <>
      <section className='vehicle-products'>
        <div className="container mt-4">
        {(!isLoading && vehicleDetails.data && vehicleDetails.data.length > 0) ?  <strong className='vehicle_dt'>This Part Fits:</strong>: ""}
       
          {(!isLoading && vehicleDetails.data && vehicleDetails.data.length > 0) ? <hr/> : ""}
  
          {vehicleDetails.data && !isLoading && vehicleDetails.data.length > 0 && (
           <div>
          <div className='vehicle-data'>
            <div className="row">
              <div className="col">
                Year
              </div>
              <div className="col">
                Make
              </div>
              <div className="col">
                Model
              </div>
              <div className="col">
                Submodel
              </div>
            </div>
            </div>
            <div className="vehicle-details-table">
              {groupByUniqueKey(vehicleDetails.data).map((groupedDetails, index) => (
                <div className="row" key={index}>
                  <div className="col">{groupedDetails.year}</div>
                  <div className="col">{groupedDetails.make_name}</div>
                  <div className="col">{groupedDetails.model_name}</div>
                  <div className="col">{groupedDetails.submodels.join(', ')}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        </div>
      </section>
    </>
  );
  
  
}
