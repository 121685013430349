import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ShimmerThumbnail } from "react-shimmer-effects";

export default function Brands() {
    const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [brandData, setBrandData] = useState(true);

    useEffect(() => {
        fetchBrandData();
    }, []);
    const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";



    const onSubmit = (item) => {
        const vendor = item.vendor_id;
        const trimmed_brand_name = item.name.trim(); // Removes leading and trailing whitespace
        const modified_brand_name = trimmed_brand_name.replace(/\s+/g, '-');
        
        if (vendor === 1) {
            setTimeout(() => {
                navigate(`/categories-home/brand-category/${modified_brand_name}/${item.brand_id}`);
            }, 1000);
        } else if(vendor === 3){
            setTimeout(() => {
                navigate(`/tires/${item.brand_id}/${modified_brand_name}`);
            }, 1000);
        }else {
            setTimeout(() => {
                navigate(`/wheels/${item.brand_id}/${modified_brand_name}`);
            }, 1000);
        }

    };

    const onChange = () => {
        setTimeout(() => {
            navigate('/brands');
        }, 1000);
    };

    const fetchBrandData = async () => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/brand12`,
            };
            axios.request(config)
                .then((response) => {
                    setBrandData(response.data);
                    setIsLoading(false);

                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }



    }


    return (
        <div>
            <section>
                <div className="main-top-brands">
                    <div className="container">
                        <h2 className="tyre-section-txt">Top Brands</h2>
                        <div className="row">
                            {
                                !isLoading && brandData ?
                                    brandData.data && brandData.data.length > 0 ?
                                        brandData.data.map((item, index) => (

                                            <div className="col-lg-2 col-md-3 col-sm-4 col-6  mt-5 brandPageTile "
                                                key={item.id}
                                                onClick={() => onSubmit(item)} // Pass the item to onSubmit function
                                                style={{ cursor: 'pointer' }}
                                            >
                                               <div className='image_brands'>
                                               <img src={ S3URL + item.logo_s3_url} alt={item.name} className='img-fluid' height="100%" width="100%" />
                                               </div>
                                                
                                            </div>

                                        ))
                                        : "No Brand Found"
                                    :
                                    Array.from({ length: 10 }).map((_, idx) => (
                                        <div className="col-lg-2 col-md-3 col-sm-4 col-6  mt-5 brandPageTile" style={{ height: 196 }} key={idx}>
                                            <ShimmerThumbnail height={90} width={150} />
                                        </div>
                                    ))
                            }
                        </div>
                        <div className="view-catagirs-btn" onClick={onChange}>
                            
                                <button className="vieww-all"> View All</button>
                          


                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}
