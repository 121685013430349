import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function Itemcards(prop) {
    const { data } = prop;

    // console.log('similar',data)
    const S3_URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const navigate = useNavigate();
    let title = `${data.brand}  ${data.line} ${data.load_range} ${data.speedrating} ${data.size}`
    const [loaderShow, setLoaderShow] = useState(false);
    const handleSubmit = (event) => {
        setLoaderShow(true)
        
        const item_id = data.item_id;
       
        setTimeout(() => {
            navigate(`/tires-details/${item_id}`);

            window.location.reload();
            setLoaderShow(false)
        }, 1000); // Delay of 2000 milliseconds (2 seconds)


    };
    let first_image_path = "";
    let image = data.s3_images;
    // let logo = data.logo;

    if (image) {
        first_image_path = S3_URL + image;
    }
    // } else if (logo) {
    //     first_image_path = S3_URL + logo;
    // }
    const onSubmit = (item) => {
        // navigate('/brands/brands-products', { state: item });       


    };

    return (
        <div className="col-lg-2 col-6 customeChanges">

            <div className="Drivetrain-cart wheelsPage">
            {
                        data.price &&  data.price >= 100 ? (
                                <div className="ribbon"><span>Free Shipping</span></div>
                            ) : (
                                ""
                            )
                        }
                <div className="engle-imge-upper" onClick={handleSubmit}>
                    {
                        !loaderShow ?
                            <button className="hover-btn " >  View Details </button>
                            :
                            <button className="hover-btn loading " > Loading  </button>
                    }


                    {
                        data && first_image_path ?
                            <img className="" src={first_image_path} alt="img" height="100%" width="100%" />
                            :
                            data.brand_logo ? <img src={S3_URL + data.brand_logo} alt="img" height="100%" width="100%" /> :
                                <img className="w-100 h-auto" src="/images/coming_soon.jpg" alt="img" height="100%" width="100%" />


                    }
                </div>

                <div className="cart-body">
                    <span onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                        <h4 className='similar_pro_cat_name'>{ }</h4>
                        <span className="m-0 description text-dark" >{title}</span>
                    </span>

                    <div className="price-cart">
                        <div className="price mt-2">
                            <strong>{data.part_number}</strong>
                        </div>
                        <div className="price" title='Stock'>
                            {
                                data.quantity == 0 ?
                                    <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> {data.quantity}</span>
                                    :
                                    <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {data.quantity}</span>
                            }

                        </div>
                    </div>



                    <div className="price-cart mt-3">

                        <div className="price">
                            {
                                data?.price ?
                                    <h5 className="m-0">${parseFloat(data.price).toFixed(2)}</h5>
                                    :
                                    <h5 className="m-0">N.A</h5>
                            }


                        </div>

                        <div className="cart-btn tiresCard">
                            <a href={`/tires?brandname=${data.brand}&brandid=${data.brand_id}`}
                                // <a href="javascript:void(0)"
                                // onClick={() => onSubmit()} // Pass the item to onSubmit function
                                style={{ cursor: 'pointer' }}
                            >
                                {
                                    data.brand_logo ?
                                        <img src={S3_URL + data.brand_logo} alt="img" height="100%" width="100%" />
                                        :
                                        <img src="/images/no-image-found.png" alt="img" height="100%" width="100%" />

                                }
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    )
}
