import { React, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export default function Breadcrum(prop) {
    const { categoryName } = prop;


    return (
        <section>
            <div className="main-inner-banner">
                <div className="parformane-bar">
                    <div className="container d-flex justify-content-between">
                        <div>
                            <a href="/" className='text-decoration-none text-light'>Home</a>
                        </div>
                       
                    </div>
                </div>
                <h2 className="text-center txt-main-listing">{categoryName ? categoryName : ""}</h2>
            </div>
            
        </section>


    )
}


