import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Mousewheel } from "swiper";
import "./styles.css";
import "swiper/css";
import "swiper/css/navigation";


export default function Slider(prop) {
    const S3_URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const { data } = prop
    
    let image_1 = (data.image_url1 && data.image_url1.trim() !== "") ? data.image_url1 : "";

    let image_2 = data.image_url2 && data.image_url2 != "undefined" ? data.image_url2 : "";
    let image_3 = data.image_url3 && data.image_url3 != "undefined" ? data.image_url3 : "";
    let image_4 = data.image_url4 && data.image_url4 != "undefined" ? data.image_url4 : "";
    let S3_IMAGES_1 = data.s3_images && data.s3_images != "undefined" ? data.s3_images.split(',').map(url => S3_URL + url) : [];
    
    const sliderImages = [image_1, image_2, image_3, image_4];
    const defaultImage = "/images/no-image-found.png";
    const brandLogo = S3_URL + data.logo

    const otherFiles = S3_IMAGES_1.length > 0 ? S3_IMAGES_1 : sliderImages.filter(imageUrl => imageUrl !== '');

    otherFiles.push(brandLogo);
    if (otherFiles.length === 0) {
        otherFiles.push(defaultImage);
    }


    const [imagesNavSlider, setImagesNavSlider] = useState(null);
    const [activeImageIndex, setActiveImageIndex] = useState(0);

    return (
        <>
            {otherFiles ?
                <section className="slider">
                    <div className="slider__flex">
                        <div className="slider__col">

                            <div className="slider__thumbs">
                                {/* <div className="slider__prev"><i className="fa fa-angle-up"></i></div> */}
                                <div className="swiper-container">
                                    <Swiper
                                        onSwiper={setImagesNavSlider}
                                        direction="vertical"
                                        spaceBetween={5}
                                        slidesPerView={5}
                                        // navigation={{
                                        //   nextEl: ".slider__next",
                                        //   prevEl: ".slider__prev"
                                        // }}
                                        className="swiper-container1"
                                        breakpoints={{
                                            0: {
                                                direction: "horizontal"
                                            },
                                            768: {
                                                direction: "vertical"
                                            }
                                        }}
                                        modules={[Navigation, Thumbs]}
                                    >
                                        {otherFiles.map((slide, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className="slider__image" onMouseEnter={() => setActiveImageIndex(index)}>

                                                        <img src={slide} alt="prodImg" height="100%" width="100%" />
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                    {/* <div className="slider__next"><i className="fa fa-angle-down"></i></div> */}
                                </div>
                            </div>


                        </div>
                        <div className="slider__images mt-2">
                            <div className="swiper-container bigimagelsider">
                                <Swiper
                                    thumbs={{ swiper: imagesNavSlider && !imagesNavSlider.destroyed ? imagesNavSlider : null }}
                                    direction="horizontal"
                                    slidesPerView={1}
                                    spaceBetween={15}
                                    mousewheel={false}
                                    navigation={{
                                        nextEl: ".slider__next",
                                        prevEl: ".slider__prev"
                                    }}
                                    breakpoints={{
                                        0: {
                                            direction: "horizontal"
                                        },
                                        768: {
                                            direction: "horizontal"
                                        }
                                    }}
                                    className="swiper-container2"
                                    modules={[Navigation, Thumbs, Mousewheel]}
                                    onSlideChange={(swiper) => setActiveImageIndex(swiper.activeIndex)}
                                >
                                    {otherFiles.map((slide, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="slider__image">
                                                    <img src={slide} alt="prodImg" height="100%" width="100%" />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </section>
                :
                "No Image Found !"}
        </>

    );
}
