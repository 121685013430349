import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function Itemcards(data) {
    const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const navigate = useNavigate();
    let S3ImagesData = data.data.s3_images;
    let ImagesData = data.data.images;
    let logo = data.data.brand_logo;
    let first_image_path = "";
    if (S3ImagesData.length > 0) {
        first_image_path = S3URL + S3ImagesData[0];
    } else if (ImagesData.length > 0) {
        first_image_path = ImagesData[0];
    }
    else {
        first_image_path = logo
    }
    const [loaderShow, setLoaderShow] = useState(false);
    const handleSubmit = (event) => {
        const ProductPage= true;
        localStorage.setItem('inProductPage', JSON.stringify(ProductPage));
        setLoaderShow(true)
        let state = {
            item_id: data.data.item_id,
            subcategory_name: data.data.subcategory_name,
            category_name: data.data.category_name,

        }        
        const item_id = data.data.item_id;
        setTimeout(() => {
            navigate(`/product-details/${item_id}`, { state: state });
            setLoaderShow(false)
        }, 1000); // Delay of 2000 milliseconds (2 seconds)


    };


    const onSubmit = (item) => {

        navigate('/brands/brands-products', { state: item });


    };


    let actualPrice = data.data.price;
    //5% off
    let discount = 0.05; // 5% discount
    let discountedPrice = actualPrice - (actualPrice * discount);
//    console.log( " typrof", typeof data.data.price)
    
    return (
        <div className="col-lg-3 col-6">
            <div className="Drivetrain-cart ribbon_box">
            {
                        data.data.price &&  data.data.price >= 100 ? (
                                <div className="ribbon"><span>Free Shipping</span></div>
                            ) : (
                                ""
                            )
                        }
           
                <div className="engle-imge-upper" onClick={handleSubmit}>
                    {
                        !loaderShow ?
                            <button className="hover-btn " >  View Details </button>
                            :
                            <button className="hover-btn loading " > Loading  </button>
                    }


                    {
                        data.data && first_image_path ?
                            <img className="" src={first_image_path} alt="img" height="100%" width="100%" />
                            :
                            <img className="" src={first_image_path} alt="img" height="100%" width="100%" />

                    }
                </div>

                <div className="cart-body">
                    <span onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                        <h4>{data.data.subcategory_name}</h4>
                        <span className="m-0 description text-dark" >{data.data.part_description ? data.data.part_description : data.data.product_name}</span>
                    </span>

                    <div className="price-cart">
                        <div className="price mt-2">
                            <strong>{data.data.part_number}</strong>
                        </div>
                        <div className="price" title='Stock'>
                            {
                                data.data.stock > 0 ?
                                    <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {data.data.stock}</span>

                                    :
                                    <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> 0 </span>
                            }

                        </div>
                    </div>



                    <div className="price-cart">
                        <div className="price">
                            {
                                data.data && data.data.price ?

                                    <h5 className="m-0  price_card_acc">
                                        
                                        <span  className='price_in_card_two'>${(parseFloat(data.data.price) + parseFloat(data.data.surcharge)).toFixed(2)} </span>
                                        {/* <s  className='price_in_card_one'>${parseFloat(actualPrice).toFixed(2)}</s> */}
                                        
                                    </h5>


                                    :
                                    <h5 className="m-0">N.A</h5>
                            }
                        </div>

                        <div className="cart-btn">
                            {/* <a href={`/categories-home/brand-category?brandname=${data.data.brand}&brandid=${data.data.brand_id}`}

                                // onClick={() => onSubmit(data.data.brand_id)} // Pass the item to onSubmit function
                                style={{ cursor: 'pointer' }}
                            > */}
                                {
                                    data.data.brand_logo ?
                                        <img src={data.data.brand_logo} alt="img" height="100%" width="100%" />
                                        :
                                        <img src="/images/no-image-found.png" alt="img" height="100%" width="100%" />

                                }
                            {/* </a> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>




    )
}
