import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Mousewheel } from "swiper";
import "./styles.css";
import "swiper/css";
import "swiper/css/navigation";


export default function Slider(prop) {
    const S3_URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const { data , brand} = prop
    // const sliderImages = data.s3_images;
    const sliderImages = data.images;
    const defaultImage = "/images/no-image-found.png";
    // const brandLogo = S3_URL + data.logo

    const otherFiles = [];
    if (sliderImages) {
        // otherFiles.push(S3_URL + sliderImages);
        otherFiles.push( sliderImages);
    }

    
    if(brand.logo_s3_url){
        
        otherFiles.push( S3_URL + brand.logo_s3_url);
    }

    if (otherFiles.length === 0) {
        otherFiles.push(defaultImage);
    }

    const [imagesNavSlider, setImagesNavSlider] = useState(null);
    const [activeImageIndex, setActiveImageIndex] = useState(0);

    return (
        <>
            {otherFiles ?
                <section className="slider">
                    <div className="slider__flex">
                        <div className="slider__col">

                            <div className="slider__thumbs">
                                {/* <div className="slider__prev"><i className="fa fa-angle-up"></i></div> */}
                                <div className="swiper-container">
                                    <Swiper
                                        onSwiper={setImagesNavSlider}
                                        direction="vertical"
                                        spaceBetween={5}
                                        slidesPerView={5}
                                        // navigation={{
                                        //   nextEl: ".slider__next",
                                        //   prevEl: ".slider__prev"
                                        // }}
                                        className="swiper-container1"
                                        breakpoints={{
                                            0: {
                                                direction: "horizontal"
                                            },
                                            768: {
                                                direction: "vertical"
                                            }
                                        }}
                                        modules={[Navigation, Thumbs]}
                                    >
                                        {otherFiles.map((slide, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className="slider__image" onMouseEnter={() => setActiveImageIndex(index)}>

                                                        <img src={slide} alt="prodImg" height="100%" width="100%" />
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                    {/* <div className="slider__next"><i className="fa fa-angle-down"></i></div> */}
                                </div>
                            </div>


                        </div>
                        <div className="slider__images mt-2">
                            <div className="swiper-container bigimagelsider">
                                <Swiper
                                    thumbs={{ swiper: imagesNavSlider && !imagesNavSlider.destroyed ? imagesNavSlider : null }}
                                    direction="horizontal"
                                    slidesPerView={1}
                                    spaceBetween={15}
                                    mousewheel={false}
                                    navigation={{
                                        nextEl: ".slider__next",
                                        prevEl: ".slider__prev"
                                    }}
                                    breakpoints={{
                                        0: {
                                            direction: "horizontal"
                                        },
                                        768: {
                                            direction: "horizontal"
                                        }
                                    }}
                                    className="swiper-container2"
                                    modules={[Navigation, Thumbs, Mousewheel]}
                                    onSlideChange={(swiper) => setActiveImageIndex(swiper.activeIndex)}
                                >
                                    {otherFiles.map((slide, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="slider__image">
                                                    <img src={slide} alt="prodImg" height="100%" width="100%" />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </section>
                :
                "No Image Found !"}
        </>

    );
}
