import { React, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import WheelsItemscard from "../../components/Cards/wheelsItemscard";
import Pagination from 'react-bootstrap/Pagination';
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import { RangeSlider } from 'rsuite';
import "rsuite/dist/rsuite.css";
import axios from 'axios';
import Collapse from 'react-bootstrap/Collapse';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Wheels() {
  const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
  const { brand_id, brand_name } = useParams();
  const currentLink = window.location.href;
  const url = new URL(currentLink);
  const PRODUCT_PER_PAGE = 28;

  const MAX_PAGES_DISPLAY = 10;
  const categoryName = 'Wheels';
  const updatedUrl = url.toString();
  const navigate = useNavigate();
  const vehical_details = JSON.parse(localStorage.getItem('vehical_details'));
  const optionsAvailbility = [
    { value: "zero", label: "ALL" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4+" },

  ];

  const [isHidden, setIsHidden] = useState(false);
  const [dataWheels, setDataWheels] = useState([]);
  const [isLoadingWheels, setIsLoadingWheels] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setoffsetCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productsPerPage, setProductsPerPage] = useState(0);
  const [firstValue, setFirstValue] = useState(80);
  const [secondValue, setSecondValue] = useState(5000);
  const [sortingValue, setSortingValue] = useState(1);
  const [daimeterData, setDaimeterData] = useState([]);
  const [offsetWheel, setOffsetWheel] = useState("");
  const [colourWheel, setColourWheel] = useState([]);
  const [stockWheel, setStockWheel] = useState("");
  const [diameterArray, setDiameterArray] = useState([]);
  const [widthArray, setWidthArray] = useState([]);
  const [patternArray, setPatternArray] = useState([]);
  const [brandArray, setBradArray] = useState([]);
  const [brandNameArray, setBradNameArray] = useState([]);
  const [modelArray, setModelArray] = useState([]);
  const [wheelOffsetArray, setWheelOffsetArray] = useState([]);
  const [openDiameter, setOpenDiameter] = useState(true);
  const [openWidth, setOpenWidth] = useState(false);
  const [openPattern, setOpenPattern] = useState(false);
  const [openBrand, setOpenBrand] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [openStock, setOpenStock] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openWheelOffset, setOpenWheelOffset] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [filterClass, setFilterClass] = useState('');
  const [showMenuSide, setShowMenuSide] = useState("");
  const [isCheckedStock, setIsCheckedStock] = useState(true);
  const [maxPriceDefault, setMaxPriceDefault] = useState(null);
  const [isdataOnClick, setIsdataOnClick] = useState(true);

  const startIndex = (currentPage - 1) * PRODUCT_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + PRODUCT_PER_PAGE - 1, totalProducts);



  useEffect(() => {
    if (brand_id) {
      setBradArray([...brandArray, brand_id]);
    }

    if (brand_name) {
      const categoryObject = { id: brand_id, name: brand_name };
      // console.log('categoryObject+++++++', categoryObject)
      setBradNameArray([...brandNameArray, categoryObject])
    }
    setIsdataOnClick(prevState => !prevState);
  }, []);


  useEffect(() => {
    const storedDiameter = localStorage.getItem('setWheelDiameterArray');
    const storedWidth = localStorage.getItem('setWheelWidthArray');
    const storedPattern = localStorage.getItem('setWheelPatternArray');
    const storedBrands = localStorage.getItem('setWheelBradArray');
    const storedBrandsName = localStorage.getItem('setWheelBradNameArray');
    const storedWheelOffset = localStorage.getItem('setWheelWheelOffsetArray');
    const storedModel = localStorage.getItem('setWheelModelArray');
    const storedMaxprice = localStorage.getItem('setWheelMaxPrice');
    const storedMinprice = localStorage.getItem('setWheelMinPrice');
    const storedFirstValue = localStorage.getItem('setWheelFirstValue');
    const storedSecondValue = localStorage.getItem('setWheelSecondValue');
    const storedColourWheel = localStorage.getItem('setWheelColourWheel');
    const storedStockWheel = localStorage.getItem('setWheelStockWheel');
    const storedIsChecked = localStorage.getItem('setWheelIsCheckedStock');
    const storedSortingValue = localStorage.getItem('setWheelSortingValue');
    const storedCurrentPage = localStorage.getItem('setWheelCurrentPage');
    const storedoffsetCurrentPage = localStorage.getItem('setWheeloffsetCurrentPage');

    if (storedCurrentPage) {
      const parsedCurrentPage = JSON.parse(storedCurrentPage);
      //   console.log("setCurrentPage", parsedCurrentPage)

      setCurrentPage(parsedCurrentPage);
      setoffsetCurrentPage(parsedCurrentPage);

    } if (storedoffsetCurrentPage) {
      const parsedCurrentPage = JSON.parse(storedoffsetCurrentPage);
      // console.log("setoffsetCurrentPage", parsedCurrentPage)
      setoffsetCurrentPage(parsedCurrentPage);

    }
    if (storedIsChecked) {
      const parsedStockWheel = JSON.parse(storedIsChecked);

      setIsCheckedStock(parsedStockWheel);

    }

    if (storedSortingValue) {
      const parsedSortingValue = JSON.parse(storedSortingValue);
      //  console.log("yessss or noo", parsedSortingValue)
      setSortingValue(parsedSortingValue);

    }

    if (storedDiameter) {
      const parsedDiameter = JSON.parse(storedDiameter);
      setDiameterArray(parsedDiameter);
      if (parsedDiameter.length > 0) {
        setOpenDiameter(true);

      }
    }

    if (storedWidth) {
      const parsedWidth = JSON.parse(storedWidth);
      setWidthArray(parsedWidth);
      if (parsedWidth.length > 0) {
        setOpenWidth(true);



      }
    }
    if (storedPattern) {
      const parsedPattern = JSON.parse(storedPattern);
      setPatternArray(parsedPattern);
      if (parsedPattern.length > 0) {
        setOpenPattern(true);



      }

    }
    if (storedBrands) {
      const parsedBrands = JSON.parse(storedBrands);
      setBradArray(parsedBrands);
      if (parsedBrands.length > 0) {
        setOpenBrand(true);


      }

    }
    if (storedBrandsName) {
      const parsedBrandsName = JSON.parse(storedBrandsName);
      setBradNameArray(parsedBrandsName);
      if (parsedBrandsName.length > 0) {
        setOpenBrand(true);


      }

    }
    if (storedWheelOffset) {
      const parsedWheelOffset = JSON.parse(storedWheelOffset);
      setWheelOffsetArray(parsedWheelOffset);
      if (parsedWheelOffset.length > 0) {
        setOpenWheelOffset(true);


      }

    }
    if (storedModel) {
      const parsedModel = JSON.parse(storedModel);
      setModelArray(parsedModel);
      if (parsedModel.length > 0) {
        setOpenModel(true);

      }

    }
    if (storedMaxprice) {
      const parsedMaxprice = JSON.parse(storedMaxprice);
      setMaxPrice(parsedMaxprice);

    }
    if (storedMinprice) {
      const parsedMinprice = JSON.parse(storedMinprice);
      setMinPrice(parsedMinprice);

    }
    if (storedFirstValue) {
      const parsedFirstValue = JSON.parse(storedFirstValue);
      setFirstValue(parsedFirstValue);

    }
    if (storedSecondValue) {
      const parsedSecondValue = JSON.parse(storedSecondValue);
      setSecondValue(parsedSecondValue);

    }
    if (storedColourWheel) {
      const parsedColourWheel = JSON.parse(storedColourWheel);
      setColourWheel(parsedColourWheel);
      if (parsedColourWheel.length > 0) {
        setOpenFinish(true);

      }
    }

    if (storedStockWheel) {
      const parsedStockWheel = JSON.parse(storedStockWheel);
      setStockWheel(parsedStockWheel);
      if (parsedStockWheel.length > 0) {
        setOpenStock(true);

      }
    }

  }, []);

  useEffect(() => {
    const apiKey = 'qYDXq4NfU3U8faPscasr06bXuKD5RJ1Z';
    if (vehical_details) {
      const selectedYear = vehical_details ? vehical_details.year : "";
      const selectedMake = vehical_details ? vehical_details.make : "";
      const selectedModel = vehical_details ? vehical_details.model : "";
      const selectedVariant = vehical_details ? vehical_details.variant : "";
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api.fitmentatlas.com/v1.1/services/Vehicles/smartsizes/?YearId=${selectedYear}&MakeId=${selectedMake}&ModelId=${selectedModel}&ProductType=wheel&SubmodelID=${selectedVariant}`,
        headers: {
          'FG-ApiKey': apiKey
        }
      };

      axios.request(config)
        .then((response) => {
          let staggered = false;
          let fmkWithStaggered = null;
          // Find the object with isStaggered: true and get its fmk
          for (const obj of response.data.result) {
            if (obj.isStaggered === true) {
              fmkWithStaggered = obj.fmk;
              staggered = true;
              break;
            }
          }


          if (fmkWithStaggered === null && response.data.result.length > 0) {
            fmkWithStaggered = response.data.result[0].fmk;
          }


          if (staggered) {
            navigate(`/wheels-search-by-vehical/staggered?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`);
          } else {
            navigate(`/wheels-search-by-vehical?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);


  useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }, [])
  useEffect(() => {

    console.log('userEffect')
    const storedProductPage = localStorage.getItem('inProductPage');

    const ProductPage = JSON.parse(storedProductPage);
    if (ProductPage) {
      const storedDiameter = localStorage.getItem('setWheelDiameterArray');
      const storedWidth = localStorage.getItem('setWheelWidthArray');
      const storedPattern = localStorage.getItem('setWheelPatternArray');
      const storedBrands = localStorage.getItem('setWheelBradArray');
      const storedBrandsName = localStorage.getItem('setWheelBradNameArray');
      const storedWheelOffset = localStorage.getItem('setWheelWheelOffsetArray');
      const storedModel = localStorage.getItem('setWheelModelArray');
      const storedMaxprice = localStorage.getItem('setWheelMaxPrice');
      const storedMinprice = localStorage.getItem('setWheelMinPrice');
      const storedColourWheel = localStorage.getItem('setWheelColourWheel');
      const storedStockWheel = localStorage.getItem('setWheelStockWheel');
      const storedCurrentPage = localStorage.getItem('setWheelCurrentPage');
      const storedoffsetCurrentPage = localStorage.getItem('setWheeloffsetCurrentPage');

      const parsedDiameter = JSON.parse(storedDiameter);
      const parsedWidth = JSON.parse(storedWidth);
      const parsedPattern = JSON.parse(storedPattern);
      const parsedBrands = JSON.parse(storedBrands);
      const parsedBrandsName = JSON.parse(storedBrandsName);
      const parsedWheelOffset = JSON.parse(storedWheelOffset);
      const parsedModel = JSON.parse(storedModel);
      const parsedMaxprice = JSON.parse(storedMaxprice);
      const parsedMinprice = JSON.parse(storedMinprice);
      const parsedColourWheel = JSON.parse(storedColourWheel);
      const parsedStockWheel = JSON.parse(storedStockWheel);
      const parsedCurrentPage = JSON.parse(storedCurrentPage);
      const parsedoffsetCurrentPage = JSON.parse(storedoffsetCurrentPage);

      setIsdataOnClick(prevState => !prevState);

      if ((brand_name && brand_id) || parsedCurrentPage != null || parsedoffsetCurrentPage != null || parsedDiameter != null || parsedWidth != null || parsedPattern != null || parsedBrands != null || parsedBrandsName != null || parsedWheelOffset != null || parsedModel != null || parsedMaxprice != null || parsedMinprice != null || parsedColourWheel != null || parsedStockWheel != null) {

        localStorage.removeItem('inProductPage');
        //console.log("diameterArray+++", diameterArray, "brandArray+++", brandArray, "patternArray+++", patternArray, "widthArray+++", widthArray, "sortingValue+++", sortingValue, "firstValue+++", firstValue, "secondValue", secondValue, "wheelOffsetArray+++", wheelOffsetArray, "colourWheel+++", colourWheel, "stockWheel+++", stockWheel, "modelArray+++", modelArray, "isCheckedStock+++", isCheckedStock);
        if (diameterArray.length > 0 || brandArray.length > 0 || patternArray.length > 0 || widthArray.length > 0 || modelArray.length > 0 || wheelOffsetArray.length > 0) {
          fetchWheelsData();
        } else {
          return;
        }
      } else {
        fetchWheelsData();
      }
    } else if (brand_name && brand_id) {
      if (diameterArray.length > 0 || brandArray.length > 0 || patternArray.length > 0 || widthArray.length > 0 || modelArray.length > 0 || wheelOffsetArray.length > 0) {
        fetchWheelsData();
      } else {
        return;
      }
    } else {
      fetchWheelsData();
    }

  }, [isdataOnClick]);

  const fetchWheelsData = async () => {
    document.body.classList.add('full-screen-loader');
    setIsLoadingWheels(true);
    const limit = 28;
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        credentials: 'include', // Send cookies with the request
        withCredentials: true,
        url: `${mainUrl}/api/wheels/getWheelsDataWithSearch?diameter=${diameterArray}&brand=${brandArray}&lugpattern=${patternArray}&width=${widthArray}&limit=${limit}&offset=${offset}&sorting=${sortingValue}&startingPrice=${firstValue}&endPrice=${secondValue}&offsetWheel=${wheelOffsetArray}&colourWheel=${colourWheel}&stockWheel=${stockWheel}&style=${modelArray}&isCheckedStock=${isCheckedStock}`,
      };
      axios.request(config)
        .then((response) => {
          setDataWheels(response.data);
          setIsLoadingWheels(false);
          setTotalPages(response.data.totalPages);
          setTotalProducts(response.data.count);
          setProductsPerPage(response.data.data.length);
          window.scrollTo({ top: 400, behavior: 'smooth' });
          setIsHidden(true);
          document.body.classList.remove('full-screen-loader');
          setMaxPriceDefault((Math.ceil(response.data.defaultMaxPrice)) + 1);

          const storedMaxprice = localStorage.getItem('setWheelMaxPrice');
          const storedMinprice = localStorage.getItem('setWheelMinPrice');
          if (storedMaxprice <= 0) {

            setMaxPrice(Math.ceil(response.data.maximumPrice));
          } else {

            setOpenPrice(true);
          }
          if (storedMinprice <= 0) {
            setMinPrice(Math.floor(response.data.minimumPrice));
          } else {
            setOpenPrice(true);
          }


          setDaimeterData(response.data.uniqueDiameterFirstTime);


        })
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoadingWheels(false);
      setIsHidden(true);
      document.body.classList.remove('full-screen-loader');     // navigate(`/error`);
    }

  };


  const handleSorting = (event) => {

    const sortingValue = event.target.value;
    setSortingValue(sortingValue);
    localStorage.setItem('setSortingValue', JSON.stringify(sortingValue));
    setIsdataOnClick(prevState => !prevState);

  };

  const handlePageChange = (page) => {
    const limit = 28;
    const offsets = (page - 1) * limit; // Calculate the offset based on the current page and limit

    setCurrentPage(page);
    setoffsetCurrentPage(offsets);
    document.body.classList.add('full-screen-loader');
    localStorage.setItem('setCurrentPage', JSON.stringify(page));
    localStorage.setItem('setoffsetCurrentPage', JSON.stringify(offsets));
    window.scrollTo({ top: 400, behavior: 'smooth' });
    setIsdataOnClick(prevState => !prevState);

  };

  const getPageNumbers = () => {
    const totalDisplayPages = Math.min(totalPages, MAX_PAGES_DISPLAY);
    const middlePage = Math.ceil(totalDisplayPages / 2);
    let startPage = Math.max(1, currentPage - middlePage + 1);

    // Ensure the start page does not exceed the available pages
    if (startPage + totalDisplayPages > totalPages) {
      startPage = Math.max(1, totalPages - totalDisplayPages + 1);
    }

    return Array.from({ length: totalDisplayPages }, (_, index) => startPage + index);
  };

  function handleRanges(value) {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    setFirstValue(value[0]);
    setSecondValue(value[1]);
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
    if (value[1]) {
      // console.log(value[1]);
      localStorage.setItem('setWheelMaxPrice', JSON.stringify(value[1]));
      localStorage.setItem('setWheelSecondValue', JSON.stringify(value[1]));
    }
    if (value[0]) {
      localStorage.setItem('setWheelMinPrice', JSON.stringify(value[0]));
      localStorage.setItem('setWheelFirstValue', JSON.stringify(value[0]));
    }

  }

  const handleDiameterCheckboxChange = (event) => {
    setIsHidden(true);
    const sdiameter = event.target.value;
    if (diameterArray.includes(sdiameter)) {
      const updatedDiameter = diameterArray.filter((id) => id !== sdiameter);
      setDiameterArray(updatedDiameter);
      localStorage.setItem('setWheelDiameterArray', JSON.stringify(updatedDiameter));
    } else {
      const updatedDiameter = [...diameterArray, sdiameter];
      setDiameterArray(updatedDiameter);
      localStorage.setItem('setWheelDiameterArray', JSON.stringify(updatedDiameter));
    }  

  };

  const handleWidthCheckboxChange = (event) => {
    setIsHidden(true);
    const sWidth = event.target.value;
    if (widthArray.includes(sWidth)) {
      const updatedWidth = widthArray.filter((id) => id !== sWidth);
      setWidthArray(updatedWidth);
      localStorage.setItem('setWheelWidthArray', JSON.stringify(updatedWidth));
    } else {
      const updatedWidth = [...widthArray, sWidth]
      setWidthArray(updatedWidth);
      localStorage.setItem('setWheelWidthArray', JSON.stringify(updatedWidth));
    }
    
  };

  const handlePatternCheckboxChange = (event) => {
    setIsHidden(true);
    const sPattern = event.target.value;
    if (patternArray.includes(sPattern)) {
      const updatedpattern = patternArray.filter((id) => id !== sPattern)
      setPatternArray(updatedpattern);
      localStorage.setItem('setWheelPatternArray', JSON.stringify(updatedpattern));
    } else {
      const updatedpattern = [...patternArray, sPattern]
      setPatternArray(updatedpattern);
      localStorage.setItem('setWheelPatternArray', JSON.stringify(updatedpattern));
    }
    
  };

  const handleBrandCheckboxChange = (event) => {
    setIsHidden(true);
    const sBrand = event.target.value;
    const brandIdName = event.target.name;

    if (brandArray.includes(sBrand)) {
      const updatedBrand = brandArray.filter((id) => id !== sBrand);
      setBradArray(updatedBrand);
      localStorage.setItem('setWheelBradArray', JSON.stringify(updatedBrand));
    } else {
      const updatedBrand = [...brandArray, sBrand];
      setBradArray(updatedBrand);
      localStorage.setItem('setWheelBradArray', JSON.stringify(updatedBrand));

    }

    // Check if the category object is already in the selectedCategoriesName array
    const categoryObject = { id: sBrand, name: brandIdName };
    const categoryObjectIndex = brandNameArray.findIndex(
      (obj) => obj.id === sBrand
    );
    if (categoryObjectIndex !== -1) {
      const updatedBrandsName = brandNameArray.filter((obj) => obj.id !== sBrand);
      setBradNameArray(updatedBrandsName);
      localStorage.setItem('setWheelBradNameArray', JSON.stringify(updatedBrandsName));
    } else {
      const updatedBrandsName = [...brandNameArray, categoryObject];
      setBradNameArray(updatedBrandsName);
      localStorage.setItem('setWheelBradNameArray', JSON.stringify(updatedBrandsName));
    }
   
    if (brand_id == sBrand) {
      navigate(`/wheels`);
    }
  };

  const handleModelCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (modelArray.includes(sModle)) {
      const updatedModle = modelArray.filter((id) => id !== sModle);
      setModelArray(updatedModle);
      localStorage.setItem('setWheelModelArray', JSON.stringify(updatedModle));
    } else {
      const updatedModle = [...modelArray, sModle];
      setModelArray(updatedModle);
      localStorage.setItem('setWheelModelArray', JSON.stringify(updatedModle));
    }

    // console.log('modelArray++', modelArray)
    
  };

  const handleWhellOffSetCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (wheelOffsetArray.includes(sModle)) {
      const updatedWhellOffSet = wheelOffsetArray.filter((id) => id !== sModle);
      setWheelOffsetArray(updatedWhellOffSet);
      localStorage.setItem('setWheelWheelOffsetArray', JSON.stringify(updatedWhellOffSet));
    } else {
      const updatedWhellOffSet = [...wheelOffsetArray, sModle];
      setWheelOffsetArray(updatedWhellOffSet);
      localStorage.setItem('setWheelWheelOffsetArray', JSON.stringify(updatedWhellOffSet));
    }

   
  };

  const handleWheelColor = (event) => {
    const selectedColor = event.target.value;
    setIsHidden(true);

    if (colourWheel.includes(selectedColor)) {
      const updatedColourWheel = colourWheel.filter((id) => id !== selectedColor);
      setColourWheel(updatedColourWheel);
      localStorage.setItem('setWheelColourWheel', JSON.stringify(updatedColourWheel));
    } else {
      const updatedColourWheel = [...colourWheel, selectedColor];
      setColourWheel(updatedColourWheel);
      localStorage.setItem('setWheelColourWheel', JSON.stringify(updatedColourWheel));
    }
    
  }

  const clearAllFilters = () => {

    localStorage.removeItem('setWheelDiameterArray');
    localStorage.removeItem('setWheelWidthArray');
    localStorage.removeItem('setWheelPatternArray');
    localStorage.removeItem('setWheelBradArray');
    localStorage.removeItem('setWheelBradNameArray');
    localStorage.removeItem('setWheelWheelOffsetArray');
    localStorage.removeItem('setWheelModelArray');
    localStorage.removeItem('setWheelMaxPrice');
    localStorage.removeItem('setWheelMinPrice');
    localStorage.removeItem('setWheelFirstValue');
    localStorage.removeItem('setWheelSecondValue');
    localStorage.removeItem('setWheelColourWheel');
    localStorage.removeItem('setWheelStockWheel');
    localStorage.removeItem('setWheelIsCheckedStock');
    localStorage.removeItem('setWheelSortingValue');
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    setDiameterArray([]);
    setWidthArray([]);
    setPatternArray([]);
    setBradArray([]);
    setModelArray([]);
    setBradNameArray([]);
    setMaxPrice([]);
    setMinPrice([]);
    setFirstValue(80);
    setSecondValue([]);
    setColourWheel([]);
    setStockWheel([]);
    setOpenStock(false);
    setOpenFinish(false);
    setOpenPattern(false);
    setOpenBrand(false);
    setOpenWidth(false);
    setOpenModel(false);
    setOpenWheelOffset(false);
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    navigate(`/wheels`);
    setIsdataOnClick(prevState => !prevState);
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');


  }

  const handleRemoveDiameter = (categoryName) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    const updatedDiameter = diameterArray.filter((category) => category !== categoryName);
    setDiameterArray(updatedDiameter);
    localStorage.setItem('setWheelDiameterArray', JSON.stringify(updatedDiameter));

  };

  const handleRemoveWidth = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    const updatedWidth = widthArray.filter((id) => id !== width);
    setWidthArray(updatedWidth);
    localStorage.setItem('setWheelWidthArray', JSON.stringify(updatedWidth));

  };

  const handleRemovePattern = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    const updatedpattern = patternArray.filter((id) => id !== width);
    setPatternArray(updatedpattern);
    localStorage.setItem('setWheelPatternArray', JSON.stringify(updatedpattern));

  };
  const handleRemoveModel = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    const updatedModle = modelArray.filter((id) => id !== width);
    setModelArray(updatedModle);
    localStorage.setItem('setWheelModelArray', JSON.stringify(updatedModle));

  };

  const handleRemoveBrand = (width) => {

    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    const updatedBrand = brandArray.filter((id) => id !== width);
    setBradArray(updatedBrand);


    const updatedBrandsName = brandNameArray.filter((name) => name.id !== width);
    setBradNameArray(updatedBrandsName);

    localStorage.setItem('setWheelBradArray', JSON.stringify(updatedBrand));
    localStorage.setItem('setWheelBradNameArray', JSON.stringify(updatedBrandsName));
    if (brand_id == width) {
      navigate(`/wheels`);
    }

  };

  const handleOffsetChange = (event) => {
    setCurrentPage(1);
    const selectedOffset = event.target.value;
    setOffsetWheel(selectedOffset)
    // console.log('selectedOffset++++++++++', selectedOffset);


  }

  const handleStockChange = (event) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    const selectedStock = event.target.value;
    setStockWheel(selectedStock)
    localStorage.setItem('setWheelStockWheel', JSON.stringify(selectedStock));
  }

  const showFiltersClick = () => {
    setFilterClass('active-filter')
    document.body.classList.add('home-layout')

  }
  const hideFilterClass = () => {
    setFilterClass('')
    document.body.classList.remove('home-layout')
  }

  const setValueMenuSideBar = (value) => {
    setShowMenuSide(value)
  }



  const optionsColour = [
    { value: "Anthracite", label: "Anthracite" },
    { value: "Blue", label: "Blue" },
    { value: "Black", label: "Black" },
    { value: "Bronze", label: "Bronze" },
    { value: "Brushed", label: "Brushed" },
    { value: "Charcoal", label: "Charcoal" },
    { value: "Copper", label: "Copper" },
    { value: "Chrome", label: "Chrome" },
    { value: "Custom", label: "Custom" },
    { value: "Gray", label: "Gray" },
    { value: "Gun Metal", label: "Gun Metal" },
    { value: "Graphite", label: "Graphite" },
    { value: "Gold", label: "Gold" },
    { value: "Machined", label: "Machined" },
    { value: "Polished", label: "Polished" },
    { value: "Red", label: "Red" },
    { value: "Smoke", label: "Smoke" },
    { value: "Silver", label: "Silver" },
    { value: "Titanium", label: "Titanium" },
    { value: "White", label: "White" },
  ];
  const handleRemoveColor = (selectedColor) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    const updatedModle = colourWheel.filter((id) => id !== selectedColor);
    setColourWheel(updatedModle);
    localStorage.setItem('setWheelColourWheel', JSON.stringify(updatedModle));
  }

  const [cartItems, setCartItems] = useState("");


  useEffect(() => {
    const storedCartItems = localStorage.getItem('totalCartItems');

    if (storedCartItems) {
      const parsedCartItems = JSON.parse(storedCartItems);
      const numCartItems = Object.values(parsedCartItems);
      const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setCartItems(sum);
    }
  }, []);

  const handleChangeStockAvail = () => {
    setIsCheckedStock(!isCheckedStock)
    localStorage.setItem('setWheelIsCheckedStock', JSON.stringify(!isCheckedStock));
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    setIsdataOnClick(prevState => !prevState);

  }


  const wheelBrandsArray = [
    { name: "Performance Replicas", id: 1245, description: "Experience high-performance vehicles with Performance Replicas wheels from Extreme Performance, meticulously crafted for style and quality." },
    { name: "Level 8", id: 1334, description: "Elevate vehicle style with Level 8 wheels from Extreme Performance, featuring modern designs and premium finishes for a sleek, sophisticated appearance." },
    { name: "Level 8 Powersports", id: 1335, description: "Dominate the off-road trails with Level 8 Powersports wheels from Extreme Performance, ensuring confidence and control in any off-road adventure." },
    { name: "Black Rhino Powersports", id: 1336, description: "Dominate any terrain with Black Rhino Powersports wheels from Extreme Performance, providing confidence in challenging conditions." },
    { name: "Black Rhino", id: 1337, description: "Dominate any terrain with Black Rhino wheels from Extreme Performance, ensuring confidence and control in challenging off-road conditions." },
    { name: "Genius", id: 1338, description: "Stand out with Genius wheels from Extreme Performance, featuring bold designs and finishes for a distinctive appearance." },
    { name: "Petrol", id: 1339, description: "Stand out with Petrol wheels from Extreme Performance, featuring unique designs and premium finishes to complement any car, truck, or SUV." },
    { name: "Ruff", id: 1340, description: "Stand out with Ruff wheels from Extreme Performance, delivering bold, innovative designs and premium finishes." },
    { name: "Beyern", id: 1341, description: "Elevate your vehicle's style with Beyern wheels from Extreme Performance, featuring timeless designs and precision engineering for both style and performance." },
    { name: "Tuff", id: 1342, description: "Dominate any terrain with Tuff wheels from Extreme Performance, engineered for maximum performance, control, and durability." },
    { name: "Mandrus", id: 1343, description: "Elevate vehicle style with Mandrus wheels from Extreme Performance, offering elegant designs to complement luxury vehicles." },
    { name: "OHM", id: 1344, description: "Illuminate your path with OHM LED lighting solutions from Extreme Performance, engineered for superior brightness, energy efficiency, and durability." },
    { name: "TSW", id: 1345, description: "Elevate your vehicle's style with TSW wheels from Extreme Performance, featuring timeless designs and precision engineering for a truly distinctive appearance." },
    { name: "Victor Equipment", id: 1346, description: "Stand out with Victor Equipment wheels from Extreme Performance, complementing any Porsche vehicle with bold form and reliable function." },
    { name: "Coventry", id: 1347, description: "Elevate vehicle style with Coventry wheels from Extreme Performance, offering timeless, elegant designs and premium finishes." },
    { name: "Cray", id: 1348, description: "Stand out with Cray wheels from Extreme Performance, offering bold designs and premium finishes for a distinctive appearance." },
    { name: "RedBourne", id: 1349, description: "Stand out with RedBourne wheels from Extreme Performance, offering bold, aggressive designs for a truly distinctive appearance." },
    { name: "XO", id: 1350, description: "Elevate vehicle style with XO wheels from Extreme Performance, featuring sleek designs and premium finishes." },
    { name: "Status", id: 1351, description: "Stand out with Status wheels from Extreme Performance, featuring innovative designs and finishes to complement any car, truck, or SUV." },
    { name: "Asanti Black", id: 1352, description: "Make a statement with Asanti Black wheels from Extreme Performance, featuring bold, aggressive designs and finishes." },
    { name: "Asanti Off Road", id: 1353, description: "Dominate any terrain with Asanti Off Road wheels from Extreme Performance, engineered for maximum performance and reliability." },
    { name: "American Force Cast", id: 1354, description: "Dominate the road with American Force Cast wheels from Extreme Performance, ensuring maximum performance and reliability in any driving condition." },
    { name: "Asanti Forged", id: 1355, description: "Stand out with Asanti Forged wheels from Extreme Performance, featuring unique designs and finishes for a truly distinctive appearance." },
    { name: "American Force", id: 1356, description: "Dominate any terrain with American Force wheels from Extreme Performance, ensuring confidence and control in any driving condition." },
    { name: "ATX", id: 1357, description: "Dominate any terrain with ATX wheels from Extreme Performance, engineered for maximum reliability and performance." },
    { name: "American Racing", id: 1358, description: "Elevate vehicle style with American Racing wheels from Extreme Performance, featuring timeless designs and precision engineering." },
    { name: "ATX Series", id: 1359, description: "Dominate any terrain with ATX Series wheels from Extreme Performance, providing confidence and control in any driving condition." },
    { name: "Fuel 1PC", id: 1360, description: "Elevate vehicle style with Fuel 1PC wheels from Extreme Performance, featuring cutting-edge designs and premium finishes." },
    { name: "Fuel UTV", id: 1361, description: "Conquer trails with Fuel UTV from Extreme Performance, engineered for power, agility, and durability." },
    { name: "Fuel 2PC", id: 1362, description: "Stand out with Fuel 2PC wheels from Extreme Performance, offering bold designs and premium finishes for a distinctive look." },
    { name: "Fuel Mono", id: 1363, description: "Stand out with Fuel Mono wheels from Extreme Performance, offering bold designs and premium finishes." },
    { name: "Foose 1PC", id: 1364, description: "Stand out with Foose 1PC wheels from Extreme Performance, featuring bold designs and finishes to complement any car, truck, or SUV." },
    { name: "MSA Offroad Wheels", id: 1365, description: "Dominate off-road trails with MSA Offroad Wheels from Extreme Performance, engineered for maximum performance and reliability." },
    { name: "Foose 2PC", id: 1366, description: "Elevate vehicle style with Foose 2PC wheels from Extreme Performance, featuring cutting-edge designs and precision engineering." },
    { name: "Fairway Alloys", id: 1367, description: "Stand out with Fairway Alloys wheels from Extreme Performance, offering bold and aggressive designs for a truly distinctive appearance." },
    { name: "Helo", id: 1368, description: "Dominate any terrain with Helo wheels from Extreme Performance, providing confidence and control in all driving conditions." },
    { name: "KMC", id: 1369, description: "Dominate any terrain with KMC wheels from Extreme Performance, engineered for rugged performance and reliability." },
    { name: "KMC Powersports", id: 1370, description: "Conquer trails with KMC Powersports wheels from Extreme Performance, offering maximum durability and performance in off-road conditions." },
    { name: "Niche 1PC", id: 1371, description: "Elevate vehicle style with Niche 1PC wheels from Extreme Performance, featuring cutting-edge designs and finishes." },
    { name: "Moto Metal", id: 1372, description: "Elevate vehicle style with Moto Metal wheels from Extreme Performance, featuring bold, innovative designs for a distinctive look." },
    { name: "Motegi", id: 1373, description: "Elevate vehicle style with Motegi wheels from Extreme Performance, featuring cutting-edge designs and premium finishes." },
    { name: "Rotiform", id: 1374, description: "Elevate vehicle style with Rotiform wheels from Extreme Performance, featuring cutting-edge designs and premium finishes for a distinctive appearance." },
    { name: "DUB 1PC", id: 1375, description: "Stand out with DUB 1PC wheels from Extreme Performance, featuring unique designs and finishes for a distinctive appearance." },
    { name: "DUB 2PC", id: 1376, description: "Stand out with DUB 2PC wheels from Extreme Performance, offering unique designs and finishes to complement any car, truck, or SUV." },
    { name: "Niche Mono", id: 1377, description: "Stand out with Niche Mono wheels from Extreme Performance, offering bold designs and finishes." },
    { name: "US Mag 1PC", id: 1378, description: "Dominate any terrain with US Mag 1PC wheels from Extreme Performance, providing confidence and control in all driving conditions." },
    { name: "American Racing Forged", id: 1379, description: "Elevate vehicle style with American Racing Forged wheels from Extreme Performance, featuring cutting-edge designs and premium finishes." },
    { name: "American Racing Vintage", id: 1380, description: "Elevate vehicle style with American Racing Vintage wheels from Extreme Performance, featuring timeless designs for a distinctive appearance." },
    { name: "XD", id: 1381, description: "Dominate any terrain with XD wheels from Extreme Performance, engineered for rugged off-road performance and reliability." },
    { name: "XD Powersports", id: 1382, description: "Conquer trails with XD Powersports wheels from Extreme Performance, providing durability and control in off-road conditions." },
    { name: "pro comp", id: 2990, description: "Dominate any terrain with Pro Comp's suspension systems from Extreme Performance, providing superior off-road capability and unmatched reliability." },
    { name: "fuel forged", id: 3195, description: "Elevate your vehicle's style with fuel forged wheels from Extreme Performance, meticulously crafted with cutting-edge designs and precision engineering." },
    { name: "adventus", id: 3199, description: "Stand out with adventus wheels from Extreme Performance, offering bold and innovative designs for a distinctive appearance." },
    { name: "ION", id: 3212, description: "Dominate any terrain with ION wheels from Extreme Performance, providing confidence and control in any driving condition." },
    { name: "TRAILER WHEELS", id: 3213, description: "Ensure safe and reliable towing with TRAILER WHEELS from Extreme Performance, offering high-quality trailer wheels for stability and performance." },
    { name: "TOUREN", id: 3214, description: "Dominate any terrain with TOUREN wheels from Extreme Performance, providing maximum traction, performance, and control." },
    { name: "MAZZI", id: 3215, description: "Stand out with MAZZI wheels from Extreme Performance, featuring bold, innovative designs and premium finishes." },
    { name: "RIDLER", id: 3216, description: "Elevate style with RIDLER wheels from Extreme Performance, offering modern designs and premium finishes for a sleek appearance." },
    { name: "MAYHEM", id: 3217, description: "Dominate any terrain with MAYHEM wheels from Extreme Performance, engineered for maximum performance and reliability." },
    { name: "CALI OFF-ROAD", id: 3218, description: "Stand out with CALI OFF-ROAD wheels from Extreme Performance, featuring bold and innovative designs." },
    { name: "DIRTY LIFE", id: 3219, description: "Conquer trails with DIRTY LIFE wheels from Extreme Performance, engineered for maximum performance and reliability." },
    { name: "AMERICAN TRUXX", id: 3220, description: "Dominate any terrain with AMERICAN TRUXX wheels from Extreme Performance, offering rugged reliability and control." },
    { name: "AT", id: 3221, description: "Dominate any terrain with AT tires from Extreme Performance, engineered for performance, reliability, and traction." },
    { name: "AMERICAN TRUXX FORGED", id: 3222, description: "Dominate any terrain with AMERICAN TRUXX FORGED wheels from Extreme Performance, offering performance and control." },
    { name: "KRAZE", id: 3223, description: "Stand out with KRAZE wheels from Extreme Performance, featuring unique designs and premium finishes." },
    { name: "TUFFSTUFF", id: 3224, description: "Enhance adventures with TUFFSTUFF accessories from Extreme Performance, providing rugged performance and capability." },
    { name: "O.E. Revolution", id: 3318, description: "O.E. Revolution wheels from Extreme Performance deliver superior strength and style for trucks and SUVs tackling adventurous terrains." },
    { name: "XF Off-Road", id: 3319, description: "XF Off-Road Wheels lightweight and durable construction from Extreme Performance blend style and performance for enthusiasts seeking premium wheel solutions." },
    { name: "Vortek", id: 3320, description: "Dominate the off-road trails with Vortek wheels from Extreme Performance, engineered for maximum performance and reliability in any terrain or weather condition." },
    { name: "Spec-1", id: 3321, description: "Spec-1 Wheels distinctive designs and craftsmanship from Extreme Performance offer enthusiasts innovative wheel solutions for stylish performance." },
    { name: "Amani", id: 3322, description: "Amani aftermarket accessories from Extreme Performance deliver enhanced performance and style for enthusiasts seeking innovative and comprehensive vehicle solutions." },
    { name: "Modern Concepts", id: 3323, description: "Modern Concepts Wheels stylish designs from Extreme Performance offer premium wheel solutions for enthusiasts seeking innovative style and craftsmanship." },
    { name: "Azara", id: 3324, description: "Azara wheels for various vehicles from Extreme Performance offer dependable traction solutions for everyday driving conditions." },
    { name: "Blade Luxury", id: 3325, description: "Blade Luxury  dependable traction and safety from Extreme Performance offer reliable tire solutions for passenger cars and SUVs worldwide." },
    { name: "Cavallo", id: 3326, description: "Elevate your vehicle's style with Cavallo wheels from Extreme Performance, featuring modern designs and premium finishes for a standout appearance." },
    { name: "Karma", id: 3327, description: "Karma wheels from Extreme Performance tackle tough trails and terrain with ease, providing innovative bumpers and armor solutions." },
    { name: "Twisted Off-Road", id: 3328, description: "Twisted Off-Road Wheels airflow and cooling from Extreme Performance deliver enthusiasts innovative wheel solutions for enhanced brake performance and style." },
    { name: "XFX Flow", id: 3329, description: "XFX Flow Wheels stylish designs and premium craftsmanship from Extreme Performance provide enthusiasts with innovative wheel solutions for superior style and performance." },
    { name: "Aodhan", id: 3331, description: "Elevate vehicle style with Aodhan wheels from Extreme Performance, featuring cutting-edge designs for a distinctive appearance." },
    { name: "ESR", id: 3394, description: "Elevate your vehicle's style with ESR wheels from Extreme Performance, featuring cutting-edge designs and premium finishes to enhance any car or truck." },
    { name: "MVX", id: 3395, description: "Elevate your vehicle's style with MVX's sleek wheels from Extreme Performance, featuring cutting-edge designs and finishes to enhance any vehicle's aesthetics." },
    { name: "Brada", id: 3396, description: "Stand out with Brada's bold wheels from Extreme Performance, engineered for high performance and style on-road or off-road." },
    { name: "Rohana", id: 3399, description: "Elevate your vehicle's style with Rohana's premium wheels from Extreme Performance, combining innovative designs and superior craftsmanship for a distinctive road look." },
    { name: "XF Dually", id: 3400, description: "Elevate your truck's style with XF Dually wheels from Extreme Performance, combining heavy-duty capability and off-road adventure style for a rugged appearance." },
    { name: "RACELINE", id: 3402, description: "Stand out with RACELINE wheels from Extreme Performance, delivering bold designs and premium finishes to complement any off-road vehicle." },
    { name: "KANSEI", id: 3404, description: "Elevate your vehicle's style with KANSEI wheels from Extreme Performance, featuring modern designs and premium finishes to complement any car or truck." },
    { name: "MOBELWAGEN", id: 3405, description: "Elevate your vehicle's style with MOBELWAGEN wheels from Extreme Performance, featuring modern designs and premium finishes to enhance any car or truck." },
    { name: "NOMAD", id: 3406, description: "Dominate the road with NOMAD tires from Extreme Performance, ensuring stability and control in any driving condition, from highways to trails." },
    { name: "4PLAY", id: 3407, description: "Dominate the road with NOMAD tires from Extreme Performance, ensuring stability and control in any driving condition, from highways to trails." },
    { name: "OE Wheels", id: 3408, description: "Dominate the road with NOMAD tires from Extreme Performance, ensuring stability and control in any driving condition, from highways to trails." },
    { name: "DEFIANT", id: 3409, description: "Dominate the road with NOMAD tires from Extreme Performance, ensuring stability and control in any driving condition, from highways to trails." }
  ];

  // Function to get description based on category name
  function getDescription(id) {
    console.log(id, 'wheels brand id');
    const product = wheelBrandsArray.find(product => product.id == id);

    return { name: product.name, description: product.description };
  }

  let brandData = "";
  if (brand_id) {
    brandData = getDescription(brand_id);
  }


  function onclickFilter() {

    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheeloffsetCurrentPage');
    localStorage.removeItem('setWheelCurrentPage');
    
    setIsdataOnClick(prevState => !prevState);
  }

  return (
    <>
      <Helmet>
        <title>{brandData ? brandData.name : "Wheels"}</title>
        <meta name="description" content={brandData ? brandData.description : 'Make a statement with Extreme Performance premium wheels, available in styles and finishes to suit your vehicle needs'} />
        <link rel="canonical" href={updatedUrl} />
      </Helmet>

      <TOPBAR isHidden={isHidden} />
      <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
      <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
      {/* { isdata ? ( */}
      <>
        <div className="main-inner-banner">
          <div className="parformane-bar">
            <div className="container d-flex justify-content-between">
              {/* <div>
              <a href="/" className='text-decoration-none text-light'>Home</a>
            </div> */}
              <ul className="p-0 m-0 listing-parmofamne">
                <li><a href="/">Home /</a></li>
                <li className='active-bread text-decoration-none'><a href="#">Wheels</a></li>
              </ul>

            </div>
          </div>
          <h2 className="text-center txt-main-listing">{categoryName}</h2>
        </div>

        <section>
          <div className="main-section-lsting wheelsPageSectioin">
            <div className="container">

              <div className="row">
                <div className="col-lg-3">
                  <div className={`main-outer-filter ${filterClass}`}>
                    <div className="filter-crose" id="close-bar">
                      {/* <button className="submit" onClick={hideFilterClass}>
                        Submit
                      </button> */}
                      <button className="abc" onClick={hideFilterClass}>
                        <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                      </button>
                    </div>


                    <div className="price-filter filter-button">
                      <div>
                        <button className='btn btn-danger' onClick={onclickFilter}>APPLY FILTER</button>
                      </div>
                    </div>


                    <div className="price-filter">
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenDiameter(!openDiameter)} aria-expanded={openDiameter} aria-controls="example-collapse-text">
                        Wheel Diameter <i className={openDiameter ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openDiameter}>
                        <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                          {

                            daimeterData && daimeterData.length > 0 ?
                              daimeterData.filter(item => item.value && item.value !== '0').map((item, index) => (
                                <li key={item.value}>
                                  <label className='label-filter'>
                                    <input
                                      type="checkbox"
                                      id={item.value}
                                      name={item.value}
                                      value={item.value}
                                      className='filter-box'
                                      checked={diameterArray.includes(item.value)}
                                      onChange={handleDiameterCheckboxChange}
                                    />
                                    {item.label + '"'}
                                    <label htmlFor={item.value} className='custome-checkboxes'></label>
                                  </label>
                                </li>
                              ))
                              : <ShimmerThumbnail height={10} rounded />
                          }
                        </ul>
                      </Collapse>
                    </div>


                    <div className="price-filter ">

                      <h3 className="price-filter-text" role="button" onClick={() => setOpenWidth(!openWidth)} aria-expanded={openWidth} aria-controls="width-collapse-text">
                        Wheel Width <i className={openWidth ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>

                      <Collapse in={openWidth}>
                        <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                          {

                            dataWheels && dataWheels.uniqueWidthFirstTime && dataWheels.uniqueWidthFirstTime.length > 0 ?
                              dataWheels.uniqueWidthFirstTime.filter(item => item.value && item.value !== '0').map((item, index) => (
                                <li key={item.value}>
                                  <label className='label-filter'>
                                    <input
                                      type="checkbox"
                                      id={"width" + item.value}
                                      name={item.value}
                                      value={item.value}
                                      className='filter-box'
                                      checked={widthArray.includes(String(item.value))}
                                      onChange={handleWidthCheckboxChange}
                                    />
                                    {item.label + '"'}
                                    <label htmlFor={"width" + item.value} className='custome-checkboxes'></label>
                                  </label>

                                </li>
                              ))
                              : <ShimmerThumbnail height={10} rounded />
                          }
                        </ul>
                      </Collapse>



                    </div>

                    <div className="price-filter ">



                      <h3 className="price-filter-text" role="button" onClick={() => setOpenPattern(!openPattern)} aria-expanded={openPattern} aria-controls="pattern-collapse-text">
                        Bolt Pattern <i className={openPattern ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openPattern}>
                        <ul className='p-0 m-0 list-filter' id="pattern-collapse-text">
                          {

                            dataWheels && dataWheels.uniqueBoltPatternFirstTime && dataWheels.uniqueBoltPatternFirstTime.length > 0 ?
                              dataWheels.uniqueBoltPatternFirstTime.filter(item => item && item !== '-' && item !== 'CUSTOM').map((item, index) => (
                                <li key={item}>
                                  <label className='label-filter'>
                                    <input
                                      type="checkbox"
                                      id={item}
                                      name={item}
                                      value={item}
                                      className='filter-box'
                                      checked={patternArray.includes(String(item))}
                                      onChange={handlePatternCheckboxChange}
                                    /> {item}
                                    <label htmlFor={item} className='custome-checkboxes'></label>
                                  </label>

                                </li>
                              ))
                              : <ShimmerThumbnail height={10} rounded />
                          }
                        </ul>
                      </Collapse>

                    </div>

                    <div className="price-filter ">

                      {/* <strong>Brands</strong> */}


                      <>
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenBrand(!openBrand)} aria-expanded={openBrand} aria-controls="brand-collapse-text">
                          Brands <i className={openBrand ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openBrand}>
                          <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                            {

                              dataWheels && dataWheels.uniqueBrandFirstTime && dataWheels.uniqueBrandFirstTime.length > 0 ?
                                dataWheels.uniqueBrandFirstTime.map((item, index) => (
                                  <li key={item.brand_id}>
                                    <label className='label-filter'>
                                      <input type="checkbox"
                                        id={item.brand_id}
                                        name={item.brand_desc}
                                        value={item.brand_id}
                                        className='filter-box'
                                        checked={brandArray.includes(String(item.brand_id))}
                                        onChange={handleBrandCheckboxChange}
                                      /> {item.brand_desc}
                                      <label htmlFor={item.brand_id} className='custome-checkboxes'></label>

                                    </label>
                                  </li>
                                ))
                                : <ShimmerThumbnail height={10} rounded />
                            }
                          </ul>
                        </Collapse>
                      </>


                    </div>


                    <div className="price-filter ">
                      <>
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenModel(!openModel)} aria-expanded={openModel} aria-controls="brand-collapse-text">
                          Model <i className={openModel ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openModel}>
                          <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                            {

                              dataWheels && dataWheels.uniqueModelFirstTime && dataWheels.uniqueModelFirstTime.length > 0 ?
                                dataWheels.uniqueModelFirstTime
                                  .filter(item => item.style && item.style !== '-')
                                  .map((item, index) => (
                                    <li key={item.style}>
                                      <label className='label-filter'>
                                        <input type="checkbox"
                                          id={"model" + item.style}
                                          name={item.style}
                                          value={item.style}
                                          className='filter-box'
                                          checked={modelArray.includes(String(item.style))}
                                          onChange={handleModelCheckboxChange}
                                        /> {item.style}
                                        <label htmlFor={"model" + item.style} className='custome-checkboxes'></label>

                                      </label>
                                    </li>
                                  ))
                                : <ShimmerThumbnail height={10} rounded />
                            }
                          </ul>
                        </Collapse>
                      </>


                    </div>

                    <div className="price-filter">
                      <div className='lugOffsetDiv mb-3'>


                        <h3 className="price-filter-text" role="button" onClick={() => setOpenWheelOffset(!openWheelOffset)} aria-expanded={openWheelOffset} aria-controls="brand-collapse-text">
                          Wheel Offset <i className={openWheelOffset ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openWheelOffset}>
                          <ul className='p-0 m-0 list-filter' id="brand-collapse-text">

                            <li>
                              <label className='label-filter'>
                                <input type="checkbox"
                                  id="negative"
                                  name="negative"
                                  value="negative"
                                  className='filter-box'
                                  checked={wheelOffsetArray.includes(String("negative"))}
                                  onChange={handleWhellOffSetCheckboxChange}
                                /> Negative
                                <label htmlFor="negative" className='custome-checkboxes'></label>

                              </label>
                            </li>
                            <li>

                              <label className='label-filter'>
                                <input type="checkbox"
                                  id="low"
                                  name="low"
                                  value="low"
                                  className='filter-box'
                                  checked={wheelOffsetArray.includes(String("low"))}
                                  onChange={handleWhellOffSetCheckboxChange}
                                /> Low 0-15
                                <label htmlFor="low" className='custome-checkboxes'></label>

                              </label>
                            </li>

                            <li>

                              <label className='label-filter'>
                                <input type="checkbox"
                                  id="medium"
                                  name="medium"
                                  value="medium"
                                  className='filter-box'
                                  checked={wheelOffsetArray.includes(String("medium"))}
                                  onChange={handleWhellOffSetCheckboxChange}
                                /> Medium 15-30
                                <label htmlFor="medium" className='custome-checkboxes'></label>

                              </label>
                            </li>

                            <li>

                              <label className='label-filter'>
                                <input type="checkbox"
                                  id="high"
                                  name="high"
                                  value="high"
                                  className='filter-box'
                                  checked={wheelOffsetArray.includes(String("high"))}
                                  onChange={handleWhellOffSetCheckboxChange}
                                /> High 30-45
                                <label htmlFor="high" className='custome-checkboxes'></label>

                              </label>
                            </li>

                            <li>

                              <label className='label-filter'>
                                <input type="checkbox"
                                  id="veryHigh"
                                  name="veryHigh"
                                  value="veryHigh"
                                  className='filter-box'
                                  checked={wheelOffsetArray.includes(String("veryHigh"))}
                                  onChange={handleWhellOffSetCheckboxChange}
                                /> Very High 45+
                                <label htmlFor="veryHigh" className='custome-checkboxes'></label>

                              </label>
                            </li>

                          </ul>
                        </Collapse>

                      </div>
                    </div>

                    <div className="price-filter">

                      <h3 className="price-filter-text" role="button" onClick={() => setOpenFinish(!openFinish)} aria-expanded={openFinish} aria-controls="color-collapse-text">
                        Finish<i className={openFinish ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openFinish}>
                        <div id='stock-collapse-text'>
                          <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                            {
                              optionsColour.map((option, index) => (
                                <li key={option.value}>
                                  <label className="label-filter">
                                    <input
                                      type="checkbox"
                                      name="wheelWidth"
                                      value={option.value}
                                      id={option.value}
                                      onChange={handleWheelColor}
                                      checked={colourWheel.includes(String(option.value))}
                                    />
                                    {option.label}
                                    <label htmlFor={option.value} className='custome-checkboxes'></label>
                                  </label>
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      </Collapse>
                    </div>

                    <div className="price-filter">
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenPrice(!openPrice)} aria-expanded={openPrice} aria-controls="price-collapse-text">
                        Price
                        <i
                          className={openPrice ? "fa fa-minus" : "fa fa-plus"}
                        >

                        </i>
                      </h3>
                      {
                        !isLoadingWheels && minPrice !== null && maxPrice !== null ?
                          <Collapse in={openPrice}>
                            <div id='price-collapse-text'>
                              <form >
                                <div className="aside-price-range-form d-flex  m-3" >
                                  <div className="form-field me-2" style={{ width: '80%' }}>
                                    {/* <label htmlFor="filter-price-min">$ Min</label> */}
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="minPrice"
                                      value={"$" + minPrice}
                                      placeholder={"$" + minPrice}
                                      autoComplete="off"
                                      maxLength="10"
                                      style={{ width: '100%' }}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="form-field me-2" style={{ width: '80%' }}>
                                    {/* <label htmlFor="filter-price-max">$ Max</label> */}
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="maxPrice"
                                      value={"$" + maxPrice}
                                      placeholder={"$" + maxPrice}
                                      autoComplete="off"
                                      maxLength="10"
                                      style={{ width: '100%' }}
                                      disabled={true}
                                    />
                                  </div>

                                </div>
                              </form>

                              <div className="">
                                <RangeSlider
                                  min={0}
                                  step={1}
                                  max={maxPriceDefault}
                                  defaultValue={[minPrice, maxPrice]}
                                  onChangeCommitted={handleRanges}
                                  valuelabeldisplay="on"

                                />
                              </div>
                            </div>
                          </Collapse> :
                          ""}
                    </div>

                    <div className="price-filter">
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenStock(!openStock)} aria-expanded={openStock} aria-controls="price-collapse-text">
                        Availability
                        <i
                          className={openStock ? "fa fa-minus" : "fa fa-plus"}
                        >

                        </i>
                      </h3>
                      <Collapse in={openStock}>
                        <div id='stock-collapse-text'>
                          <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                            {
                              optionsAvailbility.map((option, index) => (
                                <li key={index}>
                                  <label className="label-filter">
                                    <input
                                      type="checkbox"
                                      name="wheelWidth"
                                      value={option.value}
                                      id={option.value}
                                      onChange={handleStockChange}
                                      checked={stockWheel === option.value}
                                    />
                                    {option.label}
                                    <label htmlFor={option.value} className='custome-checkboxes'></label>
                                  </label>
                                </li>
                              ))
                            }
                          </ul>


                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 filter-for-mobile">

                  <div className='selectedFilters '>
                    {
                      diameterArray && diameterArray.length > 0 ?
                        diameterArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item + '"'} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveDiameter(item)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      widthArray && widthArray.length > 0 ?
                        widthArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item + '"'} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveWidth(item)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      patternArray && patternArray.length > 0 ?
                        patternArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovePattern(item)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      brandNameArray && brandNameArray.length > 0 ?
                        brandNameArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item.id}>
                            <span> {item.name} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      modelArray && modelArray.length > 0 ?
                        modelArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveModel(item)}></i>
                          </span>
                        ))
                        : ""
                    }
                    {
                      colourWheel && colourWheel.length > 0 ?
                        colourWheel.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveColor(item)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      diameterArray.length > 0 || widthArray.length > 0 || brandNameArray.length > 0 || modelArray.length > 0 || colourWheel.length > 0 ?

                        <span className="clearFilterStyle" onClick={clearAllFilters} >
                          <span>Clear filters</span>
                        </span>

                        :

                        ""
                    }
                  </div>

                  <div className="d-flex justify-content-end filters-sort-by">
                    <div className="">

                      {totalProducts > 0 ?
                        <p className="px-5">
                          Showing {startIndex} - {endIndex} of {parseInt(totalProducts).toLocaleString('en-US')} products

                        </p>
                        : ""

                      }
                    </div>
                    <div className='stockCheckbox'>
                      <ul className='stockCheckboxUl'>
                        <li>
                          <input
                            type="checkbox"
                            value="2"
                            className='tgl tgl-light'
                            onChange={handleChangeStockAvail}
                            id='stockChecki'
                            name="stockCheck"
                            checked={!isCheckedStock} // Set the checked attribute based on isChecked
                          />
                          <label htmlFor='stockChecki' className='tgl-btn'></label>
                        </li>
                        <li className='staggeredLi'>
                          {
                            isCheckedStock ?
                              <span>In Stock Items</span>
                              : <span>All Items</span>
                          }
                        </li>
                      </ul>
                    </div>
                    <div className="">
                      <select className="form-control" name="sorting" id="sortingWithPrice" onChange={handleSorting} value={sortingValue}>
                        <option disabled >Sort By Price</option>
                        <option value="1" >Low-High</option>
                        <option value="2">High-Low</option>
                      </select>

                    </div>
                    <div className="main-headin">
                      <div className="main-filter-resposive">
                        <button className="mobile-filter-btn" onClick={showFiltersClick}> <svg className="me-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" key="svg-icon">
                          <path d="M5.25901 14.35L5.25888 14.35C5.24769 14.3502 5.23405 14.3476 5.21863 14.339L5.21836 14.3389C5.19936 14.3282 5.19074 14.318 5.18703 14.3124C5.17887 14.2972 5.176 14.2839 5.176 14.2707V7.91365C5.176 7.82275 5.1652 7.72056 5.13235 7.61486C5.09945 7.50839 5.04961 7.41561 4.99386 7.33726L0.724519 1.26892C0.724485 1.26887 0.724451 1.26882 0.724418 1.26877C0.676725 1.20083 0.65495 1.13291 0.650688 1.06991C0.64665 1.00345 0.660203 0.933143 0.696549 0.863781L0.696552 0.863774C0.732993 0.794228 0.783357 0.74264 0.84197 0.707465L0.841971 0.707466L0.843135 0.706765C0.902543 0.670943 0.973036 0.65 1.05357 0.65H12.9463C13.0271 0.65 13.0973 0.670932 13.1564 0.706609L13.1578 0.70745C13.2164 0.742594 13.2667 0.794096 13.3032 0.863535C13.3407 0.935416 13.3534 1.00516 13.3492 1.06874C13.345 1.13245 13.3232 1.20066 13.2755 1.26862C13.2755 1.26872 13.2754 1.26882 13.2753 1.26892L9.0045 7.33779L9.00402 7.33848C8.94618 7.42089 8.90098 7.51067 8.86985 7.6068L8.86979 7.60678L8.8672 7.61518C8.83481 7.71999 8.82355 7.82177 8.82355 7.91441V12.1555C8.82355 12.1724 8.81984 12.1838 8.81525 12.1923C8.81075 12.1999 8.8025 12.2101 8.78506 12.2207L8.34805 12.4857L8.34827 12.4864L5.3042 14.3361C5.30409 14.3362 5.30399 14.3362 5.30388 14.3363C5.28736 14.3462 5.27261 14.3497 5.25901 14.35ZM5.27154 14.9999C5.14389 15.0023 5.01635 14.9707 4.90099 14.9061L5.27154 14.9999Z" stroke="#E81C2E" strokeWidth="1.3"></path>
                        </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    {


                      !isLoadingWheels && dataWheels ?
                        dataWheels.data && dataWheels.data.length > 0 ?
                          dataWheels.data.map((itm, idx) => {
                            return (<WheelsItemscard data={itm} key={"items" + itm.sku + idx} />);
                          })
                          : <>
                            <div className='container m-0 text-center'>
                              <h1>No Product Found</h1>
                              <p>Sorry, we couldn't find any products matching your search criteria.</p>
                              <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                            </div>

                          </>
                        :
                        Array.from({ length: 28 }).map((_, idx) => (
                          <div className="col-lg-3 col-6" key={idx}>
                            <a href="#">
                            </a>
                            <div className="Drivetrain-cart"><a href="">
                              <div className="engle-imge-upper">
                                {/* <button className="hover-btn">View Details</button> */}
                                <ShimmerThumbnail height={163} rounded />
                              </div>
                            </a>
                              <div className="cart-body">
                                <a href="#">
                                  <h6><ShimmerButton size="sm" /></h6>
                                  <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                                </a>
                                <div className="price-cart">
                                  <a href="#">
                                    <div className="price">

                                    </div>
                                  </a>
                                  <div className="cart-btn">
                                    <a href="#">
                                    </a>
                                    <a href="#">

                                      {/* <img src={data.data.brand_logo} alt="" /> */}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        ))

                    }

                  </div>
                  {totalPages > 0 &&
                    <div className="d-flex mt-4 justify-content-center">
                      <Pagination>
                        <Pagination.First
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(1)}
                        />
                        <Pagination.Prev
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        />


                        {getPageNumbers().map((page) => (
                          <Pagination.Item
                            key={page}
                            active={currentPage === page}
                            onClick={() => {
                              if (currentPage !== page) {
                                handlePageChange(page);
                              }
                            }}
                          >
                            {page}
                          </Pagination.Item>
                        ))}

                        <Pagination.Next
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(currentPage + 1)}
                        />
                        <Pagination.Last
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(totalPages)}
                        />
                      </Pagination>
                    </div>
                  }

                </div>

              </div>
            </div>
          </div>


        </section >
      </>
      <FOOTER />


    </>
  )
}
