import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { ShimmerThumbnail } from "react-shimmer-effects";
import Itemcards from "../../../components/Cards/similar-tires-items-card";

export default function Similar(prop) {
  const { item_id } = prop;
  const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;
  const [wheelData, setWheelData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
  const dataYear = vehicalDetails ? vehicalDetails.year : "";
  const dataMake = vehicalDetails ? vehicalDetails.make : "";
  const dataModel = vehicalDetails ? vehicalDetails.model : "";
  const dataModelVarient = vehicalDetails ? vehicalDetails.variant : "";
  const [isdata, setIsdata] = useState(true);

  useEffect(() => {
    fetchWheelSimilarDetails();
  }, []);

  const fetchWheelSimilarDetails = async () => {
    setIsLoading(true);
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${mainUrl}/api/tires/getSimilarTiresDetails/${item_id}?year=${dataYear}&make=${dataMake}&model=${dataModel}&varient=${dataModelVarient}`,
      };
      axios
        .request(config)
        .then((response) => {
         setWheelData(response.data);
            if(response.data.status == 500){
              setIsdata(false);
      
             }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsdata(false);
          // console.log(error);
          setIsLoading(false);
          // navigate(`/error`);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      // navigate(`/error`);
    }
  };







  return (
    <>
      <section className='similar-products-acc'>
        <div className="container">

          <div className="col-lg-12 also-bought">
            <div className="row">
            {isdata ? (<>
              <h3 className="Simila-txt py-3">Similar Products</h3>
              {
                !isLoading && wheelData  && wheelData.data?
                  wheelData.data.length ?
                    wheelData.data.map((itm, idx) => {
                      return (<Itemcards data={itm} key={itm.item_id} />);
                    })
                    : ""
                  :

                  Array.from({ length: 5 }).map((_, idx) => (
                    <div className="col-lg-2 col-md-3 col-sm-4 col-6 customeChanges" key={idx}>
                      <div className="card-tyr smilar-product">
                        <div className="tyre-imge-upper">
                          <ShimmerThumbnail height={163} rounded />
                        </div>
                        <div className="cart-body">
                          <h6><ShimmerThumbnail height={10} rounded /></h6>
                          <h4><ShimmerThumbnail height={20} rounded /></h4>
                          <div className="price-cart">
                            <div className="price">
                              <h5 className="m-0"><ShimmerThumbnail height={20} rounded /></h5>
                              <h4 className="m-0"><ShimmerThumbnail height={20} rounded /><span><ShimmerThumbnail height={20} rounded /></span></h4>
                            </div>
                            <div className="cart-btn">
                              <a href="/">
                                <ShimmerThumbnail height={20} rounded />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))

                }</>) : ("") }

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
