import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import BREADCRUMBAR from "../../components/BreadCrumBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ShimmerButton, ShimmerThumbnail, ShimmerCategoryItem } from "react-shimmer-effects";



export default function CategoryByVehicalSearch() {
    const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;
    const [isHidden, setIsHidden] = useState(false);
    const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
    const [categoryData, setCategoryData] = useState([]);
    const [loader, setLoading] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("")


    const [dataYear, setDataYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [dataMake, setDataMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [dataModel, setDataModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [dataModelVarient, setDataVarient] = useState(vehicalDetails ? vehicalDetails.variant : "");


    const rootCategories = [
        { id: 1, name: 'Interior' },
        { id: 2, name: 'Exterior' },
        { id: 3, name: 'Performance' },
        { id: 4, name: 'Lighting' },
        { id: 5, name: 'Wheels & Tires' },
        { id: 6, name: 'Parts' },
        { id: 7, name: 'Body Parts' },
        { id: 8, name: 'Audio & Electronics' },
        { id: 9, name: 'Automotive Tools' },

    ];

    const [cartItems, setCartItems] = useState("");


    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
      
        if (storedCartItems) {
          const parsedCartItems = JSON.parse(storedCartItems);
          const numCartItems = Object.values(parsedCartItems);
          const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);  
          setCartItems(sum);
        }
      }, []);
    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const navigate = useNavigate();

    const onSubmit = (id) => {
        setIsHidden(false);
        if (id === 19) {
            setTimeout(() => {
                navigate(`/wheels`);

            }, 1000);
        } else {
            setTimeout(() => {
                navigate(`/products/${id}`);

            }, 1000);
        }
    }


    useEffect(() => {
        fetchCategoryContainITems();
    }, []);


    const fetchCategoryContainITems = () => {
        try {

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/getCategoryForItemsVehical?year=${dataYear}&make=${dataMake}&model=${dataModel}&variant=${dataModelVarient}`,
            };
            axios.request(config)
                .then((response) => {
                    setCategoryData(response.data);
                    setLoading(true);

                })
                .catch((error) => {
                    console.log(error);
                    setLoading(true);
                    navigate(`/error`);

                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(true);
            navigate(`/error`);

        }
    }



    const onRedirectHome = () => {
        localStorage.removeItem('vehical_details');
        setTimeout(() => {
            navigate('/');

        }, 1000);
    }

    const setValueMenuSideBar = (value) => { 
        setShowMenuSide(value)
    }

    const wheelPageRedirect = () => {
        setTimeout(() => {
            navigate(`/wheels-search-by-vehical?year=${dataYear}&make=${dataMake}&model=${dataModel}&variant=${dataModelVarient}`);

        }, 1000);
    }

    return (
        <>
            <div className="fade_background"></div>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar}  cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <BREADCRUMBAR />
            <div className='container mt-3'>
                {vehicalDetails && vehicalDetails.year ?
                    <p className='carTitle'>

                        {vehicalDetails ? vehicalDetails.year : ""}&nbsp;
                        {vehicalDetails ? vehicalDetails.make_name : ""}
                        {vehicalDetails ? vehicalDetails.model_name : ""}&nbsp;
                        {vehicalDetails ? vehicalDetails.variant_name : ""}

                    </p> : ''
                }
            </div>
            {
                loader && categoryData ?
                    categoryData.data2.length > 0 ?
                        categoryData.data2.map((section, index) => {
                            const sectionId = Object.keys(section)[0];
                            const sectionData = section[sectionId];
                            const rootCategory = rootCategories.find(category => category.id == sectionId);
                            return (
                                <section id={`section-${sectionId}`} key={sectionId} className='mt-10'>
                                    <div className='mt-5 category-main-section'>
                                        <div className='container mt-5'>
                                            <div className='row mt-4'>
                                                <h3 className=''>{rootCategory ? rootCategory.name : '  '}</h3>
                                                {sectionData.map((item, itemIndex) => (
                                                    <div className="col-lg-3 col-md-4 col-sm-4 col-4" key={itemIndex}>
                                                        <div className="card" onClick={() => onSubmit(item.category_id)}>
                                                            <div className="row g-0 p-3">
                                                                <div className="col-md-4">
                                                                    <img src={`category/${item.image}`} alt="img" height="100%" width="100%" className="img-fluid" />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="card-body">
                                                                        <h4 className="card-title">{item.category_name} ({parseInt(item.count).toLocaleString('en-US')} )</h4>
                                                                        <span className='text-danger cursor_pointer' onClick={() => onSubmit(item.category_id)}><u>View Products</u></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            );
                        })
                        : <>
                            <div className='container text-center mt-5'>
                                <h1>No Product Found</h1>
                                <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                            </div>

                        </>


                    :
                    <section className='mt-10'>
                        <div className='mt-5'>
                            <div className='container mt-5'>
                                <div className='row'>
                                    <h2 className=''><ShimmerButton size="sm" /></h2>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                        <div className="card">
                                            <div className="row g-0 p-3">
                                                <div className="col-md-4">
                                                    <ShimmerThumbnail height={80} rounded />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h6 className="card-title"><ShimmerButton size="sm" /></h6>
                                                        <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                        <div className="card">
                                            <div className="row g-0 p-3">
                                                <div className="col-md-4">
                                                    <ShimmerThumbnail height={80} rounded />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h6 className="card-title"><ShimmerButton size="sm" /></h6>
                                                        <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                        <div className="card">
                                            <div className="row g-0 p-3">
                                                <div className="col-md-4">
                                                    <ShimmerThumbnail height={80} rounded />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h6 className="card-title"><ShimmerButton size="sm" /></h6>
                                                        <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                        <div className="card">
                                            <div className="row g-0 p-3">
                                                <div className="col-md-4">
                                                    <ShimmerThumbnail height={80} rounded />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h6 className="card-title"><ShimmerButton size="sm" /></h6>
                                                        <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                        <div className="card">
                                            <div className="row g-0 p-3">
                                                <div className="col-md-4">
                                                    <ShimmerThumbnail height={80} rounded />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h6 className="card-title"><ShimmerButton size="sm" /></h6>
                                                        <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                        <div className="card">
                                            <div className="row g-0 p-3">
                                                <div className="col-md-4">
                                                    <ShimmerThumbnail height={80} rounded />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h6 className="card-title"><ShimmerButton size="sm" /></h6>
                                                        <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                        <div className="card">
                                            <div className="row g-0 p-3">
                                                <div className="col-md-4">
                                                    <ShimmerThumbnail height={80} rounded />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h6 className="card-title"><ShimmerButton size="sm" /></h6>
                                                        <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                        <div className="card">
                                            <div className="row g-0 p-3">
                                                <div className="col-md-4">
                                                    <ShimmerThumbnail height={80} rounded />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h6 className="card-title"><ShimmerButton size="sm" /></h6>
                                                        <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

            }


            {/* <section  className='mt-10'>
                <div className='mt-5 category-main-section'>
                    <div className='container mt-5'>
                        <div className='row mt-4'>
                            <h3 className=''>Wheels</h3>
                            
                                <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                    <div className="card">
                                        <div className="row g-0 p-3">
                                            <div className="col-md-4">
                                                <img src={`category/wheels.png`} alt="img" height="100%" width="100%" className="img-fluid" />
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card-body">
                                                    <h4 className="card-title">Wheels (51,016)</h4>
                                                    <span className='text-danger cursor_pointer' onClick={wheelPageRedirect}><u>View Products</u></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </section> */}







            {/* <NEWSLETTER /> */}
            < FOOTER />
        </>

    )
}
