import { React, useState, useEffect } from 'react';
import DetaileSection from '../../../components/DetailSection/tires-details';
import axios from 'axios';
import { ShimmerSocialPost,ShimmerThumbnail  } from "react-shimmer-effects";
import Slider from '../../../components/Slider/tires-slider';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


export default function Details(prop) {
  const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;
  const {item_id,getCartData} = prop;
  const [wheelData, setWheelData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [itemName, setitemName] = useState("");
  const [isdata, setIsdata] = useState(true);

  const currentLink = window.location.href;
  const url = new URL(currentLink);

  // Remove all query parameters
  url.search = '';
  const updatedUrl = url.toString();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTiresDetails();
  }, []);

  const fetchTiresDetails = async () => {
    setIsLoading(true);
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${mainUrl}/api/tires/getSingleTiresDetails/${item_id}`,
      };
      axios
        .request(config)
        .then((response) => {
          setWheelData(response.data);
          setitemName(response.data.data[0].brand + " " + response.data.data[0].line + " " +  response.data.data[0].size + " "   +  response.data.data[0].loadindex + " " +  response.data.data[0].speedrating
          );
          setIsLoading(false);
        })
        .catch((error) => {
          setIsdata(false);
          // navigate('/pagenotfound');
          // console.log(error);
          setIsLoading(false);
          // navigate(`/error`);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      // navigate(`/error`);
    }
  };

  const setdata = (value) =>{
    getCartData(value)
  }
  const goBackToHomepage = () => {
    navigate('/');
};


  return (
    <>{ isdata ? 
    <Helmet>
    <title>{itemName}</title>
    <meta name="description" content={itemName} />
    <link rel="canonical" href={updatedUrl} /> 
    </Helmet> : 
    <Helmet>
    <title>Tire description</title>
    <meta name="description" content='We are dedicated to maintaining our position as a leading distributor, specializing in wheels, tires, and a wide array of accessories' />
    <link rel="canonical" href={updatedUrl} />
    
    </Helmet>
    
    }
    { isdata ? (
      <>
      <section>
        <div className="parformane-bar second-bar-p">
          <div className="container">
            <ul className="p-0 m-0 listing-parmofamne">
              <li><a href="/">Home/ </a></li>
              {/* <li><a href="/tires">Tires/ </a></li> */}
              <li className="active-bread"><a href="#">{itemName}</a></li>
            </ul>
          </div>
        </div>
      </section>
      <section className="slider-pernt">
        <div className='container wheelDetailsContainer'>
          <div className="row">
            <div className="col-lg-6">
              {!isLoading && typeof wheelData.data !== "undefined" && wheelData.data.length > 0 ?

                
                  <Slider data={wheelData.data[0]}  brand={wheelData.brand[0]}/>
                  

                :
                <ShimmerThumbnail height={572} rounded />
              }
            </div>
            <div className="col-lg-6">
              {
                !isLoading && typeof wheelData.data !== "undefined" && wheelData.data.length > 0 ?
                  <DetaileSection data={wheelData.data[0]}  setdata={setdata}  brand={wheelData.brand[0]}/>
                  :
                  // <ShimmerSocialPost type="text" title="false" />""
                  ""

              }


            </div>
          </div>
          
        </div>
        
      </section>
      </>

)
: (<div className="error-screen">
<h1><strong></strong></h1>
<h2>No Product Found</h2>
<button className="btn btn-sm btn-danger" onClick={goBackToHomepage}>
  Go back to homepage
</button>
</div> )}

    </>
  )
}
