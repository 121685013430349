import { React, useState, useEffect } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { ShimmerTitle } from "react-shimmer-effects";





export default function DetaileSection(prop) {

    const { data, setdata } = prop;
    console.log(data.fitment);
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    let invnetoryStock = data.quantity
    const [valueCart, setValueCart] = useState(1);
    const [cartData, setCartData] = useState([]);
    const [wheelPrice, setWheelPrice] = useState(0);

    const product_id = data.sku;
    const vendor_id = data.vendor_id;

    const product_quantity = valueCart;
    const category_type = 'wheel';
    const price = data.map_usd && data.map_usd > 0 ? data.map_usd : data.msrp_usd
    const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    let image = data.image_url1
    const vehical_details = JSON.parse(localStorage.getItem('vehical_details'));
    const fitmentType = localStorage.getItem('vehical_fitment');



    const formatDescription = (description) => {
        if (!description) return '';

        const commentIndex = description.indexOf('comment :');
        if (commentIndex !== -1) {
            const firstPart = description.slice(0, commentIndex).trim();
            const commentPart = description.slice(commentIndex + 9).trim(); // +9 to skip 'comment :'
            return (
                <>
                    {firstPart}<br />
                    <strong>Comment:</strong> {commentPart}
                </>
            );
        } else {
            return description;
        }
    };

    let descriptionWheel = "";
    if (data.product_desc) {

        if (data.vendor_id == 10) {
            descriptionWheel = data.product_desc.replace(/<.*?>/g, '');
        } else {
            const lowercaseDescription = data.product_desc.toLowerCase();
            const lines = lowercaseDescription.split('. ');
            const uppercaseLines = lines.map(line => line.charAt(0).toUpperCase() + line.slice(1));
            const result = uppercaseLines.join(". ");

            descriptionWheel = formatDescription(result);

        }
    }

    if (!image || data.vendor_id == 7) {
        image = S3URL + data.logo
    }


    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comments, setComments] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const year = vehical_details ? vehical_details.year : "";
    const make_name = vehical_details ? vehical_details.make_name : "";
    const model_name = vehical_details ? vehical_details.model_name : "";
    const variant_name = vehical_details ? vehical_details.variant_name : "";
    const fitment = fitmentType ? fitmentType : "";
    const [affirmLoad, setAffirmLoad] = useState(false);
    const [getLoader, setLoader] = useState(false);


    useEffect(() => {
        setWheelPrice(data.map_usd && data.map_usd > 0 ? data.map_usd : (data.msrp_usd ? data.msrp_usd : 0))

    }, []);

    useEffect(() => {
        const affirmConfig = {
            public_api_key: "Z6PMLBXW7DT8S738",
            script: "https://cdn1.affirm.com/js/v2/affirm.js"
        };

        const initializeAffirm = () => {
            const { affirm } = window;

            const priceUpdateEventHandler = (newPrice) => {
                // console.log("priceUpdateEventHandler");
                // changePriceHandler(newPrice);
            }

            const updateAffirmPromos = (newPrice) => {
                document.getElementById('affirmProductModal').setAttribute('data-amount', newPrice);
                document.getElementById('affirmLearnMore').setAttribute('data-amount', newPrice);
                affirm.ui.refresh();
            }

            affirm.ui.ready(() => {
                const newPrice = 699;

                // Listen for the Affirm Learn More modal close event
                affirm.events.on('learnMore:close', () => {
                    // console.log('Affirm Learn More modal closed');
                });

                // Add this script to an existing callback that fires when the price updates
                priceUpdateEventHandler(newPrice);

                // Update Affirm promos with the initial price
                updateAffirmPromos(newPrice);
            });
        };

        // Load Affirm script
        const loadAffirmScript = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = affirmConfig.script;
            script.onload = initializeAffirm;
            document.body.appendChild(script);
            setAffirmLoad(true)

        };

        // Run the initialization after 5 seconds
        setTimeout(loadAffirmScript, 10000);

        return () => {
            // Cleanup: Remove Affirm script when component is unmounted
            // document.body.removeChild(script);
        };
    }, []);

    let brand_desc = data.brand_desc;
    let style = data.style;
    let fancy_finish_desc = data.fancy_finish_desc;
    let size_desc = data.size_desc;
    let bolt_pattern_metric_ = data.bolt_pattern_metric;
    let wheel_offset = data.wheel_offset;


    let shippingWeight = "N/A";
    let wheelWeight = "N/A";
    let loadRatingMetric = "N/A";
    let loadRatingStandard = "N/A";
    let capPartNumber = "N/A";


    if (data.wheel_weight && data.wheel_weight > 0) {
        wheelWeight = removeDecimalIfZeros(data.wheel_weight) + " Lbs"
    }

    if (data.wheel_weight && data.wheel_weight > 0) {
        if (data.wheel_weight == data.shipping_weight) {
            shippingWeight = parseFloat(data.wheel_weight) + 2.50
            shippingWeight = removeDecimalIfZeros(parseFloat(shippingWeight).toFixed(2)) + " Lbs";
        } else {
            shippingWeight = removeDecimalIfZeros(data.shipping_weight) + " Lbs"
        }
    }
    if (data.load_rating_metric && data.load_rating_metric > 0) {
        loadRatingMetric = removeDecimalIfZeros(parseFloat(data.load_rating_metric).toFixed(2)) + " Kg";
    }

    if (data.load_rating_standard && data.load_rating_standard > 0) {
        loadRatingStandard = removeDecimalIfZeros(parseFloat(data.load_rating_standard).toFixed(2)) + " Lbs";
    }

    if (capPartNumber && capPartNumber != '-') {
        capPartNumber = capPartNumber;
    }

    let product_name = "";
    if (data.vendor_id == 5 || data.vendor_id == 6 || (data.vendor_id == 4 && data.wheel_offset == null)) {
        product_name = data.product_name


    } else if (data.vendor_id == 7 && data.product_name == "ESR - -  0MM 5X105") {
        product_name = data.product_desc
    } else {


        product_name = `${brand_desc}  ${style}  ${fancy_finish_desc} ${size_desc}  ${wheel_offset}MM  ${bolt_pattern_metric_}`;


    }
    if (year) {
        if (fitment) {
            product_name = `${product_name}\n("${year}, ${make_name}, ${model_name}, ${variant_name},Lifted:${fitment}")`;

        } else {
            product_name = `${product_name}\n("${year}, ${make_name}, ${model_name}, ${variant_name}")`;

        }

    }

    const apiAddtoCart = async (cartItems) => {
        setLoader(true);
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/add-to-cart`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {
                    product_image: image,
                    product_id: product_id,
                    product_name: product_name,
                    product_price: price,
                    product_quantity: valueCart,
                    category_type: category_type,
                    total_quantity: invnetoryStock,
                    part_number: product_id,
                    vendor_id: vendor_id
                },
                data: {
                    cart_key: localStorage.getItem("cart-key") ? localStorage.getItem("cart-key") : '',
                    user_token: localStorage.getItem("user-token") ? localStorage.getItem("user-token") : ''
                }
            };
            axios
                .request(config)
                .then((response) => {
                    localStorage.setItem("cart-key", response.data.addCartResponse);
                    localStorage.setItem('totalCartItems', JSON.stringify(cartItems));
                    setCartData(cartItems);
                    setdata(cartItems);
                    toast.success("Item Added to cart!", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setLoader(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoader(false);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoader(false);
        }
    };

    const addItemstoCaart = () => {
        const cartItems = JSON.parse(localStorage.getItem('totalCartItems')) || {};
        const itemId = data.sku
        const existingValue = cartItems[itemId] || 0;
        const updatedValue = existingValue + valueCart;
        // Check if the updated value exceeds the stockInventory
        if (updatedValue > invnetoryStock) {
            toast.info("Cannot add more items than available in stock! ", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        cartItems[itemId] = updatedValue;
        apiAddtoCart(cartItems);
    }

    const increaseValue = () => {

        if (valueCart < invnetoryStock) {
            setValueCart(valueCart + 1);

        }

    };

    const decreaseValue = () => {
        if (valueCart > 1) {
            setValueCart(valueCart - 1);

        }
    }

    function removeDecimalIfZeros(number) {
        const formattedNumber = Number((parseFloat(number)).toFixed(2)); // Set the desired number of decimal places
        const decimalPart = formattedNumber % 1;
        return decimalPart === 0 ? (parseFloat(formattedNumber)).toFixed(0) : formattedNumber;
    }
    function addSign(number) {
        if (number > 0) {
            return '+' + number;
        } else if (number < 0) {
            return '-' + Math.abs(number);
        } else {
            return number;
        }
    }


    const handleEnquiryModel = () => {
        setShowModal(true)
    }
    const handleClose = () => {
        setShowModal(false)
    }


    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        // Remove non-numeric characters from input
        const cleanedInput = e.target.value.replace(/\D/g, '');
        setPhone(cleanedInput);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here, e.g. sending the inquiry data to a backend API
        // You can access email, phone, and comments state variables here
        // Reset form fields if needed
        try {

            try {
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/submit-inquiry`,
                    credentials: 'include', // Send cookies with the request
                    withCredentials: true,
                    params: {
                        email: email,
                        phone: phone,
                        comments: comments,
                        firstName: firstName,
                        lastName: lastName

                    }
                };
                axios
                    .request(config)
                    .then((response) => {
                        toast.success("Submitted successfully!", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        // Reset form fields and close the modal
                        setEmail('');
                        setPhone('');
                        setComments('');
                        handleClose();

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            } catch (error) {
                console.error('Error fetching data:', error);

            }

        } catch (error) {
            console.error('Error submitting inquiry:', error);
        }


    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        // If the input is empty, set the value to an empty string
        if (inputValue === '') {
            setValueCart(1);
            return;
        }

        // Parse the input value as an integer
        const parsedValue = parseInt(inputValue, 10);

        // Check if the parsed value is a valid number
        if (!isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= invnetoryStock) {
            setValueCart(parsedValue);
        }
    };


    let bolt_pattern_metric = data.bolt_pattern_metric.replace(/-/g, 'X');

    let product_name_ = `${data.brand_desc}  ${data.style}  ${data.fancy_finish_desc} ${data.size_desc}  ${data.wheel_offset}MM  ${bolt_pattern_metric}`;
    let wheel_lip_dipth = 0;
    if (data.vendor_id == 5 || data.vendor_id == 7) {
        wheel_lip_dipth = data.lip_depth;
        // console.log(wheel_lip_dipth,"wheel_lip_dipth")
    } else {
        wheel_lip_dipth = removeDecimalIfZeros(data.lip_depth);
    }


    let fitmentData = ""

    // Function to parse the fitment data string
    const parseFitmentData = (fitmentDataString) => {
        // Remove 'Fits:' prefix and split the string into individual fitment entries
        const fitmentEntries = fitmentDataString.replace('Fits: ', '').split(', ');

        // Map each fitment entry to an object containing model and year range
        const fitmentObjects = fitmentEntries.map((entry) => {
            // Split each entry into model and year range
            const [yearRange, ...yearRangeParts] = entry.split(' ').reverse();
            const model = yearRangeParts.reverse().join(' ');

            return { yearRange, model };
        });
        fitmentObjects.pop();
        return fitmentObjects;
    };
    if (data && data.fitment && data.fitment.length > 0) {
        fitmentData = parseFitmentData(data.fitment);


    }



    return (
        <>
            <div className="main-product-data">
                <div className="product-name-details">
                    <span className='tag_name'>{data.brand_desc}</span>
                    <h2>{data.vendor_id == 10 || data.vendor_id == 5 || data.vendor_id == 6 || (data.vendor_id == 4 && data.wheel_offset == null) ? data.product_name : data.vendor_id == 7 && data.product_name == "ESR - -  0MM 5X105" ? data.product_desc : product_name_} </h2>
                    <span className='prod_cat'>{data.category_name}</span>
                    <div className="stock mb-3">
                        <h3 className="m-0">Stock</h3>
                        <h4 className="m-0">
                            {
                                data.quantity > 0 && wheelPrice > 0 ?
                                    <span className=''><i className="fa fa-circle me-1"></i> In stock ( {data.quantity} )</span>
                                    :
                                    <span className='text-danger'><i className="fa fa-circle me-1"></i>Out of Stock</span>
                            }

                        </h4>
                        <h5 className="m-0">SKU: <span>{data.sku}</span></h5>
                    </div>



                    <div id="prices" style={{ height: '25px' }}>
                        {
                            !affirmLoad ?
                                <ShimmerTitle line={1} gap={10} variant="primary" />
                                :
                                <p id="learn-more" className="affirm-as-low-as" data-amount={`${data.msrp_usd * 100}`} data-affirm-color="blue" data-learnmore-show="true" data-page-type="product"> </p>


                        }
                    </div>

                    <div className="product-deastil-preice deal_of_the_day">
                        <div className='price'>
                            <h2>

                                ${parseFloat(wheelPrice).toFixed(2)}
                                {(data.msrp_usd > data.map_usd) && data.map_usd > 0 ?
                                    <s>MSRP&nbsp;${parseFloat(data.msrp_usd).toFixed(2)}</s> : " "}

                            </h2>

                        </div>
                        <div className='free-shippping'>
                            {
                                data.msrp_usd >= 100 ? (
                                    <h2 style={{ fontSize: "14px", fontWeight: "500" }}>
                                        &nbsp;<i className="fa fa-truck" aria-hidden="true"></i> &nbsp; Free Shipping<b style={{ fontSize: '10px' }}>(Refer Terms & Conditions)</b>
                                    </h2>
                                ) : (
                                    ""
                                )
                            }
                        </div>


                    </div>

                    <div className="quantity">
                        {
                            invnetoryStock > 0 && wheelPrice > 0 ?
                                <>
                                    <div className="incres">
                                        <div className="value-button" id="decrease" onClick={decreaseValue}>
                                            <i className="fa fa-minus"></i>
                                        </div>
                                        <input type="text" id="number" value={valueCart} onChange={handleInputChange} disabled />
                                        <div className="value-button" id="increase" onClick={increaseValue}>
                                            <i className="fa fa-plus"></i>
                                        </div>
                                    </div>

                                    <div className="cart">
                                        {!getLoader ?
                                            <button className="" onClick={addItemstoCaart}>
                                                <svg className="me-1" width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.982759 1.96567H4.07847L5.65092 10.9827C5.7738 11.6214 6.48624 12.2849 7.10048 12.2849H17.6654C18.3288 12.2849 18.8201 11.6952 18.8938 11.1056L21.3263 1.42519C21.3508 1.35149 21.3508 1.2778 21.3508 1.17942C21.3508 0.516003 20.8347 0.00013155 20.1223 0.00013155H7.91125C7.37073 0.00013155 6.92856 0.442295 6.92856 0.982824C6.92856 1.52335 7.37073 1.96552 7.91125 1.96552H19.1887L18.8202 3.43977H8.59927C8.05874 3.43977 7.61658 3.88194 7.61658 4.42247C7.61658 4.963 8.05874 5.40516 8.59927 5.40516H18.3042L17.9356 6.87955H9.1397C8.59917 6.87955 8.15701 7.32171 8.15701 7.86224C8.15701 8.40277 8.59917 8.84493 9.1397 8.84493H17.4442L17.0757 10.3192H7.54275L5.87211 0.810794C5.79842 0.343929 5.38077 0 4.9139 0H0.982693C0.442163 0 0 0.442163 0 0.982692C0 1.52322 0.442163 1.96556 0.982693 1.96556L0.982759 1.96567Z" fill="white" />
                                                    <path d="M16.0444 18.4272C17.4449 18.4272 18.575 17.2725 18.575 15.8474C18.575 14.5207 17.6168 13.4396 16.3639 13.2923H16.3393H16.2656H16.1428H8.59993H8.55074H8.45253C7.05198 13.2923 5.92188 14.447 5.92188 15.8721C5.9217 17.297 7.05198 18.4272 8.42783 18.4272C9.80368 18.4272 10.9585 17.2725 10.9585 15.8474C10.9585 15.6263 10.934 15.4297 10.8848 15.2332H13.612C13.5628 15.4297 13.5383 15.6263 13.5383 15.8474C13.5383 17.2724 14.6686 18.4272 16.0445 18.4272H16.0444ZM16.0444 15.2332C16.3391 15.2332 16.6094 15.5034 16.6094 15.8474C16.6094 16.1914 16.3636 16.4616 16.0444 16.4616C15.7249 16.4616 15.4793 16.1914 15.4793 15.8474C15.4791 15.5034 15.7494 15.2332 16.0444 15.2332ZM8.42781 16.4616C8.13303 16.4616 7.86276 16.1914 7.86276 15.8474C7.86276 15.5034 8.10852 15.2332 8.42781 15.2332C8.74727 15.2332 8.99287 15.5034 8.99287 15.8474C8.99287 16.1914 8.74727 16.4616 8.42781 16.4616Z" fill="white" />
                                                </svg>
                                                Add to Cart
                                            </button>
                                            :
                                            <button className="" disabled>
                                                Loading...
                                            </button>
                                        }
                                    </div>
                                    <div className="Finance">
                                        <a href='https://consumer.snapfinance.com/#/?mid=490293126' target='_blank'>
                                            <button>Click to Finance</button>
                                        </a>
                                    </div>
                                </>
                                : <>
                                    <div className="cart">

                                        Out of stock !

                                    </div>
                                    <div className="Finance">
                                        <button className="Finance" title='Item out of stock' onClick={handleEnquiryModel}>
                                            Click for Enquiry</button>
                                    </div>
                                </>
                        }

                    </div>
                </div>

                {/* <div className='subCategoryNameDetailsPage'>
                    <span className='sub_cat_name'>
                        Wheels
                    </span>
                </div> */}


                <div className="producDescription">
                    <strong>About this product </strong>
                    <p className='mt-2'>
                        {data.product_desc ? descriptionWheel : data.product_name}
                    </p>
                </div>
                <div className="mainAttributes">
                    <div className='row'>
                        <div className='col-md-6 d-flex'>
                            <div className='iconImage'>
                                <img src='/icons/wheel-diameter.svg' alt='img' height="100%" width="100%" />
                            </div>
                            <div className='d-grid'>
                                <span><strong>Wheel Diameter</strong></span>
                                <span className='px-4 mt-1'>{removeDecimalIfZeros(data.diameter)}"</span>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex'>
                            <div className='iconImage' >
                                <img src='/icons/wheel-width.svg' alt='img' height="100%" width="100%" />
                            </div>
                            <div className='d-grid'>
                                <span><strong>Wheel Width</strong></span>
                                <span className='px-4 mt-1'>{removeDecimalIfZeros(data.width)}"</span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 d-flex'>
                            <div className='iconImage'>
                                <img src='/icons/wheel-offset.svg' alt='img' height="100%" width="100%" />
                            </div>
                            <div className='d-grid'>
                                <span><strong>Wheel Offset</strong></span>
                                <span className='px-4 mt-1'>{addSign(data.wheel_offset)}</span>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex'>
                            <div className='iconImage'>
                                <img src='/icons/lug-pattern.svg' alt='img' height="100%" width="100%" />
                            </div>
                            <div className='d-grid'>
                                <span><strong>Lug Pattern </strong></span>
                                <span className='px-4 mt-1'>{data.vendor_id == 7 && data.product_name == "ESR - -  0MM 5X105" ? "N/A" : (data.vendor_id == 10 && data.bolt_pattern_text == "BLANK") ? "BLANK" : data.bolt_pattern_metric}{data.custom_dril == "Custom Drill" ? `(${data.custom_dril})` : ""}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='singleWheelSpecification'>
                    <table className='table' style={{ fontSize: '14px' }}>
                        <tbody>

                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px', paddingLeft: '10px' }}><strong>Brand</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.brand_desc}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }} ><strong>Offset Range</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.wheel_offset}</td>
                            </tr>

                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px', paddingLeft: '10px' }}><strong>Model/Style</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.style}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }} ><strong>Size Desc</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.size_desc ? data.size_desc : `${data.diameter}X${data.width}`}</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Finish</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.fancy_finish_desc}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Hub Bore</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.vendor_id == 7 && data.product_name == "ESR - -  0MM 5X105" ? "N/A" : removeDecimalIfZeros(data.centerbore)}</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Structural Warranty </strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.structural_warranty}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Lip Depth </strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{wheel_lip_dipth}"  {data.lip_depth_text ? 'Concavity Profile' : ""}</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}> <strong>Construction type</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.construction_type ? data.construction_type : "N/A"}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Wheel Weight</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{(wheelWeight)}</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Steel Wheel</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.steel_wheel}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Shipping Weight</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{shippingWeight}</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>TPMS Compatible</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.tpms_compatible}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Load Rating Metric</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{loadRatingMetric}</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Chemical 1</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.prop65_chemical1}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Load Rating Standard </strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{loadRatingStandard}</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Chemical 2</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.prop65_chemical2}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Cap Part No.</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{capPartNumber}</td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Chemical 3</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.prop65_chemical3}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Lug Pattern Standard</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.vendor_id == 7 && data.product_name == "ESR - -  0MM 5X105" ? "N/A" : (data.vendor_id == 10 && data.bolt_pattern_text == "BLANK") ? "BLANK" : data.bolt_pattern_standard ? data.bolt_pattern_standard : "N/A"}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                {fitmentData && fitmentData.length > 0 ?

                    <div className='wheel-fitment-section'>
                        <strong>Vehicle Fitment</strong>
                        <div className="table-wrapper">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Model</th>
                                        <th>Year</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    {fitmentData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.model}</td>
                                            <td>{item.yearRange}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <p>Fitment may require lifts for some vehicles and wheel-tire sizes.</p>
                    </div>
                    : ""

                }

            </div>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Your Enquiry About : {data.product_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} className='enquiry-form-class'>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="email">
                                    <Form.Label>First Name*</Form.Label>
                                    <Form.Control
                                        type="firstName"
                                        placeholder="Enter first name"
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter last name"
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                                <Form.Group controlId="phone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter phone number"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group controlId="comments">
                                    <Form.Label>Year, Make, Model & Comments*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter your comments"
                                        value={comments}
                                        onChange={handleCommentsChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-12 text-end'>
                                <Form.Group controlId="comments">
                                    <Button variant="danger" type="submit">
                                        Submit
                                    </Button>
                                </Form.Group>
                            </div>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )
} 
