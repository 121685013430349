import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import Itemcards from "../../components/Cards/itemscard";
import Pagination from 'react-bootstrap/Pagination';
import { ShimmerButton, ShimmerThumbnail, ShimmerCategoryItem } from "react-shimmer-effects";
import { RangeSlider } from 'rsuite';
import "rsuite/dist/rsuite.css";
import axios from 'axios';
import BREADCRUMBAR from "../../components/BreadCrumBar";
import Collapse from 'react-bootstrap/Collapse';




export default function Products() {
    const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;
    const [isHidden, setIsHidden] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);
    const navigate = useNavigate();
    const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
    const { id } = useParams();
    const [dataCategory, setDataCategory] = useState([]);
    const [isLoadingCategory, setIsLoadingCategory] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [totalProducts, setTotalProducts] = useState(0);
    const [productsPerPage, setProductsPerPage] = useState(0);

    const [categoryName, setCategoryName] = useState('');

    const [dataBradnAndCategory, setDataBradnAndCategory] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const [dataYear, setDataYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [dataMake, setDataMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [dataModel, setDataModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [dataModelVarient, setDataVarient] = useState(vehicalDetails ? vehicalDetails.variant : "");


    const [selectedYear, setSelectedYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [selectedMake, setSelectedMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [selectedModel, setSelectedModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [selectedVariant, setSelectedVariant] = useState(vehicalDetails ? vehicalDetails.variant : "");

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMake, setIsLoadingMake] = useState(true);
    const [isLoadingModel, setIsLoadingModel] = useState(true);
    const [isLoadingVarient, setIsLoadingVarient] = useState(true);


    const [isLoaderMake, setIsLoaderMake] = useState(true);
    const [isLoaderModel, setIsLoaderModel] = useState(true);
    const [isLoaderVarient, setIsLoaderVarient] = useState(true);

    const [sortingValue, setSortingValue] = useState('');

    const MAX_PAGES_DISPLAY = 10;

    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoriesName, setSelectedCategoriesName] = useState([]);
    const [selectedBrandsName, setSelectedBrandsName] = useState([]);


    const [firstValue, setFirstValue] = useState(0);
    const [secondValue, setSecondValue] = useState(5000);

    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');

    const [showWholwPageLoader, setShowWholwPageLoader] = useState(false);

    const [open, setOpen] = useState(false);
    const [openBrand, setOpenBrand] = useState(false);
    const [openPrice, setOpenPrice] = useState(false);



    const [showError, setShowError] = useState(false)
    useEffect(() => {

        setTimeout(() => {
            fetchProductData();
        }, 1000);



    }, [currentPage, sortingValue, selectedCategories, selectedBrands, firstValue, secondValue]);

    useEffect(() => {
        fetchBrandAndCatData();
    }, []);

    useEffect(() => {
        fetchYearData();
    }, []);

    //fetch product items 
    const fetchProductData = async () => {
        setShowWholwPageLoader(true)

        setIsLoadingCategory(true);
        const categoriesString = selectedCategories.join(',');
        const brandString = selectedBrands.join(',');
        const limit = 28; // Define the limit per page
        const offset = (currentPage - 1) * limit; // Calculate the offset based on the current page and limit
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/category/${id}?limit=${limit}&offset=${offset}&sorting=${sortingValue}&subcategories=${categoriesString}&brands=${brandString}&startingPrice=${firstValue}&endPrice=${secondValue}&year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`,
            };
            axios.request(config)
                .then((response) => {
                    setDataCategory(response.data);
                    setIsLoadingCategory(false);
                    setTotalPages(response.data.totalPages);
                    setTotalProducts(response.data.count);
                    setProductsPerPage(response.data.data.length);
                    setCategoryName(response.data.data ? response.data.data[0].category_name : "");
                    setShowWholwPageLoader(false)
                    window.scrollTo({ top: 400, behavior: 'smooth' });
                })
                .catch((error) => {
                    console.log(error);
                    setShowWholwPageLoader(false)
                    navigate(`/error`);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoadingCategory(false);
            setShowWholwPageLoader(false)
            navigate(`/error`);

        }
    };


    //fetch brand  
    const fetchBrandAndCatData = async () => {

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/getSubCatBrandFromCat/${id}?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`,
            };
            axios.request(config)
                .then((response) => {
                    setDataBradnAndCategory(response.data);
                    setIsInitialLoad(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsInitialLoad(false);
                    navigate(`/error`);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsInitialLoad(false);
            navigate(`/error`);
        }
    };

    //fetch year


    const fetchYearData = async () => {
        setIsLoading(true);
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/getYear`,
            };
            axios
                .request(config)
                .then((response) => {
                    setDataYear(response.data);
                    setIsLoading(false);
                    fetchMakeData(selectedYear);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    navigate(`/error`);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
            navigate(`/error`);
        }
    };

    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
       

        if (selectedYear === "") {
            setSelectedMake('');
            setSelectedModel('');
            setSelectedVariant('');
            setDataMake("")
            setDataModel("")
            setDataVarient("")

        } else {
            setSelectedYear(selectedYear);
            setSelectedMake('');
            setSelectedModel('');
            setSelectedVariant('');
            // Fetch make data based on selected year
            fetchMakeData(selectedYear);
            setIsLoaderMake(false);

        }

    };

    const fetchMakeData = async (selectedYear) => {
        if (selectedYear) {
            setIsLoadingMake(true);
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/getMakeByYear/${selectedYear}`,
                };
                axios
                    .request(config)
                    .then((response) => {

                        setIsLoadingMake(false);
                        setDataMake(response.data);
                        setIsLoaderMake(true);
                        fetchModelData(selectedYear, selectedMake);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoadingMake(false);
                        navigate(`/error`);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoadingMake(false);
                navigate(`/error`);
            }
        }
    };

    const handleMakeChange = (event) => {
        const selectedMake = event.target.value;
        setSelectedMake(selectedMake);

        // setDataMake([]);
        setDataModel([]);
        setSelectedModel('');
        setSelectedVariant('');
        // Fetch model data based on selected year and make
        setIsLoaderModel(false)
        fetchModelData(selectedYear, selectedMake);
    };

    const fetchModelData = async (selectedYear, selectedMake) => {
        if (selectedYear && selectedMake) {
            setIsLoadingModel(true);
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/getModelByMake/${selectedYear}/${selectedMake}`,
                };
                axios
                    .request(config)
                    .then((response) => {
                        
                        setIsLoadingModel(false);
                        setDataModel(response.data);
                        setIsLoaderModel(true)
                        fetchVariantData(selectedYear, selectedModel);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoadingModel(false);
                        navigate(`/error`);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoadingModel(false);
                navigate(`/error`);
            }
        }
    };

    const handleModelChange = (event) => {
        const selectedModel = event.target.value;
        setSelectedModel(selectedModel);
        setSelectedVariant('');
        // Fetch variant data based on selected year, make, and model
        fetchVariantData(selectedYear, selectedModel);
        setIsLoaderVarient(false)
    };

    const fetchVariantData = async (selectedYear, selectedModel) => {
        if (selectedYear && selectedModel) {
            setIsLoadingVarient(true);
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/getSubModelByModel/${selectedYear}/${selectedModel}`,
                };
                axios
                    .request(config)
                    .then((response) => {
                        
                        setIsLoadingVarient(false);
                        setDataVarient(response.data);
                        setIsLoaderVarient(true)
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoadingVarient(false);
                        navigate(`/error`);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoadingVarient(false);
                navigate(`/error`);
            }
        }
    };

    const handleVarientChange = (event) => {
        const selectedVarient = event.target.value;
        setSelectedVariant(selectedVarient);

    };
    const handleSorting = (event) => {
        const sortingValue = event.target.value;
       
        setSortingValue(sortingValue);
    };


    const handlePageChange = (page) => {

        setCurrentPage(page);

    };

    const getPageNumbers = () => {
        const totalDisplayPages = Math.min(totalPages, MAX_PAGES_DISPLAY);
        const middlePage = Math.ceil(totalDisplayPages / 2);
        let startPage = 1;

        if (currentPage > middlePage) {
            startPage = currentPage - middlePage + 1;
        }

        const endPage = startPage + totalDisplayPages - 1;

        return Array.from({ length: totalDisplayPages }, (_, index) => startPage + index);
    };



    function handleRanges(value) {
        setFirstValue(value[0]);
        setSecondValue(value[1]);

        setMinPrice('$ ' + value[0]);
        setMaxPrice('$ ' + value[1]);
    }



    const handleSubmit = (event) => {
        let state = {
            year: selectedYear,
            make: selectedMake,
            model: selectedModel,
            variant: selectedVariant,
        }
        localStorage.setItem('vehical_details', JSON.stringify(state));
        navigate('/search-products', { state: state });
    };

    const handleBrandCheckboxChange = (event) => {
        setIsHidden(true);
        const brandId = event.target.value;
        const brandIdName = event.target.name;
        if (selectedBrands.includes(brandId)) {
            setSelectedBrands(selectedBrands.filter((id) => id !== brandId));
        } else {
            setSelectedBrands([...selectedBrands, brandId]);
        }
        // Check if the category object is already in the selectedCategoriesName array
        const categoryObject = { id: brandId, name: brandIdName };
        const categoryObjectIndex = selectedBrandsName.findIndex(
            (obj) => obj.id === brandId
        );
        if (categoryObjectIndex !== -1) {
            setSelectedBrandsName(
                selectedBrandsName.filter((obj) => obj.id !== brandId)
            );
        } else {
            setSelectedBrandsName([...selectedBrandsName, categoryObject]);
        }

        
    };

    const handleCategoryCheckboxChange = (event) => {

        setIsHidden(true);

        const categoryId = event.target.value;
        const categoryName = event.target.name;
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(selectedCategories.filter((id) => id !== categoryId));
        } else {
            setSelectedCategories([...selectedCategories, categoryId]);
        }

        // Check if the category object is already in the selectedCategoriesName array
        const categoryObject = { id: categoryId, name: categoryName };
        const categoryObjectIndex = selectedCategoriesName.findIndex(
            (obj) => obj.id === categoryId
        );
        if (categoryObjectIndex !== -1) {
            setSelectedCategoriesName(
                selectedCategoriesName.filter((obj) => obj.id !== categoryId)
            );
        } else {
            setSelectedCategoriesName([...selectedCategoriesName, categoryObject]);
        }



    };


    const handleRemoveCategory = (categoryName) => {
       
        const updatedCategories = selectedCategories.filter((category) => category !== categoryName);
        setSelectedCategories(updatedCategories);

        const updatedCategoryNames = selectedCategoriesName.filter((name) => name.id !== categoryName);
        setSelectedCategoriesName(updatedCategoryNames);
        

    };

    const handleRemoveBrand = (brandName) => {

       
        const updatedBrands = selectedBrands.filter((brand) => brand !== brandName);
        setSelectedBrands(updatedBrands);

        const updatedBrandNames = selectedBrandsName.filter((name) => name.id !== brandName);
        setSelectedBrandsName(updatedBrandNames);

       
    };



    const onRedirectHome = () => {
        localStorage.removeItem('vehical_details');
        setTimeout(() => {
            navigate('/');

        }, 1000);
    }

    const handleMinPriceChange = (e) => {

        const value = e.target.value;
        console.log(value)
        // Validate if the input is a valid number
        if (/^\d*\.?\d*$/.test(value)) {
            setMinPrice(value);
        }
    };


    const handleMaxPriceChange = (e) => {
        const value = e.target.value;
        // Validate if the input is a valid number
        if (/^\d*\.?\d*$/.test(value)) {
            setMaxPrice(value);
        }
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();

        if (!minPrice && !maxPrice) {
            setShowError(true)
            
            // You can display an error message to the user or perform any other error handling here
            return; // Stop further execution of the function
        }

        if (minPrice > maxPrice) {
            setShowError(true)
           
            // You can display an error message to the user or perform any other error handling here
            return; // Stop further execution of the function

        }

        if (maxPrice < minPrice) {
            setShowError(true)
           
            // You can display an error message to the user or perform any other error handling here
            return; // Stop further execution of the function
        }
        setShowError(false)
       
        setFirstValue(minPrice);
        setSecondValue(maxPrice);



    };

    const clearAllFilters = () => {
        setSelectedBrands([]);
        setSelectedCategories([]);
        setSelectedCategoriesName([]);
        setSelectedBrandsName([]);

    }
    return (
        <>

            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR />
            <MENUBAR />
            <BREADCRUMBAR categoryName={categoryName} />

            <section>
                <div className="main-section-lsting">
                    <div className="container">
                        <div className='d-flex justify-content-between car-selection-title'>
                            <div>
                                <strong style={{ fontSize: '30px' }}> {vehicalDetails ? vehicalDetails.year : ""}
                                    {vehicalDetails ? "," + vehicalDetails.make_name : ""}
                                    {vehicalDetails ? "," + vehicalDetails.model_name : ""}
                                    {vehicalDetails ? "," + vehicalDetails.variant_name : ""}</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3">
                                <div className="main-outer-filter">
                                    <div className="filter-crose" id="close-bar">
                                        <button className="abc">
                                            <img src="/images/x-mark.svg" alt="" />
                                        </button>
                                    </div>

                                    <div className="price-filter">
                                        <h5 className="price-filter-text" data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
                                            Category {dataBradnAndCategory.uniqueSubcategories ? "(" + dataBradnAndCategory.uniqueSubcategories.length + ")" : " "}
                                            <i
                                                className={open ? "fa fa-chevron-down" : "fa fa-chevron-up"}
                                                onClick={() => setOpen(!open)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={open}>

                                            </i>
                                        </h5>
                                        <Collapse in={open} >
                                            <ul className="p-0 m-0 list-filter" id="example-collapse-text">
                                                {
                                                    !isInitialLoad && dataBradnAndCategory ?
                                                        dataBradnAndCategory.uniqueSubcategories.map((item, index) => (
                                                            <li key={index}>
                                                                <label className="label-filter">
                                                                    <input
                                                                        className="filter-box"
                                                                        type="checkbox"
                                                                        value={item.sub_category_id}
                                                                        name={item.sub_category_name}
                                                                        checked={selectedCategories.includes(item.sub_category_id)}
                                                                        onChange={handleCategoryCheckboxChange}

                                                                    />
                                                                    {item.sub_category_name}
                                                                </label>
                                                            </li>
                                                        ))
                                                        : <ShimmerCategoryItem />

                                                }
                                            </ul>

                                        </Collapse >
                                    </div>
                                    <div className="price-filter">
                                        <h3 className="price-filter-text" data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2">
                                            Brand {dataBradnAndCategory.uniqueBrands ? "(" + dataBradnAndCategory.uniqueBrands.length + ")" : " "}
                                            <i
                                                className={openBrand ? "fa fa-chevron-down" : "fa fa-chevron-up"}
                                                onClick={() => setOpenBrand(!openBrand)}
                                                aria-controls="brand-collapse-text"
                                                aria-expanded={openBrand}>

                                            </i>
                                        </h3>
                                        <Collapse in={openBrand}>
                                            <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                                                {
                                                    !isInitialLoad && dataBradnAndCategory ?
                                                        dataBradnAndCategory.uniqueBrands.map((item, index) => (
                                                            <li key={index}>
                                                                <label className="label-filter">
                                                                    <input className="filter-box"
                                                                        type="checkbox"
                                                                        value={item.brand_id}
                                                                        name={item.brand_name}
                                                                        checked={selectedBrands.includes(String(item.brand_id))}
                                                                        onChange={handleBrandCheckboxChange}
                                                                    />
                                                                    {item.brand_name}
                                                                </label>
                                                            </li>
                                                        ))
                                                        : <ShimmerCategoryItem />

                                                }
                                            </ul>
                                        </Collapse>
                                    </div>

                                    <div className="price-filter">
                                        <h3 className="price-filter-text">
                                            Price
                                            <i
                                                className={openPrice ? "fa fa-chevron-down" : "fa fa-chevron-up"}
                                                onClick={() => setOpenPrice(!openPrice)}
                                                aria-controls="price-collapse-text"
                                                aria-expanded={openPrice}>

                                            </i>
                                        </h3>
                                        <Collapse in={openPrice}>
                                            <div id='price-collapse-text'>
                                                <form onSubmit={handleSubmitForm}>
                                                    <div className="aside-price-range-form d-flex  m-3" >
                                                        <div className="form-field me-2" style={{ width: '80%' }}>
                                                            {/* <label htmlFor="filter-price-min">$ Min</label> */}
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                value={minPrice}
                                                                onChange={handleMinPriceChange}
                                                                placeholder="$ Min"
                                                                autoComplete="off"
                                                                maxLength="10"
                                                                style={{ width: '100%' }}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="form-field me-2" style={{ width: '80%' }}>
                                                            {/* <label htmlFor="filter-price-max">$ Max</label> */}
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                value={maxPrice}
                                                                onChange={handleMaxPriceChange}
                                                                placeholder="$ Max"
                                                                autoComplete="off"
                                                                maxLength="10"
                                                                style={{ width: '100%' }}
                                                                disabled={true}
                                                            />
                                                        </div>

                                                    </div>
                                                </form>
                                                {
                                                    showError ?

                                                        <div className='text-center'>
                                                            <span className='text-danger'>Price range between 0 to 5000</span>
                                                        </div>

                                                        : ""
                                                }
                                                <div className="">
                                                    <RangeSlider
                                                        min={0}
                                                        step={1}
                                                        max={5000}
                                                        defaultValue={[0, 5000]}
                                                        onChangeCommitted={handleRanges}
                                                        tipProps={{ visible: true }}

                                                    />


                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-9" >
                                <div className='selectedFilters '>
                                    {
                                        selectedCategoriesName.map((item, index) => (
                                            <span className="span-filter-Checkbox m-1" key={item.name}>
                                                <span> {item.name} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveCategory(item.id)}></i>
                                            </span>
                                        ))
                                    }
                                    {
                                        selectedBrandsName.map((item, index) => (
                                            <span className="span-filter-Checkbox m-1" key={item.name}>
                                                <span> {item.name} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                                            </span>
                                        ))
                                    }
                                    {
                                        selectedCategoriesName.length > 0 || selectedBrandsName > 0 ?

                                            <span className="clearFilterStyle" onClick={clearAllFilters} >
                                                <span>Clear filters</span>
                                            </span>

                                            :

                                            ""
                                    }

                                </div>
                                <div className="d-flex justify-content-end filters-sort-by ">
                                    <div className="">
                                        {totalProducts > 0 ?
                                            <p className="px-5">
                                                Showing {productsPerPage} of {parseInt(totalProducts).toLocaleString('en-US')} products
                                            </p>
                                            : ""
                                        }
                                    </div>
                                    <div className="">
                                        <select className="form-control" name="sorting" id="sortingWithPrice" onChange={handleSorting}>
                                            <option disabled >Sort By Price</option>
                                            <option value="1">Low-High</option>
                                            <option value="2">High-Low</option>
                                        </select>

                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        !isLoadingCategory && dataCategory ?
                                            dataCategory.data.length > 0 ?
                                                dataCategory.data.map((itm, idx) => {
                                                    return (<Itemcards data={itm} key={itm.item_id} />);
                                                })
                                                : <>
                                                    <div className='container m-0 text-center'>
                                                        <h1>No Product Found</h1>
                                                        <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                                        <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                                                    </div>
                                                </>

                                            :
                                            Array.from({ length: 28 }).map((_, idx) => (
                                                <div className="col-lg-3 col-6" key={idx}>
                                                    <a href="#">
                                                    </a>
                                                    <div className="Drivetrain-cart"><a href="">
                                                        <div className="engle-imge-upper">
                                                            {/* <button className="hover-btn">View Details</button> */}
                                                            <ShimmerThumbnail height={163} rounded />
                                                        </div>
                                                    </a>
                                                        <div className="cart-body">
                                                            <a href="#">
                                                                <h6><ShimmerButton size="sm" /></h6>
                                                                <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                                                            </a>
                                                            <div className="price-cart">
                                                                <a href="#">
                                                                    {/* <div className="price">
                                                                        {
                                                                        itm && itm.cost ?
                                                                            <h5 className="m-0">${itm.cost}</h5>
                                                                            :
                                                                            <h5 className="m-0">N.A</h5>
                                                                    } 

                                                                    </div> */}
                                                                </a>
                                                                <div className="cart-btn">
                                                                    <a href="#">
                                                                    </a>
                                                                    <a href="#">

                                                                        {/* <img src={data.data.brand_logo} alt="" /> */}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))

                                    }


                                </div>
                                {
                                    totalPages > 0 ?

                                        <div className="d-flex mt-4 justify-content-center">
                                            <Pagination>
                                                <Pagination.First
                                                    disabled={currentPage === 1}
                                                    onClick={() => handlePageChange(1)}
                                                />
                                                <Pagination.Prev
                                                    disabled={currentPage === 1}
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                />

                                                {/* Render page numbers */}
                                                {getPageNumbers().map((page) => (
                                                    <Pagination.Item
                                                        key={page}
                                                        active={currentPage === page}
                                                        onClick={() => handlePageChange(page)}
                                                    >
                                                        {page}
                                                    </Pagination.Item>
                                                ))}

                                                <Pagination.Next
                                                    disabled={currentPage === totalPages}
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                />
                                                <Pagination.Last
                                                    disabled={currentPage === totalPages}
                                                    onClick={() => handlePageChange(totalPages)}
                                                />
                                            </Pagination>
                                        </div>
                                        : ""
                                }


                            </div>

                        </div>
                    </div>
                </div>
            </section >
            {/* <NEWSLETTER /> */}
            < FOOTER />
        </>

    )
}
