import React, { useEffect, useState } from 'react';
export default function Wheels() {

    const [content, setContent] = useState('');
    


    useEffect(() => {
        fetch('https://www.shopperapproved.com/widgets/12582/merchant/review-page/898VwTtYbdQf.js')
            .then(response => response.text())
            .then(data => {
                setContent(data);
            })
            .catch(error => console.error('Error fetching content:', error));
    }, []);
    console.log('++++++++++++', content)
    return (


        <>
            <p>Test Page</p>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </>

    )
}