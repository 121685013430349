import { React, useState, useEffect } from 'react';
import DetaileSection from '../../../components/DetailSection/wheels-details';
import axios from 'axios';
import { ShimmerSocialPost, ShimmerThumbnail } from "react-shimmer-effects";
import Slider from '../../../components/Slider/wheels-slider';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

export default function Details(prop) {
  const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
  const { sku, getCartData } = prop;
  const [wheelData, setWheelData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [itemName, setitemName] = useState("");
  const [itemdescription, setdescription] = useState("");
  const [isdata, setIsdata] = useState(true);

  const currentLink = window.location.href;
  const url = new URL(currentLink);

  // Remove all query parameters
  url.search = '';
  const updatedUrl = url.toString();
  const navigate = useNavigate();

  useEffect(() => {
    fetchWheelDetails();
  }, []);

  const fetchWheelDetails = async () => {
    setIsLoading(true);
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${mainUrl}/api/getSingleWheelDetails/${sku}`,
      };
      axios
        .request(config)
        .then((response) => {
          setWheelData(response.data);
          setdescription(`Upgrade your ride with the ${response.data.data[0].brand_desc} ${response.data.data[0].style} ${response.data.data[0].fancy_finish_desc} Wheels – a sleek and stylish choice for a bold statement. These ${response.data.data[0].size_desc} wheels feature a ${response.data.data[0].bolt_pattern_metric} bolt pattern and a ${response.data.data[0].wheel_offset}mm offset, ensuring a perfect fit for your vehicle. Elevate your driving experience with the perfect blend of performance and aesthetics`)
          if(response.data.data[0].vendor_id == 7 && response.data.data[0].product_name == "ESR - -  0MM 5X105"){
          
            setitemName(response.data.data[0].product_desc);
  
           }else{
            setitemName(response.data.data[0].product_name);
           }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsdata(false);
          console.log(error);
          setIsLoading(false);
        
        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      navigate(`/error`);
    }
  };

  const setdata = (value) => {
    getCartData(value)
  }

  const goBackToHomepage = () => {
    navigate('/');
};

  return (
    <>
        <Helmet>
    <title>{itemName ?itemName : "Wheels" }</title>
    <meta name="description" content={itemdescription ? itemdescription : "We are dedicated to maintaining our position as a leading distributor, specializing in wheels, tires, and a wide array of accessories"} />
    <link rel="canonical" href={updatedUrl} /> 
    </Helmet>
    { isdata ? (
      <>
      <section>
        <div className="parformane-bar second-bar-p">
          <div className="container">
            {wheelData && wheelData.data ? <ul className="p-0 m-0 listing-parmofamne">
              <li><a href="/">Home / </a></li>
              <li><a href={`/wheels/${wheelData && wheelData.data ? wheelData.data[0].brand_id : ""}/${wheelData && wheelData.data ? wheelData.data[0].brand_desc : ""}`}> {wheelData && wheelData.data ? wheelData.data[0].brand_desc : ""} / </a></li>
              <li className="active-bread"><a href="#">{itemName}</a></li>
            </ul> : ""}
          </div>
        </div>
      </section>
      <div className='details-discount'>
          {/* <span>
          Congratulations! Discover your exclusive OFFER by adding this product to your cart
          </span> */}
        </div>
      <section className="slider-pernt">
        
        <div className='container wheelDetailsContainer'>

          <div className="row">
            <div className="col-lg-6">
              {!isLoading && typeof wheelData.data !== "undefined" && wheelData.data.length > 0 ?


                <Slider data={wheelData.data[0]} />


                :
                <ShimmerThumbnail height={572} rounded />
              }
            </div>
            <div className="col-lg-6">
              {
                !isLoading && typeof wheelData.data !== "undefined" && wheelData.data.length > 0 ?
                  <DetaileSection data={wheelData.data[0]} setdata={setdata} />
                  :
                  // <ShimmerSocialPost type="text" title="false" />""
                  ""

              }


            </div>
          </div>

        </div>

      </section>
      </>

)
: (<div className="error-screen">
<h1><strong></strong></h1>
<h2>Product Not Found</h2>
<button className="btn btn-sm btn-danger" onClick={goBackToHomepage}>
  Go back to homepage
</button>
</div> )}

    </>
  )
}
