import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function TiresItemscard(prop) {
    const { data } = prop
    const S3_IMAGES = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    let first_image_path = "";
    let title = `${data.brand}  ${data.line} ${data.size} ${data.loadindex}  ${data.speedrating}`
    const navigate = useNavigate();
    const [loaderShow, setLoaderShow] = useState(false);
    const handleSubmit = (event) => {
        const ProductPage= true;
        localStorage.setItem('inProductPage', JSON.stringify(ProductPage));
        setLoaderShow(true)
        const item_name = title
        const sku = data.item_id;
        let name = item_name.replace(/\s+|[\\/]/g, "-").toLowerCase();
        setTimeout(() => {
            navigate(`/tires-details/${sku}`);
            setLoaderShow(false)
        }, 1000);


    };

    // let image = data.s3_images;
    let image = data.images;
    let logo = data.brand_logo;

    if (image) {
        // first_image_path = S3_IMAGES + image;
        first_image_path =  image;
    } else if (logo) {
        first_image_path = S3_IMAGES + logo;
    }


   

    return (
        <div className="col-lg-3 col-6">

            <div className="Drivetrain-cart wheelsPage">
            {
                        data &&  data.price >= 100 ? (
                                <div className="ribbon"><span>Free Shipping</span></div>
                            ) : (
                                ""
                            )
                        }
                <div className="engle-imge-upper" onClick={handleSubmit}>
                    {
                        !loaderShow ?
                            <button className="hover-btn " >  View Details </button>
                            :
                            <button className="hover-btn loading " > Loading  </button>
                    }


                    {
                        data && first_image_path ?
                            <img className="" src={first_image_path} alt="img" height="100%" width="100%" />
                            :
                            <img className="w-100 h-auto" src={`${S3_IMAGES}local_images/images/extreme_logo.svg`} alt="img" height="100%" width="100%" />

                    }
                </div>

                <div className="cart-body">
                    <span onClick={handleSubmit} style={{ cursor: 'pointer' }}>

                        <span className="m-0 description text-dark" >{title}</span>
                    </span>

                    <div className="price-cart">
                        <div className="price mt-2">
                            <strong>{data.part_number}</strong>
                        </div>
                        <div className="price" title='Stock'>
                            {
                                data.quantity == 0 ?
                                    <span><i className="fa fa-industry text-danger" aria-hidden="true"> </i> {data.quantity}</span>
                                    :
                                    <span><i className="fa fa-industry text-success" aria-hidden="true"></i> {data.quantity}</span>
                            }

                        </div>
                    </div>



                    <div className="price-cart mt-3">

                        <div className="price">
                            {
                                data && data.price ?
                                    <h5 className="m-0">${parseFloat(data.price).toFixed(2)}</h5>
                                    :
                                    <h5 className="m-0">N.A</h5>
                            }


                        </div>

                        <div className="cart-btn">
                            <a href={`/brands/brands-tires?brandname=${data.brand}&brandid=${data.brand_id}`}

                                // onClick={() => onSubmit(data.brand_id)} // Pass the item to onSubmit function
                                style={{ cursor: 'pointer' }}
                            >
                                {
                                    data.brand_logo ?
                                        <img src={S3_IMAGES + data.brand_logo} alt="img" height="100%" width="100%" />
                                        :
                                        <img src="/images/no-image-found.png" alt="img" height="100%" width="100%" />

                                }
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    )
}
