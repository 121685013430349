import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

export default function Login() {
    const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        const newErrors = {};
        if (!formData.email) newErrors.email = 'Email is required.';
        if (!formData.password) newErrors.password = 'Password is required.';
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                // Send sign-in data to server
                const response = await axios.post(`${mainUrl}/api/signin`, formData);
                if (response.data.success) {
                    toast.info(response.data.message);
                    console.log('Sign-in successful');
                } else {
                    setErrorMessage(response.data.message);
                }
            } catch (error) {
                console.error('Sign-in failed:', error);
            }
        }
    };

    return (
        <div className="container d-flex justify-content-center vh-100">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"

            />
            <div className="col-md-3">
                <div className='logo-register-page'> <img className="" src="/images/extreme_logo.svg" alt="logo" height="100%" width="100%" /> </div>
                <div className='form-main-div'>
                    <span className='heading'>Sign in</span>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password:</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                        </div>
                        <button type="submit" className="btn btn-danger">Sign In</button>
                    </form>

                    <div className="a-divider-inner"></div>
                    <span className='span-login'>New customer?{' '}<a href="register" rel="nofollow" className="nav-a" > Start here. </a> </span>
                </div>

            </div>
        </div>
    );

}