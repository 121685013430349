import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ShimmerThumbnail } from "react-shimmer-effects";




export default function Popularproduct() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const s3_images = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";

    const [topSelling, setTopSelling] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fecthData()
    }, [])


    const navigate = useNavigate();
    const fecthData = async (id) => {

        try {

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/getDashboardProducts`,
            };
            axios.request(config)
                .then((response) => {
                    setTopSelling(response.data);
                    setLoading(true);



                })
                .catch((error) => {
                    console.log(error);
                    setLoading(true);
                });

        } catch (error) {
            console.log('+++++++++++++', error);
            setLoading(true);
        }

    }


    const handleSubmit = (item_ids) => {       
        const item_id = item_ids;        
        setTimeout(() => {
            navigate(`/product-details/${item_id}`);
        }, 1000); 
    };
    return (
        <div>
            <section>
                <div className="mian-popular-banner">
                    <div className="container">
                        <div className="row product-iteam">
                            <div className="col-lg-4">
                                <div className="selling-product">
                                    <h3>Top Selling Products</h3>
                                    <div className="row">


                                        {

                                            loading && topSelling ?
                                                topSelling && topSelling.data.topSellingProductproductDetails && topSelling.data.topSellingProductproductDetails.length > 0 ?
                                                    topSelling.data.topSellingProductproductDetails.map((item, index) => (

                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 product-data" key={item.item_id} onClick={() => handleSubmit(item.item_id)}>

                                                            <div className="product-inner-image">
                                                                {
                                                                    item.s3_images[0] ?
                                                                        <img src={s3_images + item.s3_images[0]} alt="img" height="100%" width="100%" />
                                                                        : <img src={`https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/local_images/images/extreme_logo.svg`} alt="img" height="100%" width="100%" />

                                                                }
                                                            </div>
                                                            <span className='product-cate'>{item.part_description}</span>
                                                            <div className='priceandinventory'>
                                                                <span className='price'>${parseFloat(item.price).toFixed(2)}</span>
                                                                <span className='stock'><i class="fa fa-industry text-success" aria-hidden="true"></i>&nbsp;&nbsp;{item.stock}</span>
                                                            </div>
                                                            {/* <h4 className="product-name">{item.part_description}</h4> */}

                                                        </div>

                                                    ))

                                                    : ""
                                                : ""

                                        }


                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="selling-product">
                                    <h3>Popular Products</h3>
                                    <div className="row">
                                        {

                                            loading && topSelling ?
                                                topSelling && topSelling.data.popularProductproductDetails && topSelling.data.popularProductproductDetails.length > 0 ?
                                                    topSelling.data.popularProductproductDetails.map((item, index) => (

                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 product-data" key={item.item_id} onClick={() => handleSubmit(item.item_id)}>

                                                            <div className="product-inner-image">
                                                                {
                                                                    item.s3_images[0] ?
                                                                        <img src={s3_images + item.s3_images[0]} alt="img" height="100%" width="100%" />
                                                                        : <img src={`https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/local_images/images/extreme_logo.svg`} alt="img" height="100%" width="100%" />

                                                                }
                                                            </div>
                                                            <span className='product-cate'>{item.part_description}</span>
                                                            <div className='priceandinventory'>
                                                                <span className='price'>${parseFloat(item.price).toFixed(2)}</span>
                                                                <span className='stock'><i class="fa fa-industry text-success" aria-hidden="true"></i>&nbsp;&nbsp;{item.stock}</span>
                                                            </div>

                                                        </div>

                                                    ))

                                                    : ""
                                                : ""

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="selling-product">
                                    <h3>Most Rated Products</h3>
                                    <div className="row">
                                        {

                                            loading && topSelling ?
                                                topSelling && topSelling.data.mostRatedProductproductDetails && topSelling.data.mostRatedProductproductDetails.length > 0 ?
                                                    topSelling.data.mostRatedProductproductDetails.map((item, index) => (

                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 product-data" key={item.item_id} onClick={() => handleSubmit(item.item_id)}>

                                                            <div className="product-inner-image">
                                                                {
                                                                    item.s3_images[0] ?
                                                                        <img src={s3_images + item.s3_images[0]} alt="img" height="100%" width="100%" />
                                                                        : <img src={`https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/local_images/images/extreme_logo.svg`} alt="img" height="100%" width="100%" />

                                                                }
                                                            </div>
                                                            <span className='product-cate'>{item.part_description}</span>
                                                            <div className='priceandinventory'>
                                                                <span className='price'>${parseFloat(item.price).toFixed(2)}</span>
                                                                <span className='stock'><i class="fa fa-industry text-success" aria-hidden="true"></i>&nbsp;&nbsp;{item.stock}</span>
                                                            </div>

                                                        </div>

                                                    ))

                                                    : ""
                                                : ""

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
