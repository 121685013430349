import { React, useState, useEffect } from 'react';
import Slider from '../../../components/Slider';
import DetaileSection from '../../../components/DetailSection';
import axios from 'axios';
import { ShimmerSocialPost,ShimmerThumbnail  } from "react-shimmer-effects";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

export default function Details(prop) {
  const mainUrl =  process.env.REACT_APP_EXPRESS_APP_URL;
  const navigate = useNavigate();
  const currentLink = window.location.href;
  
  const url = new URL(currentLink);

  url.search = '';

  const updatedUrl = url.toString();
  const { item_id ,getCartData} = prop;
  

  const [itemData, setItemData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [cateogryName, setCateogryName] = useState("");
  const [cateogryID, setCateogryID] = useState("");
  
  const [itemName, setitemName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [brandId, setBrandId] = useState("");
  const [isdata, setIsdata] = useState(true);

  useEffect(() => {
    fetchItemData();
  }, []);

 

  const fetchItemData = async () => {
    setIsLoading(true);
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${mainUrl}/api/getItemDetails/${item_id}`,
      };
      axios
        .request(config)
        .then((response) => {
          setItemData(response.data);

          setBrandName(response.data.itemsData[0].brand);
          setBrandId(response.data.itemsData[0].brand_id);
          setitemName(response.data.itemsData[0].part_description);
          setCateogryName(response.data.itemsData[0].category_name);
          setCateogryID(response.data.itemsData[0].category_id);
       

          setIsLoading(false);
        })
        .catch((error) => {
          setIsdata(false);

          // navigate('/pagenotfound');
          // console.log(error);
          setIsLoading(false);
          
        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      navigate(`/error`);
    }
  };

  const setdata = (value) =>{
    getCartData(value)
  }

  const goBackToHomepage = () => {
    navigate('/');
};

  return (
    <>
      <Helmet>
        <title>{itemName ? itemName : "Accessories"}</title>
        <meta name="description" content={itemName ? itemName: "We are dedicated to maintaining our position as a leading distributor, specializing in wheels, tires, and a wide array of accessories" } />
        <link rel="canonical" href={updatedUrl} />
      </Helmet>
      { isdata ? (
      <>
      <section>
        <div className="parformane-bar second-bar-p">
          <div className="container">
           { !isLoading ? 
           <ul className="p-0 m-0 listing-parmofamne">
              <li><a href="/">Home /</a></li>
              {/* <li><a href="/brands">Brands /</a></li> */}
              <li><a href={`/categories-home/brand-category?brandname=${brandName}&brandid=${brandId}`} className=''>{brandName} /</a></li>
              
             
              <li className=""><a href={`/brands/brands-products/${cateogryID}?brandname=${brandName}&brandid=${brandId}`}>{cateogryName}/</a></li> 

              <li className="active-bread"><a href="#">{itemName}</a></li>
            </ul>:""
            }
          </div>
        </div>
      </section>
      <section className="slider-pernt">
        <div className='container'>
          <div className="row">
            <div className="col-lg-6">
              {!isLoading && itemData ?

                
                  <Slider data={itemData} />
                  

                :
                <ShimmerThumbnail height={572} rounded />
              }
            </div>
            <div className="col-lg-6">
              {
                !isLoading && itemData ?
                  <DetaileSection data={itemData}  setdata={setdata}/>
                  :
                  // <ShimmerSocialPost type="text" title="false" />
                  ""

              }


            </div>
          </div>
        </div>
      </section>
      </>

)
: (<div className="error-screen">
<h1><strong></strong></h1>
<h2>Product Not Found</h2>
<button className="btn btn-sm btn-danger" onClick={goBackToHomepage}>
  Go back to homepage
</button>
</div> )}
    </>
  )
}
