import React from 'react'

export default function Services() {
    return (
        <div>
            < section >
                <div className="container border-bottom main-shiiping-setion">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-6 text-center">
                            <div className="providing" data-aos="fade-left">
                                <img src="/images/shipping.svg" alt="img" height="100%" width="100%" />
                                <span className='service_heading'>We Offer</span>
                                <h2>Free Shipping</h2>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 text-center  ">
                            <div className="providing" data-aos="fade-up">                               
                                    <img src="/images/payment.svg" alt="img" height="100%" width="100%" />
                                    <span className='service_heading'>Shop with 100% confidence</span>
                                    <h2>Secure Payments</h2>                               
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 text-center">
                            <div className="providing" data-aos="fade-down">
                            <a href="tel:4808929433" className='text-decoration-none' >
                                <img src="/images/callus.svg" alt="img" height="100%" width="100%" />
                                <span className='service_heading'>Call Us</span>
                                <h2>480-892-9433</h2>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 text-center  ">

                            <div className="providing" data-aos="fade-right">
                                <a href="/financing" target="_blank" className='text-decoration-none'>
                                    <img src="/images/lattter.svg" alt="img" height="100%" width="100%" />
                                    <span className='service_heading'>100 days flexible payment</span>
                                    <h2>Financing Option</h2>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </section >


        </div>
    )
}
