import { React, useState, useEffect } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';




export default function DetaileSection(prop) {
    const { data, setdata } = prop;
    console.log('++++++++++++', prop);
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    let invnetoryStock = 100000000000
    const [valueCart, setValueCart] = useState(1);
    const [cartData, setCartData] = useState([]);

    const product_id = data[0].item_id;
    const price = data[0].price
    const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    const image = data[0].s3_images[0]
    const product_name = data[0].product_name;


    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comments, setComments] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');






    const apiAddtoCart = async () => {
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/add-to-cart`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,

                params: {
                    product_image: image,
                    product_id: product_id,
                    product_name: product_name,
                    product_price: price,
                    product_quantity: valueCart,
                    category_type: "Custome",
                    total_quantity: invnetoryStock,
                    part_number: product_id

                }
            };
            axios
                .request(config)
                .then((response) => {


                })
                .catch((error) => {
                    console.log(error);

                });
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    };

    const addItemstoCaart = () => {
        const cartItems = JSON.parse(localStorage.getItem('totalCartItems')) || {};
        const itemId = data[0].item_id
        const existingValue = cartItems[itemId] || 0;
        const updatedValue = existingValue + valueCart;
        // Check if the updated value exceeds the stockInventory
        if (updatedValue > invnetoryStock) {
            toast.info("Cannot add more items than available in stock!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        cartItems[itemId] = updatedValue;
        localStorage.setItem('totalCartItems', JSON.stringify(cartItems));
        setCartData(cartItems);
        apiAddtoCart();
        setdata(cartItems);
        toast.success("Item Added to cart!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

    }

    const increaseValue = () => {

        if (valueCart < invnetoryStock) {
            setValueCart(valueCart + 1);

        }

    };

    const decreaseValue = () => {
        if (valueCart > 1) {
            setValueCart(valueCart - 1);

        }
    }

    function removeDecimalIfZeros(number) {
        const formattedNumber = Number((parseFloat(number)).toFixed(2)); // Set the desired number of decimal places
        const decimalPart = formattedNumber % 1;
        return decimalPart === 0 ? (parseFloat(formattedNumber)).toFixed(0) : formattedNumber;
    }
    function addSign(number) {
        if (number > 0) {
            return '+' + number;
        } else if (number < 0) {
            return '-' + Math.abs(number);
        } else {
            return number;
        }
    }


    const handleEnquiryModel = () => {
        setShowModal(true)
    }
    const handleClose = () => {
        setShowModal(false)
    }


    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        // Remove non-numeric characters from input
        const cleanedInput = e.target.value.replace(/\D/g, '');
        setPhone(cleanedInput);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here, e.g. sending the inquiry data to a backend API
        // You can access email, phone, and comments state variables here
        // Reset form fields if needed
        try {

            try {
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/submit-inquiry`,
                    credentials: 'include', // Send cookies with the request
                    withCredentials: true,
                    params: {
                        email: email,
                        phone: phone,
                        comments: comments,

                    }
                };
                axios
                    .request(config)
                    .then((response) => {
                        toast.success("Submitted successfully!", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        // Reset form fields and close the modal
                        setEmail('');
                        setPhone('');
                        setComments('');
                        handleClose();

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            } catch (error) {
                console.error('Error fetching data:', error);

            }

        } catch (error) {
            console.error('Error submitting inquiry:', error);
        }


    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        // If the input is empty, set the value to an empty string
        if (inputValue === '') {
            setValueCart(1);
            return;
        }

        // Parse the input value as an integer
        const parsedValue = parseInt(inputValue, 10);

        // Check if the parsed value is a valid number
        if (!isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= invnetoryStock) {
            setValueCart(parsedValue);
        }
    };

    return (
        <>
            <div className="main-product-data">
                {/* <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"

                /> */}
                <div className="product-name-details">
                    {/* <span className='tag_name'>{data[0].brand_desc}</span> */}
                    <h2>{data[0].product_name}</h2>
                    <span className='prod_cat'>{data[0].category_name}</span>
                    <div className="stock">
                        <h3 className="m-0">Stock</h3>
                        <h4 className="m-0">
                            {
                                invnetoryStock > 0 ?
                                    <span className=''><i className="fa fa-circle me-1"></i> In stock ( {invnetoryStock} )</span>
                                    :
                                    <span className='text-danger'><i className="fa fa-circle me-1"></i>Out of Stock</span>
                            }

                        </h4>
                        <h5 className="m-0">SKU: <span>{data[0].part_number}</span></h5>
                    </div>

                    <div className="product-deastil-preice">
                        <h2>${parseFloat(data[0].price).toFixed(2)}</h2>

                    </div>

                    <div className="quantity">
                        {
                            invnetoryStock > 0 ?
                                <>
                                    <div className="incres">
                                        <div className="value-button" id="decrease" onClick={decreaseValue}>
                                            <i className="fa fa-minus"></i>
                                        </div>
                                        <input type="text" id="number" value={valueCart} onChange={handleInputChange} />

                                        <div className="value-button" id="increase" onClick={increaseValue}>
                                            <i className="fa fa-plus"></i>
                                        </div>
                                    </div>

                                    <div className="cart">
                                        <button className="" onClick={addItemstoCaart}><svg className="me-1" width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.982759 1.96567H4.07847L5.65092 10.9827C5.7738 11.6214 6.48624 12.2849 7.10048 12.2849H17.6654C18.3288 12.2849 18.8201 11.6952 18.8938 11.1056L21.3263 1.42519C21.3508 1.35149 21.3508 1.2778 21.3508 1.17942C21.3508 0.516003 20.8347 0.00013155 20.1223 0.00013155H7.91125C7.37073 0.00013155 6.92856 0.442295 6.92856 0.982824C6.92856 1.52335 7.37073 1.96552 7.91125 1.96552H19.1887L18.8202 3.43977H8.59927C8.05874 3.43977 7.61658 3.88194 7.61658 4.42247C7.61658 4.963 8.05874 5.40516 8.59927 5.40516H18.3042L17.9356 6.87955H9.1397C8.59917 6.87955 8.15701 7.32171 8.15701 7.86224C8.15701 8.40277 8.59917 8.84493 9.1397 8.84493H17.4442L17.0757 10.3192H7.54275L5.87211 0.810794C5.79842 0.343929 5.38077 0 4.9139 0H0.982693C0.442163 0 0 0.442163 0 0.982692C0 1.52322 0.442163 1.96556 0.982693 1.96556L0.982759 1.96567Z" fill="white" />
                                            <path d="M16.0444 18.4272C17.4449 18.4272 18.575 17.2725 18.575 15.8474C18.575 14.5207 17.6168 13.4396 16.3639 13.2923H16.3393H16.2656H16.1428H8.59993H8.55074H8.45253C7.05198 13.2923 5.92188 14.447 5.92188 15.8721C5.9217 17.297 7.05198 18.4272 8.42783 18.4272C9.80368 18.4272 10.9585 17.2725 10.9585 15.8474C10.9585 15.6263 10.934 15.4297 10.8848 15.2332H13.612C13.5628 15.4297 13.5383 15.6263 13.5383 15.8474C13.5383 17.2724 14.6686 18.4272 16.0445 18.4272H16.0444ZM16.0444 15.2332C16.3391 15.2332 16.6094 15.5034 16.6094 15.8474C16.6094 16.1914 16.3636 16.4616 16.0444 16.4616C15.7249 16.4616 15.4793 16.1914 15.4793 15.8474C15.4791 15.5034 15.7494 15.2332 16.0444 15.2332ZM8.42781 16.4616C8.13303 16.4616 7.86276 16.1914 7.86276 15.8474C7.86276 15.5034 8.10852 15.2332 8.42781 15.2332C8.74727 15.2332 8.99287 15.5034 8.99287 15.8474C8.99287 16.1914 8.74727 16.4616 8.42781 16.4616Z" fill="white" />
                                        </svg>
                                            Add to Cart</button>
                                    </div>
                                    {/* <div className="Finance">
                                        <a href='https://consumer.snapfinance.com/#/?mid=490293126' target='_blank'>
                                            <button>Click to Finance</button>
                                        </a>
                                    </div> */}
                                </>
                                : <>
                                    <div className="cart">

                                        Out of stock !

                                    </div>
                                    {/* <div className="Finance">
                                        <button className="Finance" title='Item out of stock' onClick={handleEnquiryModel}>
                                            Click for Enquiry</button>
                                    </div> */}
                                </>
                        }

                    </div>
                </div>

                {/* <div className='subCategoryNameDetailsPage'>
                    <span className='sub_cat_name'>
                        Wheels
                    </span>
                </div> */}


                <div className="producDescription">
                    <strong>About this product </strong>
                    <p className='mt-2'>
                        {data[0].part_description}
                    </p>
                </div>

            </div>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Your Enquiry About : {data[0].product_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} className='enquiry-form-class'>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="email">
                                    <Form.Label>First Name*</Form.Label>
                                    <Form.Control
                                        type="firstName"
                                        placeholder="Enter first name"
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter last name"
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                                <Form.Group controlId="phone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter phone number"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group controlId="comments">
                                    <Form.Label>Year,Make,Model & Comments*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter your comments"
                                        value={comments}
                                        onChange={handleCommentsChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-12 text-end'>
                                <Form.Group controlId="comments">
                                    <Button variant="danger" type="submit">
                                        Submit
                                    </Button>
                                </Form.Group>
                            </div>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )
} 
