import { React, useState, useEffect, useRef } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import SERVICES from "../../components/Footer/services";
import axios from 'axios';
import { ShimmerThumbnail, ShimmerBadge, ShimmerTitle } from "react-shimmer-effects";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';






export default function Cart() {
    document.body.classList.remove('full-screen-loader');
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    // const mainUrl = "https://www.extremeperformance.com:444";
    const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
    const vehicalDetails_checkout = JSON.parse(localStorage.getItem('vehical_details_checkout'));
    const fitmentType = localStorage.getItem('vehical_fitment');
    const navigate = useNavigate();
    const [isHidden, setIsHidden] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("")
    const [cartData, setCartData] = useState([])
    const [shippingCharges, setShippingCharges] = useState(0)
    const [cartItems, setCartItems] = useState("");
    const [tempCartData, setTempCartData] = useState([]);
    const [chekoutStatus, setchekoutStatus] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [year, setYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [make, setMake] = useState(vehicalDetails ? vehicalDetails.make_name : "");
    const [model, setModel] = useState(vehicalDetails ? vehicalDetails.model_name : "");
    const [subModel, setSubModel] = useState(vehicalDetails ? vehicalDetails.variant_name : "");
    const [vehicleFitmentType, setvehicleFitmentType] = useState(fitmentType ? fitmentType.vehical_fitment : "");
    const yearInputRef = useRef(null);
    const makeInputRef = useRef(null);
    const modelInputRef = useRef(null);
    const checkboxRef = useRef(null);
    const [highlightTerms, setHighlightTerms] = useState(false);
    const [highlightFields, setHighlightFields] = useState(false);
    const [updatedCartData, setUpdatedCartData] = useState([]);
    const [totalDiscountOffer, setTotalDiscountOffer] = useState(0);
    // const [getNonceData, setNonceData] = useState(localStorage.getItem('nonce') ? localStorage.getItem('nonce') : '');

    // const getWooCommerceSession = () => {
    //     const cookies = document.cookie.split('; ');
    //     console.log(cookies, "cookies");
    //     let sessionData = {
    //         key: '',   // Default to empty string if not found
    //         value: ''  // Default to empty string if not found
    //     };

    //     cookies.forEach(cookie => {
    //         const [name, value] = cookie.split('=');

    //         // Look for the cookie that starts with "wp_woocommerce_session_"
    //         if (name.startsWith('wp_woocommerce_session_')) {
    //             sessionData = {
    //                 key: name,   // Store the cookie key (name)
    //                 value: value // Store the value
    //             };
    //         }
    //     });

    //     return sessionData;
    // };

    // const wooCommerceSession = getWooCommerceSession();



    useEffect(() => {
        const affirmConfig = {
            public_api_key: "Z6PMLBXW7DT8S738",
            script: "https://cdn1.affirm.com/js/v2/affirm.js"
        };

        const initializeAffirm = () => {
            const { affirm } = window;


            const priceUpdateEventHandler = (newPrice) => {
                // console.log("priceUpdateEventHandler");
                // changePriceHandler(newPrice);
            }

            const updateAffirmPromos = (newPrice) => {
                document.getElementById('affirmProductModal').setAttribute('data-amount', newPrice);
                document.getElementById('affirmLearnMore').setAttribute('data-amount', newPrice);
                affirm.ui.refresh();
            }

            affirm.ui.ready(() => {
                const newPrice = 699;

                // Listen for the Affirm Learn More modal close event
                affirm.events.on('learnMore:close', () => {
                    // console.log('Affirm Learn More modal closed');
                });

                // Add this script to an existing callback that fires when the price updates
                priceUpdateEventHandler(newPrice);

                // Update Affirm promos with the initial price
                updateAffirmPromos(newPrice);
            });
        };

        // Load Affirm script
        const loadAffirmScript = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = affirmConfig.script;
            script.onload = initializeAffirm;
            document.body.appendChild(script);
        };

        // Run the initialization after 5 seconds
        setTimeout(loadAffirmScript, 8000);

        return () => {
            // Cleanup: Remove Affirm script when component is unmounted
            // document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');

        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
    }, []);

    useEffect(() => {
        fetchCartItems();
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);


    const showLoaderLine = (id) => {
        if (id === 1) {
            setIsHidden(false);
        } else {
            setIsHidden(true);
        }

    };

    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }


    const fetchCartItems = async () => {

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/cart`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,

            };
            axios
                .request(config)
                .then((response) => {
                    if (response && response.data && response.data.allData.length < 1) {
                        localStorage.removeItem('totalCartItems');
                        setCartItems("");
                    }
                    setLoadingData(true);
                    setCartData(response.data);
                    // console.log("DAtat Saving in carttttt",response.data)
                    setTempCartData(response.data);
                    setTotalDiscountOffer(response.data.totalDiscount);


                })
                .catch((error) => {
                    console.log(error);
                    setLoadingData(true);
                    navigate(`/error`);

                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingData(true);
            navigate(`/error`);

        }
    };

    const removeSingleProduct = async (productId, part_number) => {
        const cartKey = localStorage.getItem("cart-key") ? localStorage.getItem("cart-key") : '';
        const user_token = localStorage.getItem("user-token") ? localStorage.getItem("user-token") : '';


        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                // url: `${mainUrl}/api/clear/product/${productId}`,
                url: `${mainUrl}/api/clear/product/${productId}?cart_key=${cartKey}&sku=${part_number}&user_token=${user_token}`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {
                }
            };
            axios
                .request(config)
                .then((response) => {
                    if (response) {
                        fetchCartItems();
                    }

                })
                .catch((error) => {
                    console.log(error);
                    navigate(`/error`);

                });
        } catch (error) {
            console.error('Error fetching data:', error);
            navigate(`/error`);

        }
    };

    const updateQuantityOfCart = async (productId, newQuantity) => {

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/add/${newQuantity}/${productId}`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {


                }
            };
            axios
                .request(config)
                .then((response) => {
                    if (response) {
                        fetchCartItems();
                    }

                })
                .catch((error) => {
                    console.log(error);
                    navigate(`/error`);

                });
        } catch (error) {
            console.error('Error fetching data:', error);
            navigate(`/error`);

        }
    };



    const delteQuantity = (productid, category, part_number) => {
        let productId = category == "tires_cat" ? part_number : productid;
        // console.log('productId', productId);

        if (category != "tires_cat") {
            setTempCartData((prevTempCartData) => {
                const updatedCartItems = prevTempCartData.allData.map((item) => {
                    if (item.product_id === productId) {
                        if (item.product_quantity > 1) {
                            return { ...item, product_quantity: item.product_quantity - 1 };
                        }
                        // If the quantity is already 1 or less, do not modify the quantity
                        return item;
                    }
                    return item;
                });
                return { ...prevTempCartData, allData: updatedCartItems };
            });

        } else {
            setTempCartData((prevTempCartData) => {
                const updatedCartItems = prevTempCartData.allData.map((item) => {
                    if (item.part_number === productId) {
                        if (item.product_quantity > 1) {
                            return { ...item, product_quantity: item.product_quantity - 1 };
                        }
                        // If the quantity is already 1 or less, do not modify the quantity
                        return item;
                    }
                    return item;
                });
                return { ...prevTempCartData, allData: updatedCartItems };
            });

        }

    };




    // const addQuantity = (productId) => {
    //     setTempCartData((prevTempCartData) => {
    //         const updatedCartItems = prevTempCartData.allData.map((item) => {
    //             if (item.product_id === productId) {
    //                 const newQuantity = item.product_quantity + 1;
    //                 const updatedQuantity = newQuantity <= item.total_quantity  ? newQuantity : item.product_quantity;
    //                 return { ...item, product_quantity: updatedQuantity };
    //             } else {
    //                 return item;
    //             }
    //         });
    //         return { ...prevTempCartData, allData: updatedCartItems };
    //     });
    // };
    const addQuantity = (productid, category, part_number) => {
        let productId = category == "tires_cat" ? part_number : productid;

        if (category != "tires_cat") {
            setTempCartData((prevTempCartData) => {
                const updatedCartItems = prevTempCartData.allData.map((item) => {
                    if (item.product_id === productId) {
                        if (item.category_type === 'tires_cat' || item.category_type === 'wheel') {
                            const newQuantity = item.product_quantity + 1;
                            const updatedQuantity = Math.min(newQuantity, item.total_quantity);
                            return { ...item, product_quantity: updatedQuantity };
                        } else {
                            // For categories other than 'tires_cat' and 'wheel', no quantity restriction
                            const newQuantity = item.product_quantity + 1;
                            const updatedQuantity = Math.min(newQuantity, item.total_quantity);
                            return { ...item, product_quantity: updatedQuantity };
                        }
                    } else {
                        return item;
                    }
                });
                return { ...prevTempCartData, allData: updatedCartItems };
            });
        } else {

            setTempCartData((prevTempCartData) => {
                const updatedCartItems = prevTempCartData.allData.map((item) => {
                    if (item.part_number === productId) {
                        if (item.category_type === 'tires_cat' || item.category_type === 'wheel') {
                            const newQuantity = item.product_quantity + 1;
                            const updatedQuantity = Math.min(newQuantity, item.total_quantity);
                            return { ...item, product_quantity: updatedQuantity };
                        } else {
                            // For categories other than 'tires_cat' and 'wheel', no quantity restriction
                            const newQuantity = item.product_quantity + 1;
                            const updatedQuantity = Math.min(newQuantity, item.total_quantity);
                            return { ...item, product_quantity: updatedQuantity };
                        }
                    } else {
                        return item;
                    }
                });
                return { ...prevTempCartData, allData: updatedCartItems };
            });


        }



    };




    const updateCart = (productid, category, part_number) => {
        // Apply the changes from tempCartData to the real cartData state
        let productId = category == "tires_cat" ? part_number : productid;
        if (category != "tires_cat") {
            setCartData(tempCartData);
            const newQuantity = tempCartData.allData.find((item) => item.product_id === productId)?.product_quantity;
            updateQuantityOfCart(productId, newQuantity, category, part_number)

            const updatedLocalStorage = JSON.parse(localStorage.getItem('totalCartItems') || '{}');
            updatedLocalStorage[productId] = newQuantity;
            localStorage.setItem('totalCartItems', JSON.stringify(updatedLocalStorage));

            const storedCartItems = localStorage.getItem('totalCartItems');

            if (storedCartItems) {
                const parsedCartItems = JSON.parse(storedCartItems);
                const numCartItems = Object.values(parsedCartItems);
                const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                setCartItems(sum);
            }


            toast.success("Items Updated Successfully!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            fetchCartItems();


        } else {

            setCartData(tempCartData);
            const newQuantity = tempCartData.allData.find((item) => item.part_number === productId)?.product_quantity;
            updateQuantityOfCart(productId, newQuantity, category, part_number)

            const updatedLocalStorage = JSON.parse(localStorage.getItem('totalCartItems') || '{}');
            updatedLocalStorage[productId] = newQuantity;
            localStorage.setItem('totalCartItems', JSON.stringify(updatedLocalStorage));

            const storedCartItems = localStorage.getItem('totalCartItems');

            if (storedCartItems) {
                const parsedCartItems = JSON.parse(storedCartItems);
                const numCartItems = Object.values(parsedCartItems);
                const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                setCartItems(sum);
            }


            toast.success("Items Updated Successfully!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            fetchCartItems();

        }



    };



    const deleteSingleProduct = (productid, category, part_number) => {
        const shouldDelete = window.confirm('Are you sure you want to remove this product from the cart?');
        let productId = category == "tires_cat" ? part_number : productid;

        if (shouldDelete) {
            removeSingleProduct(productId, part_number);
            setLoadingData(false);
            setTempCartData((prevTempCartData) => {
                // Filter out the cart item with the given productId
                const updatedCartItems = prevTempCartData.allData.filter((item) => item.product_id !== productId);
                return { ...prevTempCartData, allData: updatedCartItems };
            });

            const updatedLocalStorage = JSON.parse(localStorage.getItem('totalCartItems') || '{}');
            delete updatedLocalStorage[productId];
            localStorage.setItem('totalCartItems', JSON.stringify(updatedLocalStorage));


            const storedCartItems = localStorage.getItem('totalCartItems');

            if (storedCartItems) {
                const parsedCartItems = JSON.parse(storedCartItems);
                const numCartItems = Object.values(parsedCartItems);
                const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                setCartItems(sum);
            }
        }


    };


    const redirectToProductPage = (productId, categId, name) => {
        try {

            if (categId != "Custome") {
                let new_name = name.replace(/\s+|[\\/]/g, "-").toLowerCase();
                if (categId == 'wheel') {
                    navigate(`/wheels-product-details/${productId}?${new_name}`);
                }

                else if (categId == "tires_cat" || categId == "ty") {
                    navigate(`/tires-details/${productId}?${new_name}`);
                }
                else {

                    navigate(`/product-details/${productId}?${new_name}`);
                }
            } else {
                navigate(`/products/uncategorized/custom_products`);
            }

        } catch (error) {
            console.log('error', error)
        }
    }

    const redirectToCheckout = async () => {
        return;
        if (isChecked) {
            const cart_key = localStorage.getItem("cart-key") ? localStorage.getItem("cart-key") : '';
            const user_token = localStorage.getItem("user-token") ? localStorage.getItem("user-token") : '';
            if (!vehicalDetails) {

                setIsHidden(false);
                setchekoutStatus(true);
                document.body.classList.add('full-screen-loader');
                const data = tempCartData.allData;
                // function changeProductNames(data) {
                //     for (let i = 0; i < data.length; i++) {

                //         data[i].product_name = data[i].product_name + `("${year},${make},${model},${subModel}")`
                //     }
                // }
                // changeProductNames(data);

                axios.post(`${mainUrl}/api/create-product`, { data, totalDiscountOffer, cart_key, user_token })
                    .then(async (response) => {
                        console.log('Data sent successfully:', response.data);
                        const cartKey = response.data.cart_key;
                        if (cartKey) {
                            localStorage.setItem("cart-key", cartKey);
                            window.location = response.data.checkoutUrl;
                            setTimeout(() => {
                                setchekoutStatus(false);
                                setIsHidden(true);
                            }, 2500);
                        } else {
                            console.error("cart key is missing");
                        }

                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error sending data:', error);
                        setchekoutStatus(false);
                        setIsHidden(true);

                    });
            } else {
                document.body.classList.add('full-screen-loader');
                setIsHidden(false);
                setchekoutStatus(true);
                const data = tempCartData.allData
                // console.log(data);
                axios.post(`${mainUrl}/api/create-product`, { data, totalDiscountOffer, cart_key, user_token })
                    .then(response => {
                        // setIsHidden(true);
                        // Handle success response
                        console.log('Data sent successfully2:', response.data);
                        const cartKey = response.data.cart_key;
                        if (cartKey) {

                            localStorage.setItem("cart-key", cartKey);
                            let formattedYear = year ? `year_${year.trim()}` : '';
                            let formattedMake = make ? encodeURIComponent(String(make).trim()) : '';
                            let formattedModel = model ? encodeURIComponent(String(model).trim()) : '';
                            let formattedSubModel = subModel ? encodeURIComponent(String(subModel).trim()) : '';
                            window.location.href = `${(response.data.checkoutUrl)}&make=${formattedMake}&model=${formattedModel}&year=${formattedYear}&subModel=${formattedSubModel}`;

                            setTimeout(() => {
                                setIsHidden(true);
                                setchekoutStatus(false);
                            }, 2500);

                        } else {
                            console.error("cart key is missing");
                        }
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error sending data:', error);
                        setchekoutStatus(false);
                        setIsHidden(true);

                    });

            }

        } else {
            focusOnCheckbox();
            setHighlightTerms(true);
            toast.info("To checkout, you must agree to our terms and condtion!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;

        }
    }


    // const redirectToCheckout = async () => {
    //     // return;
    //     if (isChecked) {
    //         const cart_key = localStorage.getItem("cart-key") ? localStorage.getItem("cart-key") : '';
    //         if (!vehicalDetails) {
    //                 setIsHidden(false);
    //                 setchekoutStatus(true);
    //                 document.body.classList.add('full-screen-loader');
    //                 const data = tempCartData.allData;
    //                 // console.log("redirectDatatatat",data);
    //                 // function changeProductNames(data) {
    //                 //     for (let i = 0; i < data.length; i++) {

    //                 //         data[i].product_name = data[i].product_name + `("${year},${make},${model},${subModel}")`
    //                 //     }
    //                 // }
    //                 // changeProductNames(data);

    //                 axios.post(`${mainUrl}/api/create-product`, { data, totalDiscountOffer, cart_key })
    //                     .then(async (response) => {
    //                         // console.log('Data sent successfully:', response.data);
    //                         const cartKey = response.data.cart_key;
    //                         if (cartKey) {
    //                             localStorage.setItem("cart-key", cartKey);
    //                         }
    //                         window.location.href = response.data.checkoutUrl;
    //                         setTimeout(() => {
    //                             setchekoutStatus(false);
    //                             setIsHidden(true);
    //                         }, 2500);
    //                     })
    //                     .catch(error => {
    //                         // Handle error
    //                         console.error('Error sending data:', error);
    //                         setchekoutStatus(false);
    //                         setIsHidden(true);

    //                     });
    //         } else {
    //             document.body.classList.add('full-screen-loader');
    //             setIsHidden(false);
    //             setchekoutStatus(true);
    //             const data = tempCartData.allData;
    //             axios.post(`${mainUrl}/api/create-product`, { data, totalDiscountOffer, cart_key })
    //                 .then(response => {
    //                     // console.log('Data sent successfully:', response.data);
    //                     const cartKey = response.data.cart_key;
    //                     if (cartKey) {
    //                         localStorage.setItem("cart-key", cartKey);
    //                     }
    //                     // window.location.href = response.data.chkouturl;
    //                     let formattedYear = year ? `year_${year.trim()}` : '';
    //                     let formattedMake = make ? encodeURIComponent(String(make).trim()) : '';
    //                     let formattedModel = model ? encodeURIComponent(String(model).trim()) : '';
    //                     let formattedSubModel = subModel ? encodeURIComponent(String(subModel).trim()) : '';
    //                     window.location.href = `${(response.data.checkoutUrl)}&make=${formattedMake}&model=${formattedModel}&year=${formattedYear}&subModel=${formattedSubModel}`;
    //                     setTimeout(() => {
    //                         setIsHidden(true);
    //                         setchekoutStatus(false);
    //                     }, 2500);

    //                 })
    //                 .catch(error => {
    //                     // Handle error
    //                     console.error('Error sending data:', error);
    //                     setchekoutStatus(false);
    //                     setIsHidden(true);

    //                 });

    //         }

    //     } else {
    //         focusOnCheckbox();
    //         setHighlightTerms(true);
    //         toast.info("To checkout, you must agree to our terms and condtion!", {
    //             position: "top-center",
    //             autoClose: 3000,
    //             hideProgressBar: true,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "colored",
    //         });
    //         return;

    //     }
    // }

    // const redirectToCheckout = async () => {
    //     // return;
    //     if (isChecked) {
    //         const cart_key = localStorage.getItem("cart-key") ? localStorage.getItem("cart-key") : '';
    //         if (!vehicalDetails) {
    //             if (year && make && model) {
    //                 setIsHidden(false);
    //                 setchekoutStatus(true);
    //                 document.body.classList.add('full-screen-loader');
    //                 const data = tempCartData.allData;
    //                 // console.log("redirectDatatatat",data);
    //                 function changeProductNames(data) {
    //                     for (let i = 0; i < data.length; i++) {

    //                         data[i].product_name = data[i].product_name + `("${year},${make},${model},${subModel}")`
    //                     }
    //                 }
    //                 changeProductNames(data);

    //                 axios.post(`${mainUrl}/api/create-product`, { data, totalDiscountOffer, cart_key })
    //                     .then(async (response) => {
    //                         // console.log('Data sent successfully:', response.data);
    //                         const cartKey = response.data.cart_key;
    //                         if (cartKey) {
    //                             localStorage.setItem("cart-key", cartKey);
    //                         }
    //                         window.location.href = response.data.checkoutUrl;
    //                         setTimeout(() => {
    //                             setchekoutStatus(false);
    //                             setIsHidden(true);
    //                         }, 2500);
    //                     })
    //                     .catch(error => {
    //                         // Handle error
    //                         console.error('Error sending data:', error);
    //                         setchekoutStatus(false);
    //                         setIsHidden(true);

    //                     });
    //             } else {
    //                 setHighlightFields(true);
    //                 toast.info("Please fill in your vehicle details!", {
    //                     position: "top-center",
    //                     autoClose: 3000,
    //                     hideProgressBar: true,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "colored",
    //                 });

    //                 if (!year) yearInputRef.current.focus();
    //                 else if (!make) makeInputRef.current.focus();
    //                 else if (!model) modelInputRef.current.focus();
    //                 return;
    //             }
    //         } else {
    //             document.body.classList.add('full-screen-loader');
    //             setIsHidden(false);
    //             setchekoutStatus(true);
    //             const data = tempCartData.allData;
    //             axios.post(`${mainUrl}/api/create-product`, { data, totalDiscountOffer, cart_key })
    //                 .then(response => {
    //                     // console.log('Data sent successfully:', response.data);
    //                     const cartKey = response.data.cart_key;
    //                     if (cartKey) {
    //                         localStorage.setItem("cart-key", cartKey);
    //                     }
    //                     window.location.href = response.data.chkouturl;
    //                     setTimeout(() => {
    //                         setIsHidden(true);
    //                         setchekoutStatus(false);
    //                     }, 2500);

    //                 })
    //                 .catch(error => {
    //                     // Handle error
    //                     console.error('Error sending data:', error);
    //                     setchekoutStatus(false);
    //                     setIsHidden(true);

    //                 });

    //         }

    //     } else {
    //         focusOnCheckbox();
    //         setHighlightTerms(true);
    //         toast.info("To checkout, you must agree to our terms and condtion!", {
    //             position: "top-center",
    //             autoClose: 3000,
    //             hideProgressBar: true,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "colored",
    //         });
    //         return;

    //     }
    // }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Toggle the state
    };

    function handleYearChange(event) {
        setYear(event.target.value);
    }

    function handleMakeChange(event) {
        setMake(event.target.value);
    }

    function handleModelChange(event) {
        setModel(event.target.value);
    }

    function handleSubModelChange(event) {
        setSubModel(event.target.value);
    }
    const focusOnCheckbox = () => {
        if (!isChecked) {
            checkboxRef.current.focus();
        }
    };


    const updateQuantity = (productId, newQuantity) => {
        setTempCartData((prevTempCartData) => {
            const updatedCartItems = prevTempCartData.allData.map((item) => {
                if (item.product_id === productId) {
                    return { ...item, product_quantity: newQuantity };
                }
                return item;
            });
            return { ...prevTempCartData, allData: updatedCartItems };
        });
    };


    // console.log('cartData++++++++', cartData);

    return (
        <>
            <div className="fade_background"></div>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />

            <div className="parformane-bar ">
                <div className="container">
                    <ul className="p-0 m-0 listing-parmofamne">
                        <li>
                            <a href="/">Home /</a>
                        </li>
                        <li className="active-bread">
                            <a href="#">Cart</a>
                        </li>
                    </ul>
                </div>
            </div>

            <section className='cart-main'>

                <div className='container'>
                    <div className='cart_title'>
                        <span className='title'>Your Cart</span>
                        {/* {
                            cartData.totalDiscount && cartData.totalDiscount > 0 ?
                                <span className='discount_message'>Congratulations! You've received a discount of  ${parseFloat(cartData.totalDiscount).toFixed(2)} on your purchase today!</span>
                                : ""
                        } */}
                    </div>
                    <div className='cart-body'>
                        <div className='row'>
                            <div className='col-md-8'>
                                {/* {

                                    tempCartData && tempCartData.allData && tempCartData.allData.length > 0 ?
                                        !vehicalDetails ?
                                            <div className='cart-box'>
                                                <div className='row '>
                                                    <strong>Please enter Year, Make, Model of your vehicle</strong>

                                                    <div className='col-md-3'>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Year*'
                                                            value={year}
                                                            onChange={handleYearChange}
                                                            ref={yearInputRef}

                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Make*'
                                                            value={make}
                                                            onChange={handleMakeChange}
                                                            ref={makeInputRef}

                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Model*'
                                                            value={model}
                                                            onChange={handleModelChange}
                                                            ref={modelInputRef}

                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Sub Model'
                                                            value={subModel}
                                                            onChange={handleSubModelChange}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='cart-box'>
                                                <div className='row '>
                                                    <strong>Please enter year make model of your vehicle</strong>
                                                    <div className='col-md-3'>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Year*'
                                                            defaultValue={year}
                                                            readOnly

                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Make*'
                                                            defaultValue={make}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Model*'
                                                            defaultValue={model}
                                                            readOnly

                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Sub Model'
                                                            defaultValue={subModel}
                                                            readOnly

                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                        : ""


                                } */}
                                {
                                    loadingData && tempCartData && tempCartData.allData ?
                                        tempCartData.allData.length > 0 ?
                                            tempCartData.allData.map((item, index) => (
                                                item.category_type == 'acc' ?
                                                    <div className='cart-box ribbon_box' key={item.product_id}>
                                                        {/* <div class="ribbon"><span>5% Off</span></div> */}

                                                        <div className='close_btn ' onClick={() => deleteSingleProduct(item.product_id, item.category_type, item.part_number)}><a href='#'><i className="fa fa-trash text-danger" aria-hidden="true"></i></a></div>
                                                        <div className='img_content'>
                                                            <div className='product_image ' role="button" onClick={() => redirectToProductPage(item.product_id, item.category_type, item.product_name)}>
                                                                {item.product_image ? <img src={`${item.product_image}`} alt="product_image" height="100%" width="100%" />
                                                                    : <img className="w-100 h-auto" src="/images/coming_soon.jpg" alt="img" height="100%" width="100%" />}
                                                            </div>
                                                            <div className='product_details'>
                                                                <h2 className='title' role="button" onClick={() => redirectToProductPage(item.product_id, item.category_type, item.product_name)}>
                                                                    {/* {item.product_name.split('\n').map((line, index) => (

                                                               <>{line}
                                                                   <br /></>

                                                           ))} */}
                                                                    {item.product_name}
                                                                </h2>
                                                                <h3 className="part_number">Part Number: <span>{item.part_number}</span></h3>
                                                                {/* <div className='list'>
                                                           <ul>
                                                               
                                                               <span className=''><i className="fa fa-circle me-1 text-success"></i> In stock ( {item.total_quantity} )</span>
                                                           </ul>
                                                           <h3 className='product_price'>${parseFloat(item.product_price).toFixed(2)}</h3>
                                                       </div> */}
                                                                {/* {item.category_type === 'acc' ? (
                                                                    <div className='list'>
                                                                        <ul>
                                                                            <span className=''><i className="fa fa-circle me-1 text-success"></i> In stock ( {item.total_quantity} )</span>

                                                                        </ul>
                                                                        <h3 className='product_price'>
                                                                            <s> ${parseFloat(item.product_price).toFixed(2)} </s>   &nbsp;
                                                                            ${((parseFloat(item.product_price)).toFixed(2) - (parseFloat(item.product_price) * 0.05).toFixed(2)).toFixed(2)}
                                                                        </h3>

                                                                    </div>
                                                                ) : (
                                                                    <div className='list'>
                                                                        <ul>
                                                                            <span className=''><i className="fa fa-circle me-1 text-success"></i> In stock ( {item.total_quantity} )</span>
                                                                        </ul>
                                                                        <h3 className='product_price'>${parseFloat(item.product_price).toFixed(2)}</h3>
                                                                    </div>
                                                                )} */}

                                                                <div className='list'>
                                                                    <ul>
                                                                        <span className=''><i className="fa fa-circle me-1 text-success"></i> In stock ( {item.total_quantity} )</span>
                                                                    </ul>
                                                                    <h3 className='product_price'>${parseFloat(item.product_price).toFixed(2)}</h3>
                                                                </div>
                                                                <div className='qty_price'>
                                                                    <div className='qty'>
                                                                        <span className='qty_text'>Qty: </span>
                                                                        <div className="quantity">
                                                                            <div className="value-button" id="decrease" onClick={() => delteQuantity(item.product_id, item.category_type, item.part_number)}>
                                                                                <i className="fa fa-minus"></i>
                                                                            </div>

                                                                            <input
                                                                                type="text"
                                                                                id="number"
                                                                                value={item.product_quantity}
                                                                                onChange={(e) => {
                                                                                    const newQuantity = e.target.value !== '' ? parseInt(e.target.value, 10) : 0;
                                                                                    if (isNaN(newQuantity)) return; // If not a valid number, do nothing

                                                                                    if (newQuantity >= 0 && newQuantity <= item.total_quantity) {
                                                                                        updateQuantity(item.product_id, newQuantity);
                                                                                    }
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    // Allow Backspace to clear the input
                                                                                    if (e.key === 'Backspace') {
                                                                                        updateQuantity(item.product_id, 0);
                                                                                    }
                                                                                }}
                                                                                disabled
                                                                            />

                                                                            <div className="value-button" id="increase" onClick={() => addQuantity(item.product_id, item.category_type, item.part_number)}>
                                                                                <i className="fa fa-plus"></i>
                                                                            </div>
                                                                        </div>
                                                                        <span className='update_cart' onClick={() => updateCart(item.product_id, item.category_type, item.part_number)}><a href='#'>Update Cart</a></span>
                                                                    </div>
                                                                    <div className='price'>
                                                                        {/* {item.category_type === 'acc' ?
                                                                            (
                                                                                <span>

                                                                                    <s> ${parseFloat(item.product_total_price).toFixed(2)} </s>   &nbsp;
                                                                                    ${parseFloat((parseFloat(item.product_total_price)).toFixed(2) - (parseFloat(item.product_total_price) * 0.05).toFixed(2)).toFixed(2)}



                                                                                    
                                                                                </span>
                                                                            )
                                                                            :

                                                                            <span>${parseFloat(item.product_total_price).toFixed(2)}</span>
                                                                        } */}
                                                                        <span>${parseFloat(item.product_total_price).toFixed(2)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <div className='cart-box' key={item.product_id}>
                                                        <div className='close_btn ' onClick={() => deleteSingleProduct(item.product_id, item.category_type, item.part_number)}><a href='#'><i className="fa fa-trash text-danger" aria-hidden="true"></i></a></div>
                                                        <div className='img_content'>
                                                            <div className='product_image ' role="button" onClick={() => redirectToProductPage(item.product_id, item.category_type, item.product_name)}>
                                                                {item.product_image ? <img src={`${item.product_image}`} alt="product_image" height="100%" width="100%" />
                                                                    : <img className="w-100 h-auto" src="/images/coming_soon.jpg" alt="img" height="100%" width="100%" />}
                                                            </div>
                                                            <div className='product_details'>
                                                                <h2 className='title' role="button" onClick={() => redirectToProductPage(item.product_id, item.category_type, item.product_name)}>
                                                                    {/* {item.product_name.split('\n').map((line, index) => (

                                                           <>{line}
                                                               <br /></>

                                                       ))} */}
                                                                    {item.product_name}
                                                                </h2>
                                                                <h3 className="part_number">Part Number: <span>{item.part_number}</span></h3>
                                                                {/* <div className='list'>
                                                       <ul>
                                                           
                                                           <span className=''><i className="fa fa-circle me-1 text-success"></i> In stock ( {item.total_quantity} )</span>
                                                       </ul>
                                                       <h3 className='product_price'>${parseFloat(item.product_price).toFixed(2)}</h3>
                                                   </div> */}
                                                                {item.category_type === 'acc' ? (
                                                                    <div className='list'>
                                                                        <ul>
                                                                            <span className=''><i className="fa fa-circle me-1 text-success"></i> In stock ( {item.total_quantity} )</span>

                                                                        </ul>
                                                                        <h3 className='product_price'>
                                                                            <s> ${parseFloat(item.product_price).toFixed(2)} </s>   &nbsp;
                                                                            ${(parseFloat(item.product_price)).toFixed(2) - (parseFloat(item.product_price) * 0.05).toFixed(2)}
                                                                        </h3>

                                                                    </div>
                                                                ) : (
                                                                    <div className='list'>
                                                                        <ul>
                                                                            <span className=''><i className="fa fa-circle me-1 text-success"></i> In stock ( {item.total_quantity} )</span>
                                                                        </ul>
                                                                        <h3 className='product_price'>${parseFloat(item.product_price).toFixed(2)}</h3>
                                                                    </div>
                                                                )}
                                                                <div className='qty_price'>
                                                                    <div className='qty'>
                                                                        <span className='qty_text'>Qty: </span>
                                                                        <div className="quantity">
                                                                            <div className="value-button" id="decrease" onClick={() => delteQuantity(item.product_id, item.category_type, item.part_number)}>
                                                                                <i className="fa fa-minus"></i>
                                                                            </div>

                                                                            <input
                                                                                type="text"
                                                                                id="number"
                                                                                value={item.product_quantity}
                                                                                onChange={(e) => {
                                                                                    const newQuantity = e.target.value !== '' ? parseInt(e.target.value, 10) : 0;
                                                                                    if (isNaN(newQuantity)) return; // If not a valid number, do nothing

                                                                                    if (newQuantity >= 0 && newQuantity <= item.total_quantity) {
                                                                                        updateQuantity(item.product_id, newQuantity);
                                                                                    }
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    // Allow Backspace to clear the input
                                                                                    if (e.key === 'Backspace') {
                                                                                        updateQuantity(item.product_id, 0);
                                                                                    }
                                                                                }}
                                                                                disabled
                                                                            />

                                                                            <div className="value-button" id="increase" onClick={() => addQuantity(item.product_id, item.category_type, item.part_number)}>
                                                                                <i className="fa fa-plus"></i>
                                                                            </div>
                                                                        </div>
                                                                        <span className='update_cart' onClick={() => updateCart(item.product_id, item.category_type, item.part_number)}><a href='#'>Update Cart</a></span>
                                                                    </div>
                                                                    <div className='price'>
                                                                        {/* {item.category_type === 'acc' ?
                                                                            (
                                                                                <span>

                                                                                    <s> ${parseFloat(item.product_total_price).toFixed(2)} </s>   &nbsp;
                                                                                    ${(parseFloat(item.product_total_price)).toFixed(2) - (parseFloat(item.product_total_price) * 0.05).toFixed(2)}

                                                                                    
                                                                                </span>
                                                                            )
                                                                            :

                                                                            <span>${parseFloat(item.product_total_price).toFixed(2)}</span>
                                                                        } */}
                                                                        <span>${parseFloat(item.product_total_price).toFixed(2)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            ))

                                            : <div className="_1AtVbE col-12-12">
                                                <div className="Vy94J0">
                                                    {/* <img src="/images/emptycart.png" className="_2giOt4" /> */}
                                                    <div className="_1LCJ1U">Your cart is empty!</div>
                                                    <div className="hKIFfL">Add items to it now.</div>
                                                    <button className="_2KpZ6l _1sbqEe _3dESVI text-light"><a href='/' className=" text-light" ><span>Shop now</span></a></button>
                                                </div>
                                            </div>

                                        : <>
                                            <div className='cart-box'>
                                                <div className='close_btn'></div>
                                                <div className='img_content'>
                                                    <div className='product_image'>
                                                        <ShimmerThumbnail height={140} rounded />
                                                    </div>
                                                    <div className='product_details'>
                                                        <h2 className='title'><ShimmerBadge width={120} /></h2>
                                                        <h5 className="part_number"><ShimmerTitle line={1} gap={10} variant="primary" /></h5>
                                                        <div className='list'>
                                                            <ul>
                                                                <ShimmerBadge width={120} />
                                                            </ul>
                                                            <h3 className='product_price'><ShimmerBadge width={120} /></h3>
                                                        </div>
                                                        <div className='qty_price'>
                                                            <div className='qty'>
                                                                <ShimmerBadge width={120} />
                                                            </div>
                                                            <div className='price'>
                                                                <ShimmerBadge width={120} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='cart-box'>
                                                <div className='close_btn'></div>
                                                <div className='img_content'>
                                                    <div className='product_image'>
                                                        <ShimmerThumbnail height={140} rounded />
                                                    </div>
                                                    <div className='product_details'>
                                                        <h2 className='title'><ShimmerBadge width={120} /></h2>
                                                        <h5 className="part_number"><ShimmerTitle line={1} gap={10} variant="primary" /></h5>
                                                        <div className='list'>
                                                            <ul>
                                                                <ShimmerBadge width={120} />
                                                            </ul>
                                                            <h3 className='product_price'><ShimmerBadge width={120} /></h3>
                                                        </div>
                                                        <div className='qty_price'>
                                                            <div className='qty'>
                                                                <ShimmerBadge width={120} />
                                                            </div>
                                                            <div className='price'>
                                                                <ShimmerBadge width={120} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                }


                            </div>
                            {

                                loadingData && cartData && cartData.allData ?
                                    cartData.allData.length > 0 ?

                                        <div className='col-md-4'>
                                            <div className='total-main'>
                                                <div className='title'>
                                                    <h2>Summary</h2>
                                                </div>
                                                <div className='details'>
                                                    <div className='subtotal'>
                                                        <span>Subtotal: </span>
                                                        <span className='price'>${parseFloat(cartData.subtotal).toFixed(2)}</span>
                                                    </div>
                                                    {
                                                        cartData.totalDiscount && cartData.totalDiscount > 0 ?
                                                            <div className='discount_items'>
                                                                <span className=''>Discount: </span>
                                                                <span className=''>${parseFloat(cartData.totalDiscount).toFixed(2)}</span>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                                <div className='totals'>
                                                    <div className='text'>
                                                        <span>Totals: </span>
                                                        {/* <span className="price">${parseFloat(cartData.subtotal).toFixed(2)}</span> */}
                                                        <span className="price">${parseFloat(cartData.subtotal - cartData.totalDiscount).toFixed(2)}</span>
                                                    </div>
                                                </div>
                                                {cartData.subtotal > 100 ? (<div className='mb-2'>
                                                    <div className='text text-danger freeshippingDiv'>
                                                        <span>
                                                            <i className="fa fa-truck" aria-hidden="true"></i>
                                                        </span>
                                                        <span>
                                                            Free Shipping
                                                        </span>

                                                    </div>
                                                </div>) : ""}

                                                <div className='policy_terms mb-3'>
                                                    <span>
                                                        <input
                                                            type='checkbox'
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                            className={isChecked ? '' : 'highlight-red'}
                                                            ref={checkboxRef}
                                                        />
                                                    </span>
                                                    <span>I have read and accept the <a href='https://www.extremeperformance.com/terms-conditoins' target='_blank'>terms & condition</a></span>
                                                </div>


                                                <div id="prices mt-2">
                                                    <p id="learn-more" className="affirm-as-low-as" data-amount={`${cartData.subtotal * 100}`} data-affirm-color="blue" data-learnmore-show="true" data-page-type="product"> </p>
                                                </div>
                                                {
                                                    !chekoutStatus ?
                                                        < div className='proceed_btn' onClick={redirectToCheckout}>
                                                            <a href='#'>Proceed to Checkout</a>
                                                        </div>

                                                        :
                                                        <div className='proceed_btn'>
                                                            <a href='#'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></a>
                                                        </div>
                                                }

                                                {/* <div className='policy'>
                                                    <span>This site is protected by reCAPTCHA and the Google <a href='#'>Privacy Policy</a></span>
                                                </div> */}
                                            </div>
                                        </div>
                                        : ""
                                    :
                                    <div className='col-md-4'>
                                        <div className='total-main'>
                                            <div className='title'>
                                                <h2><ShimmerBadge width={120} /></h2>
                                            </div>
                                            <div className='details'>
                                                <div className='subtotal'>
                                                    <span><ShimmerBadge width={120} /> </span>
                                                    <span className='price'><ShimmerBadge width={60} /></span>
                                                </div>
                                                <div className='delivery'>
                                                    <span><ShimmerBadge width={120} /> </span>
                                                    <span className='price'><ShimmerBadge width={60} /></span>
                                                </div>
                                            </div>
                                            <div className='totals'>
                                                <div className='text'>
                                                    <span><ShimmerBadge width={120} /> </span>
                                                    <span className="price"><ShimmerBadge width={60} /></span>
                                                </div>
                                            </div>
                                            <ShimmerTitle line={1} gap={15} variant="primary" />
                                            <div className='policy'>
                                                <span><ShimmerTitle line={1} gap={10} variant="primary" /></span>
                                            </div>
                                        </div>
                                    </div>
                            }



                        </div>

                    </div>
                </div>
            </section >

            <SERVICES />
            <FOOTER />

        </>
    )
}
