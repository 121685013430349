import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import { ShimmerButton, ShimmerThumbnail, ShimmerCategoryItem } from "react-shimmer-effects";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import BREADCRUMBAR from "../../components/BreadCrumBar";



export default function Products() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const navigate = useNavigate();
    const [isHidden, setIsHidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [catgoryData, setCatgegoryData] = useState(true);
    const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
    const urlParams = new URLSearchParams(window.location.search);
    // const id = urlParams.get('id');
    // const categoryName = urlParams.get('cat');

    const{id,categoryName } = useParams();

    const [selectedYear, setSelectedYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [selectedMake, setSelectedMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [selectedModel, setSelectedModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [selectedVariant, setSelectedVariant] = useState(vehicalDetails ? vehicalDetails.variant : "");

    const [cartItems, setCartItems] = useState("");
    const [isdata, setIsdata] = useState(true);


    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');

        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
    }, []);


    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);



    useEffect(() => {
        fetchCategoriesOfRoot();
    }, []);

    const fetchCategoriesOfRoot = async () => {
        try {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/getAllCategoriesofRoot?id=${id}&year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`,
            };
            axios.request(config)
                .then((response) => {
                    setCatgegoryData(response.data);
                    if(response.data.status == 500){

                        setIsdata(false);
                
                       }
                    setIsLoading(false);

                })
                .catch((error) => {
                    setIsdata(false);
                    console.log(error);
                    setIsLoading(false);
                    // navigate(`/error`);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
            navigate(`/error`);

        }
    }

    const onSubmit = (id) => {
        setTimeout(() => {
            navigate(`/products/${id}`);
        }, 1000);
    }

    const onRedirectHome = () => {
        localStorage.removeItem('vehical_details');
        setTimeout(() => {
            navigate('/');

        }, 1000);
    }
    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    const [showMenuSide, setShowMenuSide] = useState("")

    const goBackToHomepage = () => {
        navigate('/');
    };

    return (
        <>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            {isdata ? (<>
            <section>
                <div className="main-inner-banner">
                    <div className="parformane-bar">
                        <div className="container d-flex justify-content-between">

                            <ul className="p-0 m-0 listing-parmofamne">
                                <li><a href="/">Home /</a></li>
                                <li className='active-bread text-decoration-none'><a href="#">{categoryName}</a></li>
                            </ul>

                            {/* <div>
                                <a href="/" className='text-decoration-none text-light'>Home</a>
                            </div> */}

                        </div>
                    </div>
                    {/* <div className="five_percent_products"><img src='../icons/homepage_discount.png' /></div> */}
                    <h2 className="text-center txt-main-listing">{categoryName}</h2>
                </div>

            </section>
            <section className='all_category'>
                <div className='container mt-3'>
                    <p className='car-title'>

                        {vehicalDetails ? vehicalDetails.year : ""}&nbsp;
                        {vehicalDetails ? vehicalDetails.make_name : ""}
                        {vehicalDetails ? vehicalDetails.model_name : ""}&nbsp;
                        {vehicalDetails ? vehicalDetails.variant_name : ""}

                    </p>
                </div>
                <div className='category-main-section'>
                    <div className='container'>

                        <div className="row mt-4" >
                            <h3 className='' >{categoryName}</h3>
                            {
                                !isLoading && catgoryData ?
                                    catgoryData.data.length > 0 ?
                                        catgoryData.data.map((item, index) => (
                                            
                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4" key={item.category_id} onClick={() => onSubmit(item.category_id)}>
                                                    <div className="card">
                                                        <div className="row g-0 p-3">
                                                            <div className="col-md-4">
                                                                <img src={"/category/" + item.image} alt="img" className="img-fluid" height="100%" width="100%" />
                                                            </div>
                                                            <div className="col-md-8 position-relative">
                                                                <div className="card-body">
                                                                    <h4 className="card-title">{item.category_name} ({parseInt(item.count).toLocaleString('en-US')})</h4>
                                                                    {/* <span className='text-danger cursor_pointer' onClick={() => onSubmit(item.category_id)}><u>View Products</u></span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            


                                        ))
                                        : <>
                                            <div className='container text-center mt-5'>
                                                <h2>No Product Found</h2>
                                                <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                                <p ><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                                            </div>

                                        </>
                                    :
                                    Array.from({ length: 28 }).map((_, idx) => (
                                        <div className="col-lg-2 col-md-3 col-sm-4 col-6  mt-5 " style={{ height: 196 }} key={idx}>
                                            <ShimmerThumbnail height={90} rounded />
                                        </div>
                                    ))
                            }
                        </div>




                    </div>
                </div>
                <hr></hr>
            </section>
            </>   )
  : (<div className="error-screen">
  <h1><strong>404</strong></h1>
  <h2>ERROR: PAGE NOT FOUND</h2>
  <button className="btn btn-sm btn-danger" onClick={goBackToHomepage}>
      Go back to homepage
  </button>
</div> )}
            {/* <NEWSLETTER /> */}
            < FOOTER />
        </>

    )
}
