import { React, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import Itemcards from "../../components/Cards/itemscard";
import Pagination from 'react-bootstrap/Pagination';
import { ShimmerButton, ShimmerThumbnail, ShimmerCategoryItem } from "react-shimmer-effects";
import { RangeSlider } from 'rsuite';
import "rsuite/dist/rsuite.css";
import axios from 'axios';
import BREADCRUMBAR from "../../components/BreadCrumBar";
import Collapse from 'react-bootstrap/Collapse';
import { Helmet } from 'react-helmet';






export default function Products() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // const brandid = queryParams.get('brandid');
    // const brandname = queryParams.get('brandname');
    const MAX_PAGES_DISPLAY = 10;
    const PRODUCT_PER_PAGE = 28;
    const [cartItems, setCartItems] = useState("");
    const [isHidden, setIsHidden] = useState(false);

    const navigate = useNavigate();
    const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
    const { id, brandid, brandname } = useParams();
    const [dataCategory, setDataCategory] = useState([]);
    const [isLoadingCategory, setIsLoadingCategory] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [totalProducts, setTotalProducts] = useState(0);


    const [categoryName, setCategoryName] = useState('');

    const [dataBradnAndCategory, setDataBradnAndCategory] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const [dataYear, setDataYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [dataMake, setDataMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [dataModel, setDataModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [dataModelVarient, setDataVarient] = useState(vehicalDetails ? vehicalDetails.variant : "");


    const [selectedYear, setSelectedYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [selectedMake, setSelectedMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [selectedModel, setSelectedModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [selectedVariant, setSelectedVariant] = useState(vehicalDetails ? vehicalDetails.variant : "");

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMake, setIsLoadingMake] = useState(true);
    const [isLoadingModel, setIsLoadingModel] = useState(true);
    const [isLoadingVarient, setIsLoadingVarient] = useState(true);


    const [isLoaderMake, setIsLoaderMake] = useState(true);
    const [isLoaderModel, setIsLoaderModel] = useState(true);
    const [isLoaderVarient, setIsLoaderVarient] = useState(true);

    const [sortingValue, setSortingValue] = useState(1);



    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoriesName, setSelectedCategoriesName] = useState([]);
    const [selectedBrandsName, setSelectedBrandsName] = useState([]);


    const [firstValue, setFirstValue] = useState(0);
    const [secondValue, setSecondValue] = useState(5000);

    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);


    const [maxPriceDefault, setMaxPriceDefault] = useState(null);
    const [minPriceDefault, setMinPriceDefault] = useState(null);

    const [showWholwPageLoader, setShowWholwPageLoader] = useState(false);

    const [open, setOpen] = useState(true);
    const [openBrand, setOpenBrand] = useState(false);
    const [openPrice, setOpenPrice] = useState(false);

    const [stockWheel, setStockWheel] = useState([]);


    const [filterClass, setFilterClass] = useState('');




    const [showError, setShowError] = useState(false)
    const [openStock, setOpenStock] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("")

    const [isChecked, setIsChecked] = useState(true);


    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        if (brandid) {
            setSelectedBrands([...selectedBrands, brandid]);
        }

        if (brandname) {
            const categoryObject = { id: brandid, name: brandname };

            setSelectedBrandsName([...selectedBrandsName, categoryObject])
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
        fetchBrandAndCatData();
        fetchYearData();
    }, []);


    useEffect(() => {
        setTimeout(() => {
            if (selectedBrands.length > 0) {
                fetchProductData();
            }
        }, 1000);
    }, [currentPage, sortingValue, selectedCategories, selectedBrands, firstValue, secondValue, stockWheel, isChecked]);

    //fetch product items 
    const fetchProductData = async () => {
        document.body.classList.add('full-screen-loader');
        setShowWholwPageLoader(true)
        setIsLoadingCategory(true);
        const categoriesString = selectedCategories.join(',');
        const brandString = selectedBrands.join(',');
        const limit = 28; // Define the limit per page
        const offset = (currentPage - 1) * limit; // Calculate the offset based on the current page and limit
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/category/${id}?limit=${limit}&offset=${offset}&sorting=${sortingValue}&subcategories=${categoriesString}&brands=${brandString}&startingPrice=${firstValue}&endPrice=${secondValue}&year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}&stockWheel=${stockWheel}&page=${currentPage}&isChecked=${isChecked}`,
            };
            axios.request(config)
                .then((response) => {
                    setDataCategory(response.data);
                    setIsLoadingCategory(false);
                    setTotalPages(response.data.totalPages);
                    setTotalProducts(response.data.count);

                    setCategoryName(response.data.data ? response.data.data[0].category_name : "");
                    setShowWholwPageLoader(false)
                    window.scrollTo({ top: 400, behavior: 'smooth' });
                    setMinPrice(Math.floor(response.data.minimumPrice));
                    setMaxPrice(Math.ceil(response.data.maximumPrice));
                    setMaxPriceDefault((Math.ceil(response.data.maximumPrice)) + 1);
                    // setMinPriceDefault((Math.ceil(response.data.maximumPrice))-1);

                    document.body.classList.remove('full-screen-loader');

                })
                .catch((error) => {
                    console.log(error);
                    setShowWholwPageLoader(false)
                    document.body.classList.remove('full-screen-loader');

                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoadingCategory(false);
            setShowWholwPageLoader(false)
            document.body.classList.remove('full-screen-loader');


        }
    };


    //fetch brand  
    const fetchBrandAndCatData = async () => {

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/getSubCatBrandFromCat/${id}?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`,
            };
            axios.request(config)
                .then((response) => {
                    setDataBradnAndCategory(response.data);
                    setIsInitialLoad(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsInitialLoad(false);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsInitialLoad(false);
        }
    };

    //fetch year


    const fetchYearData = async () => {
        setIsLoading(true);
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/getYear`,
            };
            axios
                .request(config)
                .then((response) => {
                    setDataYear(response.data);
                    setIsLoading(false);
                    fetchMakeData(selectedYear);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    const handleYearChange = (event) => {
        const selectedYear = event.target.value;


        if (selectedYear === "") {
            setSelectedMake('');
            setSelectedModel('');
            setSelectedVariant('');
            setDataMake("")
            setDataModel("")
            setDataVarient("")

        } else {
            setSelectedYear(selectedYear);
            setSelectedMake('');
            setSelectedModel('');
            setSelectedVariant('');
            // Fetch make data based on selected year
            fetchMakeData(selectedYear);
            setIsLoaderMake(false);

        }

    };

    const fetchMakeData = async (selectedYear) => {
        if (selectedYear) {
            setIsLoadingMake(true);
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/getMakeByYear/${selectedYear}`,
                };
                axios
                    .request(config)
                    .then((response) => {

                        setIsLoadingMake(false);
                        setDataMake(response.data);
                        setIsLoaderMake(true);
                        fetchModelData(selectedYear, selectedMake);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoadingMake(false);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoadingMake(false);
            }
        }
    };

    const handleMakeChange = (event) => {
        const selectedMake = event.target.value;
        setSelectedMake(selectedMake);

        // setDataMake([]);
        setDataModel([]);
        setSelectedModel('');
        setSelectedVariant('');
        // Fetch model data based on selected year and make
        setIsLoaderModel(false)
        fetchModelData(selectedYear, selectedMake);
    };

    const fetchModelData = async (selectedYear, selectedMake) => {
        if (selectedYear && selectedMake) {
            setIsLoadingModel(true);
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/getModelByMake/${selectedYear}/${selectedMake}`,
                };
                axios
                    .request(config)
                    .then((response) => {
                        // console.log(JSON.stringify(response.data));
                        setIsLoadingModel(false);
                        setDataModel(response.data);
                        setIsLoaderModel(true)
                        fetchVariantData(selectedYear, selectedModel);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoadingModel(false);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoadingModel(false);
            }
        }
    };

    const handleModelChange = (event) => {
        const selectedModel = event.target.value;
        setSelectedModel(selectedModel);
        setSelectedVariant('');
        // Fetch variant data based on selected year, make, and model
        fetchVariantData(selectedYear, selectedModel);
        setIsLoaderVarient(false)
    };

    const fetchVariantData = async (selectedYear, selectedModel) => {
        if (selectedYear && selectedModel) {
            setIsLoadingVarient(true);
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/getSubModelByModel/${selectedYear}/${selectedModel}`,
                };
                axios
                    .request(config)
                    .then((response) => {
                        // console.log(JSON.stringify(response.data));
                        setIsLoadingVarient(false);
                        setDataVarient(response.data);
                        setIsLoaderVarient(true)
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoadingVarient(false);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoadingVarient(false);
            }
        }
    };

    const handleVarientChange = (event) => {
        const selectedVarient = event.target.value;
        setSelectedVariant(selectedVarient);

    };
    const handleSorting = (event) => {
        const sortingValue = event.target.value;
        // console.log(sortingValue);
        setSortingValue(sortingValue);
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
        document.body.classList.add('full-screen-loader');
    };

    const getPageNumbers = () => {
        const totalDisplayPages = Math.min(totalPages, MAX_PAGES_DISPLAY);
        const middlePage = Math.ceil(totalDisplayPages / 2);
        let startPage = Math.max(1, currentPage - middlePage + 1);

        // Ensure the start page does not exceed the available pages
        if (startPage + totalDisplayPages > totalPages) {
            startPage = Math.max(1, totalPages - totalDisplayPages + 1);
        }

        return Array.from({ length: totalDisplayPages }, (_, index) => startPage + index);
    };



    function handleRanges(value) {

        setFirstValue(value[0]);
        setSecondValue(value[1]);

        setMinPrice(value[0]);
        setMaxPrice(value[1]);
    }



    const handleSubmit = (event) => {
        let state = {
            year: selectedYear,
            make: selectedMake,
            model: selectedModel,
            variant: selectedVariant,
        }
        localStorage.setItem('vehical_details', JSON.stringify(state));
        navigate('/search-products', { state: state });
    };

    const handleBrandCheckboxChange = (event) => {
        setIsHidden(true);
        const brandId = event.target.value;
        const brandIdName = event.target.name;
        if (selectedBrands.includes(brandId)) {
            setSelectedBrands(selectedBrands.filter((id) => id !== brandId));
        } else {
            setSelectedBrands([...selectedBrands, brandId]);
        }
        // Check if the category object is already in the selectedCategoriesName array
        const categoryObject = { id: brandId, name: brandIdName };
        // console.log('categoryObject+++', categoryObject)
        const categoryObjectIndex = selectedBrandsName.findIndex(
            (obj) => obj.id === brandId
        );
        if (categoryObjectIndex !== -1) {
            setSelectedBrandsName(
                selectedBrandsName.filter((obj) => obj.id !== brandId)
            );
        } else {
            setSelectedBrandsName([...selectedBrandsName, categoryObject]);
        }


    };

    const handleCategoryCheckboxChange = (event) => {

        setIsHidden(true);

        const categoryId = event.target.value;
        const categoryName = event.target.name;
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(selectedCategories.filter((id) => id !== categoryId));
        } else {
            setSelectedCategories([...selectedCategories, categoryId]);
        }

        // Check if the category object is already in the selectedCategoriesName array
        const categoryObject = { id: categoryId, name: categoryName };
        const categoryObjectIndex = selectedCategoriesName.findIndex(
            (obj) => obj.id === categoryId
        );
        if (categoryObjectIndex !== -1) {
            setSelectedCategoriesName(
                selectedCategoriesName.filter((obj) => obj.id !== categoryId)
            );
        } else {
            setSelectedCategoriesName([...selectedCategoriesName, categoryObject]);
        }



    };


    const handleRemoveCategory = (categoryName) => {
        // console.log('+++++++categoryName++++++++', categoryName)
        const updatedCategories = selectedCategories.filter((category) => category !== categoryName);
        setSelectedCategories(updatedCategories);

        const updatedCategoryNames = selectedCategoriesName.filter((name) => name.id !== categoryName);
        setSelectedCategoriesName(updatedCategoryNames);
        // console.log('+selectedCategories++++++++++++++', selectedCategories)

    };

    const handleRemoveBrand = (brandName) => {

        // console.log('++++++++brandName+++++++', brandName)
        const updatedBrands = selectedBrands.filter((brand) => brand !== brandName);
        setSelectedBrands(updatedBrands);

        const updatedBrandNames = selectedBrandsName.filter((name) => name.id !== brandName);
        setSelectedBrandsName(updatedBrandNames);

        // console.log('selectedBrands++++++++++', selectedBrands)
    };



    const onRedirectHome = () => {
        localStorage.removeItem('vehical_details');
        setTimeout(() => {
            navigate('/');

        }, 1000);
    }



    const clearAllFilters = () => {
        navigate(`/products/${id}`);

        setSelectedBrands([]);
        setSelectedCategories([]);
        setSelectedCategoriesName([]);
        setSelectedBrandsName([]);

    }

    const showFiltersClick = () => {
        setFilterClass('active-filter')
        document.body.classList.add('home-layout')

    }
    const hideFilterClass = () => {
        setFilterClass('')
        document.body.classList.remove('home-layout')
    }

    const handleStockChange = (event) => {

        const selectedStock = event.target.value;
        setStockWheel(selectedStock)

    }
    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    const startIndex = (currentPage - 1) * PRODUCT_PER_PAGE + 1;
    const endIndex = Math.min(startIndex + PRODUCT_PER_PAGE - 1, totalProducts);

    const optionsAvailbility = [
        { value: "0", label: "ALL" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4+" },
    ];


    const handleStaggeredChange = (event) => {
        setIsChecked(!isChecked)
    }


    // console.log('Min Price:', minPrice);
    // console.log('Max Price:', maxPrice);
    const currentLink = window.location.href;
    const url = new URL(currentLink);
    url.search = '';
    const updatedUrl = url.toString();

    return (
        <>


            <Helmet>
                <title>{brandname?brandname:"Brand"}</title>
                <meta name="description" content={brandname ? brandname + ' in 1984 and was one of the first companies to make accessories for ATVs' : ' This brand on the companies to make accessories'} />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>


            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <section>
                <div className="main-inner-banner">
                    <div className="parformane-bar">
                        <div className="container d-flex justify-content-between">
                            <div>
                                <ul className="p-0 m-0 listing-parmofamne">
                                    <li>
                                        <a href="/" className=''>Home /</a>
                                    </li>
                                    {/* <li>
                                        <a href="/brands">Brands /</a>
                                    </li> */}
                                    <li >
                                        <a href={`/categories-home/brand-category?brandname=${brandname}&brandid=${brandid}`} className=''>{brandname} /</a>
                                    </li>
                                    <li className="active-bread text-decoration-none">
                                        <a href="#" className=''>{categoryName}</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <h2 className="text-center txt-main-listing">{categoryName}</h2>
                </div>

            </section>

            <section>
                <div className="main-section-lsting">
                    <div className="container">
                        <div className='d-flex justify-content-between car-selection-title'>
                            <div>
                                {vehicalDetails ? <strong style={{ fontSize: '30px' }}> {vehicalDetails ? vehicalDetails.year : ""}&nbsp;
                                    {vehicalDetails ? vehicalDetails.make_name : ""}
                                    {vehicalDetails ? vehicalDetails.model_name : ""}&nbsp;
                                    {vehicalDetails ? vehicalDetails.variant_name : ""}</strong> : ""}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3">
                                <div className={`main-outer-filter ${filterClass}`}>
                                    <div className="filter-crose" id="close-bar">

                                        <button className="reset" onClick={clearAllFilters}>
                                            Reset
                                        </button>

                                        <button className="submit" onClick={hideFilterClass}>
                                            Search
                                        </button>


                                        <button className="abc" onClick={hideFilterClass}>
                                            <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                                        </button>
                                    </div>

                                    <div className="price-filter">
                                        <h3 className="price-filter-text" role="button" onClick={() => setOpen(!open)} aria-expanded={open} aria-controls="example-collapse-text">
                                            Category {dataBradnAndCategory.uniqueSubcategories ? "(" + dataBradnAndCategory.uniqueSubcategories.length + ")" : " "}
                                            <i
                                                className={open ? "fa fa-minus" : "fa fa-plus"}


                                            >

                                            </i>
                                        </h3>
                                        <Collapse in={open} >
                                            <ul className={`p-0 m-0 list-filter`} id="example-collapse-text">
                                                {
                                                    !isInitialLoad && dataBradnAndCategory ?
                                                        dataBradnAndCategory.uniqueSubcategories.map((item, index) => (
                                                            <li key={item.sub_category_id}>
                                                                <label className="label-filter">
                                                                    <input
                                                                        className="filter-box"
                                                                        type="checkbox"
                                                                        value={item.sub_category_id}
                                                                        name={item.sub_category_name}
                                                                        checked={selectedCategories.includes(item.sub_category_id)}
                                                                        onChange={handleCategoryCheckboxChange}
                                                                        id={"Category" + item.sub_category_id}

                                                                    />
                                                                    {item.sub_category_name}
                                                                    <label htmlFor={"Category" + item.sub_category_id} className='custome-checkboxes'></label>
                                                                </label>
                                                            </li>
                                                        ))
                                                        : <ShimmerCategoryItem />

                                                }
                                            </ul>

                                        </Collapse >
                                    </div>
                                    <div className="price-filter">
                                        <h3 className="price-filter-text" role="button" onClick={() => setOpenBrand(!openBrand)} aria-expanded={openBrand} aria-controls="brand-collapse-text">
                                            Brand {dataBradnAndCategory.uniqueBrands ? "(" + dataBradnAndCategory.uniqueBrands.length + ")" : " "}
                                            <i
                                                className={openBrand ? "fa fa-minus" : "fa fa-plus"}
                                            >

                                            </i>
                                        </h3>
                                        <Collapse in={openBrand}>
                                            <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                                                {
                                                    !isInitialLoad && dataBradnAndCategory ?
                                                        dataBradnAndCategory.uniqueBrands.map((item, index) => (
                                                            <li key={index}>
                                                                <label className="label-filter">
                                                                    <input className="filter-box"
                                                                        type="checkbox"
                                                                        value={item.brand_id}
                                                                        name={item.brand_name}
                                                                        checked={selectedBrands.includes(String(item.brand_id))}
                                                                        onChange={handleBrandCheckboxChange}
                                                                        id={"Brand" + item.brand_id}
                                                                    />
                                                                    {item.brand_name}
                                                                    <label htmlFor={"Brand" + item.brand_id} className='custome-checkboxes'></label>
                                                                </label>
                                                            </li>
                                                        ))
                                                        : <ShimmerCategoryItem />

                                                }
                                            </ul>
                                        </Collapse>
                                    </div>

                                    <div className="price-filter">
                                        <h3 className="price-filter-text" role="button" onClick={() => setOpenPrice(!openPrice)} aria-expanded={openPrice} aria-controls="price-collapse-text">
                                            Price
                                            <i
                                                className={openPrice ? "fa fa-minus" : "fa fa-plus"}
                                            >

                                            </i>
                                        </h3>
                                        {
                                            !isLoadingCategory && minPrice !== null && maxPrice !== null ?
                                                <Collapse in={openPrice}>
                                                    <div id='price-collapse-text'>
                                                        <form >
                                                            <div className="aside-price-range-form d-flex  m-3" >
                                                                <div className="form-field me-2" style={{ width: '80%' }}>
                                                                    {/* <label htmlFor="filter-price-min">$ Min</label> */}
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="minPrice"
                                                                        value={"$" + minPrice}
                                                                        placeholder={"$" + minPrice}
                                                                        autoComplete="off"
                                                                        maxLength="10"
                                                                        style={{ width: '100%' }}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                                <div className="form-field me-2" style={{ width: '80%' }}>
                                                                    {/* <label htmlFor="filter-price-max">$ Max</label> */}
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="maxPrice"
                                                                        value={"$" + maxPrice}
                                                                        placeholder={"$" + maxPrice}
                                                                        autoComplete="off"
                                                                        maxLength="10"
                                                                        style={{ width: '100%' }}
                                                                        disabled={true}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </form>

                                                        <div className="">
                                                            <RangeSlider
                                                                min={0}
                                                                step={1}
                                                                max={maxPriceDefault}
                                                                defaultValue={[minPrice, maxPrice]}
                                                                onChangeCommitted={handleRanges}
                                                                tipprops={{ visible: true }}
                                                                tooltipvisible="true"

                                                            />
                                                        </div>
                                                    </div>
                                                </Collapse> : <ShimmerButton size="sm" />}

                                    </div>


                                    <div className="price-filter">
                                        <h3 className="price-filter-text" role="button" onClick={() => setOpenStock(!openStock)} aria-expanded={openStock} aria-controls="price-collapse-text">
                                            Availability
                                            <i
                                                className={openStock ? "fa fa-minus" : "fa fa-plus"}
                                            >

                                            </i>
                                        </h3>
                                        <Collapse in={openStock}>
                                            <div id='stock-collapse-text'>
                                                <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                                                    {
                                                        optionsAvailbility.map((option, index) => (
                                                            <li key={index}>
                                                                <label className="label-filter">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="wheelWidth"
                                                                        value={option.value}
                                                                        id={option.value}
                                                                        onChange={handleStockChange}
                                                                        checked={stockWheel === option.value}
                                                                    />
                                                                    {option.label}
                                                                    <label htmlFor={option.value} className='custome-checkboxes'></label>
                                                                </label>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>

                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="filter-crose button-bottom" id="close-bar">
                                        <button className="reset" onClick={clearAllFilters}>
                                            Reset
                                        </button>

                                        <button className="submit" onClick={hideFilterClass}>
                                            Search
                                        </button>
                                        <button className="abc " onClick={hideFilterClass}>
                                            <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 filter-for-mobile" >

                                <div className='selectedFilters '>
                                    {
                                        selectedCategoriesName && selectedCategoriesName.length > 0 ?
                                            selectedCategoriesName.map((item, index) => (
                                                <span className="span-filter-Checkbox m-1" key={item.name}>
                                                    <span> {item.name} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveCategory(item.id)}></i>
                                                </span>
                                            ))
                                            : ""
                                    }
                                    {
                                        selectedBrandsName && selectedBrandsName.length > 0 ?
                                            selectedBrandsName.map((item, index) => (
                                                <span className="span-filter-Checkbox m-1" key={item.id}>
                                                    <span> {item.name} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                                                </span>
                                            ))
                                            : ""
                                    }
                                    {
                                        selectedCategoriesName.length > 0 || selectedBrandsName.length > 0 ?

                                            <span className="clearFilterStyle" onClick={clearAllFilters} >
                                                <span>Clear filters</span>
                                            </span>

                                            :

                                            ""
                                    }

                                </div>
                                {/* {totalProducts > 0 ? */}
                                <div className="d-flex justify-content-end filters-sort-by ">
                                    <div className="">
                                        {totalProducts > 0 ?
                                            <p className="px-5">
                                                Showing {startIndex} - {endIndex} of {parseInt(totalProducts).toLocaleString('en-US')} products
                                            </p>
                                            : ""
                                        }
                                    </div>
                                    <div className='stockCheckbox'>
                                        <ul className='stockCheckboxUl'>
                                            <li>

                                                <input
                                                    type="checkbox"
                                                    value="2"
                                                    className=' tgl tgl-light'
                                                    onChange={handleStaggeredChange}
                                                    id='stockChecki'
                                                    name="stockCheck"

                                                />


                                                <label htmlFor='stockChecki' className=' tgl-btn'></label>


                                            </li>
                                            <li className='staggeredLi'>
                                                {
                                                    isChecked ?
                                                        <span>In Stock Items</span>
                                                        : <span>All Items</span>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="">
                                        <select className="form-control" name="sorting" id="sortingWithPrice" onChange={handleSorting} value={sortingValue}>
                                            <option disabled >Sort By Price</option>
                                            <option value="1" >Low-High</option>
                                            <option value="2">High-Low</option>
                                        </select>

                                    </div>
                                    <div className="main-filter-resposive">
                                        <button className="mobile-filter-btn" onClick={showFiltersClick} title="filter search"> <svg className="me-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.25901 14.35L5.25888 14.35C5.24769 14.3502 5.23405 14.3476 5.21863 14.339L5.21836 14.3389C5.19936 14.3282 5.19074 14.318 5.18703 14.3124C5.17887 14.2972 5.176 14.2839 5.176 14.2707V7.91365C5.176 7.82275 5.1652 7.72056 5.13235 7.61486C5.09945 7.50839 5.04961 7.41561 4.99386 7.33726L0.724519 1.26892C0.724485 1.26887 0.724451 1.26882 0.724418 1.26877C0.676725 1.20083 0.65495 1.13291 0.650688 1.06991C0.64665 1.00345 0.660203 0.933143 0.696549 0.863781L0.696552 0.863774C0.732993 0.794228 0.783357 0.74264 0.84197 0.707465L0.841971 0.707466L0.843135 0.706765C0.902543 0.670943 0.973036 0.65 1.05357 0.65H12.9463C13.0271 0.65 13.0973 0.670932 13.1564 0.706609L13.1578 0.70745C13.2164 0.742594 13.2667 0.794096 13.3032 0.863535C13.3407 0.935416 13.3534 1.00516 13.3492 1.06874C13.345 1.13245 13.3232 1.20066 13.2755 1.26862C13.2755 1.26872 13.2754 1.26882 13.2753 1.26892L9.0045 7.33779L9.00402 7.33848C8.94618 7.42089 8.90098 7.51067 8.86985 7.6068L8.86979 7.60678L8.8672 7.61518C8.83481 7.71999 8.82355 7.82177 8.82355 7.91441V12.1555C8.82355 12.1724 8.81984 12.1838 8.81525 12.1923C8.81075 12.1999 8.8025 12.2101 8.78506 12.2207L8.34805 12.4857L8.34827 12.4864L5.3042 14.3361C5.30409 14.3362 5.30399 14.3362 5.30388 14.3363C5.28736 14.3462 5.27261 14.3497 5.25901 14.35ZM5.27154 14.9999C5.14389 15.0023 5.01635 14.9707 4.90099 14.9061L5.27154 14.9999Z" stroke="#E81C2E" strokeWidth="1.3"></path>
                                        </svg>
                                        </button>
                                    </div>
                                </div>
                                {/* : ""
                                } */}
                                <div className='row'>
                                    {
                                        !isLoadingCategory && dataCategory ?
                                            dataCategory.data.length > 0 ?
                                                dataCategory.data.map((itm, _idx) => {
                                                    return (<Itemcards data={itm} key={itm.item_id} />);
                                                })
                                                : <>
                                                    <div className='container m-0 text-center'>
                                                        <h2>No Product Found</h2>
                                                        <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                                        <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                                                    </div>
                                                </>

                                            :
                                            Array.from({ length: 28 }).map((_, idx) => (
                                                <div className="col-lg-3 col-6" key={idx}>
                                                    <a href="#" title="view details">
                                                    </a>
                                                    <div className="Drivetrain-cart"><a href="">
                                                        <div className="engle-imge-upper">
                                                            {/* <button className="hover-btn">View Details</button> */}
                                                            <ShimmerThumbnail height={163} rounded />
                                                        </div>
                                                    </a>
                                                        <div className="cart-body">
                                                            <a href="#" title="product name">
                                                                <h4><ShimmerButton size="sm" /></h4>
                                                                <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                                                            </a>
                                                            <div className="price-cart">
                                                                <a href="#" title="product image">
                                                                    {/* <div className="price">
                                                                        {
                                                                        itm && itm.cost ?
                                                                            <h5 className="m-0">${itm.cost}</h5>
                                                                            :
                                                                            <h5 className="m-0">N.A</h5>
                                                                    } 

                                                                    </div> */}
                                                                </a>
                                                                <div className="cart-btn">
                                                                    <a href="#" title="cart button">
                                                                    </a>
                                                                    <a href="#" title="cart link">

                                                                        {/* <img src={data.data.brand_logo} alt="img" height="100%" width="100%" /> */}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))

                                    }


                                </div>
                                {
                                    totalPages > 0 ?

                                        <div className="d-flex mt-4 justify-content-center">
                                            <Pagination>
                                                <Pagination.First
                                                    disabled={currentPage === 1}
                                                    onClick={() => handlePageChange(1)}
                                                />
                                                <Pagination.Prev
                                                    disabled={currentPage === 1}
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                />

                                                {/* Render page numbers */}
                                                {getPageNumbers().map((page) => (
                                                    <Pagination.Item
                                                        key={page}
                                                        active={currentPage === page}
                                                        onClick={() => handlePageChange(page)}
                                                    >
                                                        {page}
                                                    </Pagination.Item>
                                                ))}

                                                <Pagination.Next
                                                    disabled={currentPage === totalPages}
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                />
                                                <Pagination.Last
                                                    disabled={currentPage === totalPages}
                                                    onClick={() => handlePageChange(totalPages)}
                                                />
                                            </Pagination>
                                        </div>
                                        : ""
                                }


                            </div>

                        </div>
                    </div>
                </div>
            </section >
            {/* <NEWSLETTER /> */}
            < FOOTER />
        </>

    )
}
