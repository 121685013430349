import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";

const CustomErrorScreen = () => {
    const [isHidden, setIsHidden] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("")

    const [cartItems, setCartItems] = useState("");
    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');

        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
    }, []);




    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);


    const showLoaderLine = (id) => {
        if (id === 1) {
            setIsHidden(false);
        } else {
            setIsHidden(true);
        }

    };

    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }


    return (
        <>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <div className="error-screen">
                <h1>Something went wrong!</h1>
                <p>Please try again in a few minutes.</p>
                <a href='/' className="btn btn-sm btn-danger">Go back to homepage</a>
            </div>
            <FOOTER />
        </>

    );
};

export default CustomErrorScreen;
