import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Mousewheel } from "swiper";
import "./styles.css";
import "swiper/css";
import "swiper/css/navigation";


export default function Slider(data) {
  console.log("data",data)

  const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
  const sliderImages =  data.data[0].s3_images;
  

  let imageArray = [];
  let otherFiles = [];
  otherFiles.push(sliderImages);



  const [imagesNavSlider, setImagesNavSlider] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  return (
    <>
      {imageArray ?
        <section className="slider">
          <div className="slider__flex">
            <div className="slider__col">

              <div className="slider__thumbs">
                {/* <div className="slider__prev"><i className="fa fa-angle-up"></i></div> */}
                <div className="swiper-container">
                  <Swiper
                    onSwiper={setImagesNavSlider}
                    direction="vertical"
                    spaceBetween={5}
                    slidesPerView={5}
                    // navigation={{
                    //   nextEl: ".slider__next",
                    //   prevEl: ".slider__prev"
                    // }}
                    className="swiper-container1"
                    breakpoints={{
                      0: {
                        direction: "horizontal"
                      },
                      768: {
                        direction: "vertical"
                      }
                    }}
                    modules={[Navigation, Thumbs]}
                  >
                    {otherFiles.map((slide, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="slider__image" onMouseEnter={() => setActiveImageIndex(index)}>

                            <img src={slide} alt="prodImg" height="100%" width="100%" />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  {/* <div className="slider__next"><i className="fa fa-angle-down"></i></div> */}
                </div>
              </div>


            </div>
            <div class="slider__images">
              <div class="swiper-container bigimagelsider">
                <Swiper
                  thumbs={{ swiper: imagesNavSlider && !imagesNavSlider.destroyed ? imagesNavSlider : null }}
                  direction="horizontal"
                  slidesPerView={1}
                  spaceBetween={15}
                  mousewheel={false}
                  navigation={{
                    nextEl: ".slider__next",
                    prevEl: ".slider__prev"
                  }}
                  breakpoints={{
                    0: {
                      direction: "horizontal"
                    },
                    768: {
                      direction: "horizontal"
                    }
                  }}
                  className="swiper-container2"
                  modules={[Navigation, Thumbs, Mousewheel]}
                  onSlideChange={(swiper) => setActiveImageIndex(swiper.activeIndex)}
                >
                  {otherFiles.map((slide, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="slider__image">
                          <img src={slide} alt="prodImg" height="100%" width="100%" />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>

              </div>
            </div>
          </div>
        </section>
        :
        "No Image Found !"}
    </>

  );
}
