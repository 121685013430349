import { React, useState, useEffect } from 'react';

import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import SERVICES from "../../components/Footer/services";
import { Helmet } from 'react-helmet';



export default function Aboutus() {

    const currentLink = window.location.href;
    const url = new URL(currentLink);
    const updatedUrl = url.toString();

    const [isHidden, setIsHidden] = useState(false);
    const [cartItems, setCartItems] = useState("");
    const [showMenuSide, setShowMenuSide] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);

    }, []);


    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    return (
        <>

            <Helmet>
                <title>About us</title>
                <meta name="description" content='OVER 30 YEARS OF COMBINED EXPERIENCE & EXPERTISE' />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />

            <section>
                <div className="warrent-banner about_us">
                    <div className="parformane-bar ">
                        <div className="container">
                            <ul className="p-0 m-0 listing-parmofamne">
                                <li>
                                    <a href="#">Home /</a>
                                </li>
                                <li className="active-bread">
                                    <a href="">About Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/*<h1 className="text-center txt-main-listing">About Us</h1>*/}
                </div>
            </section>

            <section>
                <div className='about-us-content'>
                    <div className='banner_image'>
                        <img src="/images/Extreme-Wheels-Shop-Store-Front-IMG.jpg" alt="banner-image" height="100%" width="100%" />
                    </div>
                    <div className='container'>
                        <div className='content_first'>
                            <h2>OVER 30 YEARS OF COMBINED EXPERIENCE & EXPERTISE!</h2>
                            <p>Thank you for giving us the opportunity to assist you in customizing your vehicle. We pledge to provide you with uncompromising industry knowledge and excellent customer service.</p>
                            <p>ExtremeWheels.com provides the biggest choice of custom rims at the most affordable price possible. We’ll beat any lower advertised price! In our effort to be the largest rim distributor, we are constantly adding new wheel brands.</p>
                        </div>
                        <div className='content_second'>
                            <h3>Our Commitment To You</h3>
                            <ul>
                                <li><strong>Low Prices –</strong> Extreme Performance is able to offer you the best price on wheels because of our long standing relationships with manufacturers from around the world. Compare us to our competitors, and you be the judge.</li>
                                <li><strong>Free Shipping –</strong> Extreme Performance provides free shipping on all of our wheels and wheel/tire packages within the continental U.S. For shipping outside the lower 48 states, contact us for a shipping quote.</li>
                                <li><strong>Customer Service –</strong> Extreme Performance is committed to making your purchasing experience as smooth as possible. If you have any questions after your purchase, feel free to contact us, and we will be happy to provide you with follow-up service.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            <SERVICES />
            < FOOTER />
        </>

    )
}
