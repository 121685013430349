import { React, useState, useEffect } from 'react';
import Slider from '../../../components/Slider/custome_product';
import DetaileSection from '../../../components/DetailSection/custome_product';
import axios from 'axios';
import { ShimmerSocialPost, ShimmerThumbnail } from "react-shimmer-effects";
import { Helmet } from 'react-helmet';

export default function Details(prop) {
  const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
  const currentLink = window.location.href;
  const { item_id, getCartData } = prop;



  const setdata = (value) => {
    getCartData(value)
  }

  return (
    <>
      <Helmet>
        <title>Custom Products</title>
        <meta name="description" content="Custom Products" />
        <link rel="canonical" href={currentLink} />
      </Helmet>
      <section>
        <div className="parformane-bar second-bar-p">
          <div className="container">
            <ul className="p-0 m-0 listing-parmofamne">
              <li><a href="/">Home /</a></li>
              <li><a href="/products/uncategorized/custom_products">Custom Products /</a></li>
              <li className='active-bread text-decoration-none'><a href="#">{item_id[0].product_name}</a></li>
            </ul>
          </div>
        </div>
      </section>
      <section className="slider-pernt">
        <div className='container'>
          <div className="row">
            <div className="col-lg-6">
              <Slider data={item_id} />
            </div>
            <div className="col-lg-6">
              <DetaileSection data={item_id} setdata={setdata} />
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
