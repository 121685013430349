import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import { ShimmerButton, ShimmerThumbnail, ShimmerCategoryItem } from "react-shimmer-effects";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';




export default function BrandCategory() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const { brand_name, brand_id } = useParams();
    const [isHidden, setIsHidden] = useState(false);
    const [isDescription, setdescription] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [catgoryData, setCatgegoryData] = useState(true);
    const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
    const [loader, setLoading] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const currentLink = window.location.href;
    const url = new URL(currentLink);

    // Updated URL without query parameters
    const updatedUrl = url.toString();

    const [selectedYear, setSelectedYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [selectedMake, setSelectedMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [selectedModel, setSelectedModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [selectedVariant, setSelectedVariant] = useState(vehicalDetails ? vehicalDetails.variant : "");
    const [isdata, setIsdata] = useState(true);

    const rootCategories = [
        { id: 1, name: 'Interior' },
        { id: 2, name: 'Exterior' },
        { id: 3, name: 'Performance' },
        { id: 4, name: 'Lighting' },
        { id: 5, name: 'Wheels & Tires' },
        { id: 6, name: 'Parts' },
        { id: 7, name: 'Body Parts' },
        { id: 8, name: 'Audio & Electronics' },
        { id: 9, name: 'Automotive Tools' },

    ];
    const [cartItems, setCartItems] = useState("");


    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');

        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
    }, []);






    useEffect(() => {
        fetchCategoriesData();
    }, []);

    const fetchCategoriesData = async () => {
        try {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/getAllBrandCategoriesWithorWithoutVehical?brandid=${brand_id}&year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`,
            };
            axios.request(config)
                .then((response) => {
                    setCategoryData(response.data);
                    if (response.data.status == 500) {
                        setIsdata(false);

                    }
                    if(response.data.data.length > 0){
                    const categoryNames = [];
                    response.data.data.forEach(section => {
                        const sectionId = Object.keys(section)[0];
                        const sectionData = section[sectionId];

                        sectionData.forEach(item => {
                            categoryNames.push(item.category_name);
                        });
                    });

                    // Create description based on category names
                    let description = `Unlock the power of performance with our premier parts from ${brand_name.replace(/-/g, ' ')} dealing in `;
                    if (categoryNames.length === 1) {
                        description += categoryNames[0];
                    } else if (categoryNames.length === 2) {
                        description += categoryNames.join(" and ");
                    } else {
                        description += categoryNames.slice(0, -1).join(", ") + " and " + categoryNames.slice(-1);
                    }

                    setdescription(description);
                    }
                    if (brand_id && brand_name) {
                        let isBrandFound = false;


                        for (let i = 0; i < response.data.brandTableData.length; i++) {

                            if (response.data.brandTableData[i].name == brand_name.replace(/-/g, ' ') && response.data.brandTableData[i].brand_id == brand_id) {

                                isBrandFound = true;
                                break;
                            }



                        }

                        if (!isBrandFound) {
                            setIsdata(false);
                        }
                    }
                    setLoading(true);
                    setIsHidden(true)


                })
                .catch((error) => {
                    setIsdata(false);

                    setLoading(true);
                    // navigate(`/error`);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(true);
            navigate(`/error`);

        }
    }


    const onSubmit = (id) => {
        setTimeout(() => {
            navigate(`/brands/brands-products/${id}/${brand_id}/${brand_name}`);
        }, 1000);
    }

    const onRedirectHome = () => {
        localStorage.removeItem('vehical_details');
        setTimeout(() => {
            navigate('/');

        }, 1000);
    }
    const goBackToHomepage = () => {
        navigate('/');
    };
    return (
        <>
            {isdata ? <Helmet>
                <title>{brand_name.replace(/-/g, ' ')}</title>
                <meta name="description" content={isDescription} />
                <link rel="canonical" href={updatedUrl} />
            </Helmet> :
                <Helmet>
                    <title>{brand_name ? brand_name.replace(/-/g, ' ') : "Brand"}</title>
                </Helmet>}
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR cartItemsdata={cartItems} />
            <MENUBAR />
            {isdata ? (
                <>
                    <section>
                        <div className="main-inner-banner">
                            <div className="parformane-bar">
                                <div className="container d-flex justify-content-between">
                                    <div>
                                        {/* <a href="/" className='text-decoration-none text-light'>Home</a>
                                <a href="#" className='text-decoration-none text-light'> /{brandName}</a> */}
                                        <ul className="p-0 m-0 listing-parmofamne">
                                            <li>
                                                <a href="/">Home /</a>
                                            </li>
                                            <li>
                                                <a href="/brands">Brands /</a>
                                            </li>
                                            <li className="active-bread">
                                                <a href="#" className='text-decoration-none text-light'>{brand_name.replace(/-/g, ' ')}</a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            {/* <div className="five_percent_products"><img src='../icons/homepage_discount.png' /></div> */}
                            <h2 className="text-center txt-main-listing">{brand_name.replace(/-/g, ' ')}</h2>
                        </div>

                    </section>

                    <section>
                        <div className='container mt-3'>
                            {brand_name.replace(/-/g, ' ') ? <p>
                                <strong style={{ borderBottom: '2px solid black', fontSize: '30px' }}>
                                    {brand_name.replace(/-/g, ' ')}
                                </strong>
                            </p> : ""}
                        </div>
                        {
                            loader && categoryData ?
                                categoryData.data.length > 0 ?
                                    categoryData.data.map((section, index) => {
                                        const sectionId = Object.keys(section)[0];
                                        const sectionData = section[sectionId];
                                        const rootCategory = rootCategories.find(category => category.id == sectionId);
                                        return (
                                            <section id={`section-${sectionId}`} key={sectionId} className='mt-10 '>
                                                <div className='mt-5 category-main-section'>
                                                    <div className='container mt-5'>
                                                        <div className='row mt-4'>
                                                            <h3 className=''>{rootCategory ? rootCategory.name : '  '}</h3>
                                                            {sectionData.map((item, itemIndex) => (
                                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4" key={itemIndex} onClick={() => onSubmit(item.category_id)}>
                                                                    <div className="card">
                                                                        <div className="row g-0 p-3">
                                                                            <div className="col-md-4">
                                                                                <img src={`/category/${item.image}`} alt="img" className="img-fluid" height="100%" width="100%" />
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <div className="card-body">
                                                                                    <h4 className="card-title">{item.category_name} ({parseInt(item.count).toLocaleString('en-US')})</h4>
                                                                                    <span className='text-danger cursor_pointer' onClick={() => onSubmit(item.category_id)}><u>View Products</u></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        );
                                    })
                                    : <>
                                        <div className='container text-center mt-5'>
                                            <h2>No Product Found</h2>
                                            <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                            <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                                        </div>

                                    </>


                                :
                                <section className='mt-10'>
                                    <div className='mt-5'>
                                        <div className='container mt-5'>
                                            <div className='row'>
                                                <h2 className=''><ShimmerButton size="sm" /></h2>
                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                                    <div className="card">
                                                        <div className="row g-0 p-3">
                                                            <div className="col-md-4">
                                                                <ShimmerThumbnail height={80} rounded />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <h4 className="card-title"><ShimmerButton size="sm" /></h4>
                                                                    <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                                    <div className="card">
                                                        <div className="row g-0 p-3">
                                                            <div className="col-md-4">
                                                                <ShimmerThumbnail height={80} rounded />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <h4 className="card-title"><ShimmerButton size="sm" /></h4>
                                                                    <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                                    <div className="card">
                                                        <div className="row g-0 p-3">
                                                            <div className="col-md-4">
                                                                <ShimmerThumbnail height={80} rounded />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <h4 className="card-title"><ShimmerButton size="sm" /></h4>
                                                                    <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                                    <div className="card">
                                                        <div className="row g-0 p-3">
                                                            <div className="col-md-4">
                                                                <ShimmerThumbnail height={80} rounded />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <h4 className="card-title"><ShimmerButton size="sm" /></h4>
                                                                    <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                                    <div className="card">
                                                        <div className="row g-0 p-3">
                                                            <div className="col-md-4">
                                                                <ShimmerThumbnail height={80} rounded />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <h4 className="card-title"><ShimmerButton size="sm" /></h4>
                                                                    <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                                    <div className="card">
                                                        <div className="row g-0 p-3">
                                                            <div className="col-md-4">
                                                                <ShimmerThumbnail height={80} rounded />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <h4 className="card-title"><ShimmerButton size="sm" /></h4>
                                                                    <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                                    <div className="card">
                                                        <div className="row g-0 p-3">
                                                            <div className="col-md-4">
                                                                <ShimmerThumbnail height={80} rounded />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <h4 className="card-title"><ShimmerButton size="sm" /></h4>
                                                                    <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                                                    <div className="card">
                                                        <div className="row g-0 p-3">
                                                            <div className="col-md-4">
                                                                <ShimmerThumbnail height={80} rounded />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <h4 className="card-title"><ShimmerButton size="sm" /></h4>
                                                                    <span className='text-danger cursor_pointer'><u><ShimmerButton size="sm" /></u></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                        }
                    </section>
                </>

            )
                : (<div className="error-screen">
                    
                    <h2>Product not found</h2>
                    <button className="btn btn-sm btn-danger" onClick={goBackToHomepage}>
                        Go back to homepage
                    </button>
                </div>)}
            {/* <NEWSLETTER /> */}
            < FOOTER />
        </>

    )
}
