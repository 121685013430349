import { React, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import Itemcards from "../../components/Cards/itemscard";
import Pagination from 'react-bootstrap/Pagination';
import { ShimmerButton, ShimmerThumbnail, ShimmerCategoryItem } from "react-shimmer-effects";
import "rsuite/dist/rsuite.css";
import axios from 'axios';
import Collapse from 'react-bootstrap/Collapse';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Helmet } from 'react-helmet';





export default function Products() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const brandid = queryParams.get('brandid');
    const brandname = queryParams.get('brandname');
    const categoryFomUrl = queryParams.get('category');
    const MAX_PAGES_DISPLAY = 10;
    const PRODUCT_PER_PAGE = 28;
    const [cartItems, setCartItems] = useState("");
    const [isHidden, setIsHidden] = useState(false);
    const currentLink = window.location.href;
    const url = new URL(currentLink);
    // Remove all query parameters
    url.search = '';
    // Updated URL without query parameters
    const updatedUrl = url.toString();
    const navigate = useNavigate();
    const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));
    const { id } = useParams();
    const [dataCategory, setDataCategory] = useState([]);
    const [isLoadingCategory, setIsLoadingCategory] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setoffsetCurrentPage] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    // const [subcategories, setsubcategories] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);


    const [categoryName, setCategoryName] = useState('');

    const [dataBradnAndCategory, setDataBradnAndCategory] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);



    const [selectedYear, setSelectedYear] = useState(vehicalDetails ? vehicalDetails.year : "");
    const [selectedMake, setSelectedMake] = useState(vehicalDetails ? vehicalDetails.make : "");
    const [selectedModel, setSelectedModel] = useState(vehicalDetails ? vehicalDetails.model : "");
    const [selectedVariant, setSelectedVariant] = useState(vehicalDetails ? vehicalDetails.variant : "");




    const [sortingValue, setSortingValue] = useState(1);



    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoriesName, setSelectedCategoriesName] = useState([]);
    const [selectedBrandsName, setSelectedBrandsName] = useState([]);


    const [firstValue, setFirstValue] = useState(0);
    const [secondValue, setSecondValue] = useState(100000);

    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();


    const [maxPriceDefault, setMaxPriceDefault] = useState(null);
    const [minPriceDefault, setMinPriceDefault] = useState(null);

    const [showWholwPageLoader, setShowWholwPageLoader] = useState(false);

    const [open, setOpen] = useState(true);
    const [openBrand, setOpenBrand] = useState(false);
    const [openPrice, setOpenPrice] = useState(false);

    const [stockWheel, setStockWheel] = useState([]);


    const [filterClass, setFilterClass] = useState('');




    const [showError, setShowError] = useState(false)
    const [openStock, setOpenStock] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("")

    const [isChecked, setIsChecked] = useState(true);
    const [isdata, setIsdata] = useState(true);


    const [isdataOnClick, setIsdataOnClick] = useState(true);



    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }

        if (brandid) {
            setSelectedBrands([...selectedBrands, brandid]);
        }

        if (brandname) {
            const categoryObject = { id: brandid, name: brandname };
            setSelectedBrandsName([...selectedBrandsName, categoryObject])
        }

        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
        fetchBrandAndCatData();
    }, []);

    useEffect(() => {
        const storedProductPage = localStorage.getItem('inProductPage');
        const ProductPage = JSON.parse(storedProductPage);
        if (ProductPage) {
            const storedCategories = localStorage.getItem('selectedCategories');
            const storedCategoriesName = localStorage.getItem('selectedCategoriesName');
            const storedBrands = localStorage.getItem('selectedBrands');
            const storedBrandsName = localStorage.getItem('selectedBrandsName');
            const storedMaxprice = localStorage.getItem('setMaxPrice');
            const storedMinprice = localStorage.getItem('setMinPrice');
            const storedStockWheel = localStorage.getItem('setStockWheel');
            const storedCurrentPage = localStorage.getItem('setCurrentPage');
            const storedoffsetCurrentPage = localStorage.getItem('setoffsetCurrentPage');
            const parsedCategories = JSON.parse(storedCategories);
            const parsedCategoriesName = JSON.parse(storedCategoriesName);
            const parsedBrands = JSON.parse(storedBrands);
            const parsedBrandsName = JSON.parse(storedBrandsName);
            const parsedMaxprice = JSON.parse(storedMaxprice);
            const parsedMinprice = JSON.parse(storedMinprice);
            const parsedCurrentPage = JSON.parse(storedCurrentPage);
            const parsedoffsetCurrentPage = JSON.parse(storedoffsetCurrentPage);
            const parsedStockWheel = JSON.parse(storedStockWheel);

            // setIsdataOnClick(prevState => !prevState);


            if (parsedCurrentPage != null || parsedoffsetCurrentPage != null || parsedCategories != null || parsedCategoriesName != null || parsedBrands != null || parsedBrandsName != null || parsedMaxprice != null || parsedMinprice != null || parsedStockWheel != null) {
                localStorage.removeItem('inProductPage');
                setIsdataOnClick(prevState => !prevState);
                if (selectedCategories.length > 0 || selectedBrands.length > 0) {
                    fetchProductData();
                } else {
                    return;
                }
            } else {
                fetchProductData();
            }
        } else {
            fetchProductData();
        }

    }, [isdataOnClick]);

    //fetch product items 
    const fetchProductData = async () => {
        setShowWholwPageLoader(true)
        setIsLoadingCategory(true);
        const categoriesString = selectedCategories.join(',');
        const brandString = selectedBrands.join(',');
        const limit = 28; // Define the limit per page    

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/category/${id}?limit=${limit}&offset=${offset}&sorting=${sortingValue}&subcategories=${categoriesString}&brands=${brandString}&startingPrice=${firstValue}&endPrice=${secondValue}&year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}&stockWheel=${stockWheel}&page=${currentPage}&isChecked=${isChecked}`,
            };
            axios.request(config)
                .then((response) => {
                    setDataCategory(response.data);
                    if (response.data.data.status == 500) {
                        setIsdata(false);
                    }
                    setIsLoadingCategory(false);
                    setTotalPages(response.data.totalPages);
                    setTotalProducts(response.data.count);
                    setCategoryName(response.data.data ? response.data.data[0].category_name : "");

                    setShowWholwPageLoader(false)
                    window.scrollTo({ top: 400, behavior: 'smooth' });
                    const storedMaxprice = localStorage.getItem('setMaxPrice');
                    const storedMinprice = localStorage.getItem('setMinPrice');
                    if (storedMaxprice <= 0) {
                        setMaxPrice(Math.ceil(response.data.maximumPrice));
                    } else {
                        setOpenPrice(true);
                    }
                    if (storedMinprice <= 0) {
                        setMinPrice(Math.floor(response.data.minimumPrice));
                    } else {
                        setOpenPrice(true);
                    }
                    setMaxPriceDefault((Math.ceil(response.data.maxDefaultPrice)) + 1);
                    document.body.classList.remove('full-screen-loader');

                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsdata(false);
            setIsLoadingCategory(false);
            setShowWholwPageLoader(false)
            document.body.classList.remove('full-screen-loader');


        }
    };


    //fetch brand  
    const fetchBrandAndCatData = async () => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/getSubCatBrandFromCat/${id}?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`,
            };
            axios.request(config)
                .then((response) => {
                    setDataBradnAndCategory(response.data);
                    if (response.data.status == 500) {
                        setIsdata(false);
                    }
                    setIsInitialLoad(false);
                })
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsInitialLoad(false);
        }
    };



    const handleSorting = (event) => {
        const sortingValue = event.target.value;
        setSortingValue(sortingValue);
        localStorage.setItem('setSortingValue', JSON.stringify(sortingValue));
        setIsdataOnClick(prevState => !prevState);


    };


    const handlePageChange = (page) => {
        const limit = 28;
        const offsets = (page - 1) * limit; // Calculate the offset based on the current page and limit

        setCurrentPage(page);
        setoffsetCurrentPage(offsets);
        document.body.classList.add('full-screen-loader');
        localStorage.setItem('setCurrentPage', JSON.stringify(page));
        localStorage.setItem('setoffsetCurrentPage', JSON.stringify(offsets));
        setIsdataOnClick(prevState => !prevState);


    };

    const getPageNumbers = () => {
        const totalDisplayPages = Math.min(totalPages, MAX_PAGES_DISPLAY);
        const middlePage = Math.ceil(totalDisplayPages / 2);
        let startPage = Math.max(1, currentPage - middlePage + 1);

        // Ensure the start page does not exceed the available pages
        if (startPage + totalDisplayPages > totalPages) {
            startPage = Math.max(1, totalPages - totalDisplayPages + 1);
        }

        return Array.from({ length: totalDisplayPages }, (_, index) => startPage + index);
    };



    const handleBrandCheckboxChange = (event) => {

        setCurrentPage(1);
        setoffsetCurrentPage(0);
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        setIsHidden(true);

        const brandId = event.target.value;
        const brandIdName = event.target.name;

        if (selectedBrands.includes(brandId)) {
            const updatedBrand = selectedBrands.filter((id) => id !== brandId);
            setSelectedBrands(updatedBrand);
            localStorage.setItem('selectedBrands', JSON.stringify(updatedBrand));
        } else {
            const updatedBrand = [...selectedBrands, brandId];
            setSelectedBrands(updatedBrand);
            localStorage.setItem('selectedBrands', JSON.stringify(updatedBrand));
        }
        // Check if the category object is already in the selectedCategoriesName array
        const categoryObject = { id: brandId, name: brandIdName };
        const categoryObjectIndex = selectedBrandsName.findIndex((obj) => obj.id === brandId);

        if (categoryObjectIndex !== -1) {
            const updatedBrandsName = selectedBrandsName.filter((obj) => obj.id !== brandId);
            setSelectedBrandsName(updatedBrandsName);
            localStorage.setItem('selectedBrandsName', JSON.stringify(updatedBrandsName));
        } else {
            const updatedBrandsName = [...selectedBrandsName, categoryObject];
            setSelectedBrandsName(updatedBrandsName);
            localStorage.setItem('selectedBrandsName', JSON.stringify(updatedBrandsName));
        }


    };

    const handleCategoryCheckboxChange = (event) => {
        setCurrentPage(1);
        setoffsetCurrentPage(0);
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        setIsHidden(true);

        const categoryId = event.target.value;
        const categoryName = event.target.name;

        if (selectedCategories.includes(categoryId)) {
            const updatedCategories = selectedCategories.filter((id) => id !== categoryId);
            setSelectedCategories(updatedCategories);
            localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
        } else {
            const updatedCategories = [...selectedCategories, categoryId];
            setSelectedCategories(updatedCategories);
            localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
        }

        const categoryObject = { id: categoryId, name: categoryName };
        const categoryObjectIndex = selectedCategoriesName.findIndex((obj) => obj.id === categoryId);

        if (categoryObjectIndex !== -1) {
            const updatedCategoriesName = selectedCategoriesName.filter((obj) => obj.id !== categoryId);
            setSelectedCategoriesName(updatedCategoriesName);
            localStorage.setItem('selectedCategoriesName', JSON.stringify(updatedCategoriesName));
        } else {
            const updatedCategoriesName = [...selectedCategoriesName, categoryObject];
            setSelectedCategoriesName(updatedCategoriesName);
            localStorage.setItem('selectedCategoriesName', JSON.stringify(updatedCategoriesName));
        }
    };

    useEffect(() => {
        const storedCategories = localStorage.getItem('selectedCategories');
        const storedCategoriesName = localStorage.getItem('selectedCategoriesName');
        const storedBrands = localStorage.getItem('selectedBrands');
        const storedBrandsName = localStorage.getItem('selectedBrandsName');
        const storedMaxprice = localStorage.getItem('setMaxPrice');
        const storedMinprice = localStorage.getItem('setMinPrice');
        const storedFirstValue = localStorage.getItem('setFirstValue');
        const storedSecondValue = localStorage.getItem('setSecondValue');
        const storedStockWheel = localStorage.getItem('setStockWheel');
        const storedIsChecked = localStorage.getItem('setIsChecked');
        const storedSortingValue = localStorage.getItem('setSortingValue');
        const storedCurrentPage = localStorage.getItem('setCurrentPage');
        const storedoffsetCurrentPage = localStorage.getItem('setoffsetCurrentPage');

        if (storedCurrentPage) {
            const parsedCurrentPage = JSON.parse(storedCurrentPage);
            setCurrentPage(parsedCurrentPage);
            setoffsetCurrentPage(parsedCurrentPage);

        }

        if (storedoffsetCurrentPage) {
            const parsedCurrentPage = JSON.parse(storedoffsetCurrentPage);

            setoffsetCurrentPage(parsedCurrentPage);

        }
        if (storedIsChecked) {
            const parsedStockWheel = JSON.parse(storedIsChecked);

            setIsChecked(parsedStockWheel);

        }

        if (storedSortingValue) {
            const parsedSortingValue = JSON.parse(storedSortingValue);

            setSortingValue(parsedSortingValue);

        }
        if (storedStockWheel) {
            const parsedStockWheel = JSON.parse(storedStockWheel);
            setStockWheel(parsedStockWheel);
            if (parsedStockWheel.length > 0) {
                setOpenStock(true);

            }
        }
        if (storedCategories) {
            const parsedCategories = JSON.parse(storedCategories);
            setSelectedCategories(parsedCategories);
        }

        if (storedCategoriesName) {
            const parsedCategoriesName = JSON.parse(storedCategoriesName);
            setSelectedCategoriesName(parsedCategoriesName);
        }
        if (storedBrands) {
            const parsedBrands = JSON.parse(storedBrands);
            setSelectedBrands(parsedBrands);
            if (parsedBrands.length > 0) {
                setOpenBrand(true);
            }

        }
        if (storedBrandsName) {
            const parsedBrandsName = JSON.parse(storedBrandsName);
            setSelectedBrandsName(parsedBrandsName);
            if (parsedBrandsName.length > 0) {
                setOpenBrand(true);
            }

        }
        if (storedMaxprice) {
            const parsedMaxprice = JSON.parse(storedMaxprice);
            setMaxPrice(parsedMaxprice);

        }
        if (storedMinprice) {
            const parsedMinprice = JSON.parse(storedMinprice);
            setMinPrice(parsedMinprice);

        }
        if (storedFirstValue) {
            const parsedFirstValue = JSON.parse(storedFirstValue);
            setFirstValue(parsedFirstValue);

        }
        if (storedSecondValue) {
            const parsedSecondValue = JSON.parse(storedSecondValue);
            setSecondValue(parsedSecondValue);

        }


    }, []);


    const handleRemoveCategory = (categoryName) => {
        setCurrentPage(1);
        setoffsetCurrentPage(0);
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');

        const updatedCategories = selectedCategories.filter((category) => category !== categoryName);
        setSelectedCategories(updatedCategories);

        const updatedCategoryNames = selectedCategoriesName.filter((name) => name.id !== categoryName);
        setSelectedCategoriesName(updatedCategoryNames);

        localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
        localStorage.setItem('selectedCategoriesName', JSON.stringify(updatedCategoryNames));
    };

    const handleRemoveBrand = (brandName) => {
        setCurrentPage(1);
        setoffsetCurrentPage(0);
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');


        const updatedBrands = selectedBrands.filter((brand) => brand !== brandName);
        setSelectedBrands(updatedBrands);

        const updatedBrandNames = selectedBrandsName.filter((name) => name.id !== brandName);
        setSelectedBrandsName(updatedBrandNames);
        localStorage.setItem('selectedBrands', JSON.stringify(updatedBrands));
        localStorage.setItem('selectedBrandsName', JSON.stringify(updatedBrandNames));
    };

    const clearAllFilters = () => {
        localStorage.removeItem('selectedCategories');
        localStorage.removeItem('selectedCategoriesName');
        localStorage.removeItem('selectedBrands');
        localStorage.removeItem('selectedBrandsName');
        localStorage.removeItem('setMaxPrice');
        localStorage.removeItem('setMinPrice');
        localStorage.removeItem('setFirstValue');
        localStorage.removeItem('setSecondValue');
        localStorage.removeItem('setStockWheel');
        localStorage.removeItem('setIsChecked');
        localStorage.removeItem('setSortingValue');
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');

        setSelectedBrands([]);
        setSelectedCategories([]);
        setSelectedCategoriesName([]);
        setSelectedBrandsName([]);
        setMaxPrice([]);
        setMinPrice([]);
        setFirstValue([]);
        setSecondValue([]);
        setStockWheel([]);
        setCurrentPage(1);
        setoffsetCurrentPage(0);
        setIsdataOnClick(prevState => !prevState);

    }

    const showFiltersClick = () => {
        setFilterClass('active-filter')
        document.body.classList.add('home-layout')

    }
    const hideFilterClass = () => {
        setFilterClass('')
        document.body.classList.remove('home-layout')
    }

    const handleStockChange = (event) => {

        const selectedStock = event.target.value;
        setStockWheel(selectedStock)
        localStorage.setItem('setStockWheel', JSON.stringify(selectedStock));
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        setCurrentPage(1);
        setoffsetCurrentPage(0);
    }
    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    const startIndex = (currentPage - 1) * PRODUCT_PER_PAGE + 1;
    const endIndex = Math.min(startIndex + PRODUCT_PER_PAGE - 1, totalProducts);

    const optionsAvailbility = [
        { value: "0", label: "ALL" },
        { value: "one", label: "1" },
        { value: "two", label: "2" },
        { value: "three", label: "3" },
        { value: "four", label: "4+" },
    ];


    const handleStaggeredChange = (event) => {
        setIsChecked(!isChecked)
        localStorage.setItem('setIsChecked', JSON.stringify(!isChecked));
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        setCurrentPage(1);
        setoffsetCurrentPage(0);
        setIsdataOnClick(prevState => !prevState);


    }
    const handleChangeSlider = (event, newValue) => {
        localStorage.removeItem('setoffsetCurrentPage');
        localStorage.removeItem('setCurrentPage');
        setCurrentPage(1);
        setoffsetCurrentPage(0);

        setFirstValue(newValue[0]);
        setSecondValue(newValue[1]);
        setMinPrice(newValue[0]);
        setMaxPrice(newValue[1]);
        if (newValue[1]) {

            localStorage.setItem('setMaxPrice', JSON.stringify(newValue[1]));
            localStorage.setItem('setSecondValue', JSON.stringify(newValue[1]));
        }
        if (newValue[0]) {
            localStorage.setItem('setMinPrice', JSON.stringify(newValue[0]));
            localStorage.setItem('setFirstValue', JSON.stringify(newValue[0]));
        }
    };
    const goBackToHomepage = () => {
        navigate('/');
    };


    let products = [
        {
            "category": "Air Filters",
            "description": "Improve engine performance and longevity with Extreme Performance high-flow air filters, providing maximum airflow and filtration efficiency.",
            "id": 25
        },
        {
            "category": "Air Intake Systems",
            "description": "Optimize performance with Extreme Performance high-performance air intake systems, delivering increased horsepower and torque.",
            "id": 26
        },
        {
            "category": "Apparel",
            "description": "Discover stylish, durable apparel at Extreme Performance, designed for comfort and optimal performance in all your outdoor adventures.",
            "id": 6
        },
        {
            "category": "Automotive Tools",
            "description": "Equip your garage with Extreme Performance automotive tools and equipment for DIY maintenance and repairs.",
            "id": 73
        },
        {
            "category": "Bags & Packs",
            "description": "Carry gear in style with Extreme Performance bags and packs for outdoor enthusiasts, offering durability and functionality.",
            "id": 72
        },
        {
            "category": "Batteries, Starting & Charging",
            "description": "Ensure reliable electrical performance with Extreme Performance batteries and charging systems, built to withstand the toughest conditions.",
            "id": 31
        },
        {
            "category": "Body Armor & Protection",
            "description": "Protect your vehicle with Extreme Performance rugged body armor and accessories, designed for extreme durability against off-road hazards.",
            "id": 10
        },
        {
            "category": "Brakes, Rotors & Pads",
            "description": "Upgrade braking performance with Extreme Performance brakes, rotors, and pads, delivering superior stopping power and control.",
            "id": 32
        },
        {
            "category": "Bumpers",
            "description": "Upgrade your vehicle's protection and aesthetics with Extreme Performance bumpers, providing durability and style on and off the road.",
            "id": 48
        },
        {
            "category": "Bumpers, Grilles & Guards",
            "description": "Enhance your vehicle's look and functionality with Extreme Performance bumpers, grilles, and guards, meticulously crafted for performance.",
            "id": 14
        },
        {
            "category": "Controls",
            "description": "Take control of your vehicle's performance with Extreme Performance control systems and accessories for precision and customization.",
            "id": 74
        },
        {
            "category": "Cooling",
            "description": "Keep your engine cool and efficient with Extreme Performance cooling systems, designed for the most demanding conditions.",
            "id": 30
        },
        {
            "category": "Deflectors",
            "description": "Reduce wind noise and improve aerodynamics with Extreme Performance deflectors, offering a sleek and functional addition to your vehicle.",
            "id": 34
        },
        {
            "category": "Drivetrain",
            "description": "Enhance traction and control with Extreme Performance's drivetrain components, engineered for durability and performance on any terrain.",
            "id": 16
        },
        {
            "category": "Engine Components",
            "description": "Explore Extreme Performance engine components to maximize power, efficiency, and durability for your vehicle's engine.",
            "id": 12
        },
        {
            "category": "Exhaust, Mufflers & Tips",
            "description": "Enhance exhaust flow and engine sound with Extreme Performance premium exhaust systems, mufflers, and tips for style and performance.",
            "id": 27
        },
        {
            "category": "Exterior Styling",
            "description": "Make a statement on the road with Extreme Performance exterior accessories, offering aggressive body kits and sleek trim enhancements.",
            "id": 4
        },
        {
            "category": "Fabrication",
            "description": "Bring your custom vehicle projects to life with Extreme Performance precision fabrication services, offering expert craftsmanship.",
            "id": 15
        },
        {
            "category": "Fender Flares & Trim",
            "description": "Customize your vehicle and protect against debris with Extreme Performance durable fender flares and trim accessories.",
            "id": 13
        },
        {
            "category": "Floor Mats",
            "description": "Protect your vehicle's interior with Extreme Performance high-quality floor mats, offering both style and functionality.",
            "id": 1
        },
        {
            "category": "Forced Induction",
            "description": "Unlock horsepower and torque with Extreme Performance forced induction systems, providing exhilarating performance gains.",
            "id": 18
        },
        {
            "category": "Fuel Delivery",
            "description": "Ensure optimal fuel delivery with Extreme Performance fuel system components, designed for reliability and efficiency.",
            "id": 20
        },
        {
            "category": "Gauges & Pods",
            "description": "Monitor your vehicle's vital signs with Extreme Performance precision gauges and pods, offering accurate, real-time data.",
            "id": 22
        },
        {
            "category": "Grille Guards & Bull Bars",
            "description": "Safeguard your vehicle's front end with Extreme Performance grille guards and bull bars, seamlessly blending style and functionality.",
            "id": 49
        },
        {
            "category": "Ignition",
            "description": "Ignite engine performance with Extreme Performance high-performance ignition systems and components for reliable starts and power.",
            "id": 23
        },
        {
            "category": "Interior Accessories",
            "description": "Enhance comfort and functionality with Extreme Performance interior accessories, from organizational solutions to luxury upgrades.",
            "id": 33
        },
        {
            "category": "Lights",
            "description": "Illuminate the path with Extreme Performance high-performance lights, offering superior visibility and style for your vehicle.",
            "id": 11
        },
        {
            "category": "Marketing",
            "description": "Elevate your brand with Extreme Performance's comprehensive marketing solutions, tailored to boost visibility and effectively engage your audience.",
            "id": 28
        },
        {
            "category": "Misc Powersports",
            "description": "Explore Extreme Performance powersports accessories for ATV, UTV, and motorcycle adventures.",
            "id": 71
        },
        {
            "category": "Nerf Bars & Running Boards",
            "description": "Step up vehicle style with Extreme Performance nerf bars and running boards, providing convenient entry and exit.",
            "id": 17
        },
        {
            "category": "Oils & Oil Filters",
            "description": "Ensure optimal engine performance and longevity with Extreme Performance's premium oils and filters, meeting the highest industry standards.",
            "id": 29
        },
        {
            "category": "Programmers & Chips",
            "description": "Unleash your engine's full potential with Extreme Performance programmers and chips, delivering enhanced power and efficiency.",
            "id": 24
        },
        {
            "category": "Roof Racks & Truck Racks",
            "description": "Maximize cargo space with Extreme Performance roof racks and truck racks, ideal for outdoor enthusiasts and professionals alike.",
            "id": 50
        },
        {
            "category": "Roofs & Roof Accessories",
            "description": "Discover Extreme Performance roofs and accessories, designed to withstand the elements and add versatility to your outdoor adventures.",
            "id": 9
        },
        {
            "category": "Safety",
            "description": "Prioritize road safety with Extreme Performance's advanced safety equipment and accessories, providing peace of mind for all passengers.",
            "id": 36
        },
        {
            "category": "Scratch & Dent",
            "description": "Find great deals on Extreme Performance scratch and dent items, offering quality automotive accessories with minor cosmetic imperfections.",
            "id": 46
        },
        {
            "category": "Services",
            "description": "Streamline operations and enhance efficiency with Extreme Performance's professional services, ensuring seamless business processes and customer satisfaction.",
            "id": 37
        },
        {
            "category": "Suspension",
            "description": "Achieve comfort and performance with Extreme Performance suspension systems, precision-engineered to meet your vehicle's needs.",
            "id": 21
        },
        {
            "category": "Tires",
            "description": "Experience superior traction and handling with Extreme Performance high-quality tires, engineered for performance and reliability.",
            "id": 35
        },
        {
            "category": "Tonneau Covers",
            "description": "Protect your cargo with Extreme Performance durable tonneau covers, offering versatile, weather-resistant solutions.",
            "id": 7
        },
        {
            "category": "Truck Bed Accessories",
            "description": "Enhance organization with Extreme Performance truck bed accessories, from storage solutions to cargo management systems.",
            "id": 5
        },
        {
            "category": "Truck Bed Liners",
            "description": "Protect your truck bed with Extreme Performance's high-quality bed liners, combining durability with a rugged look.",
            "id": 47
        },
        {
            "category": "Wheel and Tire Accessories",
            "description": "Customize your wheels and tires with Extreme Performance lug nuts, valve stems, and wheel spacers for a personalized look.",
            "id": 8
        },
        {
            "category": "WHEELS",
            "description": "Explore Extreme Performance's wheels crafted for unmatched style and durability, featuring precision engineering and superior materials.",
            "id": 59
        },
        {
            "category": "Wheels",
            "description": "Make a statement with Extreme Performance premium wheels, available in styles and finishes to suit your vehicle's needs.",
            "id": 19
        },
        {
            "category": "Winches & Hitches",
            "description": "Get out of tough spots with Extreme Performance winches and hitches, engineered for off-road strength and durability.",
            "id": 2
        }
    ]


    // Function to get description based on category name
    function getDescription(id) {
        const product = products.find(product => product.id == id);

        return { category: product.category, description: product.description };
    }

    const description = getDescription(id);


    function onclickFilter() {
        setIsdataOnClick(prevState => !prevState);
    }
    return (
        <>


            <Helmet>
                <title>{description ? description.category : "Accessories"}</title>
                <meta name="description" content={description ? description.description : "We are dedicated to maintaining our position as a leading distributor, specializing in wheels, tires, and a wide array of accessories"} />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>


            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />

            <section>
                <div className="main-inner-banner">
                    <div className="parformane-bar">

                        <div className="container d-flex justify-content-between">
                            <div>

                                <ul className="p-0 m-0 listing-parmofamne">
                                    <li><a href="/">Home /</a></li>
                                    <li className='active-bread text-decoration-none'><a href="#">{description ? description.category : "Accessories"}</a></li>
                                </ul>

                            </div>

                        </div>
                    </div>
                    {/* <div className="five_percent_products"><img src='../icons/homepage_discount.png' /></div> */}
                    <h2 className="text-center txt-main-listing">{description ? description.category : "Accessories"}</h2>


                </div>

            </section>

            <section>
                <div className="main-section-lsting">
                    <div className="container">
                        <div className='d-flex justify-content-between car-selection-title'>
                            <div>
                                {vehicalDetails ? <strong style={{ fontSize: '30px' }}> {vehicalDetails ? vehicalDetails.year : ""}&nbsp;
                                    {vehicalDetails ? vehicalDetails.make_name : ""}
                                    {vehicalDetails ? vehicalDetails.model_name : ""}&nbsp;
                                    {vehicalDetails ? vehicalDetails.variant_name : ""}</strong> : ""}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3">
                                <div className={`main-outer-filter ${filterClass}`}>
                                    <div className="filter-crose" id="close-bar">

                                        {/* <button className="reset" onClick={clearAllFilters}>
                                            Reset
                                        </button>

                                        <button className="submit" onClick={hideFilterClass}>
                                            Search
                                        </button> */}


                                        <button className="abc" onClick={hideFilterClass}>
                                            <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                                        </button>
                                    </div>


                                    <div className="price-filter filter-button">
                                        <div>
                                            <button className='btn btn-danger' onClick={onclickFilter}>APPLY FILTER</button>
                                        </div>
                                    </div>

                                    <div className="price-filter">
                                        <h3 className="price-filter-text" role="button" onClick={() => setOpen(!open)} aria-expanded={open} aria-controls="example-collapse-text">
                                            Category {dataBradnAndCategory.uniqueSubcategories ? "(" + dataBradnAndCategory.uniqueSubcategories.length + ")" : " "}
                                            <i
                                                className={open ? "fa fa-minus" : "fa fa-plus"}


                                            >

                                            </i>
                                        </h3>
                                        <Collapse in={open} >
                                            <ul className={`p-0 m-0 list-filter`} id="example-collapse-text">
                                                {
                                                    !isInitialLoad && dataBradnAndCategory ?
                                                        dataBradnAndCategory.uniqueSubcategories.map((item, index) => (
                                                            <li key={item.sub_category_id}>
                                                                <label className="label-filter">
                                                                    <input
                                                                        className="filter-box"
                                                                        type="checkbox"
                                                                        value={item.sub_category_id}
                                                                        name={item.sub_category_name}
                                                                        checked={selectedCategories.includes(item.sub_category_id)}
                                                                        onChange={handleCategoryCheckboxChange}
                                                                        id={item.sub_category_id + "_m"}

                                                                    />
                                                                    {item.sub_category_name}
                                                                    <label htmlFor={item.sub_category_id + "_m"} className='custome-checkboxes'></label>
                                                                </label>
                                                            </li>
                                                        ))
                                                        : <ShimmerCategoryItem />

                                                }
                                            </ul>

                                        </Collapse >
                                    </div>
                                    <div className="price-filter">
                                        <h3 className="price-filter-text" role="button" onClick={() => setOpenBrand(!openBrand)} aria-expanded={openBrand} aria-controls="brand-collapse-text">
                                            Brand {dataBradnAndCategory.uniqueBrands ? "(" + dataBradnAndCategory.uniqueBrands.length + ")" : " "}
                                            <i
                                                className={openBrand ? "fa fa-minus" : "fa fa-plus"}
                                            >

                                            </i>
                                        </h3>
                                        <Collapse in={openBrand}>
                                            <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                                                {
                                                    !isInitialLoad && dataBradnAndCategory ?
                                                        dataBradnAndCategory.uniqueBrands.map((item, index) => (
                                                            <li key={index}>
                                                                <label className="label-filter">
                                                                    <input className="filter-box"
                                                                        type="checkbox"
                                                                        value={item.brand_id}
                                                                        name={item.brand_name}
                                                                        checked={selectedBrands.includes(String(item.brand_id))}
                                                                        onChange={handleBrandCheckboxChange}
                                                                        id={item.brand_id}
                                                                    />
                                                                    {item.brand_name}
                                                                    <label htmlFor={item.brand_id} className='custome-checkboxes'></label>
                                                                </label>
                                                            </li>
                                                        ))
                                                        : <ShimmerCategoryItem />

                                                }
                                            </ul>
                                        </Collapse>
                                    </div>

                                    <div className="price-filter">
                                        <h3 className="price-filter-text" role="button" onClick={() => setOpenPrice(!openPrice)} aria-expanded={openPrice} aria-controls="price-collapse-text">
                                            Price
                                            <i
                                                className={openPrice ? "fa fa-minus" : "fa fa-plus"}
                                            >

                                            </i>
                                        </h3>
                                        {
                                            !isLoadingCategory && minPrice !== null && maxPrice !== null ?
                                                <Collapse in={openPrice}>
                                                    <div id='price-collapse-text'>
                                                        <form >
                                                            <div className="aside-price-range-form d-flex  m-3" >
                                                                <div className="form-field me-2" style={{ width: '80%' }}>
                                                                    {/* <label htmlFor="filter-price-min">$ Min</label> */}
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={"$" + minPrice}
                                                                        placeholder={"$" + minPrice}
                                                                        autoComplete="off"
                                                                        maxLength="10"
                                                                        style={{ width: '100%' }}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                                <div className="form-field me-2" style={{ width: '80%' }}>
                                                                    {/* <label htmlFor="filter-price-max">$ Max</label> */}
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={"$" + maxPrice}
                                                                        placeholder={"$" + maxPrice}
                                                                        autoComplete="off"
                                                                        maxLength="10"
                                                                        style={{ width: '100%' }}
                                                                        disabled={true}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </form>

                                                        <Box sx={{ width: 275, margin: 'auto' }}>
                                                            <Slider
                                                                value={[minPrice, maxPrice]}
                                                                onChangeCommitted={handleChangeSlider}
                                                                valueLabelDisplay="on"
                                                                min={0}
                                                                step={1}
                                                                max={maxPriceDefault}

                                                            />
                                                        </Box>
                                                    </div>
                                                </Collapse> : ""}

                                    </div>


                                    <div className="price-filter">
                                        <h3 className="price-filter-text" role="button" onClick={() => setOpenStock(!openStock)} aria-expanded={openStock} aria-controls="price-collapse-text">
                                            Availability
                                            <i
                                                className={openStock ? "fa fa-minus" : "fa fa-plus"}
                                            >

                                            </i>
                                        </h3>
                                        <Collapse in={openStock}>
                                            <div id='stock-collapse-text'>
                                                <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                                                    {
                                                        optionsAvailbility.map((option, index) => (
                                                            <li key={index}>
                                                                <label className="label-filter">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="wheelWidth"
                                                                        value={option.value}
                                                                        id={option.value}
                                                                        onChange={handleStockChange}
                                                                        checked={stockWheel === option.value}
                                                                    />
                                                                    {option.label}
                                                                    <label htmlFor={option.value} className='custome-checkboxes'></label>
                                                                </label>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>

                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="filter-crose button-bottom" id="close-bar">
                                        <button className="reset" onClick={clearAllFilters}>
                                            Reset
                                        </button>

                                        <button className="submit" onClick={hideFilterClass}>
                                            Search
                                        </button>
                                        <button className="abc " onClick={hideFilterClass}>
                                            <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 filter-for-mobile" >

                                <div className='selectedFilters '>
                                    {
                                        selectedCategoriesName && selectedCategoriesName.length > 0 ?
                                            selectedCategoriesName.map((item, index) => (
                                                <span className="span-filter-Checkbox m-1" key={item.name}>
                                                    <span> {item.name} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveCategory(item.id)}></i>
                                                </span>
                                            ))
                                            : ""
                                    }
                                    {
                                        selectedBrandsName && selectedBrandsName.length > 0 ?
                                            selectedBrandsName.map((item, index) => (
                                                <span className="span-filter-Checkbox m-1" key={item.id}>
                                                    <span> {item.name} </span><i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                                                </span>
                                            ))
                                            : ""
                                    }
                                    {
                                        selectedCategoriesName.length > 0 || selectedBrandsName.length > 0 ?

                                            <span className="clearFilterStyle" onClick={clearAllFilters} >
                                                <span>Clear filters</span>
                                            </span>

                                            :

                                            ""
                                    }

                                </div>
                                {/* {totalProducts > 0 ? */}
                                <div className="d-flex justify-content-end filters-sort-by ">
                                    <div className="">
                                        {totalProducts > 0 ?
                                            <p className="px-5">
                                                Showing {startIndex} - {endIndex} of {parseInt(totalProducts).toLocaleString('en-US')} products
                                            </p>
                                            : ""
                                        }
                                    </div>
                                    <div className='stockCheckbox'>
                                        <ul className='stockCheckboxUl'>
                                            <li>
                                                <input
                                                    type="checkbox"
                                                    value="2"
                                                    className='tgl tgl-light'
                                                    onChange={handleStaggeredChange}
                                                    id='stockChecki'
                                                    name="stockCheck"
                                                    checked={!isChecked} // Set the checked attribute based on isChecked
                                                />
                                                <label htmlFor='stockChecki' className='tgl-btn'></label>
                                            </li>
                                            <li className='staggeredLi'>
                                                {
                                                    isChecked ?
                                                        <span>In Stock Items</span>
                                                        : <span>All Items</span>
                                                }
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="">
                                        <select className="form-control" name="sorting" id="sortingWithPrice" onChange={handleSorting} value={sortingValue}>
                                            <option disabled >Sort By Price</option>
                                            <option value="1" >Low-High</option>
                                            <option value="2">High-Low</option>
                                        </select>

                                    </div>
                                    <div className="main-filter-resposive">
                                        <button className="mobile-filter-btn" onClick={showFiltersClick} title="filter search"> <svg className="me-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.25901 14.35L5.25888 14.35C5.24769 14.3502 5.23405 14.3476 5.21863 14.339L5.21836 14.3389C5.19936 14.3282 5.19074 14.318 5.18703 14.3124C5.17887 14.2972 5.176 14.2839 5.176 14.2707V7.91365C5.176 7.82275 5.1652 7.72056 5.13235 7.61486C5.09945 7.50839 5.04961 7.41561 4.99386 7.33726L0.724519 1.26892C0.724485 1.26887 0.724451 1.26882 0.724418 1.26877C0.676725 1.20083 0.65495 1.13291 0.650688 1.06991C0.64665 1.00345 0.660203 0.933143 0.696549 0.863781L0.696552 0.863774C0.732993 0.794228 0.783357 0.74264 0.84197 0.707465L0.841971 0.707466L0.843135 0.706765C0.902543 0.670943 0.973036 0.65 1.05357 0.65H12.9463C13.0271 0.65 13.0973 0.670932 13.1564 0.706609L13.1578 0.70745C13.2164 0.742594 13.2667 0.794096 13.3032 0.863535C13.3407 0.935416 13.3534 1.00516 13.3492 1.06874C13.345 1.13245 13.3232 1.20066 13.2755 1.26862C13.2755 1.26872 13.2754 1.26882 13.2753 1.26892L9.0045 7.33779L9.00402 7.33848C8.94618 7.42089 8.90098 7.51067 8.86985 7.6068L8.86979 7.60678L8.8672 7.61518C8.83481 7.71999 8.82355 7.82177 8.82355 7.91441V12.1555C8.82355 12.1724 8.81984 12.1838 8.81525 12.1923C8.81075 12.1999 8.8025 12.2101 8.78506 12.2207L8.34805 12.4857L8.34827 12.4864L5.3042 14.3361C5.30409 14.3362 5.30399 14.3362 5.30388 14.3363C5.28736 14.3462 5.27261 14.3497 5.25901 14.35ZM5.27154 14.9999C5.14389 15.0023 5.01635 14.9707 4.90099 14.9061L5.27154 14.9999Z" stroke="#E81C2E" strokeWidth="1.3"></path>
                                        </svg>
                                        </button>
                                    </div>
                                </div>
                                {/* : ""
                                } */}
                                <div className='row'>
                                    {
                                        !isLoadingCategory && dataCategory ?
                                            dataCategory.data.length > 0 ?
                                                dataCategory.data.map((itm, _idx) => {
                                                    return (<Itemcards data={itm} key={itm.item_id} />);
                                                })
                                                : <>
                                                    <div className='container m-0 text-center'>
                                                        <h2>No Product Found</h2>
                                                        <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                                        <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                                                    </div>
                                                </>

                                            :
                                            Array.from({ length: 28 }).map((_, idx) => (
                                                <div className="col-lg-3 col-6" key={idx}>
                                                    <a href="#" title="view details">
                                                    </a>
                                                    <div className="Drivetrain-cart"><a href="">
                                                        <div className="engle-imge-upper">
                                                            {/* <button className="hover-btn">View Details</button> */}
                                                            <ShimmerThumbnail height={163} rounded />
                                                        </div>
                                                    </a>
                                                        <div className="cart-body">
                                                            <a href="#" title="product name">
                                                                <h4><ShimmerButton size="sm" /></h4>
                                                                <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                                                            </a>
                                                            <div className="price-cart">
                                                                <a href="#" title="product image">
                                                                    {/* <div className="price">
                                                                        {
                                                                        itm && itm.cost ?
                                                                            <h5 className="m-0">${itm.cost}</h5>
                                                                            :
                                                                            <h5 className="m-0">N.A</h5>
                                                                    } 

                                                                    </div> */}
                                                                </a>
                                                                <div className="cart-btn">
                                                                    <a href="#" title="cart button">
                                                                    </a>
                                                                    <a href="#" title="cart link">

                                                                        {/* <img src={data.data.brand_logo} alt="img" height="100%" width="100%" /> */}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))

                                    }


                                </div>
                                {totalPages > 0 &&
                                    <div className="d-flex mt-4 justify-content-center">
                                        <Pagination>
                                            <Pagination.First
                                                disabled={currentPage === 1}
                                                onClick={() => handlePageChange(1)}
                                            />
                                            <Pagination.Prev
                                                disabled={currentPage === 1}
                                                onClick={() => handlePageChange(currentPage - 1)}
                                            />


                                            {getPageNumbers().map((page) => (
                                                <Pagination.Item
                                                    key={page}
                                                    active={currentPage === page}
                                                    onClick={() => {
                                                        if (currentPage !== page) {
                                                            handlePageChange(page);
                                                        }
                                                    }}
                                                >
                                                    {page}
                                                </Pagination.Item>
                                            ))}

                                            <Pagination.Next
                                                disabled={currentPage === totalPages}
                                                onClick={() => handlePageChange(currentPage + 1)}
                                            />
                                            <Pagination.Last
                                                disabled={currentPage === totalPages}
                                                onClick={() => handlePageChange(totalPages)}
                                            />
                                        </Pagination>
                                    </div>
                                }


                            </div>

                        </div>
                    </div>
                </div>
            </section >

            {/* <NEWSLETTER /> */}
            < FOOTER />
        </>

    )
}
