import { React, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import Details from "./components/details";
import Vehicle from "./components/vehicle";
import Similar from "./components/similar";
import Bought from "./components/bought";
import SERVICES from "../../components/Footer/services";

export default function ProductDetails() {
    
    const [isHidden, setIsHidden] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("");
    const [cartItems, setCartItems] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);
    const { item_id } = useParams();
    // console.log('useParams', useParams())

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        
        if (storedCartItems) {
          const parsedCartItems = JSON.parse(storedCartItems);
          const numCartItems = Object.values(parsedCartItems);
          const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);  
          setCartItems(sum);
        }
      }, []);
      


    const getCartData = (value) => {
         
        const values = Object.values(value);
        const sum = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);      
        setCartItems(sum)      

    }

  

    return (
        <>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <Details item_id={item_id} getCartData={getCartData} />
            <Vehicle item_id={item_id} />
            <Similar data={item_id} />
            <Bought data={item_id}/>
            <SERVICES />
            <FOOTER />  
        </>

    )
}
