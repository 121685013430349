import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";







export default function Index() {
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    const [isHidden, setIsHidden] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("");
    const [cartItems, setCartItems] = useState("");
    const [password, setPassword] = useState('');


    const [showMessage, setShowMessage] = useState(false);
    const [showSuccessMessage, setSuccessShowMessage] = useState(false);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);

    }, []);

    const showLoaderLine = (id) => {
        if (id === 1) {
            setIsHidden(false);
        } else {
            setIsHidden(true);
        }

    };

    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    const [email, setEmail] = useState('');
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Extract the reset token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const resetToken = urlParams.get('token');

        try {
            const response = await fetch(`${mainUrl}/api/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password,resetToken })
            });

            if (response.ok) {
                setShowMessage(false);
                setSuccessShowMessage(true)
            } else {
                setShowMessage(true);
                setSuccessShowMessage(false)
            }
        } catch (error) {
            console.error('Error sending reset email:', error);
            setSuccessShowMessage(false);
            setShowMessage(false);
        }
    };


    return (
        <>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <section>
                <div className="main-section-lsting resetPasswordMainDiv">
                    <div className="container">

                    {
                            showMessage ?
                                <div className='errorMessageDiv'>
                                    <span>
                                        "User not found or invalid token!"
                                    </span>
                                </div>

                                : ""
                        }
                        {
                            showSuccessMessage ?
                                <div className='suessMessageDiv'>
                                    <span>
                                        "Password reset successfully!"
                                    </span>
                                </div>

                                : ""
                        }


                        <div className='row resetPassword'>
                            <div className="col-lg-12">
                                <div>
                                    <h2>Reset Password</h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label>Email:</label>
                                            <input type="email" value={email} onChange={handleEmailChange} required className='form-control' />
                                        </div>
                                        <div className="form-group">
                                            <label>New Password:</label>
                                            <input type="password" value={password} onChange={handlePasswordChange} required className='form-control' />
                                        </div>
                                        <button type="submit" className='btn btn-danger btn-sm'>Reset Password</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            < FOOTER />
        </>

    )
}
