import { React, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import Details from "./components/details";
import Similar from "./components/similar";
import Bought from "./components/bought";
import SERVICES from "../../components/Footer/services";

export default function ProductDetails() {
    
    const [isHidden, setIsHidden] = useState(false);
    const [cartItems, setCartItems] = useState("");

    const { item_id } = useParams();
    // console.log(item_id)
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);
    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
      }
  const [showMenuSide, setShowMenuSide] = useState("");

  useEffect(() => {
    const storedCartItems = localStorage.getItem('totalCartItems');
   
    if (storedCartItems) {
      const parsedCartItems = JSON.parse(storedCartItems);
      const numCartItems = Object.values(parsedCartItems);
      const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);  
      setCartItems(sum);
    }
  }, []);

  const getCartData = (value) => {
    // console.log('value+++++++++++++++',value);  
    const values = Object.values(value);
    const sum = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);      
    setCartItems(sum)      

}

    return (
        <>
            <div className="fade_background"></div>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar}  cartItemsdata={cartItems}/>
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <Details item_id={item_id} getCartData={getCartData}  />

            <Similar item_id={item_id} />
            <Bought item_id={item_id}/>
            <SERVICES />
            <FOOTER />
        </>

    )
}
