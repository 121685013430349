import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import Itemcards from "../../components/Cards/custome-products-card";






export default function Index() {

    const [isHidden, setIsHidden] = useState(false);
    const [showMenuSide, setShowMenuSide] = useState("");
    const [cartItems, setCartItems] = useState("");


    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);

    }, []);


    const showLoaderLine = (id) => {
        if (id === 1) {
            setIsHidden(false);
        } else {
            setIsHidden(true);
        }

    };

    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    const data = [

        {
            "item_id": "financecoverage",
            "product_name": "Finance overage",
            "part_number": "financeoverage",
            "part_description": "Finance Overage Charge to balance on your financing order",
            "price": "1.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/deposit.png"
            ],
            "subcategory_name": "Finance Overage",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"
        },
        {
            "item_id": "shipping",
            "product_name": "Shipping",
            "part_number": "shipping",
            "part_description": "SHIPPING Fees ",
            "price": "5.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/Shipping.png"
            ],
            "subcategory_name": "Shipping",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"
        },
        {
            "item_id": "wheeldrilling",
            "product_name": "Wheel Drilling",
            "part_number": "wheeldrilling ",
            "part_description": "Change the bolt pattern of your wheels.  Pricing is per wheel. Includes shipping.",
            "price": "65.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/WheelDrilling_1.jpg"
            ],
            "subcategory_name": "Wheel Drilling ",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"
        },

        {
            "item_id": "powdercoating",
            "product_name": "Powder Coating",
            "part_number": "powdercoating",
            "part_description": "Change the bolt pattern of your wheels.  Pricing is per wheel. Includes shipping.",
            "price": "50.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/Extreme-Wheels-Powder-Coating-Blue_1.jpg"
            ],
            "subcategory_name": "Powder Coating",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"
        },


        {
            "item_id": "mountandbalance",
            "product_name": "Mount and Balance",
            "part_number": "mountandbalancestandard",
            "part_description": "Mount and Balance Standard Rate",
            "price": "35.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/Extreme-Wheels-Mount-and-Balance.jpg"
            ],
            "subcategory_name": "Mount and Balance",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"
        },
        {
            "item_id": "mountandbalancediscounted",
            "product_name": "Mount and Balance Discounted",
            "part_number": "mountandbalancediscounted",
            "part_description": "Mount and Balance Discounted Discounted Rate",
            "price": "25.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/Extreme-Wheels-Mount-and-Balance.jpg"
            ],
            "subcategory_name": "Mount and Balance Discounted",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"
        },
        {
            "item_id": "labor",
            "product_name": "Labor",
            "part_number": "Labor",
            "part_description": "Labor Charge",
            "price": "110.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/Extreme-Wheels-Labor-Sku.jpg"
            ],
            "subcategory_name": "Labor  ",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"
        },
        {
            "item_id": "alignment",
            "product_name": "Alignment",
            "part_number": "alignment",
            "part_description": "4 wheel alignment",
            "price": "100.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/Wheel-Alignment.jpg"
            ],
            "subcategory_name": "Alignment  ",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"
        },
        {
            "item_id": "wholesalemachining",
            "product_name": "Wholesale Machining",
            "part_number": "wholesalemachining",
            "part_description": "Wholesale Machining",
            "price": "25.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/Wheel-Alignment.jpg"
            ],
            "subcategory_name": "Wholesale Machining",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"
        },
        {
            "item_id": "wheelrepair",
            "product_name": "Wheel Repair",
            "part_number": "wheelrepair",
            "part_description": "Wheel Repair Quantity of 1",
            "price": "205.00",
            "s3_images": [
                "https://www.extremeperformance.com/custome_products/Extreme-Wheels-Wheel-Repair.jpg"
            ],
            "subcategory_name": "Wheel Repair",
            "images": [
                "custome_product/deposit"
            ],
            "brand_logo": "custome_product/deposit"

        },


    ]

    return (
        <>
            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <section>
                <div className="main-section-lsting">
                    <div className="container">
                        <div className='row'>
                            <div className="col-lg-3">
                            </div>
                            <div className="col-lg-9">
                                <div className='row custome_products'>
                                    {
                                        data.length > 0 ?
                                            data.map((itm, _idx) => {
                                                return (<Itemcards data={itm} key={itm.item_id} />);
                                            })
                                            : <>
                                                <div className='container m-0 text-center'>
                                                    <h2>No Product Found</h2>
                                                    <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                                    <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            < FOOTER />
        </>

    )
}
