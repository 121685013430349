import { React, useState, useEffect, useRef } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha'; // Import the reCAPTCHA component
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';



// ... (other imports)

export default function Contactus() {

    const currentLink = window.location.href;
    const url = new URL(currentLink);
    const updatedUrl = url.toString();


    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;

    const [isHidden, setIsHidden] = useState(false);
    const recaptchaRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);

    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        orderNumber: '',
        message: ''
    });

    const resetForm = () => {
        setFormValues({
            name: '',
            email: '',
            orderNumber: '',
            message: ''
        });
        recaptchaRef.current.reset(); // Reset reCAPTCHA
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            alert('Please complete the reCAPTCHA.');
            return;
        }

        const formData = {
            name: formValues.name,
            email: formValues.email,
            orderNumber: formValues.orderNumber,
            message: formValues.message,
            recaptchaValue: recaptchaValue
        };

        console.log('formData', formData);

        try {
            setIsHidden(false);
            axios.post(`${mainUrl}/api/send-contact-us-email`, formData)
                .then((response) => {
                    if (response.status) {
                        toast.success("Email sent successfully!", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        resetForm(); // Reset form after successful submission
                    } else {
                        toast.info("Something went wrong! Please try again after a few minutes!", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }

                    setIsHidden(true);
                })
                .catch((error) => {
                    console.error('Error sending email:', error);
                    setIsHidden(true);
                    toast.info("Something went wrong! Please try again after a few minutes!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                });
        } catch (error) {
            console.log(error);
            setIsHidden(true);
            toast.info("Something went wrong! Please try again after a few minutes!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const [cartItems, setCartItems] = useState("");
    const [showMenuSide, setShowMenuSide] = useState("");

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);

    }, []);


    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    return (
        <>

            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content='We are based in Phoenix, Arizona ' />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>

            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />

            <section>
                <div className="warrent-banner contact_us_banner">
                    <div className="parformane-bar ">
                        <div className="container">
                            <ul className="p-0 m-0 listing-parmofamne">
                                <li>
                                    <a href="#">Home /</a>
                                </li>
                                <li className="active-bread">
                                    <a href="">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <h2 className="text-center txt-main-listing">Contact Us</h2>
                </div>
            </section>

            <section>
                <div className="contact_us_addr">
                    <div className="container">
                        <div className='main'>
                            <h2 className='addr_title'>We are based in Phoenix, Arizona</h2>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='box'>
                                        <div className='icon'>
                                            <img src="/images/addr.png" alt="icon" height="100%" width="100%" />
                                        </div>
                                        <div className='content'>
                                            <h3>Address</h3>
                                            <p>1497 E. Baseline Rd.</p>
                                            <p>Suite 4-109 Gilbert, Arizona 85233</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='box'>
                                        <div className='icon'>
                                            <img src="/images/call.png" alt="icon" height="100%" width="100%" />
                                        </div>
                                        <div className='content'>
                                            <h3>Call Us</h3>
                                            <a href='tel:480-892-9433'>480-892-9433</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='box'>
                                        <div className='icon'>
                                            <img src="/images/assist.png" alt="icon" height="100%" width="100%" />
                                        </div>
                                        <div className='content'>
                                            <h3>Call Us</h3>
                                            <p>
                                                Week Days : 8:00am - 6:00pm <br />
                                                Saturday : 9:00am - 3:00pm <br />
                                                Sunday  : Closed
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="contact_form">
                    <div className="container">
                        <div className='main contactusForm'>
                            <h2 className='form_title'>Questions? Get In Touch</h2>
                            <p>Do you have any enquiry? Please fill the form below and get in touch with us.</p>
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="form-group position-relative">
                                            <label htmlFor="formName" className="d-block">
                                                <i className="icon" data-feather="user"></i>
                                            </label>
                                            <input
                                                type="text"
                                                id="formName"
                                                className="form-control"
                                                placeholder="Name*"
                                                name="name"
                                                value={formValues.name}
                                                onChange={handleFormChange}
                                            />
                                        </div>
                                        <div className="form-group position-relative">
                                            <label htmlFor="formEmail" className="d-block">
                                                <i className="icon" data-feather="mail"></i>
                                            </label>
                                            <input
                                                type="email"
                                                id="formEmail"
                                                className="form-control"
                                                placeholder="E-mail*"
                                                name="email"
                                                value={formValues.email}
                                                onChange={handleFormChange}
                                            />
                                        </div>
                                        <div className="form-group message">
                                            <textarea
                                                id="formMessage"
                                                className="form-control"
                                                rows="7"
                                                placeholder="Message*"
                                                name="message"
                                                value={formValues.message}
                                                onChange={handleFormChange}
                                            ></textarea>
                                        </div>
                                        <div className="recaptcha-container">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey="6LdnBTQoAAAAAOrJo4AXUjwx9ZHwFY3VS92tYlqj"
                                            />
                                        </div>
                                        <div className="form-group message">
                                            <input type="submit" value="Send Message" required />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="contact_map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.7738063661577!2d-111.80135032375573!3d33.37697085308356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872ba8fa989829f9%3A0x17657851f96e2ff0!2s1497%20E%20Baseline%20Rd%20ste%204-109%2C%20Gilbert%2C%20AZ%2085233%2C%20USA!5e0!3m2!1sen!2sin!4v1688729207778!5m2!1sen!2sin"
                        height="452"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </section>

            <FOOTER />
        </>
    );

}
