import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import WheelsItemscard from "../../components/Cards/wheelsItemscard";
import WheelsItemscardStaggered from "../../components/Cards/items-staggered-card";
import WheelsItemscardNonStaggered from "../../components/Cards/items-non-staggered-card";
import BREADCRUMBAR from "../../components/BreadCrumBar";
import Pagination from 'react-bootstrap/Pagination';
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import { RangeSlider } from 'rsuite';
import "rsuite/dist/rsuite.css";
import axios from 'axios';
// import Modal from 'react-bootstrap/Modal';
// import Select from "react-select";
import Collapse from 'react-bootstrap/Collapse';
import { Helmet } from 'react-helmet';



export default function WheelsSerachByVehical() {
  const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const vehicalDetails = JSON.parse(localStorage.getItem('vehical_details'));

  const MAX_PAGES_DISPLAY = 10;
  const year = queryParams.get('year');
  const make = queryParams.get('make');
  const model = queryParams.get('model');
  const variant = queryParams.get('variant');


  const [showMenuSide, setShowMenuSide] = useState("");
  const [isHidden, setIsHidden] = useState(false);


  const [vehicalYear, setVehicalYear] = useState(year);
  const [vehicalMake, setVehicalMake] = useState(make);
  const [vehicalModel, setVehicalModel] = useState(model);
  const [vehicalVarient, setVehicalVarient] = useState(variant);

  const [dataWheels, setDataWheels] = useState([]);
  const [dataWheels2, setDataWheels2] = useState([]);
  const [isLoadingWheels, setIsLoadingWheels] = useState(true);


  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setoffsetCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productsPerPage, setProductsPerPage] = useState(0);
  const [firstValue, setFirstValue] = useState(0);
  const [secondValue, setSecondValue] = useState(5000);
  const [sortingValue, setSortingValue] = useState(1);

  const [categoryName, setCategoryName] = useState('Wheels');
  const [filterClass, setFilterClass] = useState('');

  const [diameterArray, setDiameterArray] = useState([]);
  const [widthArray, setWidthArray] = useState([]);
  const [patternArray, setPatternArray] = useState([]);
  const [brandArray, setBradArray] = useState([]);
  const [brandNameArray, setBradNameArray] = useState([]);
  const [modelArray, setModelArray] = useState([]);
  const [wheelOffsetArray, setWheelOffsetArray] = useState([]);




  const [openDiameter, setOpenDiameter] = useState(true);
  const [openWidth, setOpenWidth] = useState(false);
  const [openPattern, setOpenPattern] = useState(false);
  const [openBrand, setOpenBrand] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [openStock, setOpenStock] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openWheelOffset, setOpenWheelOffset] = useState(false);
  const [isLoadingFilterData, setLoadingFilterData] = useState(true);
  const [openFinish, setOpenFinish] = useState(false);
  const [openFitment, setOpenFitment] = useState(true);


  const [colourWheel, setColourWheel] = useState([]);
  const [stockWheel, setStockWheel] = useState("");

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [isWheelsFilterData, setWheelsFilterData] = useState([]);

  const [offsetWheel, setOffsetWheel] = useState("");


  const [uniqueFitmentNotes, setUniqueFitmentNotes] = useState([]);
  const [uniqueRimDiameters, setUniqueRimDiameters] = useState([]);
  const [selectedFitmentType, setSelectedFitmentType] = useState("");

  const [vehicleType, setVehicaleType] = useState("");
  const [isStaggred, setIsSteggd] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [staggeredValue, setStaggeredValue] = useState(1);
  const [fmkID, setFmkID] = useState("");

  const [cartItems, setCartItems] = useState("");
  const [isCheckedStock, setIsCheckedStock] = useState(true);
  const [offsetToShow, setoffsetToShow] = useState([]);

  const [maxPriceDefault, setMaxPriceDefault] = useState(null);
  const [minPriceDefault, setMinPriceDefault] = useState(null);
  const [isdata, setIsdata] = useState(true);
  const [isFetching, setIsFetching] = useState(false);



  const [isdataOnClick, setIsdataOnClick] = useState(true);


  useEffect(() => {
    const storedCartItems = localStorage.getItem('totalCartItems');

    if (storedCartItems) {
      const parsedCartItems = JSON.parse(storedCartItems);
      const numCartItems = Object.values(parsedCartItems);
      const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setCartItems(sum);
    }
  }, []);


  useEffect(() => {


    const storedFitmentType = localStorage.getItem('setWheelsSelectedFitmentType');
    const storedDiameter = localStorage.getItem('setWheelsDiameterArray');
    const storedWidth = localStorage.getItem('setWheelsWidthArray');
    const storedBrands = localStorage.getItem('setWheelsBradArray');
    const storedBrandsName = localStorage.getItem('setWheelsBradNameArray');
    const storedWheelOffset = localStorage.getItem('setWheelsWheelOffsetArray');
    const storedModel = localStorage.getItem('setWheelsModelArray');
    const storedMaxprice = localStorage.getItem('setWheelsMaxPrice');
    const storedMinprice = localStorage.getItem('setWheelsMinPrice');
    const storedFirstValue = localStorage.getItem('setWheelsFirstValue');
    const storedSecondValue = localStorage.getItem('setWheelsSecondValue');
    const storedColourWheel = localStorage.getItem('setWheelsColourWheel');
    const storedStockWheel = localStorage.getItem('setWheelsStockWheel');
    const storedIsChecked = localStorage.getItem('setWheelsIsCheckedStock');
    const storedSortingValue = localStorage.getItem('setWheelsSortingValue');
    const storedCurrentPage = localStorage.getItem('setWheelsCurrentPage');
    const storedoffsetCurrentPage = localStorage.getItem('setWheelsoffsetCurrentPage');


    if (storedCurrentPage) {
      const parsedCurrentPage = JSON.parse(storedCurrentPage);
      //  console.log("setCurrentPage", parsedCurrentPage)

      setCurrentPage(parsedCurrentPage);
      setoffsetCurrentPage(parsedCurrentPage);

    }
    if (storedoffsetCurrentPage) {
      const parsedCurrentPage = JSON.parse(storedoffsetCurrentPage);
      //  console.log("setoffsetCurrentPage", parsedCurrentPage)
      setoffsetCurrentPage(parsedCurrentPage);

    }
    if (storedIsChecked) {
      const parsedStockWheel = JSON.parse(storedIsChecked);

      setIsCheckedStock(parsedStockWheel);

    }

    if (storedSortingValue) {
      const parsedSortingValue = JSON.parse(storedSortingValue);
      //  console.log("yessss or noo", parsedSortingValue)
      setSortingValue(parsedSortingValue);

    }

    if (storedFitmentType) {
      const parsedFitmentType = JSON.parse(storedFitmentType);
      setSelectedFitmentType(parsedFitmentType);

    }

    if (storedDiameter) {
      const parsedDiameter = JSON.parse(storedDiameter);
      setDiameterArray(parsedDiameter);
      if (parsedDiameter.length > 0) {
        setOpenDiameter(true);

      }
    }

    if (storedWidth) {
      const parsedWidth = JSON.parse(storedWidth);
      setWidthArray(parsedWidth);
      if (parsedWidth.length > 0) {
        setOpenWidth(true);



      }
    }

    if (storedBrands) {
      const parsedBrands = JSON.parse(storedBrands);
      setBradArray(parsedBrands);
      if (parsedBrands.length > 0) {
        setOpenBrand(true);


      }

    }
    if (storedBrandsName) {
      const parsedBrandsName = JSON.parse(storedBrandsName);
      setBradNameArray(parsedBrandsName);
      if (parsedBrandsName.length > 0) {
        setOpenBrand(true);


      }

    }
    if (storedWheelOffset) {
      const parsedWheelOffset = JSON.parse(storedWheelOffset);
      setWheelOffsetArray(parsedWheelOffset);
      if (parsedWheelOffset.length > 0) {
        setOpenWheelOffset(true);


      }

    }
    if (storedModel) {
      const parsedModel = JSON.parse(storedModel);
      setModelArray(parsedModel);
      if (parsedModel.length > 0) {
        setOpenModel(true);

      }

    }
    if (storedMaxprice) {
      const parsedMaxprice = JSON.parse(storedMaxprice);
      setMaxPrice(parsedMaxprice);

    }
    if (storedMinprice) {
      const parsedMinprice = JSON.parse(storedMinprice);
      setMinPrice(parsedMinprice);

    }
    if (storedFirstValue) {
      const parsedFirstValue = JSON.parse(storedFirstValue);
      setFirstValue(parsedFirstValue);

    }
    if (storedSecondValue) {
      const parsedSecondValue = JSON.parse(storedSecondValue);
      setSecondValue(parsedSecondValue);

    }
    if (storedColourWheel) {
      const parsedColourWheel = JSON.parse(storedColourWheel);
      setColourWheel(parsedColourWheel);
      if (parsedColourWheel.length > 0) {
        setOpenFinish(true);

      }
    }

    if (storedStockWheel) {
      const parsedStockWheel = JSON.parse(storedStockWheel);
      setStockWheel(parsedStockWheel);
      if (parsedStockWheel.length > 0) {
        setOpenStock(true);

      }
    }

  }, []);


  useEffect(() => {
    const storedProductPage = localStorage.getItem('inProductPage');
    const ProductPage = JSON.parse(storedProductPage);
    // console.log("selectedFitmentType+++",selectedFitmentType)

    //    console.log("isMainLoadPage+++",ProductPage)
    if (ProductPage) {
      const storedFitmentType = localStorage.getItem('setWheelsSelectedFitmentType');
      const storedDiameter = localStorage.getItem('setWheelsDiameterArray');
      const storedWidth = localStorage.getItem('setWheelsWidthArray');
      const storedBrands = localStorage.getItem('setWheelsBradArray');
      const storedBrandsName = localStorage.getItem('setWheelsBradNameArray');
      const storedWheelOffset = localStorage.getItem('setWheelsWheelOffsetArray');
      const storedModel = localStorage.getItem('setWheelsModelArray');
      const storedMaxprice = localStorage.getItem('setWheelsMaxPrice');
      const storedMinprice = localStorage.getItem('setWheelsMinPrice');
      const storedStockWheel = localStorage.getItem('setWheelsStockWheel');
      const storedColourWheel = localStorage.getItem('setWheelsColourWheel');
      const storedCurrentPage = localStorage.getItem('setWheelsCurrentPage');
      const storedoffsetCurrentPage = localStorage.getItem('setWheelsoffsetCurrentPage');


      const parsedFitmentType = JSON.parse(storedFitmentType);
      const parsedDiameter = JSON.parse(storedDiameter);
      const parsedWidth = JSON.parse(storedWidth);
      const parsedBrands = JSON.parse(storedBrands);
      const parsedBrandsName = JSON.parse(storedBrandsName);
      const parsedWheelOffset = JSON.parse(storedWheelOffset);
      const parsedModel = JSON.parse(storedModel);
      const parsedMaxprice = JSON.parse(storedMaxprice);
      const parsedMinprice = JSON.parse(storedMinprice);
      const parsedStockWheel = JSON.parse(storedStockWheel);
      const parsedColourWheel = JSON.parse(storedColourWheel);
      const parsedCurrentPage = JSON.parse(storedCurrentPage);
      const parsedoffsetCurrentPage = JSON.parse(storedoffsetCurrentPage);

      if (parsedCurrentPage != null || parsedoffsetCurrentPage != null || parsedFitmentType != null || parsedDiameter != null || parsedWidth != null || parsedBrands != null || parsedBrandsName != null || parsedWheelOffset != null || parsedModel != null || parsedMaxprice != null || parsedMinprice != null || parsedStockWheel != null || parsedColourWheel != null) {

        localStorage.removeItem('inProductPage');
        // console.log("parsedFitmentType",parsedFitmentType,"diameterArray+++",diameterArray,"brandArray+++",brandArray,"widthArray+++",widthArray,"sortingValue+++",sortingValue,"firstValue+++",firstValue,"secondValue",secondValue,"wheelOffsetArray+++",wheelOffsetArray,"stockWheel+++",stockWheel,"modelArray+++",modelArray,"isCheckedStock+++",isCheckedStock);
        if (diameterArray.length > 0 || brandArray.length > 0 || widthArray.length > 0 || modelArray.length > 0 || wheelOffsetArray.length > 0 || selectedFitmentType) {
          fetchWheelsData();

        } else {
          return;
        }
      } else {
        fetchWheelsData();
      }
    } else {
      const storedFitmentType = localStorage.getItem('setWheelsSelectedFitmentType');
      const storedDiameter = localStorage.getItem('setWheelsDiameterArray');
      const storedWidth = localStorage.getItem('setWheelsWidthArray');
      const storedBrands = localStorage.getItem('setWheelsBradArray');
      const storedBrandsName = localStorage.getItem('setWheelsBradNameArray');
      const storedWheelOffset = localStorage.getItem('setWheelsWheelOffsetArray');
      const storedModel = localStorage.getItem('setWheelsModelArray');
      const storedMaxprice = localStorage.getItem('setWheelsMaxPrice');
      const storedMinprice = localStorage.getItem('setWheelsMinPrice');
      const storedStockWheel = localStorage.getItem('setWheelsStockWheel');
      const storedColourWheel = localStorage.getItem('setWheelsColourWheel');
      const storedCurrentPage = localStorage.getItem('setWheelsCurrentPage');
      const storedoffsetCurrentPage = localStorage.getItem('setWheelsoffsetCurrentPage');

      const parsedFitmentType = JSON.parse(storedFitmentType);
      const parsedDiameter = JSON.parse(storedDiameter);
      const parsedWidth = JSON.parse(storedWidth);
      const parsedBrands = JSON.parse(storedBrands);
      const parsedBrandsName = JSON.parse(storedBrandsName);
      const parsedWheelOffset = JSON.parse(storedWheelOffset);
      const parsedModel = JSON.parse(storedModel);
      const parsedMaxprice = JSON.parse(storedMaxprice);
      const parsedMinprice = JSON.parse(storedMinprice);
      const parsedStockWheel = JSON.parse(storedStockWheel);
      const parsedColourWheel = JSON.parse(storedColourWheel);
      const parsedCurrentPage = JSON.parse(storedCurrentPage);
      const parsedoffsetCurrentPage = JSON.parse(storedoffsetCurrentPage);

      if (parsedCurrentPage != null || parsedoffsetCurrentPage != null || parsedFitmentType != null || parsedDiameter != null || parsedWidth != null || parsedBrands != null || parsedBrandsName != null || parsedWheelOffset != null || parsedModel != null || parsedMaxprice != null || parsedMinprice != null || parsedStockWheel != null || parsedColourWheel != null) {

        localStorage.removeItem('inProductPage');
        // console.log("parsedFitmentType",parsedFitmentType,"diameterArray+++",diameterArray,"brandArray+++",brandArray,"widthArray+++",widthArray,"sortingValue+++",sortingValue,"firstValue+++",firstValue,"secondValue",secondValue,"wheelOffsetArray+++",wheelOffsetArray,"stockWheel+++",stockWheel,"modelArray+++",modelArray,"isCheckedStock+++",isCheckedStock);
        if (diameterArray.length > 0 || brandArray.length > 0 || widthArray.length > 0 || modelArray.length > 0 || wheelOffsetArray.length > 0 || selectedFitmentType) {
          fetchWheelsData();

        } else {
          fetchWheelsData();
          // return;
        }
      } else {
        fetchWheelsData();
      }
    }
    setIsHidden(false);
  }, [isdataOnClick]);

  useEffect(() => {
    fetchWheelsFiltersData();
  }, [])


  const fetchWheelsData = async () => {
    document.body.classList.add('full-screen-loader');
    setIsLoadingWheels(true);
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${mainUrl}/api/wheels/getWheelsDataWithSearchByVehical?year=${vehicalYear}&make=${vehicalMake}&model=${vehicalModel}&variant=${vehicalVarient}&sorting=${sortingValue}&startingPrice=${firstValue}&endPrice=${secondValue}&diameter=${diameterArray}&brand=${brandArray}&lugpattern=${patternArray}&width=${widthArray}&offsetWheel=${wheelOffsetArray}&colourWheel=${colourWheel}&stockWheel=${stockWheel}&style=${modelArray}&fitmentType=${selectedFitmentType}&isStagered=${isChecked}&staggeredValue=${staggeredValue}&offset=${offset}&isCheckedStock=${isCheckedStock}`,
      };
      axios.request(config)
        .then((response) => {
          setDataWheels(response.data);
          if (response.data.status == 500) {
            setIsdata(false);

          }
          setDataWheels2(response.data2);
          setIsLoadingWheels(false);
          setTotalPages(response.data.totalPages);
          setTotalProducts(response.data.count);
          setProductsPerPage(response.data.data.length);
          setIsHidden(true);
          setUniqueFitmentNotes(response.data.details.uniqueFitmentNotes)
          window.scrollTo({ top: 400, behavior: 'smooth' });
          setIsHidden(true);
          setVehicaleType(response.data.details.vehicleType)
          setIsSteggd(response.data.details.staggered)
          setFmkID(response.data.fmkID)
          setoffsetToShow(response.data.offsetToShow)
          document.body.classList.remove('full-screen-loader');
          setMaxPriceDefault((Math.ceil(response.data.maximumPrice)) + 1);
          const storedMaxprice = localStorage.getItem('setWheelsMaxPrice');
          const storedMinprice = localStorage.getItem('setWheelsMinPrice');
          if (storedMaxprice <= 0) {
            //   console.log("maxxxxxx=======",maxPrice)
            setMaxPrice(Math.ceil(response.data.maximumPrice));
          } else {
            //  console.log("maxxxxxx=======+++++++++++++++++",maxPrice)
            setOpenPrice(true);
          }
          if (storedMinprice <= 0) {
            setMinPrice(Math.floor(response.data.minimumPrice));
          } else {
            setOpenPrice(true);
          }
        })

    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoadingWheels(false);
      setIsHidden(true);
      document.body.classList.remove('full-screen-loader');
      navigate(`/error`);

    }
  };
  const fetchWheelsFiltersData = async () => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${mainUrl}/api/wheels/getWheelsFiltersData`,
      };
      axios
        .request(config)
        .then((response) => {
          setWheelsFilterData(response.data)
          setLoadingFilterData(false)

        })

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoadingFilterData(false)
      navigate(`/error`);

    }
  }
  const handleRemoveDiameter = (categoryName) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    const updatedDiameter = diameterArray.filter((category) => category !== categoryName);
    setDiameterArray(updatedDiameter);
    localStorage.setItem('setWheelsDiameterArray', JSON.stringify(updatedDiameter));


  };

  const handleRemoveWidth = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    const updatedWidth = widthArray.filter((id) => id !== width);
    setWidthArray(updatedWidth);
    localStorage.setItem('setWheelsWidthArray', JSON.stringify(updatedWidth));


  };

  const handleRemovePattern = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    const updatedpattern = patternArray.filter((id) => id !== width);
    setPatternArray(updatedpattern);


  };
  const handleRemoveModel = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    const updatedModle = modelArray.filter((id) => id !== width);
    setModelArray(updatedModle);
    localStorage.setItem('setModelArray', JSON.stringify(updatedModle));


  };

  const clearAllFilters = () => {
    localStorage.removeItem('setWheelsSelectedFitmentType');
    localStorage.removeItem('setWheelsDiameterArray');
    localStorage.removeItem('setWheelsWidthArray');
    localStorage.removeItem('setWheelsPatternArray');
    localStorage.removeItem('setWheelsBradArray');
    localStorage.removeItem('setWheelsBradNameArray');
    localStorage.removeItem('setWheelsWheelOffsetArray');
    localStorage.removeItem('setWheelsModelArray');
    localStorage.removeItem('setWheelsMaxPrice');
    localStorage.removeItem('setWheelsMinPrice');
    localStorage.removeItem('setWheelsFirstValue');
    localStorage.removeItem('setWheelsSecondValue');
    localStorage.removeItem('setWheelsColourWheel');
    localStorage.removeItem('setWheelsStockWheel');
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    window.location.reload();
    setIsdataOnClick(prevState => !prevState);

  }

  const handleOffsetChange = (event) => {
    const selectedOffset = event.target.value;
    setOffsetWheel(selectedOffset)


  }

  const showFiltersClick = () => {
    setFilterClass('active-filter')
    document.body.classList.add('home-layout')

  }

  const handleRemoveBrand = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    const updatedCategories = brandArray.filter((id) => id !== width);
    setBradArray(updatedCategories);


    const updatedBrandNames = brandNameArray.filter((name) => name.id !== width);
    setBradNameArray(updatedBrandNames);

    localStorage.setItem('setWheelsBradArray', JSON.stringify(updatedCategories));
    localStorage.setItem('setWheelsBradNameArray', JSON.stringify(updatedBrandNames));

  };

  const handleWheelColor = (event) => {
    const selectedColor = event.target.value;
    setIsHidden(true);

    if (colourWheel.includes(selectedColor)) {
      const updatedColourWheel = colourWheel.filter((id) => id !== selectedColor);
      setColourWheel(updatedColourWheel);
      localStorage.setItem('setWheelsColourWheel', JSON.stringify(updatedColourWheel));
    } else {
      const updatedColourWheel = [...colourWheel, selectedColor];
      setColourWheel(updatedColourWheel);
      localStorage.setItem('setWheelsColourWheel', JSON.stringify(updatedColourWheel));
    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
  }

  const handleFitmentType = (event) => {

    console.log(event.target.value);
    const selectedValue = event.target.value;
    setSelectedFitmentType(selectedValue);
    localStorage.setItem('vehical_fitment', selectedValue);
    localStorage.setItem('setWheelsSelectedFitmentType', JSON.stringify(selectedValue));
    localStorage.removeItem('setWheelsDiameterArray');
    localStorage.removeItem('setWheelsWidthArray');
    localStorage.removeItem('setWheelsPatternArray');
    localStorage.removeItem('setWheelsBradArray');
    localStorage.removeItem('setWheelsBradNameArray');
    localStorage.removeItem('setWheelsWheelOffsetArray');
    localStorage.removeItem('setWheelsModelArray');
    localStorage.removeItem('setWheelsMaxPrice');
    localStorage.removeItem('setWheelsMinPrice');
    localStorage.removeItem('setWheelsFirstValue');
    localStorage.removeItem('setWheelsSecondValue');
    localStorage.removeItem('setWheelsColourWheel');
    localStorage.removeItem('setWheelsStockWheel');
    localStorage.removeItem('setWheelsIsCheckedStock');
    localStorage.removeItem('setWheelsSortingValue');
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    setDiameterArray([]);
    setWidthArray([]);
    setPatternArray([]);
    setBradArray([]);
    setBradNameArray([]);
    setBradNameArray([]);
    setModelArray([]);
    setColourWheel([]);
    setFirstValue(0);
    setSecondValue(5000);
    setCurrentPage(1);
    setoffsetCurrentPage(0);

    setOpenDiameter(false);
    setOpenPattern(false);
    setOpenBrand(false);
    setOpenWidth(false);
    setOpenModel(false);
    setOpenFinish(false);
    setOpenFinish(false);
    setOpenFinish(false);
    setOpenFinish(false);
  }

  const handleSorting = (event) => {
    const sortingValue = event.target.value;
    setSortingValue(sortingValue);
    localStorage.setItem('setWheelsSortingValue', JSON.stringify(sortingValue));
    setIsdataOnClick(prevState => !prevState);

  };


  const handleStockChange = (event) => {
    const selectedStock = event.target.value;
    // setStockWheel(selectedStock)
    // If the clicked checkbox is already checked, uncheck it by setting the state to null
    if (stockWheel === selectedStock) {
      setStockWheel("");
    } else {
      // Otherwise, set the state to the selected color
      setStockWheel(selectedStock);
      localStorage.setItem('setWheelsStockWheel', JSON.stringify(selectedStock));

    }

  }


  const handlePageChange = (page) => {
    const limit = 28;
    const offsets = (page - 1) * limit; // Calculate the offset based on the current page and limit
    setCurrentPage(page);
    setoffsetCurrentPage(offsets);
    document.body.classList.add('full-screen-loader');
    localStorage.setItem('setWheelsCurrentPage', JSON.stringify(page));
    localStorage.setItem('setWheelsoffsetCurrentPage', JSON.stringify(offsets));
    window.scrollTo({ top: 400, behavior: 'smooth' });
    setIsdataOnClick(prevState => !prevState);

  };

  const getPageNumbers = () => {
    const totalDisplayPages = Math.min(totalPages, MAX_PAGES_DISPLAY);
    const middlePage = Math.ceil(totalDisplayPages / 2);
    let startPage = Math.max(1, currentPage - middlePage + 1);

    // Ensure the start page does not exceed the available pages
    if (startPage + totalDisplayPages > totalPages) {
      startPage = Math.max(1, totalPages - totalDisplayPages + 1);
    }

    return Array.from({ length: totalDisplayPages }, (_, index) => startPage + index);
  };


  function handleRanges(value) {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    setFirstValue(value[0]);
    setSecondValue(value[1]);
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
    if (value[1]) {
      //  console.log(value[1]);
      localStorage.setItem('setWheelsMaxPrice', JSON.stringify(value[1]));
      localStorage.setItem('setWheelsSecondValue', JSON.stringify(value[1]));
    }
    if (value[0]) {
      localStorage.setItem('setWheelsMinPrice', JSON.stringify(value[0]));
      localStorage.setItem('setWheelsFirstValue', JSON.stringify(value[0]));
    }
  }
  const setValueMenuSideBar = (value) => {
    setShowMenuSide(value)
  }

  const hideFilterClass = () => {
    setFilterClass('')
    document.body.classList.remove('home-layout')
  }

  const handleDiameterCheckboxChange = (event) => {
    setIsHidden(true);
    const sdiameter = event.target.value;
    if (diameterArray.includes(sdiameter)) {
      const updatedDiameter = diameterArray.filter((id) => id !== sdiameter);
      setDiameterArray(updatedDiameter);
      localStorage.setItem('setWheelsDiameterArray', JSON.stringify(updatedDiameter));
    } else {
      const updatedDiameter = [...diameterArray, sdiameter];
      setDiameterArray(updatedDiameter);
      localStorage.setItem('setWheelsDiameterArray', JSON.stringify(updatedDiameter));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
  };

  const handleWidthCheckboxChange = (event) => {
    setIsHidden(true);
    const sWidth = event.target.value;
    if (widthArray.includes(sWidth)) {
      const updatedWidth = widthArray.filter((id) => id !== sWidth);
      setWidthArray(updatedWidth);
      localStorage.setItem('setWheelsWidthArray', JSON.stringify(updatedWidth));

    } else {
      const updatedWidth = [...widthArray, sWidth];
      setWidthArray(updatedWidth);
      localStorage.setItem('setWheelsWidthArray', JSON.stringify(updatedWidth));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');

  };




  const handleBrandCheckboxChange = (event) => {
    setIsHidden(true);
    const sBrand = event.target.value;
    const brandIdName = event.target.name;

    if (brandArray.includes(sBrand)) {
      const updatedBrand = brandArray.filter((id) => id !== sBrand);
      setBradArray(updatedBrand);
      localStorage.setItem('setWheelsBradArray', JSON.stringify(updatedBrand));

    } else {
      const updatedBrand = [...brandArray, sBrand];
      setBradArray(updatedBrand);
      localStorage.setItem('setWheelsBradArray', JSON.stringify(updatedBrand));

    }

    // Check if the category object is already in the selectedCategoriesName array
    const categoryObject = { id: sBrand, name: brandIdName };
    const categoryObjectIndex = brandNameArray.findIndex(
      (obj) => obj.id === sBrand
    );
    if (categoryObjectIndex !== -1) {
      const updatedBrandsName = brandNameArray.filter((obj) => obj.id !== sBrand);
      setBradNameArray(updatedBrandsName);
      localStorage.setItem('setWheelsBradNameArray', JSON.stringify(updatedBrandsName));

    } else {
      const updatedBrandsName = [...brandNameArray, categoryObject];
      setBradNameArray(updatedBrandsName);
      localStorage.setItem('setWheelsBradNameArray', JSON.stringify(updatedBrandsName));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');

  };

  const handleModelCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (modelArray.includes(sModle)) {
      const updatedModle = modelArray.filter((id) => id !== sModle);
      setModelArray(updatedModle);
      localStorage.setItem('setWheelsModelArray', JSON.stringify(updatedModle));

    } else {
      const updatedModle = [...modelArray, sModle];
      setModelArray(updatedModle);
      localStorage.setItem('setWheelsModelArray', JSON.stringify(updatedModle));

    }

    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
  };

  const handleWhellOffSetCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (wheelOffsetArray.includes(sModle)) {
      const updatedWhellOffSet = wheelOffsetArray.filter((id) => id !== sModle);
      setWheelOffsetArray(updatedWhellOffSet);
      localStorage.setItem('setWheelsWheelOffsetArray', JSON.stringify(updatedWhellOffSet));

    } else {
      const updatedWhellOffSet = [...wheelOffsetArray, sModle];
      setWheelOffsetArray(updatedWhellOffSet);
      localStorage.setItem('setWheelsWheelOffsetArray', JSON.stringify(updatedWhellOffSet));

    }


    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
  };

  const onRedirectHome = () => {
    localStorage.removeItem('vehical_details');
    setTimeout(() => {
      navigate('/');

    }, 1000);
  }

  const handleStaggeredChange = (event) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    let value = event.target.value;
    setIsChecked(!isChecked)
    setDiameterArray([]);
    setWidthArray([]);
    setPatternArray([]);
    setBradArray([]);
    setBradNameArray([]);
    setBradNameArray([]);
    setModelArray([]);

    setOpenDiameter(!openDiameter);
    setOpenPattern(!openPattern);
    setOpenBrand(!openBrand);
    setOpenWidth(!openWidth);
    setOpenModel(!openModel);
    setOpenFinish(!openFinish);

    if (isChecked) {
      setStaggeredValue(2)
    } else {
      setStaggeredValue(1)
    }
  }
  const PRODUCT_PER_PAGE = 28;
  const startIndex = (currentPage - 1) * PRODUCT_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + PRODUCT_PER_PAGE - 1, totalProducts);

  const handleChangeStockAvail = () => {
    setIsCheckedStock(!isCheckedStock)
    localStorage.setItem('setWheelsIsCheckedStock', JSON.stringify(!isCheckedStock));
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    setIsdataOnClick(prevState => !prevState);
  }

  const handleRemoveColor = (selectedColor) => {
    localStorage.removeItem('setWheelsoffsetCurrentPage');
    localStorage.removeItem('setWheelsCurrentPage');
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    const updatedModle = colourWheel.filter((id) => id !== selectedColor);
    setColourWheel(updatedModle);
    localStorage.setItem('setWheelsColourWheel', JSON.stringify(updatedModle));
  }

  const optionsAvailbility = [
    { value: "zero", label: "ALL" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4+" },
  ];

  const optionsColour = [
    { value: "Anthracite", label: "Anthracite" },
    { value: "Blue", label: "Blue" },
    { value: "Black", label: "Black" },
    { value: "Bronze", label: "Bronze" },
    { value: "Brushed", label: "Brushed" },
    { value: "Charcoal", label: "Charcoal" },
    { value: "Copper", label: "Copper" },
    { value: "Chrome", label: "Chrome" },
    { value: "Custom", label: "Custom" },
    { value: "Gray", label: "Gray" },
    { value: "Gun Metal", label: "Gun Metal" },
    { value: "Graphite", label: "Graphite" },
    { value: "Gold", label: "Gold" },
    { value: "Machined", label: "Machined" },
    { value: "Polished", label: "Polished" },
    { value: "Red", label: "Red" },
    { value: "Smoke", label: "Smoke" },
    { value: "Silver", label: "Silver" },
    { value: "Titanium", label: "Titanium" },
    { value: "White", label: "White" },

  ];
  const goBackToHomepage = () => {
    navigate('/');
  };


  function onclickFilter() {
    setIsdataOnClick(prevState => !prevState);
  }

  return (
    <>
      <Helmet>
        <title>Wheel Search</title>
      </Helmet>
      <TOPBAR isHidden={isHidden} />
      <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
      <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />

      <>
        <BREADCRUMBAR categoryName={categoryName} />

        <section>
          <div className="main-section-lsting wheelsPageSectioin">
            <div className="container">
              <div className='d-flex  car-selection-title'>

                <div className='vechicleName'>
                  <strong style={{ fontSize: '30px' }}> {vehicalDetails ? vehicalDetails.year : ""}&nbsp;
                    {vehicalDetails ? vehicalDetails.make_name : ""}
                    {vehicalDetails ? vehicalDetails.model_name : ""}&nbsp;
                    {vehicalDetails ? vehicalDetails.variant_name : ""}</strong>
                </div>
                <div className='staggeredCheckbox'>
                  {
                    vehicleType && vehicleType.toLowerCase() != "truck" && isStaggred ?
                      <div className="price-filter">
                        <ul className='p-0 m-0 list-filter'>
                          <li>

                            <input
                              type="checkbox"
                              value="2"
                              className=' tgl tgl-light'
                              onChange={handleStaggeredChange}
                              id='stagerd'
                              name="staggered"

                            />


                            <label htmlFor='stagerd' className=' tgl-btn'></label>


                          </li>
                          <li className='staggeredLi'>
                            {
                              isChecked ?
                                <span>Staggered Wheels</span>
                                : <span>Non-Staggered Wheels</span>
                            }
                          </li>
                        </ul>
                      </div>

                      : ""
                  }
                </div>
              </div>
              <div className='fitmet-type'>
                {uniqueFitmentNotes && uniqueFitmentNotes.length > 0 ?

                  <div className="price-filter-fitment">
                    <span className='title'>Lifted Options</span>

                    <div id="fitment-collapse-text-fitment">
                      <select className="form-control w-100" name="wheelFinish" onChange={handleFitmentType} value={selectedFitmentType || ''}>
                        <option value="0">All</option>
                        {!isLoadingFilterData && uniqueFitmentNotes
                          ? uniqueFitmentNotes.map((item, index) => (
                            <option className="ymm-li" value={item.id} key={item.id}>
                              {item.label}
                            </option>
                          ))
                          : ''}
                      </select>
                    </div>


                  </div>

                  : ""

                }

              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className={`main-outer-filter ${filterClass}`}>
                    <div className="filter-crose " id="close-bar" >
                      {/* <button className="submit" onClick={hideFilterClass}>
                          Submit
                        </button> */}
                      <button className="abc" onClick={hideFilterClass}>
                        <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                      </button>
                    </div>

                    <div className="price-filter filter-button">
                      <div>
                        <button className='btn btn-danger' onClick={onclickFilter}>APPLY FILTER</button>
                      </div>
                    </div>


                    {uniqueFitmentNotes && uniqueFitmentNotes.length > 0 ?

                      <div className="price-filter desktop-only">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenFitment(!openFitment)} aria-expanded={openFitment} aria-controls="fitment-collapse-text">
                          Lifted Options <i className={openFitment ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openFitment}>
                          <div id="fitment-collapse-text">
                            <select className="form-control w-100" name="wheelFinish" onChange={handleFitmentType} value={selectedFitmentType || ''}>
                              <option value="">All</option>
                              {!isLoadingFilterData && uniqueFitmentNotes
                                ? uniqueFitmentNotes.map((item, index) => (
                                  <option className="ymm-li" value={item.id} key={item.id}>
                                    {item.label}
                                  </option>
                                ))
                                : ''}
                            </select>
                          </div>
                        </Collapse>

                      </div>

                      : ""

                    }


                    {
                      uniqueFitmentNotes && uniqueFitmentNotes.length > 0 ?

                        < div className="price-filter">
                          <h3 className="price-filter-text" role="button" onClick={() => setOpenDiameter(!openDiameter)} aria-expanded={openDiameter} aria-controls="example-collapse-text">
                            Wheel Diameter <i className={openDiameter ? "fa fa-minus" : "fa fa-plus"}></i>
                          </h3>
                          <Collapse in={openDiameter}>
                            <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                              {

                                dataWheels && dataWheels.uniqueDiameterFirstTime ?
                                  dataWheels.uniqueDiameterFirstTime.filter(item => item && item !== '0').map((item, index) => (
                                    <li key={item}>
                                      <label className='label-filter'>
                                        <input
                                          type="checkbox"
                                          id={item}
                                          name={item}
                                          value={item}
                                          className='filter-box'
                                          checked={diameterArray.includes(String(item))}
                                          onChange={handleDiameterCheckboxChange}
                                        />
                                        {item + '"'}
                                        <label htmlFor={item} className='custome-checkboxes'></label>
                                      </label>
                                    </li>
                                  ))
                                  : <ShimmerThumbnail height={10} rounded />
                              }
                            </ul>
                          </Collapse>
                        </div>
                        :
                        <div className="price-filter">
                          <h3 className="price-filter-text" role="button" onClick={() => setOpenDiameter(!openDiameter)} aria-expanded={openDiameter} aria-controls="example-collapse-text">
                            Wheel Diameter <i className={openDiameter ? "fa fa-minus" : "fa fa-plus"}></i>
                          </h3>
                          <Collapse in={openDiameter}>
                            <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                              {

                                dataWheels && dataWheels.uniqueRimDiameters ?
                                  dataWheels.uniqueRimDiameters.filter(item => item && item !== '0').map((item, index) => (
                                    <li key={item}>
                                      <label className='label-filter'>
                                        <input
                                          type="checkbox"
                                          id={item}
                                          name={item}
                                          value={item}
                                          className='filter-box'
                                          checked={diameterArray.includes(String(item))}
                                          onChange={handleDiameterCheckboxChange}
                                        />
                                        {item + '"'}
                                        <label htmlFor={item} className='custome-checkboxes'></label>
                                      </label>
                                    </li>
                                  ))
                                  : <ShimmerThumbnail height={10} rounded />
                              }
                            </ul>
                          </Collapse>
                        </div>
                    }


                    <div className="price-filter ">  
                         <h3 className="price-filter-text" role="button" onClick={() => setOpenWidth(!openWidth)} aria-expanded={openWidth} aria-controls="width-collapse-text">
                          Wheel Width <i className={openWidth ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>

                          <Collapse in={openWidth}>
                            <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                              {

                                dataWheels && dataWheels.widthDataArray ?
                                  dataWheels.widthDataArray.map((item, index) => (
                                    <li key={item.value}>
                                      <label className='label-filter'>
                                        <input
                                          type="checkbox"
                                          id={"width" + item.value}
                                          name={item.value}
                                          value={item.value}
                                          className='filter-box'
                                          checked={widthArray.includes(String(item.value))}
                                          onChange={handleWidthCheckboxChange}
                                        />
                                        {item.label + '"'}
                                        <label htmlFor={"width" + item.value} className='custome-checkboxes'></label>
                                      </label>

                                    </li>
                                  ))
                                  : <ShimmerThumbnail height={10} rounded />
                              }
                            </ul>
                          </Collapse>  
                    </div>


                    <div className="price-filter ">
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenBrand(!openBrand)} aria-expanded={openBrand} aria-controls="brand-collapse-text">
                        Brands <i className={openBrand ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openBrand}>
                        <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                          {

                            dataWheels && dataWheels.brandData ?
                              dataWheels.brandData.map((item, index) => (
                                <li key={item.brand_id}>
                                  <label className='label-filter'>
                                    <input type="checkbox"
                                      id={item.brand_id}
                                      name={item.brand_desc}
                                      value={item.brand_id}
                                      className='filter-box'
                                      checked={brandArray.includes(String(item.brand_id))}
                                      onChange={handleBrandCheckboxChange}
                                    /> {item.brand_desc}
                                    <label htmlFor={item.brand_id} className='custome-checkboxes'></label>

                                  </label>
                                </li>
                              ))
                              : <ShimmerThumbnail height={10} rounded />
                          }
                        </ul>
                      </Collapse>
                    </div>


                    <div className="price-filter ">
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenModel(!openModel)} aria-expanded={openModel} aria-controls="brand-collapse-text">
                        Model <i className={openModel ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openModel}>
                        <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                          {

                            dataWheels && dataWheels.stylesData ?
                              dataWheels.stylesData.map((item, index) => (
                                <li key={item.style}>
                                  <label className='label-filter'>
                                    <input type="checkbox"
                                      id={item.style}
                                      name={item.style}
                                      value={item.style}
                                      className='filter-box'
                                      checked={modelArray.includes(String(item.style))}
                                      onChange={handleModelCheckboxChange}
                                    /> {item.style}
                                    <label htmlFor={item.style} className='custome-checkboxes'></label>

                                  </label>
                                </li>
                              ))
                              : <ShimmerThumbnail height={10} rounded />
                          }
                        </ul>
                      </Collapse>
                    </div>

                    <div className="price-filter">
                      <div className='lugOffsetDiv mb-3'>
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenWheelOffset(!openWheelOffset)} aria-expanded={openWheelOffset} aria-controls="brand-collapse-text">
                          Wheel Offset <i className={openWheelOffset ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openWheelOffset}>
                          <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                            {
                              offsetToShow && offsetToShow.length > 0 ?
                                offsetToShow.map((option, index) => (
                                  <li key={option.value}>
                                    <label className="label-filter">
                                      <input
                                        type="checkbox"
                                        name="offset"
                                        value={option.value}
                                        id={option.value}
                                        onChange={handleWhellOffSetCheckboxChange}
                                        checked={wheelOffsetArray.includes(String(option.value))}
                                      />
                                      {option.label}
                                      <label htmlFor={option.value} className='custome-checkboxes'></label>
                                    </label>
                                  </li>
                                ))
                                : ""
                            }
                          </ul>
                        </Collapse>
                      </div>
                    </div>

                    <div className="price-filter">

                      <h3 className="price-filter-text" role="button" onClick={() => setOpenFinish(!openFinish)} aria-expanded={openFinish} aria-controls="color-collapse-text">
                        Finish<i className={openFinish ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openFinish}>
                        <div id='stock-collapse-text'>
                          <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                            {
                              optionsColour.map((option, index) => (
                                <li key={option.value}>
                                  <label className="label-filter">
                                    <input
                                      type="checkbox"
                                      name="wheelWidth"
                                      value={option.value}
                                      id={option.value}
                                      onChange={handleWheelColor}
                                      checked={colourWheel.includes(String(option.value))}
                                    />
                                    {option.value}
                                    <label htmlFor={option.value} className='custome-checkboxes'></label>
                                  </label>
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      </Collapse>
                    </div>


                    <div className="price-filter">
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenPrice(!openPrice)} aria-expanded={openPrice} aria-controls="price-collapse-text">
                        Price <i className={openPrice ? "fa fa-minus" : "fa fa-plus"} />
                      </h3>
                      {
                        !isLoadingWheels && minPrice !== null && maxPrice !== null ?
                          <Collapse in={openPrice}>
                            <div id='price-collapse-text'>
                              <form >
                                <div className="aside-price-range-form d-flex  m-3" >
                                  <div className="form-field me-2" style={{ width: '80%' }}>
                                    {/* <label htmlFor="filter-price-min">$ Min</label> */}
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="minPrice"
                                      value={"$" + minPrice}
                                      placeholder={"$" + minPrice}
                                      autoComplete="off"
                                      maxLength="10"
                                      style={{ width: '100%' }}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="form-field me-2" style={{ width: '80%' }}>
                                    {/* <label htmlFor="filter-price-max">$ Max</label> */}
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="maxPrice"
                                      value={"$" + maxPrice}
                                      placeholder={"$" + maxPrice}
                                      autoComplete="off"
                                      maxLength="10"
                                      style={{ width: '100%' }}
                                      disabled={true}
                                    />
                                  </div>

                                </div>
                              </form>

                              <div className="">
                                <RangeSlider
                                  min={0}
                                  step={1}
                                  max={5000}
                                  defaultValue={[minPrice, maxPrice]}
                                  onChangeCommitted={handleRanges}
                                  valuelabeldisplay="on"

                                />
                              </div>
                            </div>
                          </Collapse> : <ShimmerButton size="sm" />
                      }
                    </div>
                    <div className="price-filter">
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenStock(!openStock)} aria-expanded={openStock} aria-controls="price-collapse-text">
                        Availability <i className={openStock ? "fa fa-minus" : "fa fa-plus"} />
                      </h3>
                      <Collapse in={openStock}>
                        <div id='stock-collapse-text'>
                          <ul className="p-0 m-0 list-filter " id='brand-collapse-text'>
                            {
                              optionsAvailbility.map((option, index) => (
                                <li key={option.value}>
                                  <label className="label-filter">
                                    <input
                                      type="checkbox"
                                      name="wheelWidth"
                                      value={option.value}
                                      id={option.value}
                                      onChange={handleStockChange}
                                      checked={stockWheel === option.value}
                                    />
                                    {option.label}
                                    <label htmlFor={option.value} className='custome-checkboxes'></label>
                                  </label>
                                </li>
                              ))
                            }
                          </ul>


                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>

                <div className="col-lg-9 filter-for-mobile">
                  <div className='selectedFilters '>
                    {
                      diameterArray && diameterArray.length > 0 ?
                        diameterArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item + '"'} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveDiameter(item)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      widthArray && widthArray.length > 0 ?
                        widthArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item + '"'} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveWidth(item)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      patternArray && patternArray.length > 0 ?
                        patternArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovePattern(item)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      brandNameArray && brandNameArray.length > 0 ?
                        brandNameArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item.id}>
                            <span> {item.name} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      modelArray && modelArray.length > 0 ?
                        modelArray.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveModel(item)}></i>
                          </span>
                        ))
                        : ""
                    }

                    {
                      colourWheel && colourWheel.length > 0 ?
                        colourWheel.map((item, index) => (
                          <span className="span-filter-Checkbox m-1" key={item}>
                            <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveColor(item)}></i>
                          </span>
                        ))
                        : ""
                    }



                    {
                      diameterArray.length > 0 || widthArray.length > 0 || brandNameArray.length > 0 || modelArray.length > 0 || colourWheel.length > 0 ?

                        <span className="clearFilterStyle" onClick={clearAllFilters} >
                          <span>Clear filters</span>
                        </span>

                        :

                        ""
                    }
                  </div>
                  <div className="d-flex filters-sort-by mainDivStaggered">

                    <div className='textShowing'>
                      <div className="">
                        {totalProducts > 0 ?
                          <p className="px-5">
                            {/* Showing {productsPerPage} of {parseInt(totalProducts).toLocaleString('en-US')} products */}
                            Showing {startIndex} - {endIndex} of {parseInt(totalProducts).toLocaleString('en-US')} products

                          </p>
                          : ""
                        }
                      </div>
                      <div className='stockCheckbox'>
                        <ul className='stockCheckboxUl'>
                          <li>
                            <input
                              type="checkbox"
                              value="2"
                              className='tgl tgl-light'
                              onChange={handleChangeStockAvail}
                              id='stockChecki'
                              name="stockCheck"
                              checked={!isCheckedStock} // Set the checked attribute based on isChecked
                            />
                            <label htmlFor='stockChecki' className='tgl-btn'></label>
                          </li>
                          <li className='staggeredLi'>
                            {
                              isCheckedStock ?
                                <span>In Stock Items</span>
                                : <span>All Items</span>
                            }
                          </li>
                        </ul>
                      </div>
                      <div className="">
                        <select className="form-control" name="sorting" id="sortingWithPrice" onChange={handleSorting} value={sortingValue}>
                          <option disabled >Sort By Price</option>
                          <option value="1" >Low-High</option>
                          <option value="2">High-Low</option>
                        </select>

                      </div>
                      <div className="main-headin">
                        <div className="main-filter-resposive">
                          <button className="mobile-filter-btn" onClick={showFiltersClick}> <svg className="me-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.25901 14.35L5.25888 14.35C5.24769 14.3502 5.23405 14.3476 5.21863 14.339L5.21836 14.3389C5.19936 14.3282 5.19074 14.318 5.18703 14.3124C5.17887 14.2972 5.176 14.2839 5.176 14.2707V7.91365C5.176 7.82275 5.1652 7.72056 5.13235 7.61486C5.09945 7.50839 5.04961 7.41561 4.99386 7.33726L0.724519 1.26892C0.724485 1.26887 0.724451 1.26882 0.724418 1.26877C0.676725 1.20083 0.65495 1.13291 0.650688 1.06991C0.64665 1.00345 0.660203 0.933143 0.696549 0.863781L0.696552 0.863774C0.732993 0.794228 0.783357 0.74264 0.84197 0.707465L0.841971 0.707466L0.843135 0.706765C0.902543 0.670943 0.973036 0.65 1.05357 0.65H12.9463C13.0271 0.65 13.0973 0.670932 13.1564 0.706609L13.1578 0.70745C13.2164 0.742594 13.2667 0.794096 13.3032 0.863535C13.3407 0.935416 13.3534 1.00516 13.3492 1.06874C13.345 1.13245 13.3232 1.20066 13.2755 1.26862C13.2755 1.26872 13.2754 1.26882 13.2753 1.26892L9.0045 7.33779L9.00402 7.33848C8.94618 7.42089 8.90098 7.51067 8.86985 7.6068L8.86979 7.60678L8.8672 7.61518C8.83481 7.71999 8.82355 7.82177 8.82355 7.91441V12.1555C8.82355 12.1724 8.81984 12.1838 8.81525 12.1923C8.81075 12.1999 8.8025 12.2101 8.78506 12.2207L8.34805 12.4857L8.34827 12.4864L5.3042 14.3361C5.30409 14.3362 5.30399 14.3362 5.30388 14.3363C5.28736 14.3462 5.27261 14.3497 5.25901 14.35ZM5.27154 14.9999C5.14389 15.0023 5.01635 14.9707 4.90099 14.9061L5.27154 14.9999Z" stroke="#E81C2E" strokeWidth="1.3"></path>
                          </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className='row'>
                    {

                      vehicleType && vehicleType.toLowerCase() != "truck" && isStaggred ?

                        isChecked ?

                          !isLoadingWheels && dataWheels ?

                            dataWheels.data && dataWheels.data.length > 0 ?
                              dataWheels.data.map((itm, idx) => {
                                return (<WheelsItemscardStaggered data={itm} key={"items" + itm.sku + idx} data2={dataWheels.data2[idx]} />);
                              })
                              :
                              <div className='container m-0 text-center'>
                                <h2>No Product Found</h2>
                                <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                              </div>



                            :
                            Array.from({ length: 28 }).map((_, idx) => (
                              <div className="col-lg-3 col-6" key={idx}>
                                <a href="#">
                                </a>
                                <div className="Drivetrain-cart"><a href="">
                                  <div className="engle-imge-upper">
                                    {/* <button className="hover-btn">View Details</button> */}
                                    <ShimmerThumbnail height={163} rounded />
                                  </div>
                                </a>
                                  <div className="cart-body">
                                    <a href="#">
                                      <h6><ShimmerButton size="sm" /></h6>
                                      <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                                    </a>
                                    <div className="price-cart">
                                      <a href="#">
                                        <div className="price">


                                        </div>
                                      </a>
                                      <div className="cart-btn">
                                        <a href="#">
                                        </a>
                                        <a href="#">

                                          {/* <img src={data.data.brand_logo} alt="" /> */}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            ))
                          :

                          !isLoadingWheels && dataWheels ?

                            dataWheels.data && dataWheels.data.length > 0 ?
                              dataWheels.data.map((itm, idx) => {
                                return (<WheelsItemscardNonStaggered data={itm} key={"items" + itm.sku + idx} />);
                              })
                              :

                              <div className='container m-0 text-center'>
                                <h2>No Product Found</h2>
                                <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                <p ><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                              </div>



                            :
                            Array.from({ length: 28 }).map((_, idx) => (
                              <div className="col-lg-3 col-6" key={idx}>
                                <a href="#">
                                </a>
                                <div className="Drivetrain-cart"><a href="">
                                  <div className="engle-imge-upper">
                                    {/* <button className="hover-btn">View Details</button> */}
                                    <ShimmerThumbnail height={163} rounded />
                                  </div>
                                </a>
                                  <div className="cart-body">
                                    <a href="#">
                                      <h6><ShimmerButton size="sm" /></h6>
                                      <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                                    </a>
                                    <div className="price-cart">
                                      <a href="#">
                                        <div className="price">


                                        </div>
                                      </a>
                                      <div className="cart-btn">
                                        <a href="#">
                                        </a>
                                        <a href="#">

                                          {/* <img src={data.data.brand_logo} alt="" /> */}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            ))




                        :

                        !isLoadingWheels && dataWheels ?

                          dataWheels.data && dataWheels.data.length > 0 ?
                            dataWheels.data.map((itm, idx) => {
                              return (<WheelsItemscard data={itm} key={"items" + itm.sku + idx} />);
                            })
                            :
                            <div className='container m-0 text-center'>
                              <h2>No Product Found</h2>
                              <p>Sorry, we couldn't find any products matching your search criteria.</p>
                              <p ><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                            </div>



                          :
                          Array.from({ length: 28 }).map((_, idx) => (
                            <div className="col-lg-3 col-6" key={idx}>
                              <a href="#">
                              </a>
                              <div className="Drivetrain-cart"><a href="">
                                <div className="engle-imge-upper">
                                  {/* <button className="hover-btn">View Details</button> */}
                                  <ShimmerThumbnail height={163} rounded />
                                </div>
                              </a>
                                <div className="cart-body">
                                  <a href="#">
                                    <h6><ShimmerButton size="sm" /></h6>
                                    <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                                  </a>
                                  <div className="price-cart">
                                    <a href="#">
                                      <div className="price">


                                      </div>
                                    </a>
                                    <div className="cart-btn">
                                      <a href="#">
                                      </a>
                                      <a href="#">

                                        {/* <img src={data.data.brand_logo} alt="" /> */}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          ))

                    }


                  </div>
                  {totalPages > 0 &&
                    <div className="d-flex mt-4 justify-content-center">
                      <Pagination>
                        <Pagination.First
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(1)}
                        />
                        <Pagination.Prev
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        />


                        {getPageNumbers().map((page) => (
                          <Pagination.Item
                            key={page}
                            active={currentPage === page}
                            onClick={() => {
                              if (currentPage !== page) {
                                handlePageChange(page);
                              }
                            }}
                          >
                            {page}
                          </Pagination.Item>
                        ))}

                        <Pagination.Next
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(currentPage + 1)}
                        />
                        <Pagination.Last
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(totalPages)}
                        />
                      </Pagination>
                    </div>
                  }

                  <div className='text-light'>{fmkID}</div>
                </div>

              </div>
            </div>
          </div>
        </section >
      </>


      <FOOTER />


    </>
  )
}
