import { React, useState, useEffect } from 'react';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import SERVICES from "../../components/Footer/services";
import { Helmet } from 'react-helmet';




export default function Aboutus() {
    const [isHidden, setIsHidden] = useState(false);
    const [cartItems, setCartItems] = useState("");
    const [showMenuSide, setShowMenuSide] = useState("");

    const currentLink = window.location.href;
    const url = new URL(currentLink);
    const updatedUrl = url.toString();
    

    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);

    }, []);


    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    return (
        <>

            <Helmet>
                <title>Blog</title>
                <meta name="description" content='Getting The Best Tonneau Cover For Your Pickup Truck ' />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>

            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
            <section>
                <div className="warrent-banner blog">
                    <div className="parformane-bar ">
                        <div className="container">
                            <ul className="p-0 m-0 listing-parmofamne">
                                <li>
                                    <a href="#">Home /</a>
                                </li>
                                <li className="active-bread">
                                    <a href="">Blogs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <h2 className="text-center txt-main-listing">Blogs</h2>
                </div>
            </section>

            <section>
                <div className='blogs_main'>
                    <div className='container'>
                        <div className='blog_row'>
                            <div className='blo_col'>
                                <div className='blog_box'>
                                    <div className='blog_img'>
                                        <a href='/blog-details'><img src="images/blog_img1.jpg" alt="blog-image" height="100%" width="100%" /></a>
                                    </div>
                                    <div className='blog_content'>
                                        <h2><a href='/blog-details'>Finding Custom Tonneau Cover Options To Match Your Trucks’ Wheels & Rims</a></h2>
                                        <span className='date'>May 31, 2022</span>
                                        <p className='content'>Getting The Best Tonneau Cover For Your Pickup Truck Are you ready to purchase a tonneau cover but unsure about the size of the truck bed? Don’t worry, there’s help. It’s not difficult to know that if you have a measuring tape. Experts in tonneau covers in Arizona </p>
                                        <a href='/blog-details' className='readmore'>read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className='blo_col'>
                                <div className='blog_box'>
                                    <div className='blog_img'>
                                        <a href='/blog-details'><img src="images/blog_img1.jpg" alt="blog-image" height="100%" width="100%" /></a>
                                    </div>
                                    <div className='blog_content'>
                                        <h2><a href='/blog-details'>Finding Custom Tonneau Cover Options To Match Your Trucks’ Wheels & Rims</a></h2>
                                        <span className='date'>May 31, 2022</span>
                                        <p className='content'>Getting The Best Tonneau Cover For Your Pickup Truck Are you ready to purchase a tonneau cover but unsure about the size of the truck bed? Don’t worry, there’s help. It’s not difficult to know that if you have a measuring tape. Experts in tonneau covers in Arizona </p>
                                        <a href='/blog-details' className='readmore'>read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className='blo_col'>
                                <div className='blog_box'>
                                    <div className='blog_img'>
                                        <a href='/blog-details'><img src="images/blog_img1.jpg" alt="blog-image" height="100%" width="100%" /></a>
                                    </div>
                                    <div className='blog_content'>
                                        <h2><a href='/blog-details'>Finding Custom Tonneau Cover Options To Match Your Trucks’ Wheels & Rims</a></h2>
                                        <span className='date'>May 31, 2022</span>
                                        <p className='content'>Getting The Best Tonneau Cover For Your Pickup Truck Are you ready to purchase a tonneau cover but unsure about the size of the truck bed? Don’t worry, there’s help. It’s not difficult to know that if you have a measuring tape. Experts in tonneau covers in Arizona </p>
                                        <a href='/blog-details' className='readmore'>read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className='blo_col'>
                                <div className='blog_box'>
                                    <div className='blog_img'>
                                        <a href='/blog-details'><img src="images/blog_img1.jpg" alt="blog-image" height="100%" width="100%" /></a>
                                    </div>
                                    <div className='blog_content'>
                                        <h2><a href='/blog-details'>Finding Custom Tonneau Cover Options To Match Your Trucks’ Wheels & Rims</a></h2>
                                        <span className='date'>May 31, 2022</span>
                                        <p className='content'>Getting The Best Tonneau Cover For Your Pickup Truck Are you ready to purchase a tonneau cover but unsure about the size of the truck bed? Don’t worry, there’s help. It’s not difficult to know that if you have a measuring tape. Experts in tonneau covers in Arizona </p>
                                        <a href='/blog-details' className='readmore'>read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className='blo_col'>
                                <div className='blog_box'>
                                    <div className='blog_img'>
                                        <a href='/blog-details'><img src="images/blog_img1.jpg" alt="blog-image" height="100%" width="100%" /></a>
                                    </div>
                                    <div className='blog_content'>
                                        <h2><a href='/blog-details'>Finding Custom Tonneau Cover Options To Match Your Trucks’ Wheels & Rims</a></h2>
                                        <span className='date'>May 31, 2022</span>
                                        <p className='content'>Getting The Best Tonneau Cover For Your Pickup Truck Are you ready to purchase a tonneau cover but unsure about the size of the truck bed? Don’t worry, there’s help. It’s not difficult to know that if you have a measuring tape. Experts in tonneau covers in Arizona </p>
                                        <a href='/blog-details' className='readmore'>read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className='blo_col'>
                                <div className='blog_box'>
                                    <div className='blog_img'>
                                        <a href='/blog-details'><img src="images/blog_img1.jpg" alt="blog-image" height="100%" width="100%" /></a>
                                    </div>
                                    <div className='blog_content'>
                                        <h2><a href='/blog-details'>Finding Custom Tonneau Cover Options To Match Your Trucks’ Wheels & Rims</a></h2>
                                        <span className='date'>May 31, 2022</span>
                                        <p className='content'>Getting The Best Tonneau Cover For Your Pickup Truck Are you ready to purchase a tonneau cover but unsure about the size of the truck bed? Don’t worry, there’s help. It’s not difficult to know that if you have a measuring tape. Experts in tonneau covers in Arizona </p>
                                        <a href='/blog-details' className='readmore'>read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <SERVICES />
            < FOOTER />
        </>

    )
}
